import React from 'react';
import CanvasJSReact from '../libs/canvasjs/canvasjs.react';

function BarPoint(value, label, index, color=null) {
  return {
    value,
    label,
    index,
    color
  }
}

export {BarPoint};

function getPointData(value, name, index=null, color=null) {
  const point = {y: value, label: name};

  if(index) {
    point.indexContent = index;
  }

  if(color) {
    point.color = color;
  }

  return point;
}


class SimpleBarGraph extends React.Component {
  getData() {
    const data = [
      {
        type: "column",
        color: this.props.barColor,
        name: this.props.name,
        showInLegend: false,
        indexLabel: "{indexContent}",
        indexLabelFontWeight: "bold",
        indexLabelFontColor: "#333",
        // yValueFormatString: "R$ #,##0.00",
        dataPoints: this.props.data.map((point) => getPointData(point.value, point.label, point.index, point.color))
      }
    ];

    return data;
  }

  getOptions() {
    const options = {
			animationEnabled: true,
			// colorSet: "colorSet2",
      culture: 'pt-BR',
			toolTip: {
				shared: true,
        contentFormatter: (e) => {
  				let content = "";

  				for (let i = 0; i < e.entries.length; i++) {
            if(i === 0) {
              content += `${e.entries[i].dataPoint.label}<br/>`;
            }

  					content += `<span style="color: ${e.entries[i].dataSeries.color};">${e.entries[i].dataSeries.name}:</span> ${this.props.labelFormatter(e.entries[i].dataPoint.y)}<br/>`;
  				}

  				return content;
  			}
			},
      axisY: {
				labelFormatter: (entry) => this.props.labelFormatter(entry.value)
			},
      axisX: {
				labelFormatter: (entry) => entry.label ? entry.label.split(' ')[0] : entry.label
			},
			data: this.getData()
		};

    if(this.props.title) {
      options.title = {
				text: this.props.title
			};
    }

    if(this.props.height) {
      options.height = this.props.height;
    }

    if(this.props.stripLine) {
      options.axisY.stripLines = [this.props.stripLine];
    }

    if(this.props.xLabelAngle) {
      options.axisX.labelAngle = this.props.xLabelAngle;
    }

    return options;
  }

  toggleDataSeries(e){
		if (typeof(e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
			e.dataSeries.visible = false;
		}
		else{
			e.dataSeries.visible = true;
		}
		this.chart.render();
	}

	render() {
		return (
		<div className={this.props.className}>
			<CanvasJSReact.CanvasJSChart
        options={this.getOptions()}
				onRef={ref => this.chart = ref}
			/>
		</div>
		);
	}
}

export default SimpleBarGraph;
