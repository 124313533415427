import React from 'react';
import { PoseGroup } from 'react-pose';
import {FadeContainer} from '../utils/pose_containers';
import PreLoader from '../utils/preloader';
import * as routes from '../constants';
import {SUPPORT_CATEGORY_FP_DIET_ID,
        SUPPORT_CATEGORY_FP_GENERAL_ID,
        SUPPORT_CATEGORY_PT_PERIOD_ID,
        SUPPORT_CATEGORY_PT_GENERAL_ID,
        DEFAULT_UNKNOWN_ERROR_MESSAGE,
        SUPPORT_MAIN_SECTIONS} from '../constants';
import DefaultMenuButton from '../components/default_menu_button';
import DefaultMenuLayout from '../components/default_menu_layout';
import ConfirmationWindow from '../components/confirmation_window';
import {getModel, postModel, setUrlParameters} from '../utils/functions';
import AccordionSection from '../components/accordion_section';
import {HorizontalRule} from '../utils/default_section';
import DefaultInput from '../utils/default_input';
import './register_support_ticket.scss';


class RegisterSupportTicket extends React.Component {
  #TOPIC_MAP = {
    'nutritionist': 'Dúvida com nutricionista (dieta, substituição de alimento, etc)',
    'training_period': 'Dúvida sobre periodização (treinos, cronograma, etc)',
    'technical': 'Dúvida sobre uso do aplicativo ou serviço em geral'
  };
  #SUBJECT_MAP = {
    'diet': 'Dieta prescrita',
    'replacement': 'Substituição de alimento',
    'app': 'Uso do aplicativo',
    'evaluation': 'Avaliação física/nutricional',
    'physical_evaluation': 'Avaliação física',
    'followup': 'Acompanhamento',
    'training_execution': 'Execução dos treinos / Cronograma',
    'training_period_update': 'Alteração de periodização',
    'other': 'Outros'
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      active_units: [],
      selectedUnit: null,
      topicSelected: null,
      subjectSelected: null,
      topicSectionVisible: false,
      subjectSectionVisible: false,
      request_message: '',
      onSendSupportRequest: false,
      ticketCreated: null,
      confirmInProgress: false,
      confirmFailed: false,
      confirmFailDescription: "",
      criticalErrorMessage: null,
      screenWidth: window.innerWidth,
    };
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  async getActiveUnits() {
    const parameters = {};

    switch (this.props.mainSection) {
      case SUPPORT_MAIN_SECTIONS[0]:
        parameters.for_nutritional_service = true;
        break;

      case SUPPORT_MAIN_SECTIONS[1]:
      case SUPPORT_MAIN_SECTIONS[2]:
      default:
        break;
    }

    return await getModel(setUrlParameters(routes.ACTIVE_UNITS_GET_API, parameters));
  }

  async componentDidMount() {
    const update = {loading: false};

    try {
      let active_units = await this.getActiveUnits();

      if(active_units) {
        update.active_units = active_units;

        if(update.active_units.length === 1) {
          if(update.active_units[0].registration_incomplete) {
            update.criticalErrorMessage = (
              <span>

                Por favor, finalize o procedimento de cadastro de aluno para liberar esta funcionalidade clicando
                <a
                  className="register-support-ticket__external-text-link"
                  href={update.active_units[0].registration_link}
                  target="_blank"
                  rel="noopener noreferrer"
                >

                    aqui

                </a>

              </span>
            );
          }
          else {
            update.selectedUnit = update.active_units[0];
          }
        }
      }
    }
    catch(errors) {
      update.criticalErrorMessage = DEFAULT_UNKNOWN_ERROR_MESSAGE;
      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            case 208:
              if(error.message.startsWith('User registration is incomplete.')) {
                update.criticalErrorMessage = 'Por favor, finalize o procedimento de cadastro de aluno para liberar esta funcionalidade.';
              }

              break;
            case 209:
              update.criticalErrorMessage = 'Sessão do usuário expirada.';

              break;
            default:
          }
        }
      }
    }

    this.setState(update);

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  onSelectUnit(unit) {
    if(unit.registration_incomplete) {
      this.setState({
        criticalErrorMessage: (
          <span>

            Por favor, finalize o procedimento de cadastro de aluno para liberar esta funcionalidade clicando
            <a
              className="register-support-ticket__external-text-link"
              href={unit.registration_link}
              target="_blank"
              rel="noopener noreferrer"
            >

                aqui

            </a>

          </span>
        )
      });
    }
    else {
      this.setState({
        selectedUnit: unit
      });
    }
  }

  getUnitOptions() {
    return this.state.active_units.map((unit, index) => (
      <DefaultMenuButton
        key={`personal_record:unit_selector:${unit.id}`}
        className="register-support-ticket__unit-button"
        onClick={() => this.onSelectUnit(unit)}
        text={unit.name}
        color="blue"
      />
    ));
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  getConfirmationWindowTitle() {
    if(this.state.confirmFailed) {
      if(this.state.onSendSupportRequest) {
        return 'Falha ao regtistrar dúvida';
      }
    }
    else if(this.state.confirmInProgress) {
      if(this.state.onSendSupportRequest) {
        return 'Registrando dúvida';
      }
    }
    else {
      if(this.state.onSendSupportRequest) {
        return 'Registrar dúvida';
      }
      if(this.state.ticketCreated !== null) {
        return 'Dúvida registrada';
      }
    }

    return 'Não implementado';
  }

  getConfirmationWindowDescription() {
    if(this.state.confirmFailed) {
      return this.state.confirmFailDescription;
    }
    else if(this.state.onSendSupportRequest) {
      return 'Gostaria de confirmar o envio de sua dúvida para nossa equipe?';
    }
    else if(this.state.ticketCreated !== null) {
      return 'Sua dúvida foi registrada com sucesso! Dentro destes próximos ' +
             'dias, retorne para a tela de suporte do aplicativo para conferir ' +
             'se já respondemos à sua requisição. Obrigado!';
    }

    return 'Não implementado';
  }

  getConfirmationWindowConfirmButtonText() {
    if(this.state.onSendSupportRequest) {
      return 'Confirmar';
    }
    if(this.state.ticketCreated !== null) {
      return 'Ir para tela de suporte';
    }

    return 'Não implementado';
  }

  confirmationWindowIsVisible() {
    return this.state.onSendSupportRequest || this.state.ticketCreated !== null;
  }

  resetConfirmationWindow() {
    this.setState({
      onSendSupportRequest: false,
      confirmFailed: false,
      confirmInProgress: false,
    });
  }

  async proceedConfirmationWindow() {
    if(this.state.onSendSupportRequest) {
      this.setState({
        confirmInProgress: true
      });

      let category_id;

      switch (this.props.mainSection) {
        case SUPPORT_MAIN_SECTIONS[0]:
          category_id = this.state.topicSelected === 'nutritionist' ? SUPPORT_CATEGORY_FP_DIET_ID : SUPPORT_CATEGORY_FP_GENERAL_ID
          break;

        case SUPPORT_MAIN_SECTIONS[1]:
          category_id = this.state.topicSelected === 'training_period' ? SUPPORT_CATEGORY_PT_PERIOD_ID : SUPPORT_CATEGORY_PT_GENERAL_ID
          break;

        // case SUPPORT_MAIN_SECTIONS[2]:
        //   parameters.general_topics_only = true;
        //   break;

        default:
          break;
      }

      const data = {
        subject: this.#SUBJECT_MAP[this.state.subjectSelected],
        description: this.state.request_message,
        unit_id: this.state.selectedUnit.id,
        category_id
      };

      try{
        const response = await postModel(routes.SUPPORT_TICKET_POST_API, data, true);

        if(response) {
          this.setState({
            ticketCreated: response.support_ticket,
            onSendSupportRequest: false,
            confirmFailed: false,
            confirmInProgress: false,
          });
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE + '.';

        if(errors instanceof Array) {
          for(let error of errors) {
            switch (error.code) {
              // case 104:
              //   for(let parameter of error.parameters) {
              //     switch (parameter.name) {
              //       case 'contracts':
              //         errorDescription = 'Serviço vinculado à um contrato de aluno. Estes contratos devem ser excluídos antes de excluir este serviço.';
              //
              //         break;
              //       default:
              //     }
              //   }
              //
              //   break;
              case 208:
                if (error.message.startsWith('No active contract')) {
                  errorDescription = 'Não foi encontrado nenhum contrato de acompanhamento nutricional ativo no momento.';
                }

                break;
              case 209:
                errorDescription = 'Sessão do usuário expirada.';

                break;
              default:
            }
          }
        }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }
    else if (this.state.ticketCreated !== null) {
      const parameters = {support_ticket_id: this.state.ticketCreated.id};

      let supportListPath = routes.MAIN_PATH;

      switch (this.props.mainSection) {
        case SUPPORT_MAIN_SECTIONS[0]:
          supportListPath = routes.NUTRITIONAL_SUPPORT_PATH;
          break;

        case SUPPORT_MAIN_SECTIONS[1]:
          supportListPath = routes.PERSONAL_TRAINING_SUPPORT_PATH;
          break;

        case SUPPORT_MAIN_SECTIONS[2]:
          break;

        default:
          break;
      }

      this.props.history.replace(setUrlParameters(supportListPath, parameters));
    }

    return;
  }

  getContentOptions() {
    if(this.state.criticalErrorMessage !== null) {
      return (
        <div className="register-support-ticket__warning-message">

          <i className="fas fa-exclamation register-support-ticket__warning-message__icon"></i>
          <p className="register-support-ticket__warning-message__text">{this.state.criticalErrorMessage}</p>

        </div>
      );
    }
    else if(this.state.selectedUnit === null) {
      if(this.state.active_units.length <= 0) {
        return (
          <div className="register-support-ticket__warning-message">

            <i className="fas fa-exclamation register-support-ticket__warning-message__icon"></i>
            <p className="register-support-ticket__warning-message__text">Nenhum serviço de acompanhamento nutricional ativo no momento.</p>

          </div>
        );
      }
      else {
        return (
          <React.Fragment>

            <h1 className="register-support-ticket__instructions">

              Selecione a unidade desejada:

            </h1>

            <div className="register-support-ticket__unit-selector">

              {this.getUnitOptions()}

            </div>

          </React.Fragment>
        );
      }
    }
    else {
      let topicOptions = null;

      switch (this.props.mainSection) {
        case SUPPORT_MAIN_SECTIONS[0]:
          topicOptions = (
            <ul className="register-support-ticket__option-list">

              <li className="register-support-ticket__option-list__item">

                <DefaultMenuButton
                  className="register-support-ticket__option-list__item__button"
                  onClick={() => this.setState({
                    topicSelected: 'nutritionist',
                    subjectSelected: null,
                    topicSectionVisible: false
                  })}
                  text={this.#TOPIC_MAP['nutritionist']}
                  color="green"
                />

              </li>

              <li className="register-support-ticket__option-list__item">

                <DefaultMenuButton
                  className="register-support-ticket__option-list__item__button"
                  onClick={() => this.setState({
                    topicSelected: 'technical',
                    subjectSelected: null,
                    topicSectionVisible: false
                  })}
                  text={this.#TOPIC_MAP['technical']}
                  color="red"
                />

              </li>

            </ul>
          );
          break;

        case SUPPORT_MAIN_SECTIONS[1]:
          topicOptions = (
            <ul className="register-support-ticket__option-list">

              <li className="register-support-ticket__option-list__item">

                <DefaultMenuButton
                  className="register-support-ticket__option-list__item__button"
                  onClick={() => this.setState({
                    topicSelected: 'training_period',
                    subjectSelected: null,
                    topicSectionVisible: false
                  })}
                  text={this.#TOPIC_MAP['training_period']}
                  color="green"
                />

              </li>

              <li className="register-support-ticket__option-list__item">

                <DefaultMenuButton
                  className="register-support-ticket__option-list__item__button"
                  onClick={() => this.setState({
                    topicSelected: 'technical',
                    subjectSelected: null,
                    topicSectionVisible: false
                  })}
                  text={this.#TOPIC_MAP['technical']}
                  color="red"
                />

              </li>

            </ul>
          );
          break;

        case SUPPORT_MAIN_SECTIONS[2]:
          break;

        default:
          break;
      }

      if (this.state.topicSelected === null) {
        return (
          <div className="register-support-ticket__wrapper">

            <h1 className="register-support-ticket__instructions">

              Qual o <span className="register-support-ticket__instructions__red-text">tópico</span> que você precisa de ajuda?

            </h1>

            {topicOptions}

          </div>
        );
      }

      let subjectOptions;

      switch (this.props.mainSection) {
        case SUPPORT_MAIN_SECTIONS[0]:
          switch (this.state.topicSelected) {
            case 'nutritionist':
              subjectOptions = (
                <ul className="register-support-ticket__option-list">

                  <li className="register-support-ticket__option-list__item">

                    <DefaultMenuButton
                      className="register-support-ticket__option-list__item__button"
                      onClick={() => this.setState({subjectSelected: 'diet'})}
                      text={this.#SUBJECT_MAP['diet']}
                      color="purple"
                    />

                  </li>

                  <li className="register-support-ticket__option-list__item">

                    <DefaultMenuButton
                      className="register-support-ticket__option-list__item__button"
                      onClick={() => this.setState({subjectSelected: 'replacement'})}
                      text={this.#SUBJECT_MAP['replacement']}
                      color="purple"
                    />

                  </li>

                  <li className="register-support-ticket__option-list__item">

                    <DefaultMenuButton
                      className="register-support-ticket__option-list__item__button"
                      onClick={() => this.setState({subjectSelected: 'other'})}
                      text={this.#SUBJECT_MAP['other']}
                      color="purple"
                    />

                  </li>

                </ul>
              );

              break;
            case 'technical':
              subjectOptions = (
                <ul className="register-support-ticket__option-list">

                  <li className="register-support-ticket__option-list__item">

                    <DefaultMenuButton
                      className="register-support-ticket__option-list__item__button"
                      onClick={() => this.setState({subjectSelected: 'app'})}
                      text={this.#SUBJECT_MAP['app']}
                      color="purple"
                    />

                  </li>

                  <li className="register-support-ticket__option-list__item">

                    <DefaultMenuButton
                      className="register-support-ticket__option-list__item__button"
                      onClick={() => this.setState({subjectSelected: 'evaluation'})}
                      text={this.#SUBJECT_MAP['evaluation']}
                      color="purple"
                    />

                  </li>

                  <li className="register-support-ticket__option-list__item">

                    <DefaultMenuButton
                      className="register-support-ticket__option-list__item__button"
                      onClick={() => this.setState({subjectSelected: 'followup'})}
                      text={this.#SUBJECT_MAP['followup']}
                      color="purple"
                    />

                  </li>

                  <li className="register-support-ticket__option-list__item">

                    <DefaultMenuButton
                      className="register-support-ticket__option-list__item__button"
                      onClick={() => this.setState({subjectSelected: 'other'})}
                      text={this.#SUBJECT_MAP['other']}
                      color="purple"
                    />

                  </li>

                </ul>
              );

              break;
            default:
              subjectOptions = (
                <h1 className="register-support-ticket__instructions">

                  TOPIC NOT IMPLEMENTED

                </h1>
              );
              break;
          }
          break;

        case SUPPORT_MAIN_SECTIONS[1]:
          switch (this.state.topicSelected) {
            case 'training_period':
              subjectOptions = (
                <ul className="register-support-ticket__option-list">

                  <li className="register-support-ticket__option-list__item">

                    <DefaultMenuButton
                      className="register-support-ticket__option-list__item__button"
                      onClick={() => this.setState({subjectSelected: 'training_execution'})}
                      text={this.#SUBJECT_MAP['training_execution']}
                      color="purple"
                    />

                  </li>

                  <li className="register-support-ticket__option-list__item">

                    <DefaultMenuButton
                      className="register-support-ticket__option-list__item__button"
                      onClick={() => this.setState({subjectSelected: 'training_period_update'})}
                      text={this.#SUBJECT_MAP['training_period_update']}
                      color="purple"
                    />

                  </li>

                  <li className="register-support-ticket__option-list__item">

                    <DefaultMenuButton
                      className="register-support-ticket__option-list__item__button"
                      onClick={() => this.setState({subjectSelected: 'other'})}
                      text={this.#SUBJECT_MAP['other']}
                      color="purple"
                    />

                  </li>

                </ul>
              );

              break;
            case 'technical':
              subjectOptions = (
                <ul className="register-support-ticket__option-list">

                  <li className="register-support-ticket__option-list__item">

                    <DefaultMenuButton
                      className="register-support-ticket__option-list__item__button"
                      onClick={() => this.setState({subjectSelected: 'app'})}
                      text={this.#SUBJECT_MAP['app']}
                      color="purple"
                    />

                  </li>

                  <li className="register-support-ticket__option-list__item">

                    <DefaultMenuButton
                      className="register-support-ticket__option-list__item__button"
                      onClick={() => this.setState({subjectSelected: 'physical_evaluation'})}
                      text={this.#SUBJECT_MAP['physical_evaluation']}
                      color="purple"
                    />

                  </li>

                  <li className="register-support-ticket__option-list__item">

                    <DefaultMenuButton
                      className="register-support-ticket__option-list__item__button"
                      onClick={() => this.setState({subjectSelected: 'other'})}
                      text={this.#SUBJECT_MAP['other']}
                      color="purple"
                    />

                  </li>

                </ul>
              );

              break;
            default:
              subjectOptions = (
                <h1 className="register-support-ticket__instructions">

                  TOPIC NOT IMPLEMENTED

                </h1>
              );
              break;
          }
          break;

        case SUPPORT_MAIN_SECTIONS[2]:
          break;

        default:
          break;
      }

      if (this.state.subjectSelected === null) {
        return (
          <div className="register-support-ticket__wrapper">

            <AccordionSection
              className="register-support-ticket__previous-selection"
              title={`TÓPICO: ${this.#TOPIC_MAP[this.state.topicSelected]}`}
              onToggleVisibility={(visible) => this.setState({topicSectionVisible: visible})}
              visible={this.state.topicSectionVisible}
            >

              {topicOptions}

            </AccordionSection>

            <HorizontalRule color="black" />

            <h1 className="register-support-ticket__instructions">

              Selecione o <span className="register-support-ticket__instructions__red-text">assunto</span> que mais se aproxima de sua dúvida:

            </h1>

            {subjectOptions}

          </div>
        );
      }

      return (
        <div className="register-support-ticket__wrapper">

          <AccordionSection
            className="register-support-ticket__previous-selection"
            title={`TÓPICO: ${this.#TOPIC_MAP[this.state.topicSelected]}`}
            onToggleVisibility={(visible) => this.setState({topicSectionVisible: visible})}
            visible={this.state.topicSectionVisible}
          >

            {topicOptions}

          </AccordionSection>

          <AccordionSection
            className="register-support-ticket__previous-selection"
            title={`ASSUNTO: ${this.#SUBJECT_MAP[this.state.subjectSelected]}`}
            onToggleVisibility={(visible) => this.setState({subjectSectionVisible: visible})}
            visible={this.state.subjectSectionVisible}
          >

            {subjectOptions}

          </AccordionSection>

          <HorizontalRule color="black" />

          <h1 className="register-support-ticket__instructions">

            <span className="register-support-ticket__instructions__red-text">Descreva</span> sua dúvida abaixo:

          </h1>

          <DefaultInput
            className="register-support-ticket__text-area-input"
            name="request_message"
            type="textarea"
            placeholder="Digite aqui sua pergunta"
            isHorizontal={true}
            rows="4"
            handleInputChange={(event) => this.handleInputChange(event)}
            value={this.state.request_message || ''}
          />

          <div className="register-support-ticket__send-button__wrapper">

            <DefaultMenuButton
              className="register-support-ticket__send-button"
              onClick={() => this.setState({onSendSupportRequest: true})}
              text="Enviar"
              color="green"
              disabled={!this.state.request_message || this.state.request_message.length < 10}
            />

          </div>

        </div>
      );
    }
  }

  render() {
    if(this.state.loading) {
      return (
        <PoseGroup>
          <FadeContainer key="preloader">
            <PreLoader />
          </FadeContainer>
        </PoseGroup>
      );
    }

    return (
      <DefaultMenuLayout
        showBackButton={true}
        history={this.props.history}
        onLogout={() => this.props.onLogout()}
        username={this.props.username}
      >

        <div className="register-support-ticket">

          {this.getContentOptions()}

        </div>

        <ConfirmationWindow
          title={this.getConfirmationWindowTitle()}
          description={this.getConfirmationWindowDescription()}
          confirmText={this.getConfirmationWindowConfirmButtonText()}
          cancelText={this.state.confirmFailed ? 'Ok' : 'Cancelar'}
          visible={this.confirmationWindowIsVisible()}
          onCancel={() => this.resetConfirmationWindow()}
          onConfirm={() => this.proceedConfirmationWindow()}
          loading={this.state.confirmInProgress}
          useSuccessIcon={this.state.ticketCreated !== null}
          useErrorIcon={this.state.confirmFailed}
          hideConfirmButton={this.state.confirmFailed}
          hideCancelButton={this.state.ticketCreated !== null}
        />

      </DefaultMenuLayout>
    );
  }
}

export default RegisterSupportTicket;
