import React from 'react';
import { PoseGroup } from 'react-pose';
import './training_day_report.scss';
import {FadeContainer} from '../../utils/pose_containers';
import PreLoader from '../../utils/preloader';
import * as routes from '../../constants';
import {SERVICE_PILATES,
        SERVICE_GYM,
        SERVICE_BIKE} from '../../constants';
import DefaultMenuLayout from '../../components/default_menu_layout';
import {getModels, getAsLocalDate} from '../../utils/functions';
import SimpleBarGraph, {BarPoint} from '../../graphs/simple_bar_graph';

class TrainingDayReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      trainings: null,
      screenWidth: window.innerWidth,
    };
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  async getTrainings() {
    return await getModels(`${routes.DEFAULT_TRAININGS_GET_API}${this.props.match.params.trainingDayId}`);
  }

  async componentDidMount() {
    const update = {loading: false};

    let data = this.getTrainings();

    data = await data;

    if(data) {
      update.trainings = data;
    }

    this.setState(update);

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  getReportData() {
    const timeData = [];
    const repsData = [];
    const pseData = [];
    const weightData = [];

    let hasTimeData = false;
    let hasRepsData = false;
    let hasWeightData = false;

    let targetService = null;

    for(let entry of this.state.trainings) {
      const total_seconds = entry.total_seconds || 0;
      const total_repetitions = entry.total_repetitions || 0;
      const total_weight = entry.total_weight || 0;
      const pse = entry.pse || 0;
      const difficulty = entry.difficulty || 'Não especificado';

      if(targetService === null) {
        targetService = entry.training_class.target_service;
      }

      if(!hasTimeData && total_seconds > 0) {
        hasTimeData = true;
      }
      if(!hasRepsData && total_repetitions > 0) {
        hasRepsData = true;
      }
      if(!hasWeightData && total_weight > 0) {
        hasWeightData = true;
      }

      const index = `${difficulty} - Fase ${entry.training_class.repetition_index}`;

      timeData.push(BarPoint(total_seconds, getAsLocalDate(entry.training_class.started_at).toLocaleDateString(), `${this.getTime(total_seconds)} - ${index}`));
      repsData.push(BarPoint(total_repetitions, getAsLocalDate(entry.training_class.started_at).toLocaleDateString(), `${total_repetitions} - ${index}`));
      weightData.push(BarPoint(total_weight, getAsLocalDate(entry.training_class.started_at).toLocaleDateString(), `${total_weight} - ${index}`));
      pseData.push(BarPoint(pse, getAsLocalDate(entry.training_class.started_at).toLocaleDateString(), `${pse} - ${index}`));
    }

    switch (targetService) {
      case SERVICE_PILATES:
      case SERVICE_BIKE:
        hasTimeData = false;
        hasRepsData = false;
        hasWeightData = false;
        break;
      case SERVICE_GYM:
        hasTimeData = false;
        hasRepsData = false;
        break;
      default:
    }

    return {
      timeData,
      repsData,
      pseData,
      weightData,
      hasTimeData,
      hasRepsData,
      hasWeightData,
    };
  }

  getDefaultGraphHeight() {
    if(this.state.screenWidth <= 420) {
      return 220;
    }

    if(this.state.screenWidth <= 600) {
      return 270;
    }

    if(this.state.screenWidth <= 1100) {
      return 350;
    }

    return null;
  }

  getTime(value) {
    const formater = new Intl.NumberFormat('en-US', { minimumIntegerDigits: 2 });

    const minutes = Math.floor(value / 60);
    const seconds = value % 60;

    return `${formater.format(minutes)}:${formater.format(seconds)}`;
  }

  render() {
    if(this.state.loading) {
      return (
        <PoseGroup>
          <FadeContainer key="preloader">
            <PreLoader />
          </FadeContainer>
        </PoseGroup>
      );
    }

    const reportData = this.getReportData();

    return (
      <DefaultMenuLayout
        showBackButton={true}
        history={this.props.history}
        onLogout={() => this.props.onLogout()}
        username={this.props.username}
      >

        <div className="training-day-report">

          <section className="training-day-report__data">

            <header className="training-day-report__data__header">

            <h3 className="training-day-report__data__header__title">
              <i className="fas fa-chart-bar"></i>
              {' '}Comparativo de treino
            </h3>

            </header>

            <div className="training-day-report__data__content">

              {reportData.hasWeightData &&
                <React.Fragment>

                  <h4 className="training-day-report__graph-section">

                    <i className="fas fa-dumbbell"></i>
                    <span className="training-day-report__graph-section__text">Kilagem total média</span>

                  </h4>

                  <SimpleBarGraph
                    className="training-day-report__graph"
                    name="Kilagem total média"
                    data={reportData.weightData}
                    height={this.getDefaultGraphHeight()}
                    barColor="#62b94d"
                    labelFormatter={(value) => value}
                  />

                </React.Fragment>
              }

              {reportData.hasTimeData &&
                <React.Fragment>

                  <h4 className="training-day-report__graph-section">

                    <i className="fas fa-stopwatch"></i>
                    <span className="training-day-report__graph-section__text">Tempo total</span>

                  </h4>

                  <SimpleBarGraph
                    className="training-day-report__graph"
                    name="Tempo total"
                    data={reportData.timeData}
                    height={this.getDefaultGraphHeight()}
                    barColor="#e65a5a"
                    labelFormatter={(value) => this.getTime(value)}
                  />

                </React.Fragment>
              }

              {reportData.hasRepsData &&
                <React.Fragment>

                  <h4 className="training-day-report__graph-section">

                    <i className="fas fa-dumbbell"></i>
                    <span className="training-day-report__graph-section__text">Repetições</span>

                  </h4>

                  <SimpleBarGraph
                    className="training-day-report__graph"
                    name="Repetições"
                    data={reportData.repsData}
                    height={this.getDefaultGraphHeight()}
                    barColor="#4d86ce"
                    labelFormatter={(value) => value}
                  />

                </React.Fragment>
              }

              <h4 className="training-day-report__graph-section">

                <i className="fas fa-dumbbell"></i>
                <span className="training-day-report__graph-section__text">Percepção Subjetiva de Esforço (PSE)</span>

              </h4>

              <SimpleBarGraph
                className="training-day-report__graph"
                name="PSE"
                data={reportData.pseData}
                height={this.getDefaultGraphHeight()}
                barColor="#b678ef"
                labelFormatter={(value) => value}
              />

            </div>

          </section>

        </div>

      </DefaultMenuLayout>
    );
  }
}

export default TrainingDayReport;
