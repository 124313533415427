import React from 'react';
import { PoseGroup } from 'react-pose';
import './personal_training_report.scss';
import {FadeContainer} from '../utils/pose_containers';
import PreLoader from '../utils/preloader';
import * as routes from '../constants';
import DefaultMenuLayout from '../components/default_menu_layout';
import {getModels, getAsLocalDate} from '../utils/functions';
import SimpleBarGraph, {BarPoint} from '../graphs/simple_bar_graph';

class PersonalTrainingReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      trainings: null,
      screenWidth: window.innerWidth,
    };
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  async getTrainings() {
    return await getModels(routes.PERSONAL_TRAINING_REPORT_GET_API.replace('{id}', this.props.match.params.personalTrainingId));
  }

  async componentDidMount() {
    const update = {loading: false};

    let data = this.getTrainings();

    data = await data;

    if(data) {
      update.trainings = data;
      update.trainings.sort((a, b) => a.date.localeCompare(b.date));
    }

    this.setState(update);

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  getReportData() {
    const timeData = [];
    const workoutTimeData = [];
    const repsData = [];
    const pseData = [];
    const weightData = [];

    for(let entry of this.state.trainings) {
      const total_seconds = entry.total_seconds || 0;
      const workout_time = entry.workout_time || 0;
      const total_repetitions = entry.total_repetitions || 0;
      const total_weight = entry.total_weight || 0;
      const pse = entry.pse || 0;
      const difficulty = entry.difficulty || 'Não especificado';

      const index = difficulty;

      timeData.push(new BarPoint(total_seconds, getAsLocalDate(entry.date).toLocaleDateString(), `${this.getTime(total_seconds)} - ${index}`));
      workoutTimeData.push(new BarPoint(workout_time, getAsLocalDate(entry.date).toLocaleDateString(), `${this.getTime(workout_time)} - ${index}`));
      repsData.push(new BarPoint(total_repetitions, getAsLocalDate(entry.date).toLocaleDateString(), `${total_repetitions} - ${index}`));
      weightData.push(new BarPoint(total_weight, getAsLocalDate(entry.date).toLocaleDateString(), `${total_weight} - ${index}`));
      pseData.push(new BarPoint(pse, getAsLocalDate(entry.date).toLocaleDateString(), `${pse} - ${index}`));
    }

    return {
      timeData,
      workoutTimeData,
      repsData,
      weightData,
      pseData
    };
  }

  getDefaultGraphHeight() {
    if(this.state.screenWidth <= 420) {
      return 220;
    }

    if(this.state.screenWidth <= 600) {
      return 270;
    }

    if(this.state.screenWidth <= 1100) {
      return 350;
    }

    return null;
  }

  getTime(value) {
    const formater = new Intl.NumberFormat('en-US', { minimumIntegerDigits: 2 });

    const minutes = Math.floor(value / 60);
    const seconds = value % 60;

    return `${formater.format(minutes)}:${formater.format(seconds)}`;
  }

  render() {
    if(this.state.loading) {
      return (
        <PoseGroup>
          <FadeContainer key="preloader">
            <PreLoader />
          </FadeContainer>
        </PoseGroup>
      );
    }

    const reportData = this.getReportData();

    return (
      <DefaultMenuLayout
        showBackButton={true}
        history={this.props.history}
        onLogout={() => this.props.onLogout()}
        username={this.props.username}
      >

        <div className="personal-training-report">

          <section className="personal-training-report__data">

            <header className="personal-training-report__data__header">

            <h3 className="personal-training-report__data__header__title">
              <i className="fas fa-chart-bar"></i>
              {' '}Comparativo de treino
            </h3>

            </header>

            <div className="personal-training-report__data__content">

              <h4 className="personal-training-report__graph-section">

                <i className="fas fa-dumbbell"></i>
                <span className="personal-training-report__graph-section__text">Kilagem total média</span>

              </h4>

              <SimpleBarGraph
                className="personal-training-report__graph"
                name="Kilagem total média"
                data={reportData.weightData}
                height={this.getDefaultGraphHeight()}
                barColor="#62b94d"
                labelFormatter={(value) => value}
              />

              <h4 className="personal-training-report__graph-section">

                <i className="fas fa-stopwatch"></i>
                <span className="personal-training-report__graph-section__text">Tempo total</span>

              </h4>

              <SimpleBarGraph
                className="personal-training-report__graph"
                name="Tempo total"
                data={reportData.timeData}
                height={this.getDefaultGraphHeight()}
                barColor="#e65a5a"
                labelFormatter={(value) => this.getTime(value)}
              />

              <h4 className="personal-training-report__graph-section">

                <i className="fas fa-stopwatch"></i>
                <span className="personal-training-report__graph-section__text">Tempo do workout</span>

              </h4>

              <SimpleBarGraph
                className="personal-training-report__graph"
                name="Tempo do workout"
                data={reportData.workoutTimeData}
                height={this.getDefaultGraphHeight()}
                barColor="#efe078"
                labelFormatter={(value) => this.getTime(value)}
              />

              <h4 className="personal-training-report__graph-section">

                <i className="fas fa-dumbbell"></i>
                <span className="personal-training-report__graph-section__text">Repetições</span>

              </h4>

              <SimpleBarGraph
                className="personal-training-report__graph"
                name="Repetições"
                data={reportData.repsData}
                height={this.getDefaultGraphHeight()}
                barColor="#4d86ce"
                labelFormatter={(value) => value}
              />

              <h4 className="personal-training-report__graph-section">

                <i className="fas fa-dumbbell"></i>
                <span className="personal-training-report__graph-section__text">Percepção Subjetiva de Esforço (PSE)</span>

              </h4>

              <SimpleBarGraph
                className="personal-training-report__graph"
                name="PSE"
                data={reportData.pseData}
                height={this.getDefaultGraphHeight()}
                barColor="#b678ef"
                labelFormatter={(value) => value}
              />

            </div>

          </section>

        </div>

      </DefaultMenuLayout>
    );
  }
}

export default PersonalTrainingReport;
