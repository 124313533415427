import React from 'react';
import { PoseGroup } from 'react-pose';
import './edit_default_training.scss';
import {FadeContainer} from '../../utils/pose_containers';
import PreLoader from '../../utils/preloader';
import * as routes from '../../constants';
import {DEFAULT_UNKNOWN_ERROR_MESSAGE,
        DIFFUCULTY_OPTION_EASY,
        DIFFUCULTY_OPTION_NORMAL,
        DIFFUCULTY_OPTION_INTERMEDIATE,
        DIFFUCULTY_OPTION_ADVANCED,
        SERVICE_GYM,
        SERVICE_PILATES,
        SERVICE_BIKE,
        SERVICE_DEFAULT_TRAINING} from '../../constants';
import DefaultMenuLayout from '../../components/default_menu_layout';
import WarningMessage from '../../components/warning_message';
import {getModel, postModel, patchModel, getAsLocalDate, getAsLocalDateString} from '../../utils/functions';
import DefaultInput, {SelectOption} from '../../utils/default_input';
import {HorizontalRule} from '../../utils/default_section';
import DefaultMenuButton from '../../components/default_menu_button';
import OverlayWindow from '../../components/overlay_window';
import ConfirmationWindow from '../../components/confirmation_window';

class EditDefaultTrainingData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      training: null,
      prExerciseAssociations: [],
      showStudentPr: null,
      studentOverlayReferenceData: null,
      studentOverlayOriginalReferenceData: null,
      studentOverlayShowInputs: false,
      supportTableVisible: false,
      highlights: [],
      warningMessage: "",
      showWarningMessage: false,
      onUpdateStudentReference: false,
      confirmInProgress: false,
      confirmFailed: false,
      confirmFailDescription: "",
      screenWidth: window.innerWidth,
    };
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  async getDefaultTraining() {
    return await getModel(`${routes.DEFAULT_TRAINING_GET_API}${this.props.match.params.defaultTrainingId}`);
  }

  async componentDidMount() {
    const update = {loading: false};

    let data = this.getDefaultTraining();

    data = await data;

    if(data) {
      update.training = data;

      const exercise_associations = data.training_day.group_associations.flatMap((group) => group.exercise_associations);
      update.prExerciseAssociations = exercise_associations.filter((association) => association.difficult_name === 'PR');
    }

    this.setState(update);

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;

    if(name === 'minutes') {
      const totalSeconds = this.state.training.total_seconds || 0;

      if(value !== null) {
        value = (parseInt(value)*60) + (totalSeconds % 60);
      }

      name = 'total_seconds';
    }
    else if(name === 'seconds') {
      const totalSeconds = this.state.training.total_seconds || 0;

      if(value !== null) {
        value = parseInt(value) + (Math.floor(totalSeconds / 60)*60);
      }

      name = 'total_seconds';
    }


    const newData = {...this.state.training, [name]: value};

    this.setState({
      training: newData
    });
  }

  onSetPse(value) {
    const newData = {...this.state.training, pse: value};

    this.setState({
      training: newData
    });
  }

  getDifficultyOptions() {
    const options = [
      DIFFUCULTY_OPTION_EASY,
      DIFFUCULTY_OPTION_NORMAL,
      DIFFUCULTY_OPTION_INTERMEDIATE,
      DIFFUCULTY_OPTION_ADVANCED,
    ];

    return [
      SelectOption('', 'Selecione uma dificuldade'),
      ...options.map((option) => SelectOption(option, option))
    ];
  }

  inputsAreValid() {
    return true;
  }

  async saveData() {
    if(this.state.training.training_class.target_service === SERVICE_DEFAULT_TRAINING && !this.state.training.difficulty) {
      this.setState({
        highlights: ['difficulty'],
        showWarningMessage: true,
        warningMessage: "Dificuldade não selecionada"
      });

      return;
    }

    let warningMessages = [];
    let highlights = [];

    this.setState({
      highlights: [],
      showWarningMessage: false,
      loading: true
    });

    const data = {...this.state.training}
    data.difficulty = data.difficulty || null;
    data.pse = parseInt(data.pse);
    data.total_repetitions = parseInt(data.total_repetitions);
    data.total_seconds = parseFloat(data.total_seconds);
    data.total_weight = parseFloat(data.total_weight);

    try {
      await patchModel(`${routes.DEFAULT_TRAINING_PATCH_API}${this.props.match.params.defaultTrainingId}`, data);
    }
    catch(errors) {
      warningMessages = [];
      highlights = [];

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            // case 102:
            //   for(let parameter of error.parameters) {
            //     switch (parameter.name) {
            //       case 'date':
            //         warningMessages.push('Data inválida. Data cadastrada não pode ser nem no futuro e nem mais do que 28 dias atrás.');
            //         highlights.push('date');
            //
            //         break;
            //       default:
            //     }
            //   }
            //
            //   break;
            case 209:
              warningMessages.push('Sessão do usuário expirada');

              break;
            default:
          }
        }
      }

      if(warningMessages.length <= 0) {
        warningMessages.push(DEFAULT_UNKNOWN_ERROR_MESSAGE);
      }

      this.setState({
        highlights: highlights,
        showWarningMessage: true,
        warningMessage: `${warningMessages.join('; ')}.`,
        loading: false
      });

      return;
    }

    this.props.history.goBack();
    // this.props.history.replace(routes.TRAININGS_PATH);
  }

  getStudentOverlayActions() {
    let addSaveAction = false;

    if(this.state.studentOverlayReferenceData !== null && this.state.studentOverlayOriginalReferenceData !== null) {
      for(let association of this.state.prExerciseAssociations) {
        if(this.state.studentOverlayReferenceData[association.exercise_id].value !== this.state.studentOverlayOriginalReferenceData[association.exercise_id].value) {
          addSaveAction = true;
        }
      }
    }

    return (
      <React.Fragment>

        <DefaultMenuButton
          className="edit-default-training__overlay__action-button"
          onClick={() => this.setState({
            showStudentPr: false,
            studentOverlayReferenceData: null,
            studentOverlayOriginalReferenceData: null,
          })}
          text="Fechar"
          disabled={this.state.onUpdateStudentReference}
        />

        {addSaveAction &&
          <DefaultMenuButton
            className="edit-default-training__overlay__action-button"
            onClick={() => this.setState({onUpdateStudentReference: true})}
            text="Salvar"
            color="green"
            disabled={this.state.onUpdateStudentReference}
          />
        }

      </React.Fragment>
    );
  }

  getPrPercentage(physicalRecuperationStatus, currentPrPercentage) {
    return Math.round(currentPrPercentage - (10*(10 - physicalRecuperationStatus)/3));
  }

  handleReferenceChange(event) {
    const target = event.target;
    let value = target.value;
    const name = target.name;

    const referenceCopy = {...this.state.studentOverlayReferenceData};

    const exercise_associations = this.state.training.training_day.group_associations.flatMap((group) => group.exercise_associations);

    if(name.startsWith('exercise_reference:')) {
      const selection = name.split(':');
      const associationId = parseInt(selection[1]);

      const association = exercise_associations.find((association) => association.id === associationId);
      const exercise_id = association.exercise_id;

      if(!value) {
        value = null;
      }
      else {
        const prPercentage = this.getPrPercentage(this.state.training.physical_recuperation_status, association.difficult_value[this.state.training.training_class.repetition_index - 1]);

        value = 100 * parseFloat(value) / prPercentage;
      }

      referenceCopy[exercise_id] = {...referenceCopy[exercise_id]};

      referenceCopy[exercise_id].value = value;
    }
    else if(name.startsWith('exercise_reference_input:')) {
      const selection = name.split(':');
      const associationId = parseInt(selection[1]);
      const parameterName = selection[2];

      const association = exercise_associations.find((association) => association.id === associationId);
      const exercise_id = association.exercise_id;

      referenceCopy[exercise_id] = {...referenceCopy[exercise_id]};

      referenceCopy[exercise_id][parameterName] = value || null;

      if(referenceCopy[exercise_id].weight && referenceCopy[exercise_id].repetition) {
        referenceCopy[exercise_id].value = (referenceCopy[exercise_id].weight * 100) / (102.78 - (2.78 * referenceCopy[exercise_id].repetition));
      }
    }

    this.setState({
      studentOverlayReferenceData: referenceCopy
    });
  }

  getStudentOverlayData() {
    if(this.state.studentOverlayReferenceData === null) {
      return null;
    }

    return this.state.prExerciseAssociations.map((association) => {
      const prValue = this.state.studentOverlayReferenceData[association.exercise_id].value;

      const normalMaxPercentage = association.difficult_value[this.state.training.training_class.repetition_index - 1];
      let maxPercentage = normalMaxPercentage;
      let minPercentage = normalMaxPercentage - 30;

      const prPercentage = this.getPrPercentage(this.state.training.physical_recuperation_status, normalMaxPercentage);

      if(association.difficult_intermediate_value) {
        const intermediateMaxPercentage = association.difficult_intermediate_value[this.state.training.training_class.repetition_index - 1];

        if(intermediateMaxPercentage > 0) {
          maxPercentage = Math.max(intermediateMaxPercentage, maxPercentage);
          minPercentage = Math.min(intermediateMaxPercentage - 30, minPercentage);
        }
      }

      if(association.difficult_advanced_value) {
        const advancedMaxPercentage = association.difficult_advanced_value[this.state.training.training_class.repetition_index - 1];

        if(advancedMaxPercentage > 0) {
          maxPercentage = Math.max(advancedMaxPercentage, maxPercentage);
          minPercentage = Math.min(advancedMaxPercentage - 30, minPercentage);
        }
      }

      let prText = '';

      // if(prValue) {
      //   prText = prValue*prPercentage*0.01;
      //
      //   prText *= 1000;
      //   prText = Math.round(prText);
      //   prText = prText / 1000;
      // }

      const percentageGraduation = [];

      minPercentage = Math.max(minPercentage, 0);

      const step = Math.ceil((maxPercentage - minPercentage) / 6);
      minPercentage = maxPercentage - (6*step);

      const percentageReference = (Math.floor(((prPercentage - minPercentage) / step))*step) + minPercentage;

      if(!this.state.studentOverlayShowInputs) {
        const resistanceEntries = [];
        const hypertrophyEntries = [];
        const strengthEntries = [];

        for(let percentage=maxPercentage; percentage >= minPercentage; percentage -= step) {
          if(percentage < 0) {
            break;
          }

          if(prValue) {
            prText = prValue*percentage*0.01;

            prText *= 10;
            prText = Math.round(prText);
            prText = prText / 10;
          }

          const entry = (
            <div
              className={`edit-default-training__overlay__reference__input-container${percentage === percentageReference ? '--selected' : ''}`}
              key={`edit_default_training:exercise_reference:${association.id}:pr_output:${percentage}`}
            >

              <p className="edit-default-training__overlay__reference__pr-input-header">
                <span className="edit-default-training__overlay__reference__input-prefix--highlighted">{percentage.toFixed(0)}%</span>
              </p>

              <DefaultInput
                className="edit-default-training__overlay__reference__input"
                name={`exercise_reference:${association.id}`}
                type="number"
                placeholder="-"
                min="0"
                step="0.1"
                handleInputChange={(event) => this.handleReferenceChange(event)}
                value={prText}
                autoComplete="off"
                suffix="kg"
                onFocus={(event) => event.target.select()}
                disabled={true}
              />

            </div>
          );

          if (percentage < 60) {
            resistanceEntries.push(entry);
          }
          else if (percentage < 85) {
            hypertrophyEntries.push(entry);
          }
          else {
            strengthEntries.push(entry);
          }
        }

        if (strengthEntries.length > 0) {
          percentageGraduation.push(
            <div
              className="class-board__student-panel__pr-section__reference__input-group-container"
              key={`class_board:exercise_reference:${association.id}:pr_output_group:strength`}
            >

              <div className="class-board__student-panel__pr-section__reference__input-group-container__title-wrapper--strength">

                <p className="class-board__student-panel__pr-section__reference__input-group-container__title">Força</p>

              </div>

              <div className="class-board__student-panel__pr-section__reference__percentages-wrapper">

                {strengthEntries}

              </div>

            </div>
          );
        }
        if (hypertrophyEntries.length > 0) {
          percentageGraduation.push(
            <div
              className="class-board__student-panel__pr-section__reference__input-group-container"
              key={`class_board:exercise_reference:${association.id}:pr_output_group:hypertrophy`}
            >

              <div className="class-board__student-panel__pr-section__reference__input-group-container__title-wrapper--hypertrophy">

                <p className="class-board__student-panel__pr-section__reference__input-group-container__title">Hipertrofia</p>

              </div>

              <div className="class-board__student-panel__pr-section__reference__percentages-wrapper">

                {hypertrophyEntries}

              </div>

            </div>
          );
        }
        if (resistanceEntries.length > 0) {
          percentageGraduation.push(
            <div
              className="class-board__student-panel__pr-section__reference__input-group-container"
              key={`class_board:exercise_reference:${association.id}:pr_output_group:resistance`}
            >

              <div className="class-board__student-panel__pr-section__reference__input-group-container__title-wrapper--resistance">

                <p className="class-board__student-panel__pr-section__reference__input-group-container__title">Resistência</p>

              </div>

              <div className="class-board__student-panel__pr-section__reference__percentages-wrapper">

                {resistanceEntries}

              </div>

            </div>
          );
        }
      }

      let updatedAtText = null;

      if(this.state.studentOverlayReferenceData[association.exercise_id].updated_at) {
        const today = getAsLocalDate((new Date()).toISOString().slice(0, 10));
        const updatedAt = getAsLocalDate(this.state.studentOverlayReferenceData[association.exercise_id].updated_at.slice(0, 10));

        const timeDiff = Math.abs(today.getTime() - updatedAt.getTime());
        let daysCount = Math.ceil(timeDiff / (1000 * 3600 * 24));

        updatedAtText = `Salvo em ${updatedAt.toLocaleDateString()} (${daysCount} dia(s))`;
      }

      return (
        <div
          key={`student:${this.state.training.id}:exercise_reference:${association.id}`}
          className="edit-default-training__overlay__reference"
        >

          {updatedAtText !== null &&
            <p className="edit-default-training__overlay__reference__date-text">

              {updatedAtText}

            </p>
          }

          <div className="edit-default-training__overlay__reference__wrapper">

            <h4 className="edit-default-training__overlay__reference__label">{association.exercise_name}:</h4>

            {!this.state.studentOverlayShowInputs ?
              <div className="edit-default-training__overlay__reference__percentages-wrapper">

                {percentageGraduation}

              </div>:
              <div className="edit-default-training__overlay__reference__input-container--horizontal">

                <div className="edit-default-training__overlay__reference__pr-input-wrapper">

                  <DefaultInput
                    key={`class_board:exercise_reference:${association.id}:weight`}
                    className="edit-default-training__overlay__reference__input--extended"
                    name={`exercise_reference_input:${association.id}:weight`}
                    type="number"
                    placeholder="-"
                    min="0"
                    step="0.1"
                    handleInputChange={(event) => this.handleReferenceChange(event)}
                    value={this.state.studentOverlayReferenceData[association.exercise_id].weight || ''}
                    autoComplete="off"
                    suffix="kg"
                    prefix="Peso"
                    onFocus={(event) => event.target.select()}
                  />

                  <DefaultInput
                    key={`class_board:exercise_reference:${association.id}:repetition`}
                    className="edit-default-training__overlay__reference__input--extended"
                    name={`exercise_reference_input:${association.id}:repetition`}
                    type="number"
                    placeholder="-"
                    min="0"
                    step="1"
                    handleInputChange={(event) => this.handleReferenceChange(event)}
                    value={this.state.studentOverlayReferenceData[association.exercise_id].repetition || ''}
                    autoComplete="off"
                    prefix="Repetições"
                    onFocus={(event) => event.target.select()}
                  />

                </div>

                {(this.state.studentOverlayReferenceData[association.exercise_id].repetition && this.state.studentOverlayReferenceData[association.exercise_id].weight) &&
                  <React.Fragment>

                    <i className="fas fa-long-arrow-alt-right edit-default-training__overlay__reference__arrow-icon"></i>

                    <p className="edit-default-training__overlay__reference__pr-text">

                      (<b>100%</b> do PR: <b>{prValue.toFixed(0)}</b> kg)

                    </p>

                  </React.Fragment>
                }

              </div>
            }

          </div>

        </div>
      );
    });
  }

  onShowStudentPr() {
    const exercise_ids = this.state.prExerciseAssociations.map((association) => association.exercise_id);

    const studentOverlayReferenceData = {};

    for(let id of exercise_ids) {
      studentOverlayReferenceData[id] = this.state.training.exercise_reference[id];
    }

    this.setState({
      studentOverlayReferenceData,
      showStudentPr: true,
      studentOverlayShowInputs: false,
      studentOverlayOriginalReferenceData: {...studentOverlayReferenceData},
    });
  }

  getConfirmationWindowTitle() {
    if(this.state.confirmFailed) {
      if(this.state.onUpdateStudentReference) {
        return 'Falha ao salvar dados de PR de aluno';
      }
    }
    else if(this.state.confirmInProgress) {
      if(this.state.onUpdateStudentReference) {
        return 'Salvando dados';
      }
    }
    else {
      if(this.state.onUpdateStudentReference) {
        return 'Salvar dados de PR';
      }
    }

    return 'Não implementado';
  }

  getConfirmationWindowDescription() {
    if(this.state.confirmFailed) {
      return this.state.confirmFailDescription;
    }
    if(this.state.onUpdateStudentReference) {
      return `Deseja realmente atualizar os dados de PR do aluno?`;
    }

    return 'Não implementado';
  }

  getConfirmationWindowConfirmButtonText() {
    if(this.state.onUpdateStudentReference) {
      return `Salvar dados`;
    }

    return 'Não implementado';
  }

  confirmationWindowIsVisible() {
    return this.state.onUpdateStudentReference === true;
  }

  resetConfirmationWindow() {
    this.setState({
      onUpdateStudentReference: false,
      confirmFailed: false,
      confirmInProgress: false,
    });
  }

  async proceedConfirmationWindow() {
    if(this.state.onUpdateStudentReference) {
      this.setState({
        confirmInProgress: true
      });

      const data = {reference_data: []};

      const referenceCopy = {...this.state.studentOverlayReferenceData};
      const todayIso = (new Date()).toISOString().slice(0, 10);

      for(let association of this.state.prExerciseAssociations) {
        referenceCopy[association.exercise_id].updated_at = todayIso;

        data.reference_data.push({
          exercise_id: association.exercise_id,
          value: parseFloat(referenceCopy[association.exercise_id].value),
          weight: parseFloat(referenceCopy[association.exercise_id].weight),
          repetition: parseInt(referenceCopy[association.exercise_id].repetition),
        });
      }

      try{
        if(await postModel(routes.EXERCISE_REFERENCES_POST_API, data)) {
          const exercise_reference = {...referenceCopy};

          this.setState({
            onUpdateStudentReference: false,
            confirmInProgress: false,
            studentOverlayOriginalReferenceData: {...referenceCopy},
            training: {...this.state.training, exercise_reference}
          });
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        if(errors instanceof Array) {
          for(let error of errors) {
            switch (error.code) {
              // case 104:
              //   for(let parameter of error.parameters) {
              //     switch (parameter.name) {
              //       case 'contracts':
              //         errorDescription = 'Serviço vinculado à um contrato de aluno. Estes contratos devem ser excluídos antes de excluir este serviço.';
              //
              //         break;
              //       default:
              //     }
              //   }
              //
              //   break;
              case 209:
                errorDescription = 'Sessão do usuário expirada.';

                break;
              default:
            }
          }
        }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }

    return;
  }

  hasSupportTable() {
    if(!this.state.training || !this.state.training.training_class || !this.state.training.training_day) {
      return false;
    }

    return this.state.training.training_day.group_associations.some((association) => association.has_support_table);
  }

  getSupportTables() {
    const filteredAssociations = this.state.training.training_day.group_associations.filter((association) => association.has_support_table);

    return filteredAssociations.map((association) => {
      const rows = association.support_table.map((row, index) => (
        <div
          className="edit-default-training__support-table__table__row-wrapper"
          key={`training_day_group_association:${association.id}:support_table:row:${index}`}
        >

          <p className="edit-default-training__support-table__table__cell">
            {row[0]}
          </p>

          <p className="edit-default-training__support-table__table__cell">
            {row[1]}
          </p>

        </div>
      ));

      return (
        <div
          className="edit-default-training__support-table__table"
          key={`training_day_group_association:${association.id}:support_table`}
        >

          <div className="edit-default-training__support-table__table__header">

            <h3 className="edit-default-training__support-table__table__title">
              {association.name}
            </h3>

          </div>

          <div className="edit-default-training__support-table__table__row-wrapper">

            <p className="edit-default-training__support-table__table__column-label">
              Nº Série
            </p>

            <p className="edit-default-training__support-table__table__column-label">
              Repetições
            </p>

          </div>

          {rows}

        </div>
      );
    });
  }

  isHighlighted(propertyName) {
    return this.state.highlights.includes(propertyName);
  }

  getInputs() {
    let minutes = '';
    let seconds = '';

    if(this.state.training.total_seconds) {
      minutes = Math.floor(this.state.training.total_seconds / 60);
      seconds = this.state.training.total_seconds % 60;
    }

    switch (this.state.training.training_class.target_service) {
      case SERVICE_PILATES:
      case SERVICE_BIKE:
        return (
          <React.Fragment>

            <div className="edit-default-training__input-wrapper">

              <h4 className="edit-default-training__input-tag-label">Serviço:</h4>

              <p className="edit-default-training__input-tag">{this.state.training.training_class.target_service}</p>

            </div>

            <div className="edit-default-training__input-wrapper">

              <h4 className="edit-default-training__input-tag-label">Treino:</h4>

              <p className="edit-default-training__input-tag">{this.state.training.training_day.name}</p>

            </div>

            <div className="edit-default-training__input-wrapper">

              <h4 className="edit-default-training__input-tag-label">Data:</h4>

              <p className="edit-default-training__input-tag">{getAsLocalDateString(this.state.training.training_class.started_at)}</p>

            </div>

            <HorizontalRule />

          </React.Fragment>
        );
      case SERVICE_GYM:
        return (
          <React.Fragment>

            {/* <div className="edit-default-training__input-wrapper">

              <h4 className="edit-default-training__input-label">Dificuldade:</h4>

              <DefaultInput
                className="edit-default-training__input"
                name="difficulty"
                isHighlighted={this.isHighlighted("difficulty")}
                type="select"
                handleInputChange={(event) => this.handleInputChange(event)}
                value={this.state.training.difficulty || ''}
                options={this.getDifficultyOptions()}
                isHorizontal={this.state.screenWidth > 580}
              />

            </div> */}

            <div className="edit-default-training__input-wrapper">

              <h4 className="edit-default-training__input-tag-label">Serviço:</h4>

              <p className="edit-default-training__input-tag">{this.state.training.training_class.target_service}</p>

            </div>

            <div className="edit-default-training__input-wrapper">

              <h4 className="edit-default-training__input-tag-label">Treino:</h4>

              <p className="edit-default-training__input-tag">{this.state.training.training_day.name}</p>

            </div>

            <div className="edit-default-training__input-wrapper">

              <h4 className="edit-default-training__input-tag-label">Data:</h4>

              <p className="edit-default-training__input-tag">{getAsLocalDateString(this.state.training.training_class.started_at)}</p>

            </div>

            <HorizontalRule />

            <div className="edit-default-training__input-wrapper">

              <h4 className="edit-default-training__input-label">Kilagem média total:</h4>

              <DefaultInput
                className="edit-default-training__input"
                name="total_weight"
                type="number"
                placeholder="-"
                min="0"
                step="1"
                handleInputChange={(event) => this.handleInputChange(event)}
                value={this.state.training.total_weight || ''}
                autoComplete="off"
                suffix="kg"
                onFocus={(event) => event.target.select()}
              />

            </div>

          </React.Fragment>
        );
      default:
        return (
          <React.Fragment>

            <div className="edit-default-training__input-wrapper">

              <h4 className="edit-default-training__input-tag-label">Serviço:</h4>

              <p className="edit-default-training__input-tag">{this.state.training.training_class.target_service}</p>

            </div>

            <div className="edit-default-training__input-wrapper">

              <h4 className="edit-default-training__input-tag-label">Treino:</h4>

              <p className="edit-default-training__input-tag">{this.state.training.training_day.name}</p>

            </div>

            <div className="edit-default-training__input-wrapper">

              <h4 className="edit-default-training__input-tag-label">Data:</h4>

              <p className="edit-default-training__input-tag">{getAsLocalDateString(this.state.training.training_class.started_at)}</p>

            </div>

            <HorizontalRule />

            <div className="edit-default-training__input-wrapper">

              <h4 className="edit-default-training__input-label">Dificuldade:</h4>

              <DefaultInput
                className="edit-default-training__input"
                name="difficulty"
                isHighlighted={this.isHighlighted("difficulty")}
                type="select"
                handleInputChange={(event) => this.handleInputChange(event)}
                value={this.state.training.difficulty || ''}
                options={this.getDifficultyOptions()}
                isHorizontal={this.state.screenWidth > 580}
              />

            </div>

            <div className="edit-default-training__input-wrapper">

              <h4 className="edit-default-training__input-label">Tempo:</h4>

              <DefaultInput
                className="edit-default-training__input"
                name="minutes"
                type="number"
                placeholder="-"
                min="0"
                step="1"
                handleInputChange={(event) => this.handleInputChange(event)}
                value={minutes}
                autoComplete="off"
                suffix={this.state.screenWidth > 680 ? 'minutos' : 'min'}
                onFocus={(event) => event.target.select()}
              />

              <DefaultInput
                className="edit-default-training__input"
                name="seconds"
                type="number"
                placeholder="-"
                min="0"
                step="1"
                handleInputChange={(event) => this.handleInputChange(event)}
                value={seconds}
                autoComplete="off"
                suffix={this.state.screenWidth > 680 ? 'segundos' : 'seg'}
                onFocus={(event) => event.target.select()}
              />

            </div>

            <div className="edit-default-training__input-wrapper">

              <h4 className="edit-default-training__input-label">Repetições:</h4>

              <DefaultInput
                className="edit-default-training__input"
                name="total_repetitions"
                type="number"
                placeholder="-"
                min="0"
                step="1"
                handleInputChange={(event) => this.handleInputChange(event)}
                value={this.state.training.total_repetitions || ''}
                autoComplete="off"
                suffix="reps"
                onFocus={(event) => event.target.select()}
              />

            </div>

          </React.Fragment>
        );
    }
  }

  render() {
    if(this.state.loading) {
      return (
        <PoseGroup>
          <FadeContainer key="preloader">
            <PreLoader />
          </FadeContainer>
        </PoseGroup>
      );
    }

    return (
      <React.Fragment>

        <DefaultMenuLayout
          showBackButton={true}
          history={this.props.history}
          onLogout={() => this.props.onLogout()}
          username={this.props.username}
        >

          <div className="edit-default-training">

            <section className="edit-default-training__data">

              <header className="edit-default-training__data__header">

              <h3 className="edit-default-training__data__header__title">
                <i className="fas fa-edit"></i>
                {' '}Editar treino
              </h3>

              </header>

              <div className="edit-default-training__data__content">

                <div className="edit-default-training__additional-actions">

                  <DefaultMenuButton
                    className="edit-default-training__pr-button"
                    onClick={() => this.onShowStudentPr()}
                    text="Recorde Pessoal (PR)"
                  />

                </div>

                <HorizontalRule />

                <div className="edit-default-training__warning-container">

                  <WarningMessage
                    message={this.state.warningMessage}
                    onClose={() => {this.setState({highlights: [], showWarningMessage: false})}}
                    visible={this.state.showWarningMessage}
                  />

                </div>

                <div className="edit-default-training__data-inputs">

                  {this.getInputs()}

                  {this.state.training.energy_expended !== null &&
                    <div className="edit-default-training__input-wrapper">

                      <h4 className="edit-default-training__input-label--red">Gasto calórico:</h4>

                      <DefaultInput
                        className="edit-default-training__input"
                        name={`training_data:${this.state.training.id}:energy_expended`}
                        type="number"
                        placeholder="-"
                        // handleInputChange={(event) => this.handleDataChange(event)}
                        value={this.state.training.energy_expended.toFixed(0)}
                        autoComplete="off"
                        suffix="kcal"
                        disabled
                      />
                    </div>
                  }

                  <div className="edit-default-training__input-wrapper--vertical">

                    <h4 className="edit-default-training__input-label">PSE - O quão pesado foi seu treino?</h4>

                    <div className="edit-default-training__notification-container">

                      <i className="fas fa-exclamation edit-default-training__notification-container__icon"></i>

                      <p className="edit-default-training__notification-container__text">

                        O preenchimento da PSE deve ser feito cerca de 20-30 minutos após o fim da atividade física para
                        utilizar nossa metodologia de análise de carga de forma precisa.

                      </p>

                    </div>

                    <ul className="edit-default-training__scale">

                      <li className="edit-default-training__scale__group">

                        <div className="edit-default-training__scale__group__image--2">

                          <i className="fas fa-kiss-beam edit-default-training__scale__group__image-icon"></i>

                        </div>

                        <div className="edit-default-training__scale__group__options">

                          <button
                            className="edit-default-training__scale__group__option--1"
                            onClick={() => this.onSetPse(1)}
                            disabled={this.state.training.pse === 1}
                          >

                            <p className="edit-default-training__scale__group__option-number">
                              1
                            </p>

                            {/* <p className="edit-default-training__scale__group__option-text--flex">
                              Muito leve
                            </p> */}

                          </button>

                          <button
                            className="edit-default-training__scale__group__option--2"
                            onClick={() => this.onSetPse(2)}
                            disabled={this.state.training.pse === 2}
                          >

                            <p className="edit-default-training__scale__group__option-number">
                              2
                            </p>

                            {/* <p className="edit-default-training__scale__group__option-text--flex">
                              Leve
                            </p> */}

                          </button>

                        </div>

                        <div className="edit-default-training__scale__group__description--2">

                          <p className="edit-default-training__scale__group__description__text">
                            Muito leve
                          </p>

                        </div>

                      </li>

                      <li className="edit-default-training__scale__group">

                        <div className="edit-default-training__scale__group__image--4">

                          <i className="fas fa-grin edit-default-training__scale__group__image-icon"></i>

                        </div>

                        <div className="edit-default-training__scale__group__options">

                          <button
                            className="edit-default-training__scale__group__option--3"
                            onClick={() => this.onSetPse(3)}
                            disabled={this.state.training.pse === 3}
                          >

                            <p className="edit-default-training__scale__group__option-number">
                              3
                            </p>

                            {/* <p className="edit-default-training__scale__group__option-text--flex">
                              Tranquilo
                            </p> */}

                          </button>

                          <button
                            className="edit-default-training__scale__group__option--4"
                            onClick={() => this.onSetPse(4)}
                            disabled={this.state.training.pse === 4}
                          >

                            <p className="edit-default-training__scale__group__option-number">
                              4
                            </p>

                            {/* <p className="edit-default-training__scale__group__option-text--flex">
                              Moderado
                            </p> */}

                          </button>

                        </div>

                        <div className="edit-default-training__scale__group__description--4">

                          <p className="edit-default-training__scale__group__description__text">
                            Leve
                          </p>

                        </div>

                      </li>

                      <li className="edit-default-training__scale__group">

                        <div className="edit-default-training__scale__group__image--6">

                          <i className="fas fa-meh edit-default-training__scale__group__image-icon"></i>

                        </div>

                        <div className="edit-default-training__scale__group__options">

                          <button
                            className="edit-default-training__scale__group__option--5"
                            onClick={() => this.onSetPse(5)}
                            disabled={this.state.training.pse === 5}
                          >

                            <p className="edit-default-training__scale__group__option-number">
                              5
                            </p>

                            {/* <p className="edit-default-training__scale__group__option-text--flex">
                              Requer esforço
                            </p> */}

                          </button>

                          <button
                            className="edit-default-training__scale__group__option--6"
                            onClick={() => this.onSetPse(6)}
                            disabled={this.state.training.pse === 6}
                          >

                            <p className="edit-default-training__scale__group__option-number">
                              6
                            </p>

                            {/* <p className="edit-default-training__scale__group__option-text--flex">
                              Pouco pesado
                            </p> */}

                          </button>

                        </div>

                        <div className="edit-default-training__scale__group__description--6">

                          <p className="edit-default-training__scale__group__description__text">
                            Moderado
                          </p>

                        </div>

                      </li>

                      <li className="edit-default-training__scale__group">

                        <div className="edit-default-training__scale__group__image--8">

                          <i className="fas fa-frown-open edit-default-training__scale__group__image-icon"></i>

                        </div>

                        <div className="edit-default-training__scale__group__options">

                          <button
                            className="edit-default-training__scale__group__option--7"
                            onClick={() => this.onSetPse(7)}
                            disabled={this.state.training.pse === 7}
                          >

                            <p className="edit-default-training__scale__group__option-number">
                              7
                            </p>

                            {/* <p className="edit-default-training__scale__group__option-text--flex">
                              Pesado
                            </p> */}

                          </button>

                          <button
                            className="edit-default-training__scale__group__option--8"
                            onClick={() => this.onSetPse(8)}
                            disabled={this.state.training.pse === 8}
                          >

                            <p className="edit-default-training__scale__group__option-number">
                              8
                            </p>

                            {/* <p className="edit-default-training__scale__group__option-text--flex">
                              Muito pesado
                            </p> */}

                          </button>

                        </div>

                        <div className="edit-default-training__scale__group__description--8">

                          <p className="edit-default-training__scale__group__description__text">
                            Pesado
                          </p>

                        </div>

                      </li>

                      <li className="edit-default-training__scale__group">

                        <div className="edit-default-training__scale__group__image--10">

                          <i className="fas fa-tired edit-default-training__scale__group__image-icon"></i>

                        </div>

                        <div className="edit-default-training__scale__group__options">

                          <button
                            className="edit-default-training__scale__group__option--9"
                            onClick={() => this.onSetPse(9)}
                            disabled={this.state.training.pse === 9}
                          >

                            <p className="edit-default-training__scale__group__option-number">
                              9
                            </p>

                            {/* <p className="edit-default-training__scale__group__option-text--flex">
                              Extremamente pesado
                            </p> */}

                          </button>

                          <button
                            className="edit-default-training__scale__group__option--10"
                            onClick={() => this.onSetPse(10)}
                            disabled={this.state.training.pse === 10}
                          >

                            <p className="edit-default-training__scale__group__option-number">
                              10
                            </p>

                            {/* <p className="edit-default-training__scale__group__option-text--flex">
                              Quase impossível
                            </p> */}

                          </button>

                        </div>

                        <div className="edit-default-training__scale__group__description--10">

                          <p className="edit-default-training__scale__group__description__text">
                            Muito pesado
                          </p>

                        </div>

                      </li>

                    </ul>

                    <p className="edit-default-training__small-note">

                      *A PSE é uma estimativa para quantificar o esforço físico necessário para realizar a atividade
                      proposta e está diretamente relacionada com o cansaço físico persistente depois de sua execução.

                    </p>

                  </div>

                </div>

                <HorizontalRule />

                <div className="edit-default-training__buttons-container">

                  <DefaultMenuButton
                    className="edit-default-training__save-button"
                    onClick={() => this.saveData()}
                    text="Salvar"
                    color="blue"
                  />

                  <DefaultMenuButton
                    className="edit-default-training__cancel-button"
                    onClick={() => this.props.history.goBack()}
                    text="Cancelar"
                  />

                </div>

              </div>

            </section>

          </div>

        </DefaultMenuLayout>

        {this.hasSupportTable() &&
          <aside className={`edit-default-training__support-table${this.state.supportTableVisible ? '--visible' : ''}`}>

            <div className="edit-default-training__support-table__controls">

              <DefaultMenuButton
                className="edit-default-training__support-table__toggle-button"
                onClick={() => this.setState({supportTableVisible: !this.state.supportTableVisible})}
                text={(<i className="fas fa-chevron-down"></i>)}
                color="red"
              />

            </div>

            <header className="edit-default-training__support-table__header">

              <div className="edit-default-training__support-table__header__title-wrapper">

                <h2 className="edit-default-training__support-table__header__title">

                  Tabela auxiliar

                </h2>

              </div>

            </header>

            <div className="edit-default-training__support-table__content">

              {this.getSupportTables()}

            </div>

          </aside>
        }

        <OverlayWindow
          className="edit-default-training__overlay"
          visible={this.state.showStudentPr}
          loading={false}
          actions={(
            <div className="edit-default-training__overlay__action-container">

              {this.getStudentOverlayActions()}

            </div>
          )}
        >

          <header className="edit-default-training__overlay__header">

            <h3 className="edit-default-training__overlay__header__title">
              {this.state.studentOverlayShowInputs ? 'Definição de PR' : 'Referências de peso'}
            </h3>

            <DefaultMenuButton
              className="edit-default-training__overlay__header__toggle-button"
              onClick={() => this.setState({
                studentOverlayShowInputs: !this.state.studentOverlayShowInputs
              })}
              text={(<i className="fas fa-exchange-alt"></i>)}
            />

          </header>

          <hr className="edit-default-training__horizontal-rule" />

          <div className="edit-default-training__overlay__reference-container">

            {this.getStudentOverlayData()}

          </div>

        </OverlayWindow>

        <ConfirmationWindow
          title={this.getConfirmationWindowTitle()}
          description={this.getConfirmationWindowDescription()}
          confirmText={this.getConfirmationWindowConfirmButtonText()}
          cancelText={this.state.confirmFailed ? 'Ok' : 'Cancelar'}
          visible={this.confirmationWindowIsVisible()}
          onCancel={() => this.resetConfirmationWindow()}
          onConfirm={() => this.proceedConfirmationWindow()}
          loading={this.state.confirmInProgress}
          useErrorIcon={this.state.confirmFailed}
          hideConfirmButton={this.state.confirmFailed}
        />

      </React.Fragment>
    );
  }
}

export default EditDefaultTrainingData;
