import React from 'react';
import './prompt.scss';
import DefaultMenuButton from './default_menu_button';

class Prompt extends React.Component {
  render() {
    return (
      <aside className="prompt">

        {this.props.logo || null}

        <h3 className="prompt__title">{this.props.title}</h3>

        <DefaultMenuButton
          className="prompt__action-button"
          onClick={() => this.props.onConfirm()}
          text={this.props.actionText}
          color="green"
        />

        <DefaultMenuButton
          className="prompt__cancel-button"
          onClick={() => this.props.onCancel()}
          text={this.props.cancelText}
        />

      </aside>
    );
  }
}

export default Prompt;
