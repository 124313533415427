import React from 'react';
import { PoseGroup } from 'react-pose';
import { Link } from 'react-router-dom';
import DietGraph, {WeightPoint} from '../graphs/diet_graph';
import {DefaultSubSectionTitle, HorizontalRule} from '../utils/default_section';
import {FadeContainer} from '../utils/pose_containers';
import PreLoader from '../utils/preloader';
import * as routes from '../constants';
import {DEFAULT_ERROR_MESSAGE,
        FOOD_MEASUREMENT_GRAM_ID,
        FORM_ANSWER_TYPE_LONG_TEXT_ID,
        FORM_ANSWER_TYPE_HORIZONTAL_SELECT_ID,
        FYD_FORM_NUTRI_FOLLOWUP_ID,
        FYD_FORM_FOLLOWUP_QUESTION_TRAINING_ID,
        FYD_FORM_FOLLOWUP_QUESTION_DIET_ID,
        FYD_FORM_FOLLOWUP_QUESTION_STRESS_ID,
        FYD_FORM_FOLLOWUP_QUESTION_SLEEP_ID,
        FYD_FORM_FOLLOWUP_QUESTION_WATER_ID} from '../constants';
import DefaultMenuButton from '../components/default_menu_button';
import DefaultMenuLayout from '../components/default_menu_layout';
import {getModel, postModel, deleteModel, getAsLocalDate, getLocalDateIsoString, getAsLocalDateString} from '../utils/functions';
import {getFoodPrescriptionNutritionalData,
        getFoodRecipeNutricionalData,
        CARBOHYDRATE_TO_KCAL_CONVERSION_CONTANT,
        PROTEIN_TO_KCAL_CONVERSION_CONTANT,
        FAT_TO_KCAL_CONVERSION_CONTANT} from '../utils/fyd_food';
import {VerticalAccordionContainer, VerticalAccordionSubContainer, VerticalAccordionSub2Container} from '../utils/pose_containers';
import DefaultInput, {HalfWrapper} from '../utils/default_input';
import ConfirmationWindow from '../components/confirmation_window';
import OverlayWindow from '../components/overlay_window';
import logo from '../images/logo_fyd_club.png';
import HorizontalBarGraph from '../graphs/custom/horizontal_bar_graph';
import hexOuterBorderImg from '../images/hexagon_border_white.svg';
import hexOuterBorderSmallImg from '../images/hexagon_border_white_small.svg';
import hexInnerBorderImg from '../images/hexagon_border_dashed_white.svg';
import hexInnerBorderSmallImg from '../images/hexagon_border_dashed_white_small.svg';
import reportBackground from '../images/nutritional_report/background.svg';
import waterBottle from '../images/nutritional_report/water_bottle.svg';
import fullGlass from '../images/nutritional_report/full_glass.svg';
import emptyBottle from '../images/nutritional_report/empty_glass.svg';
import sleepyFace from '../images/nutritional_report/sleepy_face.svg';
import zFilled from '../images/nutritional_report/z_filled.svg';
import zEmpty from '../images/nutritional_report/z_empty.svg';
import stressFace from '../images/nutritional_report/stress_face.svg';
import stressFilled from '../images/nutritional_report/stress_filled.svg';
import stressEmpty from '../images/nutritional_report/stress_empty.svg';
import './food_prescription.scss';


class FoodPrescription extends React.Component {
  constructor(props) {
    super(props);

    const today = new Date();
    today.setHours(0, 0, 0, 0);
    this.todayIsoString = getLocalDateIsoString(today);
    this.todayWeekDay = today.getDay();

    this.state = {
      loading: true,
      active_units: [],
      activePrescriptionData: null,
      selectedUnit: null,
      last_exam: null,
      training_frequency: null,
      support_warning_message: null,
      weeklyExerciseEnergyExpense: 0,
      service_energy_expense_map: {},
      ignoreExerciseEnergyExpense: false,
      weightHistoryVisible: false,
      activeDietVisible: false,
      purchaseCalculatorVisible: false,
      mealAssociationVisibilityMap: {},
      mealPreparationVisibilityMap: {},
      weightMeasurementDate: this.todayIsoString,
      weightMeasurementValue: '',
      weightMeasurementToRegister: null,
      weightMeasurementToRemove: null,
      purchaseCalculatorQuantityMap: new Map(),
      showFollowupReport: false,
      showFollowupForm: false,
      highlightRequiredFollowupFormQuestions: false,
      followupFormAnswerMap: new Map(),
      followupForm: null,
      submittingFollowupForm: false,
      confirmInProgress: false,
      confirmFailed: false,
      confirmFailDescription: "",
      screenWidth: window.innerWidth,
    };

    this.followupFormInputRefMap = new Map();

    this.LOCAL_STORAGE_PURCHASE_CALCULATOR_KEY = 'purchase_calculator_quantity_map';
  }

  async getFoodPrescription() {
    return await getModel(routes.FOOD_PRESCRIPTION_GET_API);
  }

  async getFollowupForm() {
    return await getModel(routes.NUTRITIONAL_FOLLOWUP_FORM_GET_API);
  }

  updateSize() {
    const update = {screenWidth: window.innerWidth};

    this.setState(update);
  }

  async componentDidMount() {
    let update = {loading: false};

    let food_prescription = await this.getFoodPrescription();

    if(food_prescription) {
      if(!food_prescription.is_active) {
        this.onBackClicked();
        return;
      }

      update.active_units = food_prescription.active_units;

      if(food_prescription.last_exam) {
        update.last_exam = food_prescription.last_exam;
      }

      if(food_prescription.training_frequency) {
        update.training_frequency = food_prescription.training_frequency;
      }

      if(food_prescription.support_warning_message) {
        update.support_warning_message = food_prescription.support_warning_message;
      }

      if(food_prescription.service_energy_expense_map) {
        update.service_energy_expense_map = food_prescription.service_energy_expense_map;
      }

      if(update.active_units.length === 1) {
        update.selectedUnit = update.active_units[0];

        update = {
          ...update,
          ...this.onSelectUnit(update.selectedUnit, update.last_exam, false)
        };
      }
    }

    this.setState(update);

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;

    if(name.startsWith('followup_form:question:')) {
      const selection = name.split(':');
      const questionId = parseInt(selection[2]);

      const followupFormAnswerMap = new Map(this.state.followupFormAnswerMap);

      followupFormAnswerMap.set(questionId, value);

      this.setState({
        followupFormAnswerMap
      });
    }
    else {
      this.setState({
        [name]: value
      });
    }
  }

  onUpdateShoppingQuantity(event, association) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    // let name = target.name;

    const purchaseCalculatorQuantityMap = new Map(this.state.purchaseCalculatorQuantityMap);

    let quantity = 0;

    if (value.length > 0) {
      quantity = parseFloat(value);
    }

    if (!purchaseCalculatorQuantityMap.has(association.id)) {
      purchaseCalculatorQuantityMap.set(association.id, {
        association,
        quantity
      });
    }
    else {
      purchaseCalculatorQuantityMap.get(association.id).quantity = quantity;
    }

    const quantityCache = [...purchaseCalculatorQuantityMap.values()].map((entry) => {
      return {
        id: entry.association.id,
        quantity: entry.quantity,
        isRecipe: entry.association.isRecipe
      };
    })

    localStorage.setItem(this.LOCAL_STORAGE_PURCHASE_CALCULATOR_KEY, JSON.stringify(quantityCache));

    this.setState({purchaseCalculatorQuantityMap});
  }

  onSelectUnit(selectedUnit, last_exam, setState=true) {
    const update = {
      selectedUnit
    };

    selectedUnit.weight_measurements.sort((a, b) => b.date.localeCompare(a.date));
    selectedUnit.followup_form_submissions.sort((a, b) => b.submitted_at.localeCompare(a.submitted_at));

    if (selectedUnit.active_period && last_exam !== null && last_exam.data.weight && last_exam.data.weight.length > 0) {
      const weight = parseFloat(last_exam.data.weight);
      let totalEnergy = 0;

      for(const trainingDay of selectedUnit.active_period.training_days) {
        for(const association of trainingDay.group_associations) {
          if(association.activity_reference) {
            totalEnergy += weight * (association.time_required / 60) * association.activity_reference.met;
          }
        }
      }

      const periodReference = selectedUnit.active_period.period_reference || 7;

      update.weeklyExerciseEnergyExpense = 7 * (totalEnergy / periodReference);
    }

    update.activePrescriptionData = getFoodPrescriptionNutritionalData(selectedUnit.food_prescription);

    const quantityCacheJson = localStorage.getItem(this.LOCAL_STORAGE_PURCHASE_CALCULATOR_KEY);

    if (quantityCacheJson) {
      const quantityCache = JSON.parse(quantityCacheJson);

      update.purchaseCalculatorQuantityMap = new Map(this.state.purchaseCalculatorQuantityMap);

      for (const cacheEntry of quantityCache) {
        let association;

        if (cacheEntry.isRecipe) {
          association = selectedUnit.food_prescription.food_recipe_associations.find((entry) => entry.id === cacheEntry.id);
        }
        else {
          association = selectedUnit.food_prescription.food_ingredient_associations.find((entry) => entry.id === cacheEntry.id);
        }

        if (association) {
          update.purchaseCalculatorQuantityMap.set(association.id, {
            association: {
              ...association,
              isRecipe: cacheEntry.isRecipe,
            },
            quantity: cacheEntry.quantity
          });
        }
      }
    }

    update.showFollowupForm = false;

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const startDate = getAsLocalDate(selectedUnit.initial_date);
    const endDate = getAsLocalDate(selectedUnit.final_date);

    if (today >= startDate && today < endDate) {
      const timeDiff = Math.abs(today.getTime() - startDate.getTime());

      const weekIndex = Math.floor(Math.ceil(timeDiff / (1000 * 3600 * 24)) / 7) + 1;

      if (weekIndex > 1) {
        const weekInitialDate = new Date(startDate);
        const weekFinalDate = new Date(startDate);

        weekInitialDate.setDate(weekInitialDate.getDate() + ((weekIndex - 1) * 7));
        weekFinalDate.setDate(weekFinalDate.getDate() + ((weekIndex) * 7) - 1);

        const followupFormIsUpToDate = selectedUnit.followup_form_submissions.some((entry) => {
          const submissionDate = getAsLocalDate(entry.submitted_at);

          return submissionDate >= weekInitialDate && submissionDate <= weekFinalDate;
        });

        update.showFollowupForm = !followupFormIsUpToDate;
      }

      this.onShowFollowupForm(false);
    }

    if (setState) {
      this.setState(update);
    }

    return update;
  }

  onBackClicked() {
    this.props.history.replace(routes.MAIN_PATH);
  }

  async onShowFollowupForm(setOverlayState=true) {
    if (setOverlayState) {
      this.setState({
        showFollowupForm: true,
        showFollowupReport: false,
      });
    }

    if (this.state.followupForm === null) {
      const followupForm = await this.getFollowupForm();

      followupForm.questions.sort((a, b) => a.order - b.order);

      const followupFormAnswerMap = new Map(this.state.followupFormAnswerMap);

      for (const question of followupForm.questions) {
        followupFormAnswerMap.set(question.id, null);
        this.followupFormInputRefMap.set(question.id, null);
      }

      this.setState({
        followupForm,
        followupFormAnswerMap
      });
    }
  }

  onSetFollowupFormAnswer(questionId, answer) {
    const followupFormAnswerMap = new Map(this.state.followupFormAnswerMap);

    followupFormAnswerMap.set(questionId, answer);

    this.setState({
      followupFormAnswerMap
    });
  }

  getUnitOptions() {
    return this.state.active_units.map((unit) => (
      <DefaultMenuButton
        key={`food_prescription:unit_selector:${unit.id}`}
        className="food-prescription__unit-button"
        onClick={() => this.onSelectUnit(unit, this.state.last_exam)}
        text={unit.name}
        color="blue"
      />
    ));
  }

  getGraphHeight() {
    if(this.state.screenWidth <= 380) {
      return 300;
    }

    if(this.state.screenWidth <= 600) {
      return 350;
    }

    return 400;
  }

  getDefaultGraphHeight() {
    if(this.state.screenWidth <= 420) {
      return 220;
    }

    if(this.state.screenWidth <= 600) {
      return 270;
    }

    if(this.state.screenWidth <= 1100) {
      return 350;
    }

    return null;
  }

  getPrescriptionDetails() {
    if (this.state.last_exam === null) {
      return (
        <p className="food-prescription__alert-text">

          <i className="fas fa-exclamation food-prescription__alert-text__icon"></i>
          O aluno ainda não realizou uma avaliação física

        </p>
      );
    }
    else if (!this.state.last_exam.data.basal_metabolic_rate || this.state.last_exam.data.basal_metabolic_rate.length <= 0) {
      return (
        <p className="food-prescription__alert-text">

          <i className="fas fa-exclamation food-prescription__alert-text__icon"></i>
          Taxa metabólica basal não especificada

        </p>
      );
    }
    else if (!this.state.last_exam.data.basal_metabolic_multiplier || this.state.last_exam.data.basal_metabolic_multiplier.length <= 0) {
      return (
        <p className="food-prescription__alert-text">

          <i className="fas fa-exclamation food-prescription__alert-text__icon"></i>
          Nível de atividade não especificado

        </p>
      );
    }
    else if (!this.state.last_exam.data.weight || this.state.last_exam.data.weight.length <= 0) {
      return (
        <p className="food-prescription__alert-text">

          <i className="fas fa-exclamation food-prescription__alert-text__icon"></i>
          Peso não especificado

        </p>
      );
    }
    else if (!this.state.last_exam.data.height || this.state.last_exam.data.height.length <= 0) {
      return (
        <p className="food-prescription__alert-text">

          <i className="fas fa-exclamation food-prescription__alert-text__icon"></i>
          Altura não especificada

        </p>
      );
    }
    else if (!this.state.selectedUnit.final_date) {
      return (
        <p className="food-prescription__alert-text">

          <i className="fas fa-exclamation food-prescription__alert-text__icon"></i>
          Previsão para fim de dieta não especificado

        </p>
      );
    }
    else if (this.state.weeklyExerciseEnergyExpense <= 0 && this.state.training_frequency === null) {
      return (
        <p className="food-prescription__alert-text">

          <i className="fas fa-exclamation food-prescription__alert-text__icon"></i>
          Frequência de treino semanal não definido

        </p>
      );
    }

    const weight = parseFloat(this.state.last_exam.data.weight);

    const basal_metabolic_rate = Math.round(10 * parseFloat(this.state.last_exam.data.basal_metabolic_rate)) / 10;
    const basal_metabolic_multiplier = parseFloat(this.state.last_exam.data.basal_metabolic_multiplier);

    const required_water_per_day = Math.round(10 * 0.035 * weight) / 10;

    const height = parseFloat(this.state.last_exam.data.height);

    const weeklyDietMeanEnergyInput = this.state.activePrescriptionData.energyStatistic.dailyMeans.reduce((sum, value) => sum + value, 0);

    const energyPerClassList = [];

    const energyMeans = [];

    for (const [key, value] of Object.entries(this.state.service_energy_expense_map)) {
      energyMeans.push(value * weight);

      energyPerClassList.push(
        <span
          key={`service:${key}:mean_energy`}
          className="food-prescription__class-energy-mean"
        >

          <span className="food-prescription__class-energy-mean__label">{key}:</span>
          <span className="food-prescription__class-energy-mean__value">{Math.round(value * weight)}</span> kcal/aula

        </span>
      );
    }

    const meanEnergyExpensePerClass = energyMeans.reduce((sum, value) => sum + value, 0) / energyMeans.length;

    let weeklyExerciseEnergyExpense;

    if (this.state.weeklyExerciseEnergyExpense > 0) {
      weeklyExerciseEnergyExpense = this.state.weeklyExerciseEnergyExpense;
    }
    else {
      weeklyExerciseEnergyExpense = this.state.training_frequency * meanEnergyExpensePerClass;
    }

    // const weeklyBasicActivityEnergyExpense = (7 * basal_metabolic_rate * (basal_metabolic_multiplier - 1)) - weeklyExerciseEnergyExpense;

    const startDate = getAsLocalDate(this.state.selectedUnit.initial_date);
    const endDate = getAsLocalDate(this.state.selectedUnit.final_date);

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    let currentWeek = 'Não iniciado';

    let weekInitialDate = null;
    let weekFinalDate = null;

    let remainingWeekDays = 0;

    let mainStatusIndicator = {
      status: '--disabled',
      text: 'Aguardando início da dieta',
      description: null
    };
    let weightMeasureIndicator = {
      status: '--disabled',
      text: 'Aguardando início da dieta'
    };
    let energyExpenseIndicator = {
      status: '--disabled',
      text: (
        <p className="food-prescription__active-prescription__details__status-field__value__text--disabled">
          Aguardando início da dieta
        </p>
      ),
      additionalText: ''
    };

    let prescriptionHasStarted = false;
    let prescriptionHasEnded = false;
    let currentWeekWeightMeasurement = false;
    let followupFormIsUpToDate = true;

    let weekIndex;

    if (today >= endDate) {
      prescriptionHasStarted = true;
      prescriptionHasEnded = true;

      currentWeek = 'Finalizado';

      mainStatusIndicator.text = 'Acompanhamento finalizado';
      mainStatusIndicator.description = 'Esperamos que tenha aproveitado ao máximo este acompanhamento. Como próximo passo, não deixe de realizar o agendamento de finalização com nosso nutricionista e, caso tenha interesse, estamos à sua disposição para iniciarmos um novo período de acompanhamento.';

      let lastWeightMeasurement = null;

      for (const entry of this.state.selectedUnit.weight_measurements) {
        if (lastWeightMeasurement === null || lastWeightMeasurement.date < entry.date) {
          lastWeightMeasurement = entry;
        }
      }

      weightMeasureIndicator.text = (
        <React.Fragment>

          Último peso registrado: {lastWeightMeasurement !== null ? (
            <React.Fragment>
              <span className="food-prescription__active-prescription__details__status-field__number">{lastWeightMeasurement.weight}</span> kg ({getAsLocalDateString(lastWeightMeasurement.date)})
            </React.Fragment>
          ) : 'Não registrado'}

        </React.Fragment>
      );

      let totalEnergyExpended = 0;

      for(const trainingData of this.state.selectedUnit.training_data) {
        const executionDate = getAsLocalDate(trainingData.date);

        if(executionDate >= startDate && executionDate <= endDate) {
          let entryEnergyExpense = 0;

          if (trainingData.energy_expended) {
            entryEnergyExpense = trainingData.energy_expended;
          }
          else {
            if (trainingData.is_custom_training && trainingData.training_day_id === null && trainingData.activity_reference_id !== null) {
              entryEnergyExpense = (weight * (trainingData.total_time / 60) * trainingData.activity_reference.met)
            }
            else if (trainingData.training_day) {
              entryEnergyExpense = trainingData.training_day.group_associations.reduce((accumulator, association) => {
                if (association.activity_reference) {
                  return accumulator + (weight * (association.time_required / 60) * association.activity_reference.met);
                }

                return accumulator;
              }, 0);
            }
          }

          totalEnergyExpended += entryEnergyExpense;
        }
      }

      const timeDiff = Math.abs(endDate.getTime() - startDate.getTime());
      const totalWeeks = Math.ceil(timeDiff / (1000 * 3600 * 24)) / 7;

      const totalExpectedEnergyExpense = totalWeeks * weeklyExerciseEnergyExpense;
      const percentageAccomplished = 100 * totalEnergyExpended / totalExpectedEnergyExpense;

      energyExpenseIndicator.text = (
        <p className={`food-prescription__active-prescription__details__status-field__value__text${energyExpenseIndicator.status}`}>
          Total de calorias gasta: <span className="food-prescription__active-prescription__details__status-field__number">{Math.round(totalEnergyExpended)}</span> / <span className="food-prescription__active-prescription__details__status-field__number">{Math.round(totalExpectedEnergyExpense)}</span> kcal (<span className="food-prescription__active-prescription__details__status-field__number">{Math.round(percentageAccomplished)}%</span>)
        </p>
      );
    }
    else if (today >= startDate) {
      prescriptionHasStarted = true;

      const timeDiff = Math.abs(today.getTime() - startDate.getTime());

      weekIndex = Math.floor(Math.ceil(timeDiff / (1000 * 3600 * 24)) / 7) + 1;
      currentWeek = `Semana ${weekIndex}`;

      weekInitialDate = new Date(startDate);
      weekFinalDate = new Date(startDate);

      weekInitialDate.setDate(weekInitialDate.getDate() + ((weekIndex - 1) * 7));
      weekFinalDate.setDate(weekFinalDate.getDate() + ((weekIndex) * 7) - 1);

      const weekTimeDiff = Math.abs(weekFinalDate.getTime() - today.getTime());

      remainingWeekDays = Math.ceil(weekTimeDiff / (1000 * 3600 * 24)) + 1;

      let weekFinalDayName = (new Intl.DateTimeFormat('pt-BR', {
        weekday: 'long'
      })).format(weekFinalDate);

      let currentWeekEnergyExpended = 0;
      let totalEnergyExpended = 0;

      for(const trainingData of this.state.selectedUnit.training_data) {
        const executionDate = getAsLocalDate(trainingData.date);

        if(executionDate >= startDate && executionDate <= weekFinalDate) {
          let entryEnergyExpense = 0;

          if (trainingData.energy_expended) {
            entryEnergyExpense = trainingData.energy_expended;
          }
          else {
            if (trainingData.is_custom_training && trainingData.training_day_id === null && trainingData.activity_reference_id !== null) {
              entryEnergyExpense = (weight * (trainingData.total_time / 60) * trainingData.activity_reference.met)
            }
            else if (trainingData.training_day) {
              entryEnergyExpense = trainingData.training_day.group_associations.reduce((accumulator, association) => {
                if (association.activity_reference) {
                  return accumulator + (weight * (association.time_required / 60) * association.activity_reference.met);
                }

                return accumulator;
              }, 0);
            }
          }

          totalEnergyExpended += entryEnergyExpense;

          if (executionDate >= weekInitialDate) {
            currentWeekEnergyExpended += entryEnergyExpense;
          }
        }
      }

      const currentWeekRemainingExpense = weeklyExerciseEnergyExpense - currentWeekEnergyExpended;

      currentWeekWeightMeasurement = this.state.selectedUnit.weight_measurements.find((entry) => {
        const measurementDate = getAsLocalDate(entry.date);

        return measurementDate >= weekInitialDate && measurementDate <= weekFinalDate;
      });

      if (weekIndex > 1) {
        followupFormIsUpToDate = this.state.selectedUnit.followup_form_submissions.some((entry) => {
          const submissionDate = getAsLocalDate(entry.submitted_at);

          return submissionDate >= weekInitialDate && submissionDate <= weekFinalDate;
        });
      }

      if (!currentWeekWeightMeasurement) {
        mainStatusIndicator.status = '--danger';
        mainStatusIndicator.text = 'Pesagem não realizada';
        mainStatusIndicator.description = `Não deixe de cadastrar seu peso até o dia ${weekFinalDate.toLocaleDateString()} (${weekFinalDayName}).`;

        weightMeasureIndicator.status = '--danger';
        weightMeasureIndicator.text = 'Não realizada';
      }
      else {
        weightMeasureIndicator.status = '--ok';
        weightMeasureIndicator.text = (
          <React.Fragment>

            <span className="food-prescription__active-prescription__details__status-field__number">{currentWeekWeightMeasurement.weight}</span> kg ({getAsLocalDateString(currentWeekWeightMeasurement.date)})

          </React.Fragment>
        );

        if (currentWeekEnergyExpended < weeklyExerciseEnergyExpense) {
          if ((remainingWeekDays * meanEnergyExpensePerClass) <= currentWeekRemainingExpense) {
            mainStatusIndicator.text = 'Não desista do treino';
            mainStatusIndicator.status = '--danger';
            mainStatusIndicator.description = `Mesmo que não seja possível comprir sua meta semanal de treino, um pouco de treino ainda é melhor do que nenhum treino. Então deixa o desânimo de lado e bora treinar mais um pouco!`;
          }
          else {
            mainStatusIndicator.text = 'Não deixe de realizar seus exercícios semanais';
            mainStatusIndicator.status = '--alert';
            mainStatusIndicator.description = `Ainda faltam ${Math.round(currentWeekRemainingExpense)} kcal, o que, em média, te deixa em dívida de pelo menos mais ${Math.ceil(currentWeekRemainingExpense / meanEnergyExpensePerClass)} treinos. Bora treinar!`;
          }
        }
        else if (totalEnergyExpended < (weekIndex * weeklyExerciseEnergyExpense)) {
          mainStatusIndicator.text = 'Se possível, bora treinar para tirar o atraso';
          mainStatusIndicator.status = '--alert';
          mainStatusIndicator.description = `Parabéns por ter comprido a meta desta semana! Agora, se você quiser caprichar um pouco mais, que tal compensar o que vc deixou de treinar nas semanas anteriores? Sua dívida atual: ${Math.round((weekIndex * weeklyExerciseEnergyExpense) - totalEnergyExpended)} kcal`;
        }
        else {
          mainStatusIndicator.text = 'Parabéns, continue assim!';
          mainStatusIndicator.status = '--ok';
          mainStatusIndicator.description = `Você está no caminho certo. Agora é só manter esse ritmo e não desanimar! Quanto mais você conseguir se adaptar para incorporar hábitos saudáveis no seu dia a dia, mais duradouros serão os resultados que você irá colher no final deste acompanhamento.`;
        }
      }

      energyExpenseIndicator.additionalText = (
        <React.Fragment>
          (faltam <span className="food-prescription__active-prescription__details__status-field__number">{Math.round(currentWeekRemainingExpense)}</span>)
        </React.Fragment>
      );

      if (currentWeekEnergyExpended < (weeklyExerciseEnergyExpense / 3)) {
        energyExpenseIndicator.status = '--danger';
      }
      else if (currentWeekEnergyExpended < weeklyExerciseEnergyExpense) {
        energyExpenseIndicator.status = '--alert';
      }
      else {
        energyExpenseIndicator.status = '--ok';
        energyExpenseIndicator.additionalText = '';
      }

      energyExpenseIndicator.text = (
        <p className={`food-prescription__active-prescription__details__status-field__value__text${energyExpenseIndicator.status}`}>
          <span className="food-prescription__active-prescription__details__status-field__number">{Math.round(currentWeekEnergyExpended)} / {Math.round(weeklyExerciseEnergyExpense)}</span> kcal {energyExpenseIndicator.additionalText}
        </p>
      );
    }

    return (
      <React.Fragment>

        {(prescriptionHasStarted && this.state.selectedUnit.indicators_visible_to_student) &&
          <React.Fragment>

            {(!prescriptionHasEnded && !currentWeekWeightMeasurement) &&
              <React.Fragment>

                <h3 className="food-prescription__measure-weight__title">Pesagem semanal</h3>

                <div className="food-prescription__measure-weight">

                  <div className="food-prescription__measure-weight__inputs">

                    <HalfWrapper>

                      <DefaultInput
                        className="food-prescription__measure-weight__input"
                        labelClassName="food-prescription__measure-weight__input-label"
                        name="weightMeasurementDate"
                        label="Data da pesagem:"
                        type="date"
                        placeholder="Data de execução"
                        handleInputChange={(event) => this.handleInputChange(event)}
                        value={this.state.weightMeasurementDate}
                        min={getLocalDateIsoString(startDate)}
                        max={this.todayIsoString}
                        autoComplete="off"
                      />

                      <DefaultInput
                        className="food-prescription__measure-weight__input"
                        labelClassName="food-prescription__measure-weight__input-label"
                        name="weightMeasurementValue"
                        label="Peso:"
                        type="number"
                        placeholder="-"
                        min="0"
                        step="0.1"
                        handleInputChange={(event) => this.handleInputChange(event)}
                        value={this.state.weightMeasurementValue}
                        autoComplete="off"
                        suffix="kg"
                        onFocus={(event) => event.target.select()}
                      />

                    </HalfWrapper>

                  </div>

                  <DefaultMenuButton
                    className="food-prescription__measure-weight__confirm-button"
                    onClick={() => this.onMeasureWeight()}
                    text={(
                      <React.Fragment>

                        <i className="fa-solid fa-check food-prescription__measure-weight__confirm-button__icon"></i>
                        {this.state.screenWidth > 360 ? 'Registrar' : ''}

                      </React.Fragment>
                    )}
                    color="green"
                    disabled={this.state.weightMeasurementDate.length <= 0 || this.state.weightMeasurementValue.length <= 0}
                  />

                </div>

                <HorizontalRule />

              </React.Fragment>
            }

            {(!prescriptionHasEnded && !followupFormIsUpToDate) &&
              <React.Fragment>

                <h3 className="food-prescription__followup-form__title">Pesquisa de acompanhamento</h3>
                <p className="food-prescription__followup-form__sub-title">(Não leva nem 1 minuto!)</p>

                <div className="food-prescription__followup-form">

                  <DefaultMenuButton
                    className="food-prescription__followup-form__show-button"
                    onClick={() => this.onShowFollowupForm()}
                    text={(
                      <React.Fragment>

                        <i className="fa-solid fa-list-check food-prescription__followup-form__show-button__icon"></i>
                        Preencher pesquisa

                      </React.Fragment>
                    )}
                    color="purple"
                  />

                </div>

                <HorizontalRule />

              </React.Fragment>
            }

            {this.state.selectedUnit.followup_form_submissions.length > 0 &&
              <React.Fragment>

                <div className="food-prescription__followup-report-access">

                  <DefaultMenuButton
                    className="food-prescription__followup-report-access__show-button"
                    onClick={() => this.setState({showFollowupReport: true})}
                    text={(
                      <React.Fragment>

                        <i className="fa-solid fa-chart-column food-prescription__followup-report-access__show-button__icon"></i>
                        Relatório de acompanhamento

                      </React.Fragment>
                    )}
                    color="blue"
                  />

                </div>

                <HorizontalRule />

              </React.Fragment>
            }

            {this.state.selectedUnit.weight_measurements.length > 0 &&
              <React.Fragment>
                <section
                  className="food-prescription__weight-history"
                >

                  <header
                    className="food-prescription__weight-history__header"
                    onClick={() => this.setState({weightHistoryVisible: !this.state.weightHistoryVisible})}
                  >

                    <h3 className="food-prescription__weight-history__header__text">
                      <i className="fa-solid fa-weight-scale food-prescription__weight-history__header__text-icon"></i>
                      Histórico de pesagens
                    </h3>

                    {this.state.weightHistoryVisible ?
                      <i className="fas fa-chevron-down food-prescription__weight-history__header__visible-icon"></i>:
                      <i className="fas fa-chevron-up food-prescription__weight-history__header__visible-icon"></i>
                    }

                  </header>

                  <VerticalAccordionContainer
                    className="vertical-accordion-container food-prescription__weight-history__content"
                    pose={this.state.weightHistoryVisible ? 'verticalOpen' : 'verticalClosed'}>

                    <div className="vertical-accordion-container food-prescription__weight-history__content-wrapper">

                      <ul className="food-prescription__weight-history__list">

                        {this.state.selectedUnit.weight_measurements.map((entry) => (
                          <li
                            key={`weight_measurement:${entry.date}`}
                            className="food-prescription__weight-history__entry"
                          >

                            <div className="food-prescription__weight-history__entry__data-container">

                              <p className="food-prescription__weight-history__entry__date">{this.getDatetimeText(entry.date)}</p>

                              <p className="food-prescription__weight-history__entry__weight">
                                <span className="food-prescription__weight-history__entry__weight__number">{entry.weight}</span>
                                kg
                              </p>

                            </div>

                            <div className="food-prescription__weight-history__entry__actions-container">

                              {!prescriptionHasEnded &&
                                <DefaultMenuButton
                                  className="food-prescription__weight-history__entry__action"
                                  onClick={() => this.setState({weightMeasurementToRemove: entry})}
                                  text={<i className="fas fa-trash-alt"></i>}
                                  color="red"
                                />
                              }

                            </div>

                          </li>
                        ))}

                      </ul>

                    </div>

                  </VerticalAccordionContainer>

                </section>

                <HorizontalRule />
              </React.Fragment>
            }

          </React.Fragment>
        }

        <div className="food-prescription__active-prescription__details">

          {this.state.selectedUnit.indicators_visible_to_student &&
            <React.Fragment>

              <h1 className="food-prescription__active-prescription__details__current-week">{currentWeek}</h1>

              <div className="food-prescription__active-prescription__details__status">

                <h2 className="food-prescription__active-prescription__details__status__label">Situação:</h2>
                <div className="food-prescription__active-prescription__details__status__value">

                  <p className={`food-prescription__active-prescription__details__status__main-value${mainStatusIndicator.status}`}>{mainStatusIndicator.text}</p>
                  {mainStatusIndicator.description !== null &&
                    <p className="food-prescription__active-prescription__details__status__description">

                      {mainStatusIndicator.description}

                    </p>
                  }

                </div>

              </div>

              <div className="food-prescription__active-prescription__details__status-field">

                <h2 className="food-prescription__active-prescription__details__status-field__label">Pesagem:</h2>
                <div className="food-prescription__active-prescription__details__status-field__value">

                  <p className={`food-prescription__active-prescription__details__status-field__value__text${weightMeasureIndicator.status}`}>{weightMeasureIndicator.text}</p>

                </div>

              </div>

              <div className="food-prescription__active-prescription__details__status-field">

                <h2 className="food-prescription__active-prescription__details__status-field__label">Atividade física:</h2>
                <div className="food-prescription__active-prescription__details__status-field__value">

                  {energyExpenseIndicator.text}
                  {this.state.service_energy_expense_map !== null &&
                    <p className="food-prescription__active-prescription__details__status-field__description">

                      Médias de gasto calório de aulas FYD: {energyPerClassList}

                    </p>
                  }

                </div>

              </div>

              <HorizontalRule />

              <div className="food-prescription__active-prescription__details__indicators-container">

                <div className="food-prescription__active-prescription__details__indicators-wrapper">

                  <div className="food-prescription__active-prescription__details__indicator">

                    <h2 className="food-prescription__active-prescription__details__indicator__label">Ingestão de água</h2>
                    <p className="food-prescription__active-prescription__details__indicator__value">

                      <span className="food-prescription__active-prescription__details__indicator__value__number">{required_water_per_day}</span>
                      <span className="food-prescription__active-prescription__details__indicator__value__unit">L/dia</span>

                    </p>

                  </div>

                  <div className="food-prescription__active-prescription__details__indicator">

                    <h2 className="food-prescription__active-prescription__details__indicator__label">Taxa basal</h2>
                    <p className="food-prescription__active-prescription__details__indicator__value">

                      <span className="food-prescription__active-prescription__details__indicator__value__number">{basal_metabolic_rate}</span>
                      <span className="food-prescription__active-prescription__details__indicator__value__unit">kcal/dia</span>

                    </p>

                  </div>

                  <div
                    className={`food-prescription__active-prescription__details__indicator${this.state.ignoreExerciseEnergyExpense ? '--disabled' : ''} food-prescription__active-prescription__details__indicator--clickable`}
                    onClick={() => this.setState({ignoreExerciseEnergyExpense: !this.state.ignoreExerciseEnergyExpense})}
                  >

                    <h2 className="food-prescription__active-prescription__details__indicator__label">Meta de atividade física</h2>
                    <p className="food-prescription__active-prescription__details__indicator__value">

                      <span className="food-prescription__active-prescription__details__indicator__value__number">{this.state.ignoreExerciseEnergyExpense ? 0 : Math.round(weeklyExerciseEnergyExpense)}</span>
                      <span className="food-prescription__active-prescription__details__indicator__value__unit">kcal/semana</span>

                    </p>

                  </div>

                </div>

              </div>
            </React.Fragment>
          }

          {this.state.selectedUnit.graph_visible_to_student &&
            <div className="food-prescription__graphs-container">

              <DietGraph
                // height={this.getDefaultGraphHeight()}
                legendVerticalAlign={this.state.screenWidth > 770 ? 'center' : 'top'}
                legendHorizontalAlign={this.state.screenWidth > 770 ? 'right' : 'center'}
                bodyHeight={height}
                startDate={startDate}
                endDate={endDate}
                normalLegendOrder={this.state.screenWidth <= 770}
                basalMetabolicRate={basal_metabolic_rate}
                basalMetabolicMultiplier={basal_metabolic_multiplier}
                weeklyDietMeanEnergyInput={weeklyDietMeanEnergyInput}
                weeklyEnergyExpenseOffset={this.state.ignoreExerciseEnergyExpense ? -weeklyExerciseEnergyExpense : 0}
                initialWeight={weight}
                weightPoints={this.state.selectedUnit.weight_measurements.map((entry) => WeightPoint(entry.weight, entry.date))}
                // normalLegendOrder={true}
              />

            </div>
          }

        </div>

      </React.Fragment>
    );
  }

  getDatetimeText(isoDatetime) {
    const date = getAsLocalDate(isoDatetime);

    let dateText;

    if(this.state.screenWidth > 360) {
      dateText = date.toLocaleDateString();
    }
    else {
      const dateFormat = {
        day: '2-digit',
        month: '2-digit'
      };

      dateText = new Intl.DateTimeFormat('pt-BR', dateFormat).format(date);
    }

    return dateText;
  }

  async onMeasureWeight() {
    if(this.state.weightMeasurementDate.length > 0 && this.state.weightMeasurementValue.length > 0) {
      const data = {
        weight: parseFloat(this.state.weightMeasurementValue),
        date: this.state.weightMeasurementDate,
      };

      this.setState({
        confirmInProgress: true,
        weightMeasurementToRegister: data
      });

      try{
        if(await postModel(routes.WEIGHT_MEASUREMENT_POST_API, data)) {
          const active_units = this.state.active_units.map((entry) => {
            entry.weight_measurements = [
              ...entry.weight_measurements,
              data
            ];
            entry.weight_measurements.sort((a, b) => b.date.localeCompare(a.date));

            return entry;
          });

          this.setState({
            weightMeasurementValue: '',
            weightMeasurementToRegister: null,
            confirmFailed: false,
            confirmInProgress: false,
            active_units
          });
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_ERROR_MESSAGE + '.';

        if(errors instanceof Array) {
          for(let error of errors) {
            switch (error.code) {
              // case 104:
              //   for(let parameter of error.parameters) {
              //     switch (parameter.name) {
              //       case 'contracts':
              //         errorDescription = 'Serviço vinculado à um contrato de aluno. Estes contratos devem ser excluídos antes de excluir este serviço.';
              //
              //         break;
              //       default:
              //     }
              //   }
              //
              //   break;
              case 201:
                errorDescription = 'Limite de registros de peso alcançado. Por favor, tente novamente dentro de alguns minutos.';

                break;
              case 209:
                errorDescription = 'Sessão do usuário expirada.';

                break;
              default:
            }
          }
        }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }
  }

  getMacronutrientDistribution(association) {
    let carbohydrateWeight = 0;
    let proteinWeight = 0;
    let fatWeight = 0;
    let totalMean = 0;

    let recipeNutritionalData;

    if (association.isRecipe) {
      recipeNutritionalData = getFoodRecipeNutricionalData(association.food_recipe, association.recipe_adjustments);
    }
    else {
      recipeNutritionalData = getFoodRecipeNutricionalData({
        basic_ingredient_associations: [association],
        advanced_ingredient_associations: [],
        people_served: 1
      });
    }

    carbohydrateWeight = CARBOHYDRATE_TO_KCAL_CONVERSION_CONTANT * recipeNutritionalData.carbohydrateWeight;
    proteinWeight = PROTEIN_TO_KCAL_CONVERSION_CONTANT * recipeNutritionalData.proteinWeight;
    fatWeight = FAT_TO_KCAL_CONVERSION_CONTANT * recipeNutritionalData.fatWeight;

    totalMean = carbohydrateWeight + proteinWeight + fatWeight;

    if (totalMean <= 0) {
      return null;
    }

    const total_carbohydrate_percentage = carbohydrateWeight / totalMean;
    const total_protein_percentage = proteinWeight / totalMean;
    const total_fat_percentage = fatWeight / totalMean;

    return (
      <div className="food-prescription__active-diet__meal-option__macronutrient-distribution__container">

        <div className="food-prescription__active-diet__meal-option__macronutrient-distribution">

          <div className="food-prescription__active-diet__meal-option__macronutrient-distribution__nutrient--carb">

            <h4 className="food-prescription__active-diet__meal-option__macronutrient-distribution__nutrient__label">C</h4>
            <div
              className="food-prescription__active-diet__meal-option__macronutrient-distribution__nutrient__bar"
              style={{width: `${6 * total_carbohydrate_percentage}em`}}
            >
            </div>
            <p className="food-prescription__active-diet__meal-option__macronutrient-distribution__nutrient__value">{Math.round(100 * total_carbohydrate_percentage)}%</p>

          </div>

          <div className="food-prescription__active-diet__meal-option__macronutrient-distribution__nutrient--protein">

            <h4 className="food-prescription__active-diet__meal-option__macronutrient-distribution__nutrient__label">P</h4>
            <div
              className="food-prescription__active-diet__meal-option__macronutrient-distribution__nutrient__bar"
              style={{width: `${6 * total_protein_percentage}em`}}
            >
            </div>
            <p className="food-prescription__active-diet__meal-option__macronutrient-distribution__nutrient__value">{Math.round(100 * total_protein_percentage)}%</p>

          </div>

          <div className="food-prescription__active-diet__meal-option__macronutrient-distribution__nutrient--fat">

            <h4 className="food-prescription__active-diet__meal-option__macronutrient-distribution__nutrient__label">G</h4>
            <div
              className="food-prescription__active-diet__meal-option__macronutrient-distribution__nutrient__bar"
              style={{width: `${6 * total_fat_percentage}em`}}
            >
            </div>
            <p className="food-prescription__active-diet__meal-option__macronutrient-distribution__nutrient__value">{Math.round(100 * total_fat_percentage)}%</p>

          </div>

        </div>

      </div>
    );
  }

  getActiveDietMealAssociationContent(association) {
    const mealName = association.isRecipe ? association.food_recipe.name : association.food_ingredient_source_association.food_ingredient.name;
    const associationKey = `active-diet:${association.isRecipe ? 'recipe_association' : 'ingredient_association'}:${association.id}`;

    // const weekdays = [
    //   'segunda',
    //   'terça',
    //   'quarta',
    //   'quinta',
    //   'sexta',
    //   'sábado',
    //   'domingo',
    // ];
    const weekdays = [
      'S',
      'T',
      'Q',
      'Q',
      'S',
      'S',
      'D',
    ];

    let convertedTodayWeekDay = this.todayWeekDay - 1;
    if (convertedTodayWeekDay < 0) {
      convertedTodayWeekDay = 6;
    }

    let mayEatToday = false;

    const weekdayInputs = weekdays.map((weekday, index) => {
      const checked = association.weekdays & Math.pow(2, index);

      if (index === convertedTodayWeekDay && checked) {
        mayEatToday = true;
      }

      return (
        <div
          key={`${associationKey}:weekday_enabled:${index}`}
          className={`food-prescription__active-diet__meal-option__weekday${!checked ? '--disabled': index === convertedTodayWeekDay ? '--highlighted' : ''}`}
        >

          <p className="food-prescription__active-diet__meal-option__weekday__text">

            {weekday}

          </p>

          <button
            className="food-prescription__active-diet__meal-option__weekday__select-button"
            disabled={true}
          >

            {(checked > 0) &&
              <i className="fas fa-check"></i>
            }

          </button>

        </div>
      );
    });

    const ingredientList = [];

    if (association.isRecipe) {
      const ingredients = [
        ...association.food_recipe.advanced_ingredient_associations.map((entry) => {
          let adjustment = association.recipe_adjustments.find((recipe_adjustment) => recipe_adjustment.food_recipe_advanced_ingredient_association_id === entry.id);

          if (!adjustment) {
            adjustment = entry;
          }

          const newEntry = {
            key: `${associationKey}:advanced_ingredient:${entry.id}`,
            order: entry.order,
            quantity: adjustment.quantity,
            isBasic: false,
            name: entry.name !== null ? entry.name : entry.advanced_ingredient.name
          };

          return newEntry;
        }),
        ...association.food_recipe.basic_ingredient_associations.map((entry) => {
          let adjustment = association.recipe_adjustments.find((recipe_adjustment) => recipe_adjustment.food_recipe_basic_ingredient_association_id === entry.id);

          if (!adjustment) {
            adjustment = entry;
          }

          const newEntry = {
            key: `${associationKey}:basic_ingredient:${entry.id}`,
            food_ingredient_source_association: entry.food_ingredient_source_association,
            food_ingredient_measurement_association: adjustment.food_ingredient_measurement_association,
            order: entry.order,
            quantity: adjustment.quantity,
            isBasic: true,
            name: entry.name !== null ? entry.name : entry.food_ingredient_source_association.food_ingredient.name
          };

          return newEntry;
        })
      ];

      ingredients.sort((a, b) => a.order - b.order);

      for (const ingredient of ingredients) {
        let ingredientText = ingredient.name;
        let categoryText = 'Receita';

        let weightText;
        let weightValue;

        if (ingredient.isBasic) {
          categoryText = ingredient.food_ingredient_source_association.food_ingredient.food_classification.name;

          if (ingredient.food_ingredient_source_association.processing_method_id !== null) {
            ingredientText += ` - ${ingredient.food_ingredient_source_association.processing_method.name}`;
          }

          weightValue = Math.round(10 * ingredient.quantity * ingredient.food_ingredient_measurement_association.weight_reference) / 10;

          if (ingredient.food_ingredient_measurement_association.food_measurement_id !== FOOD_MEASUREMENT_GRAM_ID) {
            weightText = (
              <p className="food-prescription__active-diet__meal-option__ingredient__quantity">
                <span className="food-prescription__active-diet__meal-option__ingredient__quantity__label">Quantidade:</span>

                <span className="food-prescription__active-diet__meal-option__ingredient__quantity__wrapper">

                  <span className="food-prescription__active-diet__meal-option__ingredient__quantity__number">
                    {ingredient.quantity}
                  </span>
                  <span className="food-prescription__active-diet__meal-option__ingredient__quantity__unit">
                    {ingredient.food_ingredient_measurement_association.food_measurement.name}
                  </span>

                </span>

                <span className="food-prescription__active-diet__meal-option__ingredient__quantity__separator">
                  ou
                </span>

                <span className="food-prescription__active-diet__meal-option__ingredient__quantity__wrapper">

                  <span className="food-prescription__active-diet__meal-option__ingredient__quantity__number">
                    {weightValue}
                  </span>
                  <span className="food-prescription__active-diet__meal-option__ingredient__quantity__unit">
                    {ingredient.food_ingredient_measurement_association.weight_reference_unit.shortname}
                  </span>

                </span>
              </p>
            );
          }
          else {
            weightText = (
              <p className="food-prescription__active-diet__meal-option__ingredient__quantity">
                <span className="food-prescription__active-diet__meal-option__ingredient__quantity__label">Quantidade:</span>

                <span className="food-prescription__active-diet__meal-option__ingredient__quantity__wrapper">

                  <span className="food-prescription__active-diet__meal-option__ingredient__quantity__number">
                    {weightValue}
                  </span>
                  <span className="food-prescription__active-diet__meal-option__ingredient__quantity__unit">
                    {ingredient.food_ingredient_measurement_association.weight_reference_unit.shortname}
                  </span>

                </span>
              </p>
            );
          }
        }
        else {
          weightText = (
            <p className="food-prescription__active-diet__meal-option__ingredient__quantity">
              <span className="food-prescription__active-diet__meal-option__ingredient__quantity__label">Quantidade:</span>

              <span className="food-prescription__active-diet__meal-option__ingredient__quantity__wrapper">

                <span className="food-prescription__active-diet__meal-option__ingredient__quantity__number">
                  {ingredient.quantity}
                </span>
                <span className="food-prescription__active-diet__meal-option__ingredient__quantity__unit">
                  Receita
                </span>

              </span>
            </p>
          );
        }

        ingredientList.push(
          <li
            key={ingredient.key}
            className="food-prescription__active-diet__meal-option__ingredient"
          >
            <div className="food-prescription__active-diet__meal-option__ingredient__title-wrapper">

              <i className="fa-solid fa-circle food-prescription__active-diet__meal-option__ingredient__bullet"></i>

              <div className="food-prescription__active-diet__meal-option__ingredient__name-wrapper">

                <h4 className="food-prescription__active-diet__meal-option__ingredient__name">{ingredientText}:</h4>
                <p className="food-prescription__active-diet__meal-option__ingredient__category">{categoryText}</p>

              </div>

            </div>

            {weightText}

          </li>
        );
      }
    }
    else {
      let ingredientText = association.food_ingredient_source_association.food_ingredient.name;

      if (association.food_ingredient_source_association.processing_method_id !== null) {
        ingredientText += ` - ${association.food_ingredient_source_association.processing_method.name}`;
      }

      let weightValue = Math.round(10 * association.quantity * association.food_ingredient_measurement_association.weight_reference) / 10;

      let weightText;

      if (association.food_ingredient_measurement_association.food_measurement_id !== FOOD_MEASUREMENT_GRAM_ID) {
        weightText = (
          <p className="food-prescription__active-diet__meal-option__ingredient__quantity">
            <span className="food-prescription__active-diet__meal-option__ingredient__quantity__label">Quantidade:</span>

            <span className="food-prescription__active-diet__meal-option__ingredient__quantity__wrapper">

              <span className="food-prescription__active-diet__meal-option__ingredient__quantity__number">
                {association.quantity}
              </span>
              <span className="food-prescription__active-diet__meal-option__ingredient__quantity__unit">
                {association.food_ingredient_measurement_association.food_measurement.name}
              </span>

            </span>

            <span className="food-prescription__active-diet__meal-option__ingredient__quantity__separator">
              ou
            </span>

            <span className="food-prescription__active-diet__meal-option__ingredient__quantity__wrapper">

              <span className="food-prescription__active-diet__meal-option__ingredient__quantity__number">
                {weightValue}
              </span>
              <span className="food-prescription__active-diet__meal-option__ingredient__quantity__unit">
                {association.food_ingredient_measurement_association.weight_reference_unit.shortname}
              </span>

            </span>
          </p>
        );
      }
      else {
        weightText = (
          <p className="food-prescription__active-diet__meal-option__ingredient__quantity">
            <span className="food-prescription__active-diet__meal-option__ingredient__quantity__label">Quantidade:</span>

            <span className="food-prescription__active-diet__meal-option__ingredient__quantity__wrapper">

              <span className="food-prescription__active-diet__meal-option__ingredient__quantity__number">
                {weightValue}
              </span>
              <span className="food-prescription__active-diet__meal-option__ingredient__quantity__unit">
                {association.food_ingredient_measurement_association.weight_reference_unit.shortname}
              </span>

            </span>
          </p>
        );
      }

      ingredientList.push(
        <li
          key={`${associationKey}:ingredient_description`}
          className="food-prescription__active-diet__meal-option__ingredient"
        >
          <div className="food-prescription__active-diet__meal-option__ingredient__title-wrapper">

            <i className="fa-solid fa-circle food-prescription__active-diet__meal-option__ingredient__bullet"></i>

            <div className="food-prescription__active-diet__meal-option__ingredient__name-wrapper">

              <h4 className="food-prescription__active-diet__meal-option__ingredient__name">{ingredientText}:</h4>
              <p className="food-prescription__active-diet__meal-option__ingredient__category">{association.food_ingredient_source_association.food_ingredient.food_classification.name}</p>

            </div>

          </div>

          {weightText}

        </li>
      );
    }

    return (
      <section
        className="food-prescription__active-diet__meal-option"
        key={associationKey}
      >

        <header
          className={`food-prescription__active-diet__meal-option__header${mayEatToday ? '--highlighted' : ''}`}
          onClick={() => {
            let mealAssociationVisibilityMap = {...this.state.mealAssociationVisibilityMap};

            if (this.state.mealAssociationVisibilityMap[associationKey] !== true) {
              mealAssociationVisibilityMap[associationKey] = true;
            }
            else {
              mealAssociationVisibilityMap[associationKey] = false;
            }

            this.setState({mealAssociationVisibilityMap});
          }}
        >

          <div className="food-prescription__active-diet__meal-option__header__wrapper">

            <p className="food-prescription__active-diet__meal-option__header__text-icon">{association.priority}</p>

            <div className="food-prescription__active-diet__meal-option__header__content-wrapper">

              <h3 className="food-prescription__active-diet__meal-option__header__text">
                {association.name !== null ? association.name : mealName}
              </h3>

              <p className="food-prescription__active-diet__meal-option__sub-title">Consumir nos dias:</p>

              <div className="food-prescription__active-diet__meal-option__weekdays">

                {weekdayInputs}

              </div>

            </div>

          </div>

          {this.state.mealAssociationVisibilityMap[associationKey] === true ?
            <i className="fas fa-chevron-down food-prescription__active-diet__meal-option__header__visible-icon"></i>:
            <i className="fas fa-chevron-up food-prescription__active-diet__meal-option__header__visible-icon"></i>
          }

        </header>

        <VerticalAccordionSubContainer
          className="vertical-accordion-container food-prescription__active-diet__meal-option__content"
          pose={this.state.mealAssociationVisibilityMap[associationKey] === true ? 'verticalSubOpen' : 'verticalSubClosed'}>

          <div className="vertical-accordion-container food-prescription__active-diet__meal-option__content-wrapper">

            {this.state.mealAssociationVisibilityMap[associationKey] === true ? this.getMacronutrientDistribution(association) : null}

            <p className="food-prescription__active-diet__meal-option__ingredient__sub-title">

              Ingredientes:

            </p>

            {(association.isRecipe && association.food_recipe.people_served !== null && association.food_recipe.people_served > 1) &&
              <p className="food-prescription__active-diet__meal-option__people-served">
                <span className="food-prescription__active-diet__meal-option__people-served__label">
                  Rendimento:
                </span>
                <span className="food-prescription__active-diet__meal-option__people-served__value">
                  {association.food_recipe.people_served} Pessoas
                </span>
              </p>
            }

            <ul className="food-prescription__active-diet__meal-option__ingredient-list">

              {ingredientList}

            </ul>

            {(association.isRecipe && (association.food_recipe.preparation_description !== null || association.food_recipe.preparation_steps.length > 0)) &&
              <React.Fragment>

                <HorizontalRule
                  color="black"
                />

                <section
                  className="food-prescription__active-diet__meal-option__preparation"
                >

                  <header
                    className="food-prescription__active-diet__meal-option__preparation__header"
                    onClick={() => {
                      let mealPreparationVisibilityMap = {...this.state.mealPreparationVisibilityMap};

                      if (this.state.mealPreparationVisibilityMap[associationKey] !== true) {
                        mealPreparationVisibilityMap[associationKey] = true;
                      }
                      else {
                        mealPreparationVisibilityMap[associationKey] = false;
                      }

                      this.setState({mealPreparationVisibilityMap});
                    }}
                  >

                    <h4 className="food-prescription__active-diet__meal-option__preparation__header__text">
                      Instruções de preparação
                    </h4>

                    {this.state.mealPreparationVisibilityMap[associationKey] === true ?
                      <i className="fa-solid fa-chevron-down food-prescription__active-diet__meal-option__preparation__header__visible-icon"></i>:
                      <i className="fa-solid fa-chevron-up food-prescription__active-diet__meal-option__preparation__header__visible-icon"></i>
                    }

                  </header>

                  <VerticalAccordionSub2Container
                    className="vertical-accordion-container food-prescription__active-diet__meal-option__preparation__content"
                    pose={this.state.mealPreparationVisibilityMap[associationKey] === true ? 'verticalSub2Open' : 'verticalSub2Closed'}>

                    <div className="vertical-accordion-container food-prescription__active-diet__meal-option__preparation__content-wrapper">

                      {association.food_recipe.preparation_description !== null &&
                        <p className="food-prescription__active-diet__meal-option__preparation__description">

                          {association.food_recipe.preparation_description}

                        </p>
                      }

                      {association.food_recipe.preparation_steps.length > 0 &&
                        <ul className="food-prescription__active-diet__meal-option__preparation__step-list">

                          {association.food_recipe.preparation_steps.map((step) => {
                            return (
                              <li
                                key={`${associationKey}:preparation_step:${step.id}`}
                                className="food-prescription__active-diet__meal-option__preparation__step"
                              >
                                <div className="food-prescription__active-diet__meal-option__preparation__step__name-wrapper">

                                  {/* <i className="fa-solid fa-circle food-prescription__active-diet__meal-option__preparation__step__bullet"></i> */}

                                  <h4 className="food-prescription__active-diet__meal-option__preparation__step__name">{`${step.order}) `}{step.name}{step.step_duration !== null ? ` (${step.step_duration} min)` : ''}</h4>

                                </div>

                                <p className="food-prescription__active-diet__meal-option__preparation__step__instruction">

                                  {step.step_instruction}

                                </p>

                              </li>
                            );
                          })}

                        </ul>
                      }

                    </div>

                  </VerticalAccordionSub2Container>

                </section>

              </React.Fragment>
            }

          </div>

        </VerticalAccordionSubContainer>

      </section>
    );
  }

  getCalculatorMealAssociationContent(association) {
    const mealName = association.isRecipe ? association.food_recipe.name : association.food_ingredient_source_association.food_ingredient.name;
    const associationKey = `purchase-calculator:${association.isRecipe ? 'recipe_association' : 'ingredient_association'}:${association.id}`;

    let quantity = 0;

    if (this.state.purchaseCalculatorQuantityMap.has(association.id)) {
      quantity = this.state.purchaseCalculatorQuantityMap.get(association.id).quantity;
    }

    return (
      <li
        className="food-prescription__purchase-calculator__meal-option"
        key={associationKey}
      >

        <div
          className="food-prescription__purchase-calculator__meal-option__header"
        >

          <div className="food-prescription__purchase-calculator__meal-option__header__wrapper">

            <p className="food-prescription__purchase-calculator__meal-option__header__text-icon">{association.priority}</p>

            <h3 className="food-prescription__purchase-calculator__meal-option__header__text">
              {association.name !== null ? association.name : mealName}
            </h3>

          </div>

          <DefaultInput
            className="food-prescription__purchase-calculator__meal-option__input"
            labelClassName="food-prescription__purchase-calculator__meal-option__input-label"
            name={`purchase_calculator:meal_option:${association.id}`}
            label="Quantidade:"
            type="number"
            placeholder="-"
            min="0"
            step="0.1"
            handleInputChange={(event) => this.onUpdateShoppingQuantity(event, association)}
            value={quantity}
            autoComplete="off"
            suffix={this.state.screenWidth <= 360 ? null : 'Preparo(s)'}
            onFocus={(event) => event.target.select()}
          />

        </div>

      </li>
    );
  }

  getCalculatorResult() {
    const purchaseMap = new Map();

    for (const entry of this.state.purchaseCalculatorQuantityMap.values()) {
      if (entry.quantity <= 0) {
        continue;
      }

      if (entry.association.isRecipe) {
        for (const recipeAssociation of entry.association.food_recipe.advanced_ingredient_associations) {
          const key = `recipe:${recipeAssociation.advanced_ingredient.id}`;

          let adjustment = entry.association.recipe_adjustments.find((recipe_adjustment) => recipe_adjustment.food_recipe_advanced_ingredient_association_id === recipeAssociation.id);

          if (!adjustment) {
            adjustment = recipeAssociation;
          }

          if (!purchaseMap.has(key)) {
            purchaseMap.set(key, {
              key,
              name: recipeAssociation.advanced_ingredient.name,
              totalWeight: adjustment.quantity * entry.quantity,
              isRecipe: true
            });
          }
          else {
            purchaseMap.get(key).totalWeight += adjustment.quantity * entry.quantity;
          }
        }

        for (const ingredientAssociation of entry.association.food_recipe.basic_ingredient_associations) {
          const key = `ingredient:${ingredientAssociation.food_ingredient_source_association.food_ingredient.id}`;

          let adjustment = entry.association.recipe_adjustments.find((recipe_adjustment) => recipe_adjustment.food_recipe_basic_ingredient_association_id === ingredientAssociation.id);

          if (!adjustment) {
            adjustment = ingredientAssociation;
          }

          if (!purchaseMap.has(key)) {
            purchaseMap.set(key, {
              key,
              name: ingredientAssociation.food_ingredient_source_association.food_ingredient.name,
              food_ingredient_measurement_association: adjustment.food_ingredient_measurement_association,
              totalWeight: adjustment.quantity * adjustment.food_ingredient_measurement_association.weight_reference * entry.quantity,
              isRecipe: false
            });
          }
          else {
            purchaseMap.get(key).totalWeight += adjustment.quantity * adjustment.food_ingredient_measurement_association.weight_reference * entry.quantity;
          }
        }
      }
      else {
        const key = `ingredient:${entry.association.food_ingredient_source_association.food_ingredient.id}`;

        if (!purchaseMap.has(key)) {
          purchaseMap.set(key, {
            key,
            name: entry.association.food_ingredient_source_association.food_ingredient.name,
            food_ingredient_measurement_association: entry.association.food_ingredient_measurement_association,
            totalWeight: entry.association.quantity * entry.association.food_ingredient_measurement_association.weight_reference * entry.quantity,
            isRecipe: false
          });
        }
        else {
          purchaseMap.get(key).totalWeight += entry.association.quantity * entry.association.food_ingredient_measurement_association.weight_reference * entry.quantity;
        }
      }
    }

    const purchaseList = [...purchaseMap.values()];

    if (purchaseList.length <= 0) {
      return null;
    }

    purchaseList.sort((a, b) => a.name.localeCompare(b.name));

    const elementList = purchaseList.map((entry) => {
      let weightText;

      if (entry.isRecipe) {
        weightText = (
          <p className="food-prescription__purchase-calculator__meal-option__ingredient__quantity">
            <span className="food-prescription__purchase-calculator__meal-option__ingredient__quantity__label">Quantidade:</span>

            <span className="food-prescription__purchase-calculator__meal-option__ingredient__quantity__wrapper">

              <span className="food-prescription__purchase-calculator__meal-option__ingredient__quantity__number">
                {entry.totalWeight}
              </span>
              <span className="food-prescription__purchase-calculator__meal-option__ingredient__quantity__unit">
                Receita
              </span>

            </span>
          </p>
        );
      }
      else {
        const weightValue = Math.round(10 * entry.totalWeight) / 10;
        const quantity = Math.round(10 * entry.totalWeight / entry.food_ingredient_measurement_association.weight_reference) / 10;

        if (entry.food_ingredient_measurement_association.food_measurement_id !== FOOD_MEASUREMENT_GRAM_ID) {
          weightText = (
            <p className="food-prescription__purchase-calculator__meal-option__ingredient__quantity">
              <span className="food-prescription__purchase-calculator__meal-option__ingredient__quantity__label">Quantidade:</span>

              <span className="food-prescription__purchase-calculator__meal-option__ingredient__quantity__wrapper">

                <span className="food-prescription__purchase-calculator__meal-option__ingredient__quantity__number">
                  {quantity}
                </span>
                <span className="food-prescription__purchase-calculator__meal-option__ingredient__quantity__unit">
                  {entry.food_ingredient_measurement_association.food_measurement.name}
                </span>

              </span>

              <span className="food-prescription__purchase-calculator__meal-option__ingredient__quantity__separator">
                ou
              </span>

              <span className="food-prescription__purchase-calculator__meal-option__ingredient__quantity__wrapper">

                <span className="food-prescription__purchase-calculator__meal-option__ingredient__quantity__number">
                  {weightValue}
                </span>
                <span className="food-prescription__purchase-calculator__meal-option__ingredient__quantity__unit">
                  {entry.food_ingredient_measurement_association.weight_reference_unit.shortname}
                </span>

              </span>
            </p>
          );
        }
        else {
          weightText = (
            <p className="food-prescription__purchase-calculator__meal-option__ingredient__quantity">
              <span className="food-prescription__purchase-calculator__meal-option__ingredient__quantity__label">Quantidade:</span>

              <span className="food-prescription__purchase-calculator__meal-option__ingredient__quantity__wrapper">

                <span className="food-prescription__purchase-calculator__meal-option__ingredient__quantity__number">
                  {weightValue}
                </span>
                <span className="food-prescription__purchase-calculator__meal-option__ingredient__quantity__unit">
                  {entry.food_ingredient_measurement_association.weight_reference_unit.shortname}
                </span>

              </span>
            </p>
          );
        }
      }

      return (
        <li
          key={`purchase_calculator:item:${entry.key}`}
          className="food-prescription__purchase-calculator__meal-option__ingredient"
        >
          <div className="food-prescription__purchase-calculator__meal-option__ingredient__title-wrapper">

            <i className="fa-solid fa-circle food-prescription__purchase-calculator__meal-option__ingredient__bullet"></i>

            <div className="food-prescription__purchase-calculator__meal-option__ingredient__name-wrapper">

              <h4 className="food-prescription__purchase-calculator__meal-option__ingredient__name">{entry.name}:</h4>

            </div>

          </div>

          {weightText}

        </li>
      );
    });

    return (
      <div className="food-prescription__purchase-calculator__resume">

        <h4 className="food-prescription__purchase-calculator__resume__title">

          Lista de ingredientes:

        </h4>

        <ul className="food-prescription__purchase-calculator__resume__list">

            {elementList}

        </ul>

      </div>
    );
  }

  getActiveDietContent(isCalculatorMode=false) {
    const mealPeriodMap = new Map();
    const mealPeriodAssociationMap = new Map();

    const dietAssociations = [
      ...this.state.selectedUnit.food_prescription.food_ingredient_associations.map((association) => {
        return {
          ...association,
          isRecipe: false,
        };
      }),
      ...this.state.selectedUnit.food_prescription.food_recipe_associations.map((association) => {
        return {
          ...association,
          isRecipe: true,
        };
      })
    ];

    for (const association of dietAssociations) {
      let mealPeriodAssociations;

      if (!mealPeriodMap.has(association.meal_period_id)) {
        mealPeriodMap.set(association.meal_period_id, association.meal_period);

        mealPeriodAssociations = [];
      }
      else {
        mealPeriodAssociations = mealPeriodAssociationMap.get(association.meal_period_id);
      }

      mealPeriodAssociations.push(association);

      mealPeriodAssociationMap.set(association.meal_period_id, mealPeriodAssociations);
    }

    const mealPeriods = [...mealPeriodMap.values()];
    mealPeriods.sort((a, b) => {
      if (a.start_time !== null && b.start_time !== null) {
        const result = a.start_time.localeCompare(b.start_time);

        if (result !== 0) {
          return result;
        }

        return a.name.localeCompare(b.name);
      }
      else if (a.start_time !== null && b.start_time === null) {
        return -1;
      }
      else if (a.start_time === null && b.start_time !== null) {
        return 1;
      }
      else {
        return a.name.localeCompare(b.name);
      }
    });

    return mealPeriods.map((mealPeriod) => {
      let timePeriod = '';

      if (mealPeriod.start_time !== null && mealPeriod.end_time !== null) {
        timePeriod = ` (${mealPeriod.start_time} - ${mealPeriod.end_time})`;
      }
      else if (mealPeriod.start_time !== null && mealPeriod.end_time === null) {
        timePeriod = ` (a partir de ${mealPeriod.start_time})`;
      }
      else if (mealPeriod.start_time === null && mealPeriod.end_time !== null) {
        timePeriod = ` (até ${mealPeriod.end_time})`;
      }

      const associations = mealPeriodAssociationMap.get(mealPeriod.id);
      associations.sort((a, b) => a.priority - b.priority);

      let mealList;

      if (isCalculatorMode) {
        mealList = associations.map((association) => this.getCalculatorMealAssociationContent(association));
      }
      else {
        mealList = associations.map((association) => this.getActiveDietMealAssociationContent(association));
      }

      return (
        <React.Fragment key={`active_dite:meal_period:${mealPeriod.id}`}>

          <DefaultSubSectionTitle
            className="food-prescription__active-diet__meal-period__title"
            icon={<i className="fas fa-clock"></i>}
            text={`${mealPeriod.name}${timePeriod}`}
          />

          {this.state.selectedUnit.food_prescription.meal_period_note_map[mealPeriod.id] &&
            <p className="food-prescription__active-diet__meal-period__note">

              {this.state.selectedUnit.food_prescription.meal_period_note_map[mealPeriod.id]}

            </p>
          }

          <ul className={`food-prescription__${isCalculatorMode ? 'purchase-calculator' : 'active-diet'}__meal-period-list`}>

            {mealList}

          </ul>

        </React.Fragment>
      );
    });
  }

  getContentOptions() {
    if(this.state.selectedUnit === null) {
      return (
        <React.Fragment>

          <h1 className="food-prescription__instructions">

            Selecione a unidade desejada:

          </h1>

          <div className="food-prescription__training-selector">

            {this.getUnitOptions()}

          </div>

        </React.Fragment>
      );
    }
    else {
      return (
        <React.Fragment>

          <section
            className="food-prescription__active-diet"
          >

            <header
              className="food-prescription__active-diet__header"
              onClick={() => this.setState({activeDietVisible: !this.state.activeDietVisible})}
            >

              <h3 className="food-prescription__active-diet__header__text">
                <i className="fa-solid fa-utensils food-prescription__active-diet__header__text-icon"></i>
                Cardápio da dieta
              </h3>

              {this.state.activeDietVisible ?
                <i className="fas fa-chevron-down food-prescription__active-diet__header__visible-icon"></i>:
                <i className="fas fa-chevron-up food-prescription__active-diet__header__visible-icon"></i>
              }

            </header>

            <VerticalAccordionContainer
              className="vertical-accordion-container food-prescription__active-diet__content"
              pose={this.state.activeDietVisible ? 'verticalOpen' : 'verticalClosed'}>

              <div className="vertical-accordion-container food-prescription__active-diet__content-wrapper">

                {this.getActiveDietContent()}

              </div>

            </VerticalAccordionContainer>

          </section>

          <section
            className="food-prescription__purchase-calculator"
          >

            <header
              className="food-prescription__purchase-calculator__header"
              onClick={() => this.setState({purchaseCalculatorVisible: !this.state.purchaseCalculatorVisible})}
            >

              <h3 className="food-prescription__purchase-calculator__header__text">
                <i className="fa-solid fa-cart-shopping food-prescription__purchase-calculator__header__text-icon"></i>
                Lista de compras
              </h3>

              {this.state.purchaseCalculatorVisible ?
                <i className="fas fa-chevron-down food-prescription__purchase-calculator__header__visible-icon"></i>:
                <i className="fas fa-chevron-up food-prescription__purchase-calculator__header__visible-icon"></i>
              }

            </header>

            <VerticalAccordionContainer
              className="vertical-accordion-container food-prescription__purchase-calculator__content"
              pose={this.state.purchaseCalculatorVisible ? 'verticalOpen' : 'verticalClosed'}>

              <div className="vertical-accordion-container food-prescription__purchase-calculator__content-wrapper">

                {this.getActiveDietContent(true)}

                {this.getCalculatorResult()}

              </div>

            </VerticalAccordionContainer>

          </section>

          {(this.state.selectedUnit.indicators_visible_to_student || this.state.selectedUnit.graph_visible_to_student) &&
            <section className="food-prescription__default-section">

              <header className="food-prescription__default-section__header">

                <h3 className="food-prescription__default-section__header__title">
                  <i className="fas fa-chart-line food-prescription__default-icon"></i> Acompanhamento
                </h3>

              </header>

              <div className="food-prescription__default-section__content">

                {this.getPrescriptionDetails()}

              </div>

            </section>
          }

        </React.Fragment>
      );
    }
  }

  getConfirmationWindowTitle() {
    if(this.state.confirmFailed) {
      if(this.state.weightMeasurementToRegister !== null) {
        return 'Falha ao registrar peso';
      }
      else if(this.state.weightMeasurementToRemove !== null) {
        return 'Falha ao remover cadastro de peso';
      }
      else if(this.state.submittingFollowupForm) {
        return 'Falha ao enviar respostas';
      }
    }
    else if(this.state.confirmInProgress) {
      if(this.state.weightMeasurementToRegister !== null) {
        return 'Registrando peso';
      }
      else if(this.state.weightMeasurementToRemove !== null) {
        return 'Removendo cadastro de peso';
      }
      else if(this.state.submittingFollowupForm) {
        return 'Enviando respostas';
      }
    }
    else {
      if(this.state.weightMeasurementToRegister !== null) {
        return 'Registrar peso';
      }
      else if(this.state.weightMeasurementToRemove !== null) {
        return 'Remover cadastro de peso';
      }
      else if(this.state.submittingFollowupForm) {
        return 'Enviar respotas';
      }
    }

    return 'Não implementado';
  }

  getConfirmationWindowDescription() {
    if(this.state.confirmFailed) {
      return this.state.confirmFailDescription;
    }
    else if(this.state.weightMeasurementToRegister !== null) {
      return `Ao registrar o peso, este será inserido tanto no histórico de peso quanto no gráfico de acompanhamento.`;
    }
    else if(this.state.weightMeasurementToRemove !== null) {
      return `Deseja realmente apagar o cadastro de peso do dia ${getAsLocalDateString(this.state.weightMeasurementToRemove.date)} (${this.state.weightMeasurementToRemove.weight}kg)?`;
    }
    else if(this.state.submittingFollowupForm) {
      return `Aguarde enquanto salvamos suas respostas do formulário de acompanhamento.`;
    }

    return 'Não implementado';
  }

  getConfirmationWindowConfirmButtonText() {
    if(this.state.weightMeasurementToRegister !== null) {
      return 'Registrar';
    }
    else if(this.state.weightMeasurementToRemove !== null) {
      return 'Remover';
    }
    else if(this.state.submittingFollowupForm) {
      return 'Enviar';
    }

    return 'Não implementado';
  }

  confirmationWindowIsVisible() {
    return this.state.weightMeasurementToRegister !== null || this.state.weightMeasurementToRemove !== null || this.state.submittingFollowupForm;
  }

  resetConfirmationWindow() {
    const newState = {
      weightMeasurementToRegister: null,
      weightMeasurementToRemove: null,
      submittingFollowupForm: false,
      confirmFailed: false,
      confirmInProgress: false,
    };

    if (this.state.submittingFollowupForm) {
      newState.showFollowupForm = true;
    }

    this.setState(newState);
  }

  async proceedConfirmationWindow() {
    if(this.state.weightMeasurementToRemove !== null) {
      this.setState({
        confirmInProgress: true
      });

      try{
        if(await deleteModel(`${routes.WEIGHT_MEASUREMENT_DELETE_API}${this.state.weightMeasurementToRemove.date}`)) {
          const active_units = this.state.active_units.map((entry) => {
            entry.weight_measurements = entry.weight_measurements.filter((measurement) => measurement.date !== this.state.weightMeasurementToRemove.date);

            return entry;
          });

          this.setState({
            weightMeasurementToRemove: null,
            confirmFailed: false,
            confirmInProgress: false,
            active_units
          });
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_ERROR_MESSAGE + '.';

        if(errors instanceof Array) {
          for(let error of errors) {
            switch (error.code) {
              // case 104:
              //   for(let parameter of error.parameters) {
              //     switch (parameter.name) {
              //       case 'contracts':
              //         errorDescription = 'Serviço vinculado à um contrato de aluno. Estes contratos devem ser excluídos antes de excluir este serviço.';
              //
              //         break;
              //       default:
              //     }
              //   }
              //
              //   break;
              case 209:
                errorDescription = 'Sessão do usuário expirada.';

                break;
              default:
            }
          }
        }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }

    return;
  }

  getWaterBar(value) {
    const barElements = [];

    for (let i=1; i <= 10; i++) {
      barElements.push(
        <div
          key={`followup_report:water_indicator:bar_image:${i}`}
          className="food-prescription__followup-report__additional-info__indicator__value-bar__image__wrapper--water"
        >
          <img
            className="food-prescription__followup-report__additional-info__indicator__value-bar__image"
            src={value >= i ? fullGlass : emptyBottle}
            alt=""
          />
        </div>
      );
    }

    return barElements;
  }

  getSleepBar(value) {
    const barElements = [];

    for (let i=1; i <= 10; i++) {
      barElements.push(
        <div
          key={`followup_report:water_indicator:bar_image:${i}`}
          className="food-prescription__followup-report__additional-info__indicator__value-bar__image__wrapper--sleep"
        >
          <img
            className="food-prescription__followup-report__additional-info__indicator__value-bar__image"
            src={value >= i ? zFilled : zEmpty}
            alt=""
          />
        </div>
      );
    }

    return barElements;
  }

  getStressBar(value) {
    const barElements = [];

    for (let i=1; i <= 10; i++) {
      barElements.push(
        <div
          key={`followup_report:water_indicator:bar_image:${i}`}
          className="food-prescription__followup-report__additional-info__indicator__value-bar__image__wrapper--stress"
        >
          <img
            className="food-prescription__followup-report__additional-info__indicator__value-bar__image"
            src={value >= i ? stressFilled : stressEmpty}
            alt=""
          />
        </div>
      );
    }

    return barElements;
  }

  getIndicatorProps(currentValue, lastValue) {
    let scoreStyle = '';
    let iconStyle = null;
    let text = '';

    if (currentValue === null) {
      return [
        scoreStyle,
        iconStyle,
        text
      ];
    }

    if (currentValue >= 9) {
      scoreStyle = '--green';
      text = 'Ótimo';

      if (lastValue !== null) {
        if (lastValue < 9 || lastValue < currentValue) {
          iconStyle = 'fa-solid fa-angles-up';
          text = 'Melhorou';
        }
        else {
          iconStyle = 'fa-solid fa-angles-right';
        }
      }
    }
    else if (currentValue >= 7) {
      scoreStyle = '--green';
      text = 'Regular';

      if (lastValue !== null) {
        if (lastValue < 7 || lastValue < currentValue) {
          iconStyle = 'fa-solid fa-angles-up';
          text = 'Melhorou';
        }
        else if (lastValue >= 9) {
          iconStyle = 'fa-solid fa-angles-down';
          text = 'Piorou';
        }
        else {
          iconStyle = 'fa-solid fa-angles-right';
        }
      }
    }
    else if (currentValue >= 4) {
      scoreStyle = '--yellow';
      text = 'Ruim';

      if (lastValue !== null) {
        if (lastValue < 4) {
          iconStyle = 'fa-solid fa-angles-up';
          text = 'Melhorou';
        }
        else if (lastValue >= 7) {
          iconStyle = 'fa-solid fa-angles-down';
          text = 'Piorou';
        }
        else {
          iconStyle = 'fa-solid fa-angles-right';
        }
      }
    }
    else {
      scoreStyle = '--red';
      text = 'Péssimo';

      if (lastValue !== null) {
        if (lastValue >= 4) {
          iconStyle = 'fa-solid fa-angles-down';
          text = 'Piorou';
        }
        else {
          iconStyle = 'fa-solid fa-angles-right';
        }
      }
    }

    return [
      scoreStyle,
      iconStyle,
      text
    ];
  }

  getYLabelTextSize() {
    if(this.state.screenWidth <= 360) {
      return 11;
    }
    if(this.state.screenWidth <= 420) {
      return 12;
    }
    if(this.state.screenWidth <= 510) {
      return 13;
    }
    if(this.state.screenWidth <= 680) {
      return 14;
    }
    if(this.state.screenWidth <= 1100) {
      return 15;
    }
    if(this.state.screenWidth <= 1300) {
      return 16;
    }

    return 18;
  }

  getXLabelTextSize() {
    if(this.state.screenWidth <= 360) {
      return 13;
    }
    if(this.state.screenWidth <= 420) {
      return 14;
    }
    if(this.state.screenWidth <= 510) {
      return 15;
    }
    if(this.state.screenWidth <= 680) {
      return 16;
    }
    if(this.state.screenWidth <= 1100) {
      return 17;
    }
    if(this.state.screenWidth <= 1300) {
      return 18;
    }

    return 20;
  }

  getXAxistUnitLabelTextSize() {
    if(this.state.screenWidth <= 360) {
      return 8;
    }
    if(this.state.screenWidth <= 420) {
      return 9;
    }
    if(this.state.screenWidth <= 510) {
      return 10;
    }
    if(this.state.screenWidth <= 680) {
      return 11;
    }
    if(this.state.screenWidth <= 1100) {
      return 12;
    }
    if(this.state.screenWidth <= 1300) {
      return 13;
    }

    return 15;
  }

  getBarBorderRadius() {
    if(this.state.screenWidth <= 360) {
      return [2, 2, 0, 0];
    }
    if(this.state.screenWidth <= 420) {
      return [4, 4, 0, 0];
    }
    if(this.state.screenWidth <= 1100) {
      return [8, 8, 0, 0];
    }

    return [12, 12, 0, 0];
  }

  getXAxisUnitLabelHorizontalOffset() {
    if(this.state.screenWidth <= 1100) {
      return 0;
    }

    return 5;
  }

  getFollowupReport() {
    if (this.state.last_exam === null) {
      return (
        <p className="food-prescription__alert-text">

          <i className="fas fa-exclamation food-prescription__alert-text__icon"></i>
          O aluno ainda não realizou uma avaliação física

        </p>
      );
    }
    else if (!this.state.last_exam.data.weight || this.state.last_exam.data.weight.length <= 0) {
      return (
        <p className="food-prescription__alert-text">

          <i className="fas fa-exclamation food-prescription__alert-text__icon"></i>
          Peso não especificado

        </p>
      );
    }
    else if (!this.state.selectedUnit.final_date) {
      return (
        <p className="food-prescription__alert-text">

          <i className="fas fa-exclamation food-prescription__alert-text__icon"></i>
          Previsão para fim de dieta não especificado

        </p>
      );
    }
    else if (this.state.weeklyExerciseEnergyExpense <= 0 && this.state.training_frequency === null) {
      return (
        <p className="food-prescription__alert-text">

          <i className="fas fa-exclamation food-prescription__alert-text__icon"></i>
          Frequência de treino semanal não definido

        </p>
      );
    }

    const weight = parseFloat(this.state.last_exam.data.weight);

    const energyMeans = [];

    for (const value of Object.values(this.state.service_energy_expense_map)) {
      energyMeans.push(value * weight);
    }

    const meanEnergyExpensePerClass = energyMeans.reduce((sum, value) => sum + value, 0) / energyMeans.length;

    let weeklyExerciseEnergyExpense;

    if (this.state.weeklyExerciseEnergyExpense > 0) {
      weeklyExerciseEnergyExpense = this.state.weeklyExerciseEnergyExpense;
    }
    else {
      weeklyExerciseEnergyExpense = this.state.training_frequency * meanEnergyExpensePerClass;
    }

    const startDate = getAsLocalDate(this.state.selectedUnit.initial_date);
    const endDate = getAsLocalDate(this.state.selectedUnit.final_date);

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    if (today < startDate) {
      return null;
    }

    let referenceDate;

    if (today >= endDate) {
      referenceDate = endDate;
    }
    else if (today >= startDate) {
      referenceDate = today;
    }

    const timeDiff = Math.abs(referenceDate.getTime() - startDate.getTime());

    let lastWeekIndex = Math.floor(Math.ceil(timeDiff / (1000 * 3600 * 24)) / 7);

    const lastWeekInitialDate = new Date(startDate);
    const lastWeekFinalDate = new Date(startDate);

    lastWeekInitialDate.setDate(lastWeekInitialDate.getDate() + ((lastWeekIndex - 1) * 7));
    lastWeekFinalDate.setDate(lastWeekFinalDate.getDate() + ((lastWeekIndex) * 7) - 1);

    const totalTimeDiff = Math.abs(endDate.getTime() - startDate.getTime());
    const finalWeekIndex = Math.floor(Math.ceil(totalTimeDiff / (1000 * 3600 * 24)) / 7);

    const totalDays = Math.ceil(totalTimeDiff / (1000 * 3600 * 24)) + 1;
    const currentDay = Math.ceil(timeDiff / (1000 * 3600 * 24)) + 1;

    let lastWeekEnergyExpended = 0;

    for(const trainingData of this.state.selectedUnit.training_data) {
      const executionDate = getAsLocalDate(trainingData.date);

      if(executionDate >= startDate && executionDate <= lastWeekFinalDate) {
        let entryEnergyExpense = 0;

        if (trainingData.energy_expended) {
          entryEnergyExpense = trainingData.energy_expended;
        }
        else {
          if (trainingData.is_custom_training && trainingData.training_day_id === null && trainingData.activity_reference_id !== null) {
            entryEnergyExpense = (weight * (trainingData.total_time / 60) * trainingData.activity_reference.met)
          }
          else if (trainingData.training_day) {
            entryEnergyExpense = trainingData.training_day.group_associations.reduce((accumulator, association) => {
              if (association.activity_reference) {
                return accumulator + (weight * (association.time_required / 60) * association.activity_reference.met);
              }

              return accumulator;
            }, 0);
          }
        }

        if (executionDate >= lastWeekInitialDate) {
          lastWeekEnergyExpended += entryEnergyExpense;
        }
      }
    }

    const lastWeekExerciseAccomplished = lastWeekEnergyExpended >= weeklyExerciseEnergyExpense;

    const lastWeekWeightMeasurementPerformed = this.state.selectedUnit.weight_measurements.some((entry) => {
      const measurementDate = getAsLocalDate(entry.date);

      return measurementDate >= lastWeekInitialDate && measurementDate <= lastWeekFinalDate;
    });

    let objectiveResultText = "Baixo";
    let objectiveResultStyle = "--1";

    if (lastWeekExerciseAccomplished && lastWeekWeightMeasurementPerformed) {
      objectiveResultText = "Excelente";
      objectiveResultStyle = "--3";
    }
    else if (lastWeekExerciseAccomplished || lastWeekWeightMeasurementPerformed) {
      objectiveResultText = "Regular";
      objectiveResultStyle = "--2";
    }

    let currentDietScoreText = '?';
    let currentTrainingScoreText = '?';
    let currentDietScoreValue = null;
    let currentTrainingScoreValue = null;
    let currentFinalScoreText = '?';

    let lastWaterValue = null;
    let currentWaterValue = null;
    let lastSleepValue = null;
    let currentSleepValue = null;
    let lastStressValue = null;
    let currentStressValue = null;

    const scoreHistoryList = [];
    const dietGraphData = [];
    const trainingGraphData = [];

    for (let i=2; i <= finalWeekIndex + 1; ++i) {
      const initialDateReference = new Date(startDate);
      let finalDateReference = new Date(startDate);

      initialDateReference.setDate(initialDateReference.getDate() + ((i - 1) * 7));
      finalDateReference.setDate(finalDateReference.getDate() + ((i) * 7) - 1);

      if (finalDateReference > endDate) {
        finalDateReference = new Date(endDate);
      }

      const form = this.state.selectedUnit.followup_form_submissions.find((entry) => {
        const submissionDate = getAsLocalDate(entry.submitted_at);

        return submissionDate >= initialDateReference && submissionDate <= finalDateReference;
      });


      if (form) {
        const dietScore = parseFloat(form.answers.find((answer) => answer.fyd_form_question_id === FYD_FORM_FOLLOWUP_QUESTION_DIET_ID).answer);
        const trainingScore = parseFloat(form.answers.find((answer) => answer.fyd_form_question_id === FYD_FORM_FOLLOWUP_QUESTION_TRAINING_ID).answer);

        const waterScore = parseFloat(form.answers.find((answer) => answer.fyd_form_question_id === FYD_FORM_FOLLOWUP_QUESTION_WATER_ID).answer);
        const sleepScore = parseFloat(form.answers.find((answer) => answer.fyd_form_question_id === FYD_FORM_FOLLOWUP_QUESTION_SLEEP_ID).answer);
        const stressScore = parseFloat(form.answers.find((answer) => answer.fyd_form_question_id === FYD_FORM_FOLLOWUP_QUESTION_STRESS_ID).answer);

        if (i === lastWeekIndex + 1) {
          currentDietScoreValue = dietScore;
          currentTrainingScoreValue = trainingScore;
          currentDietScoreText = dietScore < 10 ? dietScore.toFixed(1) : dietScore.toFixed(0);
          currentTrainingScoreText = trainingScore < 10 ? trainingScore.toFixed(1) : trainingScore.toFixed(0);

          const currentFinalScore = (dietScore + trainingScore) / 2;

          currentFinalScoreText = currentFinalScore < 10 ? currentFinalScore.toFixed(1) : currentFinalScore.toFixed(0);

          currentWaterValue = waterScore;
          currentSleepValue = sleepScore;
          currentStressValue = stressScore;
        }
        else if (i === lastWeekIndex) {
          lastWaterValue = waterScore;
          lastSleepValue = sleepScore;
          lastStressValue = stressScore;
        }

        let historyScoreText = (dietScore + trainingScore) / 2;
        historyScoreText = historyScoreText < 10 ? historyScoreText.toFixed(1) : historyScoreText.toFixed(0);

        scoreHistoryList.push((
          <li
            key={`followup_report:score_history_entry:${i-1}`}
            className="food-prescription__followup-report__score-history__list__item"
          >

            <p className="food-prescription__followup-report__score-history__list__item__value">{historyScoreText}</p>
            <p className="food-prescription__followup-report__score-history__list__item__label">{`${i-1}ª ${this.state.screenWidth > 510 ? 'semana' : 'sem'}`}</p>

          </li>
        ));

        dietGraphData.push({
          value: dietScore,
          label: `${i-1}ª`,
          fillColor: "#16d8c5"
        });
        trainingGraphData.push({
          value: trainingScore,
          label: `${i-1}ª`,
          fillColor: "#29a8d0"
        });
      }
      else {
        scoreHistoryList.push((
          <li
            key={`followup_report:score_history_entry:${i-1}`}
            className="food-prescription__followup-report__score-history__list__item--faded"
          >

            <p className="food-prescription__followup-report__score-history__list__item__value">?</p>
            <p className="food-prescription__followup-report__score-history__list__item__label">{`${i-1}ª ${this.state.screenWidth > 510 ? 'semana' : 'sem'}`}</p>

          </li>
        ));

        let dietValue = 0;
        let trainingValue = 0;

        if (i > lastWeekIndex) {
          dietValue = 10;
          trainingValue = 10;

          if (currentDietScoreValue !== null && currentDietScoreValue <= 6 ) {
            const daysToMax = Math.floor((10 - currentDietScoreValue) / 2);
            dietValue = currentDietScoreValue + ((10 - currentDietScoreValue) * Math.min(daysToMax, i - lastWeekIndex - 1) / daysToMax);
          }
          if (currentTrainingScoreValue !== null && currentTrainingScoreValue <= 6 ) {
            const daysToMax = Math.floor((10 - currentTrainingScoreValue) / 2);
            trainingValue = currentTrainingScoreValue + ((10 - currentTrainingScoreValue) * Math.min(daysToMax, i - lastWeekIndex - 1) / daysToMax);
          }
        }

        dietGraphData.push({
          value: dietValue,
          label: `${i-1}ª`,
          fillColor: "none",
          borderColor: "white",
          borderLineDash: this.state.screenWidth <= 360 ? [3, 3] : [5, 5]
        });
        trainingGraphData.push({
          value: trainingValue,
          label: `${i-1}ª`,
          fillColor: "none",
          borderColor: "white",
          borderLineDash: this.state.screenWidth <= 360 ? [3, 3] : [5, 5]
        });
      }
    }

    const [waterScoreStyle, waterIconStyle, waterIndicatorText] = this.getIndicatorProps(currentWaterValue, lastWaterValue);
    const [sleepScoreStyle, sleepIconStyle, sleepIndicatorText] = this.getIndicatorProps(currentSleepValue, lastSleepValue);
    const [stressScoreStyle, stressIconStyle, stressIndicatorText] = this.getIndicatorProps(11 - currentStressValue, 11 - lastStressValue);

    return (
      <article className="food-prescription__followup-report">

        <img
          className="food-prescription__followup-report__background"
          src={reportBackground}
          alt=""
        />

        <header className="food-prescription__followup-report__header">

          <div className="food-prescription__followup-report__header__title__wrapper">

            <h1 className="food-prescription__followup-report__header__title">{this.props.username}</h1>

          </div>

          <h3 className="food-prescription__followup-report__header__sub-title">

            <span>Relatório</span>
            <span>{`${lastWeekIndex}ª semana`}</span>

          </h3>

        </header>

        <section className="food-prescription__followup-report__objectives">

          <div className="food-prescription__followup-report__objectives__wrapper">

            <div className="food-prescription__followup-report__objectives__checklist">

              <div className="food-prescription__followup-report__objectives__checklist__item">

                <div className="food-prescription__followup-report__objectives__checklist__item__background"></div>

                <div className="food-prescription__followup-report__objectives__checklist__item__mark">

                  <div className="food-prescription__followup-report__objectives__checklist__item__mark__corner-1"></div>
                  <div className="food-prescription__followup-report__objectives__checklist__item__mark__corner-2"></div>
                  <div className="food-prescription__followup-report__objectives__checklist__item__mark__corner-3"></div>
                  <div className="food-prescription__followup-report__objectives__checklist__item__mark__corner-4"></div>

                  <i className={`fa-sharp fa-solid ${lastWeekWeightMeasurementPerformed ? 'fa-check' : 'fa-xmark'} food-prescription__followup-report__objectives__checklist__item__mark__icon${lastWeekWeightMeasurementPerformed ? '--positive' : '--negative'}`}></i>

                </div>

                <p className="food-prescription__followup-report__objectives__checklist__item__text">Pesou</p>

              </div>

              <div className="food-prescription__followup-report__objectives__symbol">

                <i className="fa-sharp fa-solid fa-plus food-prescription__followup-report__objectives__symbol__icon"></i>

              </div>

              <div className="food-prescription__followup-report__objectives__checklist__item">

                <div className="food-prescription__followup-report__objectives__checklist__item__background"></div>

                <div className="food-prescription__followup-report__objectives__checklist__item__mark">

                  <div className="food-prescription__followup-report__objectives__checklist__item__mark__corner-1"></div>
                  <div className="food-prescription__followup-report__objectives__checklist__item__mark__corner-2"></div>
                  <div className="food-prescription__followup-report__objectives__checklist__item__mark__corner-3"></div>
                  <div className="food-prescription__followup-report__objectives__checklist__item__mark__corner-4"></div>

                  <i className={`fa-sharp fa-solid ${lastWeekExerciseAccomplished ? 'fa-check' : 'fa-xmark'} food-prescription__followup-report__objectives__checklist__item__mark__icon${lastWeekExerciseAccomplished ? '--positive' : '--negative'}`}></i>

                </div>

                <p className="food-prescription__followup-report__objectives__checklist__item__text">Treino</p>
                <p className="food-prescription__followup-report__objectives__checklist__item__sub-text">
                  {Math.round(lastWeekEnergyExpended)}/{Math.round(weeklyExerciseEnergyExpense)} kcal
                </p>

              </div>

            </div>

            <div className="food-prescription__followup-report__objectives__symbol--more-spaced">

              <i className="fa-sharp fa-solid fa-equals food-prescription__followup-report__objectives__symbol__icon--white"></i>

            </div>

            <div className={`food-prescription__followup-report__objectives__result${objectiveResultStyle}`}>

              <h3 className="food-prescription__followup-report__objectives__result__text">{objectiveResultText}</h3>
              <p className="food-prescription__followup-report__objectives__result__sub-text">Grau de motivação</p>

            </div>

          </div>

        </section>

        <section className="food-prescription__followup-report__self-evaluation-scores">

          <div className="food-prescription__followup-report__self-evaluation-scores__days-track">

            {this.screenWidth > 420 ? (
              <img
                className="food-prescription__followup-report__self-evaluation-scores__days-track__background-1"
                src={hexOuterBorderImg}
                alt=""
              />
            ) : (
              <img
                className="food-prescription__followup-report__self-evaluation-scores__days-track__background-1"
                src={hexOuterBorderSmallImg}
                alt=""
              />
            )}
            {this.screenWidth > 420 ? (
              <img
                className="food-prescription__followup-report__self-evaluation-scores__days-track__background-2"
                src={hexInnerBorderImg}
                alt=""
              />
            ) : (
              <img
                className="food-prescription__followup-report__self-evaluation-scores__days-track__background-2"
                src={hexInnerBorderSmallImg}
                alt=""
              />
            )}

            <div className="food-prescription__followup-report__self-evaluation-scores__days-track__wrapper">

              <div className="food-prescription__followup-report__self-evaluation-scores__days-track__logo-image__wrapper">

                <img className="food-prescription__followup-report__self-evaluation-scores__days-track__logo-image" src={logo} alt="Logo da FYD" />

              </div>
              <h3 className="food-prescription__followup-report__self-evaluation-scores__days-track__title">FYD Nutri</h3>
              <p className="food-prescription__followup-report__self-evaluation-scores__days-track__text">
                <span className="food-prescription__followup-report__self-evaluation-scores__days-track__text--highlight">{currentDay}</span>/{totalDays} dias
              </p>

            </div>

          </div>

          <div className="food-prescription__followup-report__self-evaluation-scores__separator-1">

            <div className="food-prescription__followup-report__self-evaluation-scores__separator__decorator-1"></div>
            <div className="food-prescription__followup-report__self-evaluation-scores__separator__decorator-2"></div>

          </div>

          <div className="food-prescription__followup-report__self-evaluation-scores__current-values">

            <div className="food-prescription__followup-report__self-evaluation-scores__current-values__diet">

              <p className="food-prescription__followup-report__self-evaluation-scores__current-values__diet__value">{currentDietScoreText}</p>
              <p className="food-prescription__followup-report__self-evaluation-scores__current-values__diet__label">dieta</p>

            </div>

            <div className="food-prescription__followup-report__self-evaluation-scores__current-values__training">

              <p className="food-prescription__followup-report__self-evaluation-scores__current-values__training__value">{currentTrainingScoreText}</p>
              <p className="food-prescription__followup-report__self-evaluation-scores__current-values__training__label">Treino</p>

            </div>

          </div>

          <div className="food-prescription__followup-report__self-evaluation-scores__separator-2">

            <div className="food-prescription__followup-report__self-evaluation-scores__separator__decorator-3"></div>
            <div className="food-prescription__followup-report__self-evaluation-scores__separator__decorator-4"></div>

          </div>

          <div className="food-prescription__followup-report__self-evaluation-scores__result">

            <div className="food-prescription__followup-report__self-evaluation-scores__result__wrapper">

              <p className="food-prescription__followup-report__self-evaluation-scores__result__value">{currentFinalScoreText}</p>
              <p className="food-prescription__followup-report__self-evaluation-scores__result__label">Sua nota</p>

            </div>

          </div>

        </section>

        <section className="food-prescription__followup-report__score-history">

          <ul className="food-prescription__followup-report__score-history__list">

            {scoreHistoryList}

          </ul>

          <div className="food-prescription__followup-report__score-history__graphs-container">

            <div className="food-prescription__followup-report__score-history__graph">

              <h3 className="food-prescription__followup-report__score-history__graph__title">
                Autoavaliação <span className="food-prescription__followup-report__score-history__graph__title__highlight--diet">dieta</span>
              </h3>

              <div className="food-prescription__followup-report__score-history__graph__wrapper">

                <HorizontalBarGraph
                  scrollContainerClassName="food-prescription__followup-report__score-history__graph__scroll-container"
                  yAxisRange={[0, 10]}
                  data={dietGraphData}
                  axesColor="white"
                  yLabelColor="white"
                  yGridColor="#446e95"
                  yAxisRangeOffsetPercentage={0.2}
                  yAxisLabelStep={2}
                  // barBorderRadius={this.getBarBorderRadius()}
                  barLineWidth={1}
                  xLabelTextSize={this.getXLabelTextSize()}
                  yLabelTextSize={this.getYLabelTextSize()}
                  yLabelIsBold={this.state.screenWidth <= 360 ? false : true}
                  xLabelIsBold={true}
                  xAxisUnitLabel="SEMANA"
                  xAxisUnitLabelPosition="start"
                  xAxistUnitLabelTextSize={this.getXAxistUnitLabelTextSize()}
                  xAxisUnitLabelHorizontalOffset={this.getXAxisUnitLabelHorizontalOffset()}
                  xAxisUnitLabelVerticalOffset={3}
                />

              </div>

            </div>

            <div className="food-prescription__followup-report__score-history__graph">

              <h3 className="food-prescription__followup-report__score-history__graph__title">
                Autoavaliação <span className="food-prescription__followup-report__score-history__graph__title__highlight--training">treino</span>
              </h3>

              <div className="food-prescription__followup-report__score-history__graph__wrapper">

                <HorizontalBarGraph
                  scrollContainerClassName="food-prescription__followup-report__score-history__graph__scroll-container"
                  yAxisRange={[0, 10]}
                  data={trainingGraphData}
                  axesColor="white"
                  yLabelColor="white"
                  yGridColor="#446e95"
                  yAxisRangeOffsetPercentage={0.2}
                  yAxisLabelStep={2}
                  // barBorderRadius={this.getBarBorderRadius()}
                  barLineWidth={1}
                  xLabelTextSize={this.getXLabelTextSize()}
                  yLabelTextSize={this.getYLabelTextSize()}
                  yLabelIsBold={this.state.screenWidth <= 360 ? false : true}
                  xLabelIsBold={this.state.screenWidth <= 360 ? false : true}
                  xAxisUnitLabel="SEMANA"
                  xAxisUnitLabelPosition="start"
                  xAxistUnitLabelTextSize={this.getXAxistUnitLabelTextSize()}
                  xAxisUnitLabelHorizontalOffset={this.getXAxisUnitLabelHorizontalOffset()}
                  xAxisUnitLabelVerticalOffset={3}
                />

              </div>

            </div>

          </div>

        </section>

        <section className="food-prescription__followup-report__additional-info">

          {currentWaterValue !== null &&
            <article className="food-prescription__followup-report__additional-info__indicator">

              <div className="food-prescription__followup-report__additional-info__indicator__image--water__wrapper">

                <img
                  className="food-prescription__followup-report__additional-info__indicator__image--water"
                  src={waterBottle}
                  alt=""
                />

              </div>

              <div className="food-prescription__followup-report__additional-info__indicator__wrapper">

                <header className="food-prescription__followup-report__additional-info__indicator__header--water">

                  <div className="food-prescription__followup-report__additional-info__indicator__header__text-wrapper">

                    <p className="food-prescription__followup-report__additional-info__indicator__header__subtext">

                      {waterIconStyle !== null &&
                        <i className={`${waterIconStyle} food-prescription__followup-report__additional-info__indicator__header__subtext__icon`}></i>
                      }
                      <span className={`food-prescription__followup-report__additional-info__indicator__header__subtext${waterScoreStyle}`}>
                        {currentWaterValue.toFixed(0)}/10 {waterIndicatorText}
                      </span>

                    </p>
                    <h3 className="food-prescription__followup-report__additional-info__indicator__header__title">Consumo de água</h3>

                  </div>

                </header>

                <div className="food-prescription__followup-report__additional-info__indicator__value-bar">

                  {this.getWaterBar(currentWaterValue)}

                </div>

              </div>

            </article>
          }

          {currentSleepValue !== null &&
            <article className="food-prescription__followup-report__additional-info__indicator">

              <div className="food-prescription__followup-report__additional-info__indicator__image--sleep__wrapper">

                <img
                  className="food-prescription__followup-report__additional-info__indicator__image--sleep"
                  src={sleepyFace}
                  alt=""
                />

              </div>

              <div className="food-prescription__followup-report__additional-info__indicator__wrapper">

                <header className="food-prescription__followup-report__additional-info__indicator__header--sleep">

                  <div className="food-prescription__followup-report__additional-info__indicator__header__text-wrapper">

                    <p className="food-prescription__followup-report__additional-info__indicator__header__subtext">

                      {sleepIconStyle !== null &&
                        <i className={`${sleepIconStyle} food-prescription__followup-report__additional-info__indicator__header__subtext__icon`}></i>
                      }
                      <span className={`food-prescription__followup-report__additional-info__indicator__header__subtext${sleepScoreStyle}`}>
                        {currentSleepValue.toFixed(0)}/10 {sleepIndicatorText}
                      </span>

                    </p>
                    <h3 className="food-prescription__followup-report__additional-info__indicator__header__title">Qualidade do sono</h3>

                  </div>

                </header>

                <div className="food-prescription__followup-report__additional-info__indicator__value-bar">

                  {this.getSleepBar(currentSleepValue)}

                </div>

              </div>

            </article>
          }

          {currentStressValue !== null &&
            <article className="food-prescription__followup-report__additional-info__indicator">

              <div className="food-prescription__followup-report__additional-info__indicator__image--stress__wrapper">

                <img
                  className="food-prescription__followup-report__additional-info__indicator__image--stress"
                  src={stressFace}
                  alt=""
                />

              </div>

              <div className="food-prescription__followup-report__additional-info__indicator__wrapper">

                <header className="food-prescription__followup-report__additional-info__indicator__header--stress">

                  <div className="food-prescription__followup-report__additional-info__indicator__header__text-wrapper">

                    <p className="food-prescription__followup-report__additional-info__indicator__header__subtext">

                      {stressIconStyle !== null &&
                        <i className={`${stressIconStyle} food-prescription__followup-report__additional-info__indicator__header__subtext__icon`}></i>
                      }
                      <span className={`food-prescription__followup-report__additional-info__indicator__header__subtext${stressScoreStyle}`}>
                        {currentStressValue.toFixed(0)}/10 {stressIndicatorText}
                      </span>

                    </p>
                    <h3 className="food-prescription__followup-report__additional-info__indicator__header__title">Nível de estresse e ansiedade</h3>

                  </div>

                </header>

                <div className="food-prescription__followup-report__additional-info__indicator__value-bar">

                  {this.getStressBar(currentStressValue)}

                </div>

              </div>

            </article>
          }

        </section>

      </article>
    );
  }

  getOverlayTitle() {
    if (this.state.showFollowupForm) {
      return "NUTRI: Pesquisa sobre sua última semana";
    }

    return "Relatório de acompanhamento";
  }

  getFormQuestion(question) {
    const highligthRequirement = this.state.highlightRequiredFollowupFormQuestions && question.is_required && this.state.followupFormAnswerMap.get(question.id) === null;

    switch (question.fyd_form_answer_type_id) {
      case FORM_ANSWER_TYPE_HORIZONTAL_SELECT_ID:
        if (question.answer_options === null) {
          return (
            <p
              key={`fyd_form:question:${question.id}`}
              className="food-prescription__alert-text"
            >

              <i className="fas fa-exclamation food-prescription__alert-text__icon"></i>
              OPÇÕES DE PERGUNTA NÃO CONFIGURADAS CORRETAMENTE

            </p>
          );
        }

        const defaultCharacterWidth = 1.1;

        let answerOptions = JSON.parse(question.answer_options);

        const minOptionWidth = defaultCharacterWidth * Math.max(...answerOptions.map((option) => option.value.length));

        answerOptions = answerOptions.map((option, index) => {
          const selected = this.state.followupFormAnswerMap.get(question.id) === option.value;

          let itemStyle = {
            borderColor: option.color,
          };

          if (selected) {
            itemStyle.background = option.color;
          }
          else {
            itemStyle.color = option.color;
          }

          return (
            <li
              key={`fyd_form:question:${question.id}:answer_option:${index}`}
              className="food-prescription__fyd-form__question__answer-options__item"
              style={{minWidth: `${minOptionWidth}em`}}
            >

              <button
                className="food-prescription__fyd-form__question__answer-options__item__button"
                onClick={() => this.onSetFollowupFormAnswer(question.id, option.value)}
                disabled={selected}
                style={itemStyle}
              >

                <p className="food-prescription__fyd-form__question__answer-options__item__value">
                  {option.value}
                </p>

              </button>

            </li>
          );
        });

        return (
          <div
            key={`fyd_form:question:${question.id}`}
            className={`food-prescription__fyd-form__question${highligthRequirement ? '--required' : ''}`}
            ref={(element) => {
              if (element == null) {
                this.followupFormInputRefMap.set(question.id, null);
              }
              else {
                this.followupFormInputRefMap.set(question.id, element);
              }
            }}
          >
            <div className="food-prescription__fyd-form__question__content-wrapper">

              <h4 className="food-prescription__fyd-form__question__label">{question.question_title}</h4>

              <ul
                className="food-prescription__fyd-form__question__answer-options"
              >

                {answerOptions}

              </ul>

            </div>

            {highligthRequirement && (
              <div className="food-prescription__fyd-form__question__required-warning">

                <i className="fa-solid fa-circle-exclamation food-prescription__fyd-form__question__required-warning__icon"></i>
                <p className="food-prescription__fyd-form__question__required-warning__text">
                  Preenchimento obrigatório
                </p>

              </div>
            )}

          </div>
        );
      case FORM_ANSWER_TYPE_LONG_TEXT_ID:
        return (
          <div
            key={`fyd_form:question:${question.id}`}
            className={`food-prescription__fyd-form__question${highligthRequirement ? '--required' : ''}`}
            ref={(element) => {
              if (element == null) {
                this.followupFormInputRefMap.set(question.id, null);
              }
              else {
                this.followupFormInputRefMap.set(question.id, element);
              }
            }}
          >

            <div className="food-prescription__fyd-form__question__content-wrapper">

              <h4 className="food-prescription__fyd-form__question__label">{question.question_title}</h4>

              <DefaultInput
                className="food-prescription__fyd-form__question__long-text-input"
                name={`followup_form:question:${question.id}`}
                type="textarea"
                placeholder={question.answer_tip}
                rows="4"
                handleInputChange={(event) => this.handleInputChange(event)}
                value={this.state.followupFormAnswerMap.get(question.id) || ''}
                autoComplete="off"
                onFocus={(event) => event.target.select()}
              />

            </div>

          </div>
        );
      default:
        return (
          <p
            key={`fyd_form:question:${question.id}`}
            className="food-prescription__alert-text"
          >

            <i className="fas fa-exclamation food-prescription__alert-text__icon"></i>
            PERGUNTA NÃO CONFIGURADA CORRETAMENTE

          </p>
        );
    }
  }

  getOverlayContent() {
    if (!this.state.showFollowupForm && !this.state.showFollowupReport) {
      return null;
    }

    if (this.state.showFollowupForm) {
      if (this.state.followupForm === null) {
        return (
          <div className="food-prescription__overlay__loading-icon">

          </div>
        );
      }

      return this.state.followupForm.questions.map((question) => this.getFormQuestion(question));
    }

    return this.getFollowupReport();
  }

  getOverlayActionButtons() {
    if (!this.state.showFollowupForm && !this.state.showFollowupReport) {
      return null;
    }

    if (this.state.showFollowupForm) {
          return (
            <DefaultMenuButton
              className="food-prescription__overlay__action-button"
              onClick={() => this.onSubmitFollowupForm()}
              text="Enviar"
              color="green"
            />
          );
    }

    return (
      <DefaultMenuButton
        className="food-prescription__overlay__action-button"
        onClick={() => this.setState({showFollowupReport: false})}
        text="Fechar"
        color="black"
      />
    );
  }

  async onSubmitFollowupForm() {
    if (this.state.followupForm.questions.some((question) => {
      const requerimentNotFilled = question.is_required && this.state.followupFormAnswerMap.get(question.id) === null;

      if (requerimentNotFilled) {
        this.followupFormInputRefMap.get(question.id).scrollIntoView();
      }

      return requerimentNotFilled;
    })) {
      this.setState({highlightRequiredFollowupFormQuestions: true});
      return;
    }

    const data = {
      answers: [],
      fyd_form_id: FYD_FORM_NUTRI_FOLLOWUP_ID,
      unit_id: this.state.selectedUnit.id
    };

    for (const [key, value] of this.state.followupFormAnswerMap) {
      data.answers.push({
        answer: value,
        fyd_form_question_id: key
      });
    }

    this.setState({
      confirmInProgress: true,
      submittingFollowupForm: true,
      showFollowupForm: false,
    });

    try{
      const response = await postModel(routes.FYD_FORM_POST_API, data, true);

      if(response) {
        const selectedUnit = {...this.state.selectedUnit};
        selectedUnit.followup_form_submissions = [...selectedUnit.followup_form_submissions, response.model_saved];
        selectedUnit.followup_form_submissions.sort((a, b) => b.submitted_at.localeCompare(a.submitted_at));

        this.setState({
          followupFormAnswerMap: new Map(),
          showFollowupReport: true,
          submittingFollowupForm: false,
          confirmFailed: false,
          confirmInProgress: false,
          selectedUnit
        });
      }
    }
    catch(errors) {
      let errorDescription = DEFAULT_ERROR_MESSAGE + '.';

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            // case 104:
            //   for(let parameter of error.parameters) {
            //     switch (parameter.name) {
            //       case 'contracts':
            //         errorDescription = 'Serviço vinculado à um contrato de aluno. Estes contratos devem ser excluídos antes de excluir este serviço.';
            //
            //         break;
            //       default:
            //     }
            //   }
            //
            //   break;
            case 201:
              errorDescription = 'Limite de registros de resposta alcançado. Por favor, tente novamente dentro de alguns minutos.';

              break;
            case 209:
              errorDescription = 'Sessão do usuário expirada.';

              break;
            default:
          }
        }
      }

      this.setState({
        confirmFailDescription: errorDescription,
        confirmFailed: true,
        confirmInProgress: false
      });

      return;
    }
  }

  render() {
    return this.state.loading ? (
      <PoseGroup>
        <FadeContainer key="preloader">
          <PreLoader />
        </FadeContainer>
      </PoseGroup>
    ):
    (
      <DefaultMenuLayout
        showBackButton={true}
        history={this.props.history}
        onLogout={() => this.props.onLogout()}
        username={this.props.username}
      >

        <div className="food-prescription">

          {/* <a
            className="food-prescription__support-button"
            href="https://wa.me/5516997475660"
            target="_blank"
            rel="noopener noreferrer"
          >

              <i className="fa-brands fa-whatsapp food-prescription__support-button__icon"></i> Suporte
          </a> */}
          <Link
            className="food-prescription__support-button"
            to={routes.NUTRITIONAL_SUPPORT_PATH}
          >

            <i className="fa-solid fa-headset food-prescription__support-button__icon"></i>

            <div className="food-prescription__support-button__text-wrapper">

              <span>Suporte</span>

              {this.state.support_warning_message !== null &&
                <span className="food-prescription__support-button__note-text">({this.state.support_warning_message})</span>
              }

            </div>

          </Link>

          <hr className="food-prescription__horizontal-rule" />

          {this.getContentOptions()}

        </div>

        <OverlayWindow
          className="food-prescription__overlay"
          visible={this.state.showFollowupForm || this.state.showFollowupReport}
          actions={(this.state.followupForm !== null || this.state.showFollowupReport) ? (
            <div className="food-prescription__overlay__action-container">

              {this.getOverlayActionButtons()}

            </div>
          ) : null}
        >

          <header className="food-prescription__overlay__header">

            <h3 className="food-prescription__overlay__header__title">
              {this.getOverlayTitle()}
            </h3>

          </header>

          <hr className="food-prescription__horizontal-rule" />

          <div
            className="food-prescription__overlay__content-container"
          >

            {this.getOverlayContent()}

          </div>

        </OverlayWindow>

        <ConfirmationWindow
          title={this.getConfirmationWindowTitle()}
          description={this.getConfirmationWindowDescription()}
          confirmText={this.getConfirmationWindowConfirmButtonText()}
          cancelText={this.state.confirmFailed ? 'Ok' : 'Cancelar'}
          visible={this.confirmationWindowIsVisible()}
          onCancel={() => this.resetConfirmationWindow()}
          onConfirm={() => this.proceedConfirmationWindow()}
          loading={this.state.confirmInProgress}
          useErrorIcon={this.state.confirmFailed}
          hideConfirmButton={this.state.confirmFailed}
        />

      </DefaultMenuLayout>
    );
  }
}

export default FoodPrescription;
