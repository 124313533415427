import React from 'react';
import { Link } from 'react-router-dom';
import './password_recovery.scss';
import {getStateToken} from '../utils/functions';
import WarningMessage from '../components/warning_message';
import {RESET_PASSWORD_POST_API} from '../constants'
import * as routes from '../constants';

class PasswordRecovery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      passwordConfirmation: "",
      passwordUpdated: false,
      invalidHash: false,
      authenticating: false,
      warningMessage: "",
      showWarningMessage: false,
    };
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  inputsAreValid() {
    return this.state.password.length > 0 &&
           this.state.passwordConfirmation.length > 0;
  }

  handleKeyDown(event) {
    if(event.keyCode === 13 && this.inputsAreValid()) {
      this.updatePasssword();
    }
  }

  async handleRequestError(response) {
    if(response.status === 401) {
      const data = await response.json();

      if(data.code === 202) {
        this.setState({
          authenticating: false,
          invalidHash: true,
          showWarningMessage: true,
          warningMessage: "Link de redefinição de senha inválido.",
          password: "",
          passwordConfirmation: "",
        });

        return null;
      }
    }
    else if(response.status === 403) {
      const data = await response.json();

      if(data.code === 203) {
        this.setState({
          authenticating: false,
          invalidHash: true,
          showWarningMessage: true,
          warningMessage: "Link de redefinição de senha inválido.",
          password: "",
          passwordConfirmation: "",
        });

        return null;
      }
    }

    throw Error('Infelizmente estamos com problemas em nosso serviço. Por favor, tente novamente mais tarde.');
  }

  async updatePasssword() {
    const password = this.state.password.trim();
    const passwordConfirmation = this.state.passwordConfirmation.trim();

    if(password !== passwordConfirmation) {
      this.setState({
        showWarningMessage: true,
        warningMessage: "Confirmação de senha inválida. Por favor, digite novamente sua nova senha.",
        password: "",
        passwordConfirmation: "",
      });

      return null;
    }

    this.setState({
      authenticating: true,
      showWarningMessage: false,
    });

    let stateToken = await getStateToken(true);

    if(stateToken) {
      const headers = new Headers({
        'Content-Type': 'application/json',
        'state': stateToken
      });

      const body = JSON.stringify({
        password
      });

      try {
        let response = await fetch(`${RESET_PASSWORD_POST_API}${this.props.match.params.authHash}`,
          {
            method: "POST",
            headers: headers,
            cache: 'no-store',
            body: body,
          });

        if(!response.ok) {
          return await this.handleRequestError(response);
        }

        let data = await response.json();

        this.setState({
          passwordUpdated: true,
          showWarningMessage: true,
          warningMessage: "Senha atualizada com sucesso.",
        });

        return data;
      }
      catch(error) {
        console.log(error);
      }
    }

    this.setState({
      authenticating: false,
      showWarningMessage: true,
      warningMessage: "Infelizmente estamos com problemas em nosso serviço. Por favor, tente novamente mais tarde.",
    });

    return null;
  }

  render() {
    return (
      <section className="password-recovery">

        <div className="password-recovery__container">

          <Link
            className="password-recovery__main-link"
            to={routes.MAIN_PATH}
            >

              <header className="password-recovery__header">

                <h1 className="password-recovery__header__text">
                  FYD
                  {' '}
                  <span className="password-recovery__header__text--red">CLUB</span>
                </h1>

              </header>

          </Link>

          <div className="password-recovery__title">

            <p className="password-recovery__title__text">
              Digite sua nova senha nos campos abaixo:
            </p>

          </div>

          <div className="password-recovery__warning-wrapper">

            <WarningMessage
              message={this.state.warningMessage}
              onClose={() => {this.setState({showWarningMessage: false})}}
              visible={this.state.showWarningMessage}
              background={this.state.passwordUpdated ? '#e1fbd8' : null}
              color={this.state.passwordUpdated ? '#379000' : null}
            />

          </div>

          {(!this.state.passwordUpdated && !this.state.invalidHash) &&
            <React.Fragment>

              <div className="password-recovery__input-wrapper">

                <input
                  className="login__input"
                  name="password"
                  type="password"
                  placeholder="Nova senha"
                  onChange={(event) => this.handleInputChange(event)}
                  value={this.state.password}
                  onKeyDown={(event) => this.handleKeyDown(event)}
                />

                <input
                  className="login__input"
                  name="passwordConfirmation"
                  type="password"
                  placeholder="Confirmar nova senha"
                  onChange={(event) => this.handleInputChange(event)}
                  value={this.state.passwordConfirmation}
                  onKeyDown={(event) => this.handleKeyDown(event)}
                />

              </div>

              <div className="password-recovery__request-wrapper">

                <button
                  className="password-recovery__request-button"
                  disabled={!this.inputsAreValid() || this.state.authenticating}
                  onClick={() => this.updatePasssword()}
                >

                  <i className="fas fa-key"></i>
                  {this.state.authenticating ? ' Redefinindo...' : ' Redefinir senha'}

                </button>

              </div>

            </React.Fragment>
          }

          <footer className="password-recovery__footer">

            <p className="password-recovery__footer__text">

              <i className="far fa-copyright"></i>
              {' '}{(new Date()).getFullYear()} | FYD CENTRO DE TREINAMENTO

             </p>

          </footer>

        </div>

      </section>
    );
  }
}

export default PasswordRecovery;
