import React from 'react';
import { Link } from 'react-router-dom';
import { PoseGroup } from 'react-pose';
import './personal_training.scss';
import {FadeContainer} from '../utils/pose_containers';
import PreLoader from '../utils/preloader';
import * as routes from '../constants';
import {DEFAULT_UNKNOWN_ERROR_MESSAGE, SERVICE_GYM} from '../constants';
import DefaultMenuButton from '../components/default_menu_button';
import DefaultMenuLayout from '../components/default_menu_layout';
import {getModel, postModel, getAsLocalDate} from '../utils/functions';
import ClassBoard from '../components/class_board/class_board';
import HorizontalBarGraph, {BarDataPoint} from '../graphs/horizontal_bar_graph';
import StackedBarGraph, {StackGroup, StackPoint} from '../graphs/stacked_bar_graph';
import {VerticalAccordionContainer} from '../utils/pose_containers';
import ConfirmationWindow from '../components/confirmation_window';


function roundValue(value, decimals) {
  const multiplier = Math.pow(10, decimals);

  return Math.round(value * multiplier) / multiplier;
}


class PersonalTraining extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      studentNoteVisible: false,
      trainingVisibleMap: new Map(),
      loading: true,
      active_units: [],
      selectedUnit: null,
      selectedTraining: null,
      student_weight: null,
      student: null,
      errorMessage: null,
      support_warning_message: null,
      screenWidth: window.innerWidth,
    };

    const today = new Date();
    today.setHours(0, 0, 0, 0);
    this.todayWeekDay = today.getDay();
  }

  async getPersonalTraining() {
    return await getModel(routes.PERSONAL_TRAINING_GET_API);
  }

  updateSize() {
    const update = {screenWidth: window.innerWidth};

    this.setState(update);
  }

  async componentDidMount() {
    const update = {loading: false};

    let personal_training = await this.getPersonalTraining();

    if(personal_training) {
      if(!personal_training.is_active) {
        this.onBackClicked();
        return;
      }

      update.active_units = personal_training.active_units;
      update.student = personal_training.student;

      if(update.active_units.length === 1) {
        update.selectedUnit = update.active_units[0];
      }

      if(personal_training.student_weight) {
        update.student_weight = personal_training.student_weight;
      }

      if(personal_training.support_warning_message) {
        update.support_warning_message = personal_training.support_warning_message;
      }
    }

    this.setState(update);

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  onSelectUnit(unit) {
    const trainingVisibleMap = new Map();

    if(unit.training_period && unit.training_period.training_days.length > 0) {
      for (const training of unit.training_period.training_days) {
        trainingVisibleMap.set(training.id, false);
      }
    }

    this.setState({
      selectedUnit: unit,
      trainingVisibleMap
    });
  }

  async onSelectTraining(training) {
    const update = {selectedTraining: training};

    if (this.state.selectedUnit.bound_to_services) {
      this.setState({
        loading: true
      });

      update.loading = false;

      const data = {
        training_day_id: training.id,
        unit_id: this.state.selectedUnit.id
      };

      try{
        await postModel(routes.ACTIVE_GYM_TRAINING_DAY_POST_API, data);
      }
      catch(errors) {
        let errorDescription = "Falha durante comunicação com o sistema. Alguns serviços podem não estar funcionando corretamente.";

        if(errors instanceof Array) {
          for(let error of errors) {
            switch (error.code) {
              // case 104:
              //   for(let parameter of error.parameters) {
              //     switch (parameter.name) {
              //       case 'contracts':
              //         errorDescription = 'Serviço vinculado à um contrato de aluno. Estes contratos devem ser excluídos antes de excluir este serviço.';
              //
              //         break;
              //       default:
              //     }
              //   }
              //
              //   break;
              case 209:
                errorDescription = 'Sessão do usuário expirada.';

                break;
              default:
            }
          }
        }

        update.errorMessage = errorDescription;
      }
    }

    this.setState(update);
  }

  onBackClicked() {
    this.props.history.replace(routes.MAIN_PATH);
  }

  async onFinishClicked(currentTrainingReferenceData, onErrorCallback) {
    this.setState({
      confirmInProgress: true
    });

    const data = {
      training_day_id: this.state.selectedTraining.id,
      unit_id: this.state.selectedUnit.id
    };

    let total_weight = 0;

    for(const groupAssociation of this.state.selectedTraining.group_associations) {
      for(const association of groupAssociation.exercise_associations.filter((association) => association.exercise.target_service === SERVICE_GYM)) {
        const referenceData = currentTrainingReferenceData[association.day_group_association_id].exercise_association_data[association.id];

        const weight = referenceData.weight;
        const repetition = referenceData.repetition;

        if(weight && repetition) {
          total_weight += parseFloat(weight) * parseFloat(repetition);
        }
      }
    }

    data.total_weight = total_weight;

    try{
      const response = await postModel(routes.PERSONAL_TRAINING_POST_API, data, true);

      if(response) {
        this.props.history.replace(`${routes.PERSONAL_TRAINING_EDIT_PATH}${response}`);
      }
    }
    catch(errors) {
      let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            // case 104:
            //   for(let parameter of error.parameters) {
            //     switch (parameter.name) {
            //       case 'contracts':
            //         errorDescription = 'Serviço vinculado à um contrato de aluno. Estes contratos devem ser excluídos antes de excluir este serviço.';
            //
            //         break;
            //       default:
            //     }
            //   }
            //
            //   break;
            case 209:
              errorDescription = 'Sessão do usuário expirada.';

              break;
            default:
          }
        }
      }

      onErrorCallback({
        confirmFailDescription: errorDescription,
        confirmFailed: true,
        confirmInProgress: false
      });
    }
  }

  getUnitOptions() {
    return this.state.active_units.map((unit) => (
      <DefaultMenuButton
        key={`personal_training:unit_selector:${unit.id}`}
        className="personal-training__unit-button"
        onClick={() => this.onSelectUnit(unit)}
        text={unit.name}
        color="blue"
      />
    ));
  }

  getTrainingOptions() {
    if(this.state.selectedUnit === null || !this.state.selectedUnit.training_period || this.state.selectedUnit.training_period.training_days.length <= 0) {
      return 'Treino não configurado';
    }

    const periodIds = this.state.selectedUnit.training_period.training_days.map((entry) => entry.id);

    const lastTraining = {date: null, id: null};

    for(const data of this.state.selectedUnit.training_data) {
      if(!periodIds.includes(data.training_day_id)) {
        continue;
      }

      if(lastTraining.date === null || lastTraining.date < data.date) {
        lastTraining.date = data.date;
        lastTraining.id = data.training_day_id;
      }
    }

    return this.state.selectedUnit.training_period.training_days.map((training) => {
      const executedLast = (lastTraining.date !== null && lastTraining.id === training.id);
      let lastExecutionDate = null;
      let daysCount = 0;

      if(executedLast) {
        lastExecutionDate = getAsLocalDate(lastTraining.date);
        const today = getAsLocalDate((new Date()).toISOString().slice(0, 10));

        const timeDiff = Math.abs(today.getTime() - lastExecutionDate.getTime());
        daysCount = Math.ceil(timeDiff / (1000 * 3600 * 24));
      }

      return (
        <section
          className="personal-training__training-option-section"
          key={`training_day:${training.id}`}
        >

          <header
            className={`personal-training__training-option${executedLast ? '--highlighted' : ''}`}
          >

            <div
              className={`personal-training__training-option__label${training.note === null ? '--unclickable' : ''}`}
              onClick={() => {
                if (training.note === null) {
                  return;
                }

                const trainingVisibleMap = new Map(this.state.trainingVisibleMap);

                trainingVisibleMap.set(training.id, !trainingVisibleMap.get(training.id));

                this.setState({trainingVisibleMap});
              }}
            >

              {training.note !== null ? (this.state.trainingVisibleMap.get(training.id) ?
                <i className="fas fa-chevron-down personal-training__training-option-section__header__visible-icon"></i>:
                <i className="fas fa-chevron-up personal-training__training-option-section__header__visible-icon"></i>) :
                null
              }

              <div className="personal-training__training-option__label__text-wrapper">

                <p className="personal-training__training-option__label__text">{training.name}</p>

                {executedLast &&
                  <p className="personal-training__training-option__label__date">{`${lastExecutionDate.toLocaleDateString()} - ${daysCount} dia(s)`}</p>
                }

              </div>

            </div>

            {!training.is_placeholder &&
              <div className="personal-training__training-option__action-container">

                <DefaultMenuButton
                  className="personal-training__training-option__action"
                  onClick={() => this.onSelectTraining(training)}
                  text="Iniciar"
                  color="green"
                />

              </div>
            }

          </header>

          <VerticalAccordionContainer
            className="vertical-accordion-container personal-training__training-option-section__content"
            pose={this.state.trainingVisibleMap.get(training.id) ? 'verticalOpen' : 'verticalClosed'}>

            <div className={`vertical-accordion-container personal-training__training-option-section__content-wrapper${executedLast ? '--highlighted' : ''}`}>

              <div className="personal-training__training-option__text-wrapper">

                {training.note !== null &&
                  <p className="personal-training__training-option__text"> <span className="personal-training__training-option__note-label">OBS:</span> {training.note}</p>
                }

              </div>

            </div>

          </VerticalAccordionContainer>

        </section>
      );
    });
  }

  getIndicators() {
    if(this.state.selectedUnit === null) {
      return null;
    }

    const periodReference = this.state.selectedUnit.training_period.period_reference || 7;

    const cycleCount = this.state.selectedUnit.training_period.repetition_count;

    const initialDate = getAsLocalDate(this.state.selectedUnit.initial_date);
    const today = getAsLocalDate((new Date()).toISOString().slice(0, 10));

    const timeDiff = Math.abs(today.getTime() - initialDate.getTime());
    let daysCount = Math.ceil(timeDiff / (1000 * 3600 * 24));

    const currentCycle = initialDate <= today ? Math.floor(daysCount / periodReference) + 1 : 0;
    const cycleReferenceMultiplier = Math.min(cycleCount, currentCycle);

    let expectedEnergyConsunption = 0;
    let energyExpended = 0;

    const trainingsCountExpected = this.state.selectedUnit.training_period.training_days.length * cycleReferenceMultiplier;
    let trainingsExecuted = 0;

    const capacitiesMap = new Map();
    const capacityColorMap = new Map();

    const energyExpenseMap = new Map();
    const energyExpectedExpenseMap = new Map();

    const personalTrainingDayIds = [];

    for(const trainingDay of this.state.selectedUnit.training_period.training_days) {
      personalTrainingDayIds.push(trainingDay.id);

      for(const association of trainingDay.group_associations) {
        if(association.activity_reference) {
          if(this.state.student_weight !== null) {
            expectedEnergyConsunption += this.state.student_weight * (association.time_required / 60) * association.activity_reference.met;
          }

          const capacityAbbreviation = association.capacity_abbreviation;

          if(!capacitiesMap.has(capacityAbbreviation)) {
            capacitiesMap.set(capacityAbbreviation, {
              expectedCount: 1 * cycleReferenceMultiplier,
              executed: 0
            });
          }
          else {
            const mapEntry = capacitiesMap.get(capacityAbbreviation);
            mapEntry.expectedCount += 1 * cycleReferenceMultiplier;
          }

          if(!capacityColorMap.has(capacityAbbreviation)) {
            capacityColorMap.set(capacityAbbreviation, association.capacity_color);
          }
        }
      }

      // if(trainingDay.activity_reference) {
      //   if(this.state.student_weight !== null) {
      //     const totalTime = trainingDay.group_associations.reduce((accumulator, currentValue) => accumulator + currentValue.time_required, 0) / 60;
      //
      //     expectedEnergyConsunption += this.state.student_weight * totalTime * trainingDay.activity_reference.met;
      //   }
      //
      //   const capacityAbbreviation = trainingDay.capacity_abbreviation;
      //
      //   if(!capacitiesMap.has(capacityAbbreviation)) {
      //     capacitiesMap.set(capacityAbbreviation, {
      //       expectedCount: 1 * cycleReferenceMultiplier,
      //       executed: 0
      //     });
      //   }
      //   else {
      //     const mapEntry = capacitiesMap.get(capacityAbbreviation);
      //     mapEntry.expectedCount += 1 * cycleReferenceMultiplier;
      //   }
      //
      //   if(!capacityColorMap.has(capacityAbbreviation)) {
      //     capacityColorMap.set(capacityAbbreviation, trainingDay.capacity_color);
      //   }
      // }
    }

    for(const trainingData of this.state.selectedUnit.training_data) {
      if(!this.state.selectedUnit.bound_to_services && (!trainingData.training_day || !personalTrainingDayIds.includes(trainingData.training_day.id))) {
        continue;
      }

      const executionDate = getAsLocalDate(trainingData.date);

      if(executionDate >= initialDate) {
        trainingsExecuted += 1;

        let entryEnergyExpense = 0;

        if (trainingData.energy_expended) {
          entryEnergyExpense = trainingData.energy_expended;
        }
        else if (this.state.student_weight !== null) {
          if (trainingData.is_custom_training && trainingData.training_day_id === null && trainingData.activity_reference_id !== null) {
            entryEnergyExpense = this.state.student_weight * (trainingData.total_time / 60) * trainingData.activity_reference.met;
          }
          else if (trainingData.training_day) {
            entryEnergyExpense = trainingData.training_day.group_associations.reduce((accumulator, association) => {
              if (association.activity_reference) {
                return accumulator + (this.state.student_weight * (association.time_required / 60) * association.activity_reference.met);
              }

              return accumulator;
            }, 0);
          }
        }

        if(entryEnergyExpense > 0) {
          energyExpended += entryEnergyExpense;

          const entryDiff = Math.abs(executionDate.getTime() - initialDate.getTime());
          let days = Math.ceil(entryDiff / (1000 * 3600 * 24));

          const cycleIndex = Math.floor(days / periodReference) + 1;
          const cycleLabel = `Ciclo ${cycleIndex}`;

          if(!energyExpenseMap.has(cycleIndex)) {
            energyExpenseMap.set(cycleIndex, StackPoint(entryEnergyExpense, cycleLabel, entryEnergyExpense));
          }
          else {
            const stackPoint = energyExpenseMap.get(cycleIndex);
            stackPoint.value += entryEnergyExpense;
            stackPoint.index += entryEnergyExpense;
          }
        }

        if (trainingData.is_custom_training && trainingData.training_day_id === null && trainingData.activity_reference_id !== null) {
          const capacityAbbreviation = trainingData.activity_reference.type_abbreviation;

          if(!capacitiesMap.has(capacityAbbreviation)) {
            capacitiesMap.set(capacityAbbreviation, {
              expectedCount: 0,
              executed: 1
            });
          }
          else {
            const mapEntry = capacitiesMap.get(capacityAbbreviation);
            mapEntry.executed += 1;
          }

          if(!capacityColorMap.has(capacityAbbreviation)) {
            capacityColorMap.set(capacityAbbreviation, trainingData.activity_reference.type_color);
          }
        }
        else if (trainingData.training_day) {
          for(const association of trainingData.training_day.group_associations) {
            if(association.capacity_abbreviation) {
              const capacityAbbreviation = association.capacity_abbreviation;

              if(!capacitiesMap.has(capacityAbbreviation)) {
                capacitiesMap.set(capacityAbbreviation, {
                  expectedCount: 0,
                  executed: 1
                });
              }
              else {
                const mapEntry = capacitiesMap.get(capacityAbbreviation);
                mapEntry.executed += 1;
              }

              if(!capacityColorMap.has(capacityAbbreviation)) {
                capacityColorMap.set(capacityAbbreviation, association.capacity_color);
              }
            }
          }
        }

        // if(trainingData.capacity_abbreviation) {
        //   const capacityAbbreviation = trainingData.capacity_abbreviation;
        //
        //   if(!capacitiesMap.has(capacityAbbreviation)) {
        //     capacitiesMap.set(capacityAbbreviation, {
        //       expectedCount: 0,
        //       executed: 1
        //     });
        //   }
        //   else {
        //     const mapEntry = capacitiesMap.get(capacityAbbreviation);
        //     mapEntry.executed += 1;
        //   }
        //
        //   if(!capacityColorMap.has(capacityAbbreviation)) {
        //     capacityColorMap.set(capacityAbbreviation, trainingData.capacity_color);
        //   }
        // }
      }
    }

    for(const entry of energyExpenseMap.values()) {
      entry.index = roundValue(entry.index, 2);
    }

    const energyExpectedExpensePoints = []
    const energyExpensePoints = []

    const cycleLabels = [];

    if(cycleReferenceMultiplier > 0) {
      for(let i=1; i<=cycleReferenceMultiplier; ++i) {
        const cycleLabel = `Ciclo ${i}`;

        cycleLabels.push(i);

        energyExpectedExpenseMap.set(i, StackPoint(expectedEnergyConsunption, cycleLabel, roundValue(expectedEnergyConsunption, 2)));
      }
    }

    for(const key of energyExpenseMap.keys()) {
      if(!cycleLabels.includes(key)) {
        cycleLabels.push(key);
      }
    }

    cycleLabels.sort((a, b) => a - b);

    for(const labelIndex of cycleLabels) {
      const label = `Ciclo ${labelIndex}`;

      if(energyExpenseMap.has(labelIndex)) {
        energyExpensePoints.push(energyExpenseMap.get(labelIndex));
      }
      else {
        energyExpensePoints.push(StackPoint(0, label, 0));
      }

      if(energyExpectedExpenseMap.has(labelIndex)) {
        energyExpectedExpensePoints.push(energyExpectedExpenseMap.get(labelIndex));
      }
      else {
        energyExpectedExpensePoints.push(StackPoint(0, label, 0));
      }
    }

    const energyConsumptionData = [
      StackGroup('Gasto', energyExpensePoints, '#f95b5b', null),
      StackGroup('Estimado', energyExpectedExpensePoints, '#d08989', null),
    ];

    const capacityExpected = [];
    const capacityExecuted = [];

    for(let [key, value] of capacitiesMap) {
      capacityExecuted.push(BarDataPoint(value.executed, key, capacityColorMap.get(key)));
      capacityExpected.push(BarDataPoint(value.expectedCount, key, `${capacityColorMap.get(key)}5c`));
    }

    const capacityGraphData = [
      {
        name: "Esperada",
        data: capacityExpected
      },
      {
        name: "Executada",
        data: capacityExecuted
      },
    ];

     energyExpended = roundValue(energyExpended, 0);
     expectedEnergyConsunption = roundValue(cycleReferenceMultiplier * expectedEnergyConsunption, 0);

     const totalProgress = 100 * trainingsExecuted / (this.state.selectedUnit.training_period.training_days.length * cycleCount);

    return {
      periodReference,
      cycleCount,
      currentCycle,
      expectedEnergyConsunption,
      energyExpended,
      trainingsCountExpected,
      trainingsExecuted,
      capacityGraphData,
      energyConsumptionData,
      totalProgress
    };
  }

  getGraphHeight() {
    if(this.state.screenWidth <= 380) {
      return 300;
    }

    if(this.state.screenWidth <= 600) {
      return 350;
    }

    return 400;
  }

  getDefaultGraphHeight() {
    if(this.state.screenWidth <= 420) {
      return 220;
    }

    if(this.state.screenWidth <= 600) {
      return 270;
    }

    if(this.state.screenWidth <= 1100) {
      return 350;
    }

    return null;
  }

  getScheduleConfiguration() {
    if (this.state.selectedUnit.schedule_configuration.length <= 0) {
      return null;
    }

    let schedule_configuration = this.state.selectedUnit.schedule_configuration;

    const weekdays = [
      'Segunda',
      'Terça',
      'Quarta',
      'Quinta',
      'Sexta',
      'Sábado',
      'Domingo',
    ];

    let convertedTodayWeekDay = this.todayWeekDay - 1;
    if (convertedTodayWeekDay < 0) {
      convertedTodayWeekDay = 6;
    }

    let currentWeekIndex = 0;

    if (schedule_configuration.length > 0) {
      const initialDate = getAsLocalDate(this.state.selectedUnit.initial_date);
      const today = getAsLocalDate((new Date()).toISOString().slice(0, 10));

      const timeDiff = Math.abs(today.getTime() - initialDate.getTime());
      const daysCount = Math.ceil(timeDiff / (1000 * 3600 * 24)) + convertedTodayWeekDay;

      currentWeekIndex = (Math.floor(daysCount / 7)) % schedule_configuration.length;
    }

    const weekdayList = weekdays.map((weekday, index) => {
      return (
        <div
          key={`schedule_configuration:header:${index}`}
          className={`personal-training__schedule-configuration__header__weekday${index === convertedTodayWeekDay ? '--highlighted' : ''}`}
        >

          <h3 className="personal-training__schedule-configuration__header__weekday__text">

            {weekday}

          </h3>

        </div>
      );
    });

    const weeks = schedule_configuration.map((weekList, weekIndex) => {
      return (
        <div
          key={`schedule_configuration:week:${weekIndex}`}
          className="personal-training__schedule-configuration__week"
        >

          <div
            className={`personal-training__schedule-configuration__week__header${weekIndex === currentWeekIndex ? '--highlighted' : ''}`}
          >

            <h3 className="personal-training__schedule-configuration__week__header__text">

              {`Semana ${weekIndex + 1}`}

            </h3>

          </div>

          <div className="personal-training__schedule-configuration__week__content">

            {weekList.map((entry, dayIndex) => {
              let content = null;

              if (entry !== null) {
                content = (
                  <div
                    className="personal-training__schedule-configuration__week__weekday__wrapper"
                    style={{background: entry.color}}
                  >

                    <p className="personal-training__schedule-configuration__week__weekday__text">

                      {entry.text}

                    </p>

                  </div>
                );
              }

              return (
                <div
                  key={`schedule_configuration:week:${weekIndex}:weekday:${dayIndex}`}
                  className="personal-training__schedule-configuration__week__weekday"
                >

                  {content}

                </div>
              );
            })}

          </div>

        </div>
      );
    });

    return (
      <div className="personal-training__schedule-configuration__wrapper">

        <div className="personal-training__schedule-configuration">

          <div className="personal-training__schedule-configuration__header">

            {weekdayList}

          </div>

          <div
            className="personal-training__schedule-configuration__week-list"
          >

            {weeks}

          </div>

        </div>

      </div>
    );
  }

  getContentOptions() {
    if(this.state.selectedUnit === null) {
      return (
        <React.Fragment>

          <h1 className="personal-training__instructions">

            Selecione a unidade desejada:

          </h1>

          <div className="personal-training__training-selector">

            {this.getUnitOptions()}

          </div>

        </React.Fragment>
      );
    }
    else {
      const indicatorsData = this.getIndicators();

      return (
        <React.Fragment>

          <p className="personal-training__how-to-use-text">
            Veja nosso vídeo explicativo de como executar o treino
            <a
              className="personal-training__how-to-use-link"
              href="https://youtu.be/hMKjY76fGlw"
              target="_blank"
              rel="noopener noreferrer"
            >

                aqui

            </a>.
          </p>

          <p className="personal-training__note-text">
            Lembre-se de ajustar a configuração de descanso de tela de seu dispositivo antes de iniciar o treino.
          </p>

          <section className="personal-training__default-section">

            <header className="personal-training__default-section__header">

              <h3 className="personal-training__default-section__header__title">{this.state.selectedUnit.training_period.name}</h3>

              <div className="personal-training__default-section__header__end-date">

                <p className="personal-training__default-section__header__end-date__label">DATA LIMITE</p>

                <p className="personal-training__default-section__header__end-date__text">{getAsLocalDate(this.state.selectedUnit.final_date).toLocaleDateString()}</p>

              </div>

            </header>

            <div className="personal-training__default-section__content">

              {(this.state.selectedUnit.schedule_configuration.length > 0 || this.state.selectedUnit.training_period.note !== null) &&
                <section
                  className="personal-training__student-note"
                >

                  <header
                    className="personal-training__student-note__header"
                    onClick={() => this.setState({studentNoteVisible: !this.state.studentNoteVisible})}
                  >

                    <h3 className="personal-training__student-note__header__text">
                      <i className="fa-solid fa-calendar-days personal-training__student-note__header__text-icon"></i>
                      Cronograma
                    </h3>

                    {this.state.studentNoteVisible ?
                      <i className="fas fa-chevron-down personal-training__student-note__header__visible-icon"></i>:
                      <i className="fas fa-chevron-up personal-training__student-note__header__visible-icon"></i>
                    }

                  </header>

                  <VerticalAccordionContainer
                    className="vertical-accordion-container personal-training__student-note__content"
                    pose={this.state.studentNoteVisible ? 'verticalOpen' : 'verticalClosed'}>

                    <div className="vertical-accordion-container personal-training__student-note__content-wrapper">

                      {this.getScheduleConfiguration()}

                      {this.state.selectedUnit.training_period.note !== null ? (
                        <p className="personal-training__student-note__text">

                          {this.state.selectedUnit.training_period.note}

                        </p>
                      ) : null}

                    </div>

                  </VerticalAccordionContainer>

                </section>
              }

              {this.getTrainingOptions()}

            </div>

          </section>

          <section className="personal-training__default-section">

            <header className="personal-training__default-section__header">

              <h3 className="personal-training__default-section__header__title">
                <i className="fas fa-chart-line personal-training__default-icon"></i> Acompanhamento
              </h3>

            </header>

            <div className="personal-training__default-section__content">

              <div className="personal-training__indicators-container">

                <div className="personal-training__indicators-wrapper">

                  <div className="personal-training__indicator">

                    <h2 className="personal-training__indicator__label">Duração do ciclo:</h2>
                    <p className="personal-training__indicator__value">{`${indicatorsData.periodReference} dias`}</p>

                  </div>

                  <div className="personal-training__indicator">

                    <h2 className="personal-training__indicator__label">Ciclo atual:</h2>
                    <p className="personal-training__indicator__value">{`${indicatorsData.currentCycle} / ${indicatorsData.cycleCount}`}</p>

                  </div>

                  <div className="personal-training__indicator">

                    <h2 className="personal-training__indicator__label">Treinos executados:</h2>
                    <p className="personal-training__indicator__value">{`${indicatorsData.trainingsExecuted} / ${indicatorsData.trainingsCountExpected}`}</p>

                  </div>

                  <div className="personal-training__indicator">

                    <h2 className="personal-training__indicator__label">Gasto calórico:</h2>
                    <p className="personal-training__indicator__value">{`${indicatorsData.energyExpended} / ${indicatorsData.expectedEnergyConsunption} kcal`}</p>

                  </div>

                </div>

                <div className="personal-training__progress-indicator">

                  <p className="personal-training__progress-indicator__text">

                    {indicatorsData.totalProgress.toFixed(0)}%

                  </p>

                </div>

              </div>

              <div className="personal-training__graphs-container">

                <div className="personal-training__graphs-wrapper">

                  {(indicatorsData.currentCycle > 0 && indicatorsData.capacityGraphData.some((entry) => entry.data.length > 0)) &&
                    <HorizontalBarGraph
                      className="personal-training__graph"
                      title="Capacidades exercidas"
                      data={indicatorsData.capacityGraphData}
                      multipleGroups={true}
                      height={this.getGraphHeight()}
                      toolTipValueCallback={(value) => `${roundValue(value, 2)} grupo(s)`}
                    />
                  }

                  {indicatorsData.energyExpended > 0 &&
                    <StackedBarGraph
                      className="personal-training__graph"
                      title="Gasto calórico"
                      data={indicatorsData.energyConsumptionData}
                      height={this.getDefaultGraphHeight()}
                      legendVerticalAlign={this.state.screenWidth > 770 ? 'center' : 'bottom'}
                      legendHorizontalAlign={this.state.screenWidth > 770 ? 'right' : 'center'}
                      doNotStack={true}
                      ToolTipValueCallback={(value) => `${roundValue(value, 2)} kcal`}
                      normalXLabel={true}
                      normalLegendOrder={true}
                    />
                  }

                </div>

              </div>

            </div>

          </section>

          {/* <section className="personal-training__default-section">

            <header className="personal-training__default-section__header">

              <h3 className="personal-training__default-section__header__title">
                <i className="fas fa-history personal-training__default-icon"></i> Histórico
              </h3>

            </header>

            <div className="personal-training__default-section__content">

            </div>

          </section> */}

        </React.Fragment>
      );
    }
  }

  render() {
    return this.state.loading ? (
      <PoseGroup>
        <FadeContainer key="preloader">
          <PreLoader />
        </FadeContainer>
      </PoseGroup>
    ) : (
      <React.Fragment>

        {this.state.selectedTraining !== null ? (
          <ClassBoard
            selectedTraining={this.state.selectedTraining}
            onBackClicked={() => this.onBackClicked()}
            onFinishClicked={(currentTrainingReferenceData, callback) => this.onFinishClicked(currentTrainingReferenceData, callback)}
            disableFinishButton={this.state.selectedUnit.bound_to_services}
            student={this.state.student}
            personalActivePeriodData={{
              initial_date: this.state.selectedUnit.initial_date,
              final_date: this.state.selectedUnit.final_date,
              bound_to_services: this.state.selectedUnit.bound_to_services,
              repetition_count: this.state.selectedUnit.training_period.repetition_count,
              period_reference: this.state.selectedUnit.training_period.period_reference,
              target_service: this.state.selectedUnit.training_period.target_service
            }}
          />
        ) : (
          <DefaultMenuLayout
            showBackButton={true}
            history={this.props.history}
            onLogout={() => this.props.onLogout()}
            username={this.props.username}
          >

            <div className="personal-training">

              <Link
                className="personal-training__support-button"
                to={routes.PERSONAL_TRAINING_SUPPORT_PATH}
              >

                <i className="fa-solid fa-headset personal-training__support-button__icon"></i>

                <div className="personal-training__support-button__text-wrapper">

                  <span> Suporte</span>

                  {this.state.support_warning_message !== null &&
                    <span className="personal-training__support-button__note-text">({this.state.support_warning_message})</span>
                  }

                </div>

              </Link>

              <hr className="personal-training__horizontal-rule" />

              {this.getContentOptions()}

            </div>

          </DefaultMenuLayout>
        )}

        <ConfirmationWindow
          title="Falha de conexão"
          description={this.state.errorMessage}
          cancelText="Ok"
          visible={this.state.errorMessage !== null}
          onCancel={() => this.setState({errorMessage: null})}
          useErrorIcon={true}
          hideConfirmButton={true}
        />

      </React.Fragment>
    );
  }
}

export default PersonalTraining;
