import React from 'react';
import './default_header.scss';
import logo from '../images/logo_fyd_club.png';

function DefaultHeader(props) {
  return (
    <header className={`default-header ${props.className}`}>

      {props.showBackButton &&
        <button
          className="default-header__back-button"
          onClick={() => props.onBackClick ? props.onBackClick() : props.history.goBack()}
        >
          <i className="fas fa-angle-left default-header__back-button__icon"></i>
          <span>Voltar</span>
        </button>
      }

      <div className="default-header__logo-wrapper">

        <img className="default-header__logo" src={logo} alt="Logo da FYD" />

      </div>

      <p className="default-header__username">
        {props.username.split(' ')[0]}
      </p>

      <button
        className="default-header__exit-button"
        onClick={() => props.onLogout()}
      >
        <i className="fas fa-sign-out-alt default-header__exit-button__icon"></i>
      </button>

    </header>
  );
}

export default DefaultHeader;
