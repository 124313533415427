import React from 'react';
import { PoseGroup } from 'react-pose';
import './add_custom_training.scss';
import {FadeContainer} from '../../utils/pose_containers';
import PreLoader from '../../utils/preloader';
import * as routes from '../../constants';
import {DEFAULT_UNKNOWN_ERROR_MESSAGE} from '../../constants';
import DefaultMenuLayout from '../../components/default_menu_layout';
import {postModel, getModels, getLocalDateIsoString} from '../../utils/functions';
import CustomTrainingData from './custom_training_data';

class AddTrainingData extends React.Component {
  constructor(props) {
    super(props);

    const date = getLocalDateIsoString(new Date());

    this.state = {
      loading: true,
      training: {
        training_day_id: null,
        exercise: "",
        activity_reference_id: null,
        date,
        pse: null,
        total_repetitions: null,
        total_seconds: null,
        total_time: 0,
      },
      exercise_activities: [],
      highlights: [],
      warningMessage: "",
      showWarningMessage: false,
      screenWidth: window.innerWidth,
    };
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  async componentDidMount() {
    const update = {loading: false};

    let exercise_activities = getModels(routes.EXERCISE_ACTIVITIES_GET_API);

    exercise_activities = await exercise_activities;

    if(exercise_activities) {
      update.exercise_activities = exercise_activities;
      update.exercise_activities.sort((a, b) => a.name.localeCompare(b.name))
    }

    this.setState(update);

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;

    const newData = {...this.state.training, [name]: value};

    this.setState({
      training: newData
    });
  }

  onSetPse(value) {
    const newData = {...this.state.training, pse: value};

    this.setState({
      training: newData
    });
  }

  inputsAreValid() {
    return true;
  }

  async saveData() {
    let warningMessages = [];
    let highlights = [];

    // if(this.state.training.exercise.length <= 0) {
    //   highlights.push('exercise');
    //   warningMessages.push('Nome do exercício obrigatório');
    // }
    if(this.state.training.activity_reference_id === null || this.state.training.activity_reference_id.length <= 0) {
      highlights.push('activity_reference_id');
      warningMessages.push('Exercício obrigatório');
    }
    if(this.state.training.date.length <= 0) {
      highlights.push('date');
      warningMessages.push('Data obrigatório');
    }
    if(this.state.training.total_time <= 0) {
      highlights.push('total_time');
      warningMessages.push('Preencha o tempo do total do exercício');
    }
    if(this.state.training.pse === null) {
      highlights.push('pse');
      warningMessages.push('Selecione o PSE');
    }

    if(highlights.length > 0) {
      this.setState({
        highlights: highlights,
        showWarningMessage: true,
        warningMessage: `${warningMessages.join('; ')}.`,
      });

      return;
    }

    this.setState({
      highlights: [],
      showWarningMessage: false,
      loading: true
    });

    const data = {...this.state.training}
    data.total_time = parseFloat(data.total_time);
    data.activity_reference_id = parseInt(data.activity_reference_id);
    data.exercise = this.state.exercise_activities.find((entry) => entry.id === data.activity_reference_id).name;

    try {
      await postModel(routes.CUSTOM_TRAINING_POST_API, data);
    }
    catch(errors) {
      warningMessages = [];
      highlights = [];

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            case 102:
              for(let parameter of error.parameters) {
                switch (parameter.name) {
                  case 'date':
                    warningMessages.push('Data inválida. Data cadastrada não pode ser nem no futuro e nem mais do que 28 dias atrás');
                    highlights.push('date');

                    break;
                  default:
                }
              }

              break;
            case 209:
              warningMessages.push('Sessão do usuário expirada');

              break;
            default:
          }
        }
      }

      if(warningMessages.length <= 0) {
        warningMessages.push(DEFAULT_UNKNOWN_ERROR_MESSAGE);
      }

      this.setState({
        highlights: highlights,
        showWarningMessage: true,
        warningMessage: `${warningMessages.join('; ')}.`,
        loading: false
      });

      return;
    }

    this.props.history.goBack();
    // this.props.history.replace(routes.TRAININGS_PATH);
  }

  render() {
    if(this.state.loading) {
      return (
        <PoseGroup>
          <FadeContainer key="preloader">
            <PreLoader />
          </FadeContainer>
        </PoseGroup>
      );
    }

    return (
      <DefaultMenuLayout
        showBackButton={true}
        history={this.props.history}
        onLogout={() => this.props.onLogout()}
        username={this.props.username}
      >

        <div className="add-custom-training">

          <section className="add-custom-training__data">

            <header className="add-custom-training__data__header">

              <h3 className="add-custom-training__data__header__title">
                <i className="fas fa-plus"></i>
                {' '}Cadastrar treino complementar
              </h3>

            </header>

            <div className="add-custom-training__data__content">

              <CustomTrainingData
                warningMessage={this.state.warningMessage}
                showWarningMessage={this.state.showWarningMessage}
                training={this.state.training}
                onSave={() => this.saveData()}
                onCloseWarning={() => {this.setState({highlights: [], showWarningMessage: false})}}
                enableSave={this.inputsAreValid()}
                handleInputChange={(event) => this.handleInputChange(event)}
                highlights={this.state.highlights}
                onCancelPath={() => this.props.history.goBack()}
                onSetPse={(value) => this.onSetPse(value)}
                exercise_activities={this.state.exercise_activities}
              />

            </div>

          </section>

        </div>

      </DefaultMenuLayout>
    );
  }
}

export default AddTrainingData;
