import React from 'react';
import { PoseGroup } from 'react-pose';
import './home_training.scss';
import {FadeContainer} from '../utils/pose_containers';
import PreLoader from '../utils/preloader';
import * as routes from '../constants';
import {DEFAULT_UNKNOWN_ERROR_MESSAGE} from '../constants';
import DefaultMenuButton from '../components/default_menu_button';
import DefaultMenuLayout from '../components/default_menu_layout';
import {VerticalAccordionContainer} from '../utils/pose_containers';
import {getModel, postModel} from '../utils/functions';
import ClassBoard from '../components/class_board/class_board';


class HomeTraining extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      rac: 0,
      training_periods: [],
      periods_visible: {},
      selectedTraining: null,
    };
  }

  async getHomeTrainings() {
    return await getModel(routes.HOME_TRAININGS_GET_API);
  }

  async getRAC() {
    return await getModel(routes.RAC_GET_API);
  }

  async componentDidMount() {
    const update = {loading: false};

    let training_periods = await this.getHomeTrainings();
    let rac = await this.getRAC();

    if(training_periods) {
      update.training_periods = training_periods;
    }

    if(rac) {
      update.rac = rac;
    }

    this.setState(update);
  }

  onSelectTraining(training) {
    const update = {selectedTraining: training};

    this.setState(update);
  }

  getTrainingOptions() {
    if(this.state.training_periods.length <= 0) {
      return (
        <p className="home-training__main-message">
          Nenhum treino disponível no momento
        </p>
      );
    }

    return this.state.training_periods.map((period) => {
      const periods_visible = {...this.state.periods_visible};
      periods_visible[period.period_name] = !(periods_visible[period.period_name] || false);

      const trainingElements = period.trainings.map((training, index) => (
        <DefaultMenuButton
          key={`home_training:training:${training.id}`}
          className="home-training__training-button"
          onClick={() => this.onSelectTraining(training)}
          text={training.name}
          color="dark"
        />
      ))

      return (
        <section
          key={`home_training:period:${period.period_name}`}
          className="home-training__period-list"
        >

          <header
            className="home-training__period-list__header"
            onClick={() => this.setState({periods_visible})}
          >

            <h3 className="home-training__period-list__header__text">
              {period.period_name}
            </h3>

            {this.state.periods_visible[period.period_name] ?
              <i className="fas fa-chevron-down home-training__period-list__header__visible-icon"></i>:
              <i className="fas fa-chevron-up home-training__period-list__header__visible-icon"></i>
            }

          </header>

          <VerticalAccordionContainer
            className="vertical-accordion-container home-training__period-list__content"
            pose={this.state.periods_visible[period.period_name] ? 'verticalOpen' : 'verticalClosed'}>

            <div className="vertical-accordion-container home-training__period-list__content-wrapper">

              {trainingElements}

            </div>

          </VerticalAccordionContainer>

        </section>
      );
    });
  }

  onBackClicked() {
    this.props.history.replace(routes.MAIN_PATH);
  }

  async onFinishClicked() {
    this.setState({
      confirmInProgress: true
    });

    const data = {training_day_id: this.state.selectedTraining.id};

    try{
      const response = await postModel(routes.HOME_TRAINING_FINISH_POST_API, data, true);

      if(response) {
        this.props.history.replace(`${routes.EDIT_CUSTOM_TRAINING_PATH}${response}`);
      }
    }
    catch(errors) {
      let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            // case 104:
            //   for(let parameter of error.parameters) {
            //     switch (parameter.name) {
            //       case 'contracts':
            //         errorDescription = 'Serviço vinculado à um contrato de aluno. Estes contratos devem ser excluídos antes de excluir este serviço.';
            //
            //         break;
            //       default:
            //     }
            //   }
            //
            //   break;
            case 209:
              errorDescription = 'Sessão do usuário expirada.';

              break;
            default:
          }
        }
      }

      this.setState({
        confirmFailDescription: errorDescription,
        confirmFailed: true,
        confirmInProgress: false
      });

      return;
    }
  }

  render() {
    return this.state.loading ? (
      <PoseGroup>
        <FadeContainer key="preloader">
          <PreLoader />
        </FadeContainer>
      </PoseGroup>
    ):
    this.state.selectedTraining !== null ?
    (
      <ClassBoard
        selectedTraining={this.state.selectedTraining}
        onBackClicked={() => this.onBackClicked()}
        onFinishClicked={() => this.onFinishClicked()}
        zone_index={this.state.rac}
        hidePRSInput={true}
      />
    ):
    (
      <DefaultMenuLayout
        showBackButton={true}
        history={this.props.history}
        onLogout={() => this.props.onLogout()}
        username={this.props.username}
      >

        <div className="home-training">

          <p className="home-training__how-to-use-text">
            Em caso de qualquer dúvida, clique
            <a
              className="home-training__how-to-use-link"
              href="https://youtu.be/hMKjY76fGlw"
              target="_blank"
              rel="noopener noreferrer"
            >

                aqui

            </a>
            para assistir nosso vídeo explicativo de como funcionam nossos treinos.
          </p>

          <p className="home-training__note-text">
            Lembre-se de ajustar a configuração de descanso de tela de seu dispositivo antes de iniciar o treino.
          </p>

          <hr className="home-training__horizontal-rule" />

          <h1 className="home-training__instructions">

            Selecione o treino desejado:

          </h1>

          <div className="home-training__training-selector">

            {this.getTrainingOptions()}

          </div>

        </div>

      </DefaultMenuLayout>
    );
  }
}

export default HomeTraining;
