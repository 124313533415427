import React from 'react';
import './default_filter_input.scss';

function DefaultFilterInput(props) {
  return (
    <div className="default-filter-input">

      <label
        className="default-filter-input__label"
        htmlFor={`filter_input:${props.name}`}
      >
        {props.label}
      </label>

      <input
        className="default-filter-input__input"
        type="text"
        id={`filter_input:${props.name}`}
        name={props.name}
        placeholder={props.placeholder}
        autoComplete={props.autoComplete}
        value={props.value}
        onChange={props.handleInputChange}
      />

    </div>
  );
}

export default DefaultFilterInput;
