import React from 'react';
import CanvasJSReact from '../libs/canvasjs/canvasjs.react';

function BarDataPoint(value, label, color) {
  return {
    value,
    label,
    color
  }
}

export {BarDataPoint};

class HorizontalBarGraph extends React.Component {
  componentDidMount() {
    if(window.beforeToggleMenuCallbacks) {
      this.menuToggleCallback = (value) => {
        setTimeout(() => this.chart.render(), 400);
      };

      window.beforeToggleMenuCallbacks.add(this.menuToggleCallback);
    }
  }

  componentWillUnmount() {
    if(window.beforeToggleMenuCallbacks) {
      window.beforeToggleMenuCallbacks.delete(this.menuToggleCallback);
    }
  }

  getOptions() {
    const options = {
			animationEnabled: true,
			theme: "light2",
			axisX: {
				reversed: true,
        labelFontWeight: 'bold',
			}
		}

    if(this.props.toolTipValueCallback) {
      options.toolTip = {
				shared: true,
        contentFormatter: (e) => {
  				let content = "";

  				for (let i = e.entries.length - 1; i >= 0; i--) {
            if(i === e.entries.length - 1) {
              content += `${e.entries[i].dataPoint.label}<br/>`;
            }

            content += `<span style="color: ${e.entries[i].dataPoint.color};">${e.entries[i].dataSeries.name}:</span> ${this.props.toolTipValueCallback(e.entries[i].dataPoint.y)}<br/>`;
  				}

  				return content;
  			}
			};
    }

    if(this.props.multipleGroups) {
      options.data = this.props.data.map((group) => ({
				type: "bar",
        name: group.name,
				dataPoints: group.data.map((entry) => ({
          y: entry.value,
          label: entry.label,
          color: entry.color
        })),
			}));
    }
    else {
      options.data = [{
				type: "bar",
				dataPoints: this.props.data.map((entry) => ({
          y: entry.value,
          label: entry.label,
          color: entry.color
        })),
			}];
    }

    if(this.props.title) {
      options.title = {
        text: this.props.title,
        fontFamily: "Verdana",
        fontWeight: "normal",
      };
    }

    if(this.props.xLabel) {
      options.axisX.title = this.props.xLabel;
    }

    if(this.props.yLabel) {
      options.axisY = {
        title: this.props.yLabel,
      };
    }

    if(this.props.height) {
      options.height = this.props.height;
    }

    return options;
  }

	render() {
		return (
		<div className={this.props.className}>
			<CanvasJSReact.CanvasJSChart
        options={this.getOptions()}
        onRef={ref => this.chart = ref}
			/>
		</div>
		);
	}
}

export default HorizontalBarGraph;
