import React from 'react';
import { Link } from 'react-router-dom';
import './login.scss';
import {getStateToken} from '../utils/functions';
import WarningMessage from '../components/warning_message';
import {AUTHENTICATE_API} from '../constants'
import * as routes from '../constants';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      authenticating: false,
      warningMessage: "",
      showWarningMessage: false,
    };
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  mayAuthenticate() {
    return this.state.email.match(/.+@.+\..+/) != null &&
           this.state.password.length > 0 ;
  }

  handleKeyDown(event) {
    if(event.keyCode === 13 && this.mayAuthenticate()) {
      this.authenticate();
    }
  }

  async handleAuthenticateError(response) {
    if(response.status === 401) {
      const data = await response.json();

      if(data.code === 202) {
        this.setState({
          authenticating: false,
          showWarningMessage: true,
          warningMessage: "E-mail ou(e) senha inválido(s). Tente novamente.",
          password: "",
        });

        return null;
      }
    }
    else if(response.status === 403) {
      const data = await response.json();

      if(data.code === 203) {
        if(data.message.includes('No active contract')) {
          this.setState({
            authenticating: false,
            showWarningMessage: true,
            warningMessage: "Aluno(a) inativo(a).",
            password: "",
          });
        }
        else {
          this.setState({
            authenticating: false,
            showWarningMessage: true,
            warningMessage: "Usuário não possui permissão.",
            password: "",
          });
        }

        return null;
      }
    }

    throw Error('Infelizmente estamos com problemas em nosso serviço. Por favor, tente novamente mais tarde.');
  }

  async authenticate() {
    this.setState({
      authenticating: true,
      showWarningMessage: false,
    });

    let stateToken = await getStateToken(true);

    if(stateToken) {
      const headers = new Headers({
        'Content-Type': 'application/json',
        'state': stateToken
      });

      const body = JSON.stringify({
        email: this.state.email,
        password: this.state.password,
      });

      try {
        let response = await fetch(AUTHENTICATE_API,
          {
            method: "POST",
            headers: headers,
            cache: 'no-store',
            body: body,
          });

        if(!response.ok) {
          return await this.handleAuthenticateError(response);
        }

        let data = await response.json();

        if(data.redirect_to) {
          this.props.history.replace(data.redirect_to);
        }

        this.props.onAuthenticate(data);

        return data;
      }
      catch(error) {
        console.log(error);
      }
    }

    this.setState({
      authenticating: false,
      showWarningMessage: true,
      warningMessage: "Infelizmente estamos com problemas em nosso serviço. Por favor, tente novamente mais tarde.",
    });

    return null;
  }

  render() {
    return (
      <section className="login">

        <div className="login__container">

          <header className="login__header">

            <h1 className="login__header__text">
              FYD
              {' '}
              <span className="login__header__text--red">CLUB</span>
            </h1>

          </header>

          {/* <div className="login__title">

            <p className="login__title__text">
              Realize o login para requisitar a autorização do dispositivo
            </p>

          </div> */}

          <div className="login__warning-wrapper">

            <WarningMessage
              message={this.state.warningMessage}
              onClose={() => {this.setState({showWarningMessage: false})}}
              visible={this.state.showWarningMessage}
            />

          </div>

          <div className="login__input-wrapper">

            <input
              className="login__input"
              name="email"
              type="email"
              placeholder="E-mail"
              onChange={(event) => this.handleInputChange(event)}
              value={this.state.email}
              onKeyDown={(event) => this.handleKeyDown(event)}
            />

            <input
              className="login__input"
              name="password"
              type="password"
              placeholder="Senha"
              onChange={(event) => this.handleInputChange(event)}
              value={this.state.password}
              onKeyDown={(event) => this.handleKeyDown(event)}
            />

          </div>

          <div className="login__authenticate-wrapper">

            <button
              className="login__authenticate-button"
              disabled={!this.mayAuthenticate() || this.state.authenticating}
              onClick={() => this.authenticate()}
            >

              {this.mayAuthenticate() ? (<i className="fas fa-unlock"></i>) : (<i className="fas fa-lock"></i>)}
              {this.state.authenticating ? ' Autenticando...' : ' Entrar'}

            </button>

          </div>

          <div className="login__forgot-password-link__wrapper">

            <Link
              className="login__forgot-password-link"
              to={routes.FORGOT_PASSWORD_PATH}
              >

                <p className="login__forgot-password-link__text">
                  Esqueci minha senha
                </p>

            </Link>

          </div>

          <footer className="login__footer">

            <p className="login__footer__text">

              <i className="far fa-copyright"></i>
              {' '}{(new Date()).getFullYear()} | FYD CENTRO DE TREINAMENTO

             </p>

          </footer>

        </div>

      </section>
    );
  }
}

export default Login;
