import React from 'react';
import CanvasJSReact from '../libs/canvasjs/canvasjs.react';
import {getCurrencyText} from '../utils/functions';

function StackGroup(name, points, color, totalTag=null, visible=true, showInLegend=true) {
  return {
    name: name,
    points: points,
    color: color,
    totalTag: totalTag,
    visible: visible,
    showInLegend: showInLegend,
  }
}

function LineGroup(name, points, color, totalTag=null, lineType='solid', visible=true, showInTooltip=true) {
  return {
    name: name,
    points: points,
    color: color,
    totalTag: totalTag,
    lineType: lineType,
    visible: visible,
    showInTooltip: showInTooltip,
  }
}

function StackPoint(value, label, index, totalTag=null, color=null) {
  return {
    value,
    label,
    index,
    color,
  }
}

class ErrorPoint {
  constructor(value, label, color=null) {
    this.value = value;
    this.label = label;
    this.color = color;
  }

  get graphPoint() {
    const point = {y: this.value, label: this.label};

    if(this.color) {
      point.color = this.color;
    }

    return point;
  }
}

export {StackGroup, LineGroup, StackPoint, ErrorPoint};

function getPointData(value, name, index=null, totalTag=null, showInTooltip=true, color=null) {
  const point = {y: value, label: name, totalTag, showInTooltip};

  if(index) {
    point.indexContent = index;
  }

  if(color !== null) {
    point.color = color;
  }

  return point;
}


class StackedBarGraph extends React.Component {
  componentDidMount() {
    if(window.beforeToggleMenuCallbacks) {
      this.menuToggleCallback = (value) => {
        setTimeout(() => {
          if (this.chart) {
            this.chart.render()
          }
        }, 400);
      };

      window.beforeToggleMenuCallbacks.add(this.menuToggleCallback);
    }
  }

  componentWillUnmount() {
    if(window.beforeToggleMenuCallbacks) {
      window.beforeToggleMenuCallbacks.delete(this.menuToggleCallback);
    }
  }

  getData() {
    let data = [...this.props.data.map((group) => ({
      type: this.props.isHorizontal ? this.props.doNotStack ? 'bar' : 'stackedBar' : this.props.doNotStack ? 'column' : 'stackedColumn',
      name: group.name,
      color: group.color,
      visible: group.visible,
      indexLabel: "{indexContent}",
      indexLabelFontWeight: "bold",
      indexLabelFontColor: "#333",
      showInLegend: group.showInLegend,
      yValueFormatString: "R$ #,##0.00",
      dataPoints: group.points.map((point) => getPointData(point.value, point.label, point.index, group.totalTag, true, point.color))
    }))];

    if(this.props.lineData) {
      data = [
        ...data,
        ...this.props.lineData.map((group) => ({
          type: "line",
          axisYType: this.props.lineYAxisType || 'primary',
          lineDashType: group.lineType,
          name: group.name,
          color: group.color,
          visible: group.visible,
          indexLabelPlacement: "outside",
          indexLabelBackgroundColor: "#ffffff94",
          indexLabelFontWeight: "bold",
          indexLabel: "{indexContent}",
          indexLabelFontColor: "#333",
          showInLegend: true,
          yValueFormatString: "R$ #,##0.00",
          dataPoints: group.points.map((point) => getPointData(point.value, point.label, point.index, group.totalTag, group.showInTooltip))
        }))
      ];
    }

    if(this.props.errorData) {
      data.push({
        type: "error",
        name: "Desvio padr�o",
        color: "#C0504E",
        linkedDataSeriesIndex: 0,
        stemThickness: 1,
        whiskerThickness: 1,
        stemDashType: "dash",
        dataPoints: this.props.errorData.map((point) => point.graphPoint)
      });
    }
    else if (this.props.improvedErrorData) {
      let index = 0;

      for (const entry of this.props.improvedErrorData) {
        data.push({
          type: "error",
          name: "Desvio padr�o",
          color: "#C0504E",
          linkedDataSeriesIndex: index,
          stemThickness: 1,
          whiskerThickness: 1,
          stemDashType: "dash",
          dataPoints: entry.map((point) => point.graphPoint)
        });

        index += 1;
      }
    }

    return data;
  }

  getOptions() {
    const getToolTipValue = this.props.ToolTipValueCallback || getCurrencyText;

    const options = {
			animationEnabled: true,
			// colorSet: "colorSet2",
      culture: 'pt-BR',
			toolTip: {
				shared: true,
        contentFormatter: (e) => {
  				let content = "";

          let totalValueMap = new Map();

  				for (let i = 0; i < e.entries.length; i++) {
            if(i === 0) {
              content += `${e.entries[i].dataPoint.label}<br/>`;
            }

            if(e.entries[i].dataPoint.totalTag) {
              const totalTag = e.entries[i].dataPoint.totalTag;

              if(!totalValueMap.has(totalTag)) {
                totalValueMap.set(totalTag, 0);
              }

              totalValueMap.set(totalTag, totalValueMap.get(totalTag) + e.entries[i].dataPoint.y);
            }

            if(e.entries[i].dataPoint.showInTooltip) {
              content += `<span style="color: ${e.entries[i].dataSeries.color};">${e.entries[i].dataSeries.name}:</span> ${getToolTipValue(e.entries[i].dataPoint.y)}<br/>`;
            }
  				}

          if(totalValueMap.size > 0) {
            content += '<hr/>'
            for(var [key, value] of totalValueMap) {
              content += `<b>${key} :</b> ${getToolTipValue(value)}<br/>`;
            }
          }

  				return content;
  			}
			},
      axisX: {
			},
      axisY: {
				labelFormatter: (entry) => getToolTipValue(entry.value)
			},
      axisY2: {
        title: "",
        tickLength: 0,
        lineThickness:0,
        margin:0,
        valueFormatString:" "
			},
      legend: {
				verticalAlign: this.props.legendVerticalAlign || "center",
				horizontalAlign: this.props.legendHorizontalAlign || "right",
				reversed: this.props.normalLegendOrder ? false : true,
				cursor: "pointer",
				itemclick: (e) => this.toggleDataSeries(e)
			},
			data: this.getData()
		};

    if(!this.props.normalXLabel) {
      options.axisX.labelFormatter = (entry) => entry.label ? entry.label.split(' ')[0] : entry.label;
    }

    // if(this.props.isHorizontal) {
    //   options.axisX.reversed = true;
    // }

    if(this.props.title) {
      options.title = {
				text: this.props.title,
        fontFamily: "Verdana",
        fontWeight: "normal",
			};
    }

    if(this.props.height) {
      options.height = this.props.height;
    }

    if(this.props.stripLine) {
      options.axisY.stripLines = [this.props.stripLine];
    }

    if(this.props.width && this.props.width !== null) {
      options.width = this.props.width;
    }

    return options;
  }

  toggleDataSeries(e){
		if (typeof(e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
			e.dataSeries.visible = false;
		}
		else{
			e.dataSeries.visible = true;
		}
		this.chart.render();
	}

	render() {
		return (
  		<div
        className={this.props.className}
      >
        {!this.props.hidden &&
          <CanvasJSReact.CanvasJSChart
            options={this.getOptions()}
    				onRef={ref => this.chart = ref}
    			/>
        }
  		</div>
		);
	}
}

export default StackedBarGraph;
