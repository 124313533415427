import React from 'react';
import { Link } from 'react-router-dom';
import './email_update.scss';
import {getStateToken} from '../utils/functions';
import WarningMessage from '../components/warning_message';
import {UPDATE_EMAIL_POST_API} from '../constants'
import * as routes from '../constants';

class EmailUpdate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      emailConfirmation: "",
      emailUpdated: false,
      invalidHash: false,
      authenticating: false,
      warningMessage: "",
      showWarningMessage: false,
    };
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  inputsAreValid() {
    return this.state.email.length > 0 &&
           this.state.emailConfirmation.length > 0;
  }

  handleKeyDown(event) {
    if(event.keyCode === 13 && this.inputsAreValid()) {
      this.updatePasssword();
    }
  }

  async handleRequestError(response) {
    if(response.status === 401) {
      const data = await response.json();

      if(data.code === 202) {
        this.setState({
          authenticating: false,
          invalidHash: true,
          showWarningMessage: true,
          warningMessage: "Link de alteração de email inválido.",
          email: "",
          emailConfirmation: "",
        });

        return null;
      }
    }
    else if(response.status === 403) {
      const data = await response.json();

      if(data.code === 203) {
        this.setState({
          authenticating: false,
          invalidHash: true,
          showWarningMessage: true,
          warningMessage: "Link de alteração de email inválido.",
          email: "",
          emailConfirmation: "",
        });

        return null;
      }
    }
    else if(response.status === 422) {
      const data = await response.json();

      if(data.errors[0].code === 102) {
        this.setState({
          authenticating: false,
          showWarningMessage: true,
          warningMessage: "Email inválido. Certifique-se de que esteja digitando seu email corretamente.",
          email: "",
          emailConfirmation: "",
        });

        return null;
      }
      else if(data.errors[0].code === 103) {
        this.setState({
          authenticating: false,
          showWarningMessage: true,
          warningMessage: "Email já cadastrado.",
          email: "",
          emailConfirmation: "",
        });

        return null;
      }
    }

    throw Error('Infelizmente estamos com problemas em nosso serviço. Por favor, tente novamente mais tarde.');
  }

  async updatePasssword() {
    const email = this.state.email.trim();
    const emailConfirmation = this.state.emailConfirmation.trim();

    if(email !== emailConfirmation) {
      this.setState({
        showWarningMessage: true,
        warningMessage: "Confirmação de email inválida. Por favor, digite novamente seu novo email.",
        email: "",
        emailConfirmation: "",
      });

      return null;
    }

    this.setState({
      authenticating: true,
      showWarningMessage: false,
    });

    let stateToken = await getStateToken(true);

    if(stateToken) {
      const headers = new Headers({
        'Content-Type': 'application/json',
        'state': stateToken
      });

      const body = JSON.stringify({
        email
      });

      try {
        let response = await fetch(`${UPDATE_EMAIL_POST_API}${this.props.match.params.authHash}`,
          {
            method: "POST",
            headers: headers,
            cache: 'no-store',
            body: body,
          });

        if(!response.ok) {
          return await this.handleRequestError(response);
        }

        let data = await response.json();

        this.setState({
          emailUpdated: true,
          showWarningMessage: true,
          warningMessage: "Email atualizado com sucesso.",
        });

        return data;
      }
      catch(error) {
        console.log(error);
      }
    }

    this.setState({
      authenticating: false,
      showWarningMessage: true,
      warningMessage: "Infelizmente estamos com problemas em nosso serviço. Por favor, tente novamente mais tarde.",
    });

    return null;
  }

  render() {
    return (
      <section className="email-update">

        <div className="email-update__container">

          <Link
            className="email-update__main-link"
            to={routes.MAIN_PATH}
            >

              <header className="email-update__header">

                <h1 className="email-update__header__text">
                  FYD
                  {' '}
                  <span className="email-update__header__text--red">CLUB</span>
                </h1>

              </header>

          </Link>

          <div className="email-update__title">

            <p className="email-update__title__text">
              Digite seu novo email nos campos abaixo:
            </p>

          </div>

          <div className="email-update__warning-wrapper">

            <WarningMessage
              message={this.state.warningMessage}
              onClose={() => {this.setState({showWarningMessage: false})}}
              visible={this.state.showWarningMessage}
              background={this.state.emailUpdated ? '#e1fbd8' : null}
              color={this.state.emailUpdated ? '#379000' : null}
            />

          </div>

          {(!this.state.emailUpdated && !this.state.invalidHash) &&
            <React.Fragment>

              <div className="email-update__input-wrapper">

                <input
                  className="login__input"
                  name="email"
                  type="email"
                  placeholder="Novo email"
                  onChange={(event) => this.handleInputChange(event)}
                  value={this.state.email}
                  onKeyDown={(event) => this.handleKeyDown(event)}
                />

                <input
                  className="login__input"
                  name="emailConfirmation"
                  type="email"
                  placeholder="Confirmar novo email"
                  onChange={(event) => this.handleInputChange(event)}
                  value={this.state.emailConfirmation}
                  onKeyDown={(event) => this.handleKeyDown(event)}
                />

              </div>

              <div className="email-update__request-wrapper">

                <button
                  className="email-update__request-button"
                  disabled={!this.inputsAreValid() || this.state.authenticating}
                  onClick={() => this.updatePasssword()}
                >

                  <i className="fas fa-key"></i>
                  {this.state.authenticating ? ' Redefinindo...' : ' Atualizar email'}

                </button>

              </div>

            </React.Fragment>
          }

          <footer className="email-update__footer">

            <p className="email-update__footer__text">

              <i className="far fa-copyright"></i>
              {' '}{(new Date()).getFullYear()} | FYD CENTRO DE TREINAMENTO

             </p>

          </footer>

        </div>

      </section>
    );
  }
}

export default EmailUpdate;
