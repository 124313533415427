import React from 'react';
import { PoseGroup } from 'react-pose';
import {FadeContainer} from '../utils/pose_containers';
import PreLoader from '../utils/preloader';
import { Link } from 'react-router-dom';
import './validate_account.scss';
import {getStateToken} from '../utils/functions';
import WarningMessage from '../components/warning_message';
import * as routes from '../constants';

class ValidateAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingData: true,
      account_validated: false,
      authenticating: false,
      messageIsGood: false,
      warningMessage: "",
      showWarningMessage: false,
    };
  }

  async componentDidMount() {
    let stateToken = await getStateToken(true);

    if(stateToken) {
      const headers = new Headers({
        'Content-Type': 'application/json',
        'state': stateToken
      });

      try {
        let response = await fetch(`${routes.VALIDATE_EMAIL_POST_API}${this.props.match.params.authHash}`,
          {
            method: "POST",
            headers: headers,
            cache: 'no-store',
          });

        if(!response.ok) {
          return await this.handleRequestError(response);
        }

        this.setState({
          loadingData: false,
          account_validated: true,
          messageIsGood: true,
          showWarningMessage: true,
          warningMessage: "Conta validada com sucesso.",
        });

        return;
      }
      catch(error) {
        console.log(error);
      }
    }

    this.setState({
      loadingData: false,
      messageIsGood: false,
      showWarningMessage: true,
      warningMessage: "Infelizmente estamos com problemas em nosso serviço. Por favor, tente novamente mais tarde.",
    });
  }

  async handleRequestError(response) {
    if(response.status === 401) {
      const data = await response.json();

      if(data.code === 202) {
        this.setState({
          loadingData: false,
          authenticating: false,
          messageIsGood: false,
          showWarningMessage: true,
          warningMessage: "Link de autenticação inválido.",
        });

        return null;
      }
    }
    else if(response.status === 403) {
      const data = await response.json();

      if(data.code === 203) {
        this.setState({
          loadingData: false,
          authenticating: false,
          messageIsGood: false,
          showWarningMessage: true,
          warningMessage: "Link de autenticação inválido.",
        });

        return null;
      }
    }
    else if(response.status === 422) {
      const data = await response.json();

      if(data.code === 208) {
        if(data.message.includes('Email not validated')) {
          this.setState({
            loadingData: false,
            authenticating: false,
            messageIsGood: false,
            showWarningMessage: true,
            warningMessage: "Email não validado. Em caso de qualquer dúvida, por favor, entre em contato com o responsável pela sua unidade.",
          });
        }
        else {
          this.setState({
            loadingData: false,
            authenticating: false,
            messageIsGood: false,
            showWarningMessage: true,
            warningMessage: "Foram detectados problemas com seu email. Por favor, entre em contato com o resposável pela unidade.",
          });
        }

        return null;
      }
    }

    throw Error('Infelizmente estamos com problemas em nosso serviço. Por favor, tente novamente mais tarde.');
  }

  getContent() {
    if(this.state.account_validated) {
      return (
        <React.Fragment>

          <div className="validate-account__title">

            <p className="validate-account__title__text">
              Clique no botão abaixo caso deseje redefinir sua senha de acesso à área do aluno.
            </p>

          </div>

          <div className="validate-account__request-wrapper">

            <Link
              className="validate-account__request-button"
              to={`${routes.PASSWORD_RECOVERY_PATH}${this.props.match.params.authHash}`}
              >

                Alterar senha

            </Link>

          </div>

        </React.Fragment>
      );
    }
  }

  render() {
    return this.state.loadingData ? (
      <PoseGroup>
        <FadeContainer className="validate-account__loading-container" key="preloader">
          <PreLoader local={true} />
        </FadeContainer>
      </PoseGroup>
    ):
    (
      <section className="validate-account">

        <div className="validate-account__container">

          <Link
            className="validate-account__main-link"
            to="/"
            >

              <header className="validate-account__header">

                <h1 className="validate-account__header__text">
                  FYD
                  {' '}
                  <span className="validate-account__header__text--red">CLUB</span>
                </h1>

              </header>

          </Link>

          <div className="validate-account__warning-wrapper">

            <WarningMessage
              message={this.state.warningMessage}
              onClose={() => {this.setState({showWarningMessage: false})}}
              visible={this.state.showWarningMessage}
              background={this.state.messageIsGood ? '#e1fbd8' : null}
              color={this.state.messageIsGood ? '#379000' : null}
            />

          </div>

          {this.getContent()}

          <footer className="validate-account__footer">

            <p className="validate-account__footer__text">

              <i className="far fa-copyright"></i>
              {' '}{(new Date()).getFullYear()} | FYD CENTRO DE TREINAMENTO

             </p>

          </footer>

        </div>

      </section>
    );
  }
}

export default ValidateAccount;
