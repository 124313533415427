import React from 'react';
import { Link } from 'react-router-dom';
import './forgot_password.scss';
import {getStateToken} from '../utils/functions';
import WarningMessage from '../components/warning_message';
import {PASSWORD_RECOVERY_POST_API} from '../constants'
import * as routes from '../constants';

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      emailSent: false,
      authenticating: false,
      warningMessage: "",
      showWarningMessage: false,
    };
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  inputsAreValid() {
    return this.state.email.match(/.+@.+\..+/) != null;
  }

  handleKeyDown(event) {
    if(event.keyCode === 13 && this.inputsAreValid()) {
      this.requestEmail();
    }
  }

  async handleRequestError(response) {
    if(response.status === 401) {
      const data = await response.json();

      if(data.code === 202) {
        this.setState({
          authenticating: false,
          showWarningMessage: true,
          warningMessage: "E-mail de aluno inválido.",
        });

        return null;
      }
    }

    throw Error('Infelizmente estamos com problemas em nosso serviço. Por favor, tente novamente mais tarde.');
  }

  async requestEmail() {
    this.setState({
      authenticating: true,
      showWarningMessage: false,
    });

    let stateToken = await getStateToken(true);

    if(stateToken) {
      const headers = new Headers({
        'Content-Type': 'application/json',
        'state': stateToken
      });

      const body = JSON.stringify({
        email: this.state.email,
      });

      try {
        let response = await fetch(PASSWORD_RECOVERY_POST_API,
          {
            method: "POST",
            headers: headers,
            cache: 'no-store',
            body: body,
          });

        if(!response.ok) {
          return await this.handleRequestError(response);
        }

        let data = await response.json();

        this.setState({
          emailSent: true,
          showWarningMessage: true,
          warningMessage: "Email enviado.",
        });

        return data;
      }
      catch(error) {
        console.log(error);
      }
    }

    this.setState({
      authenticating: false,
      showWarningMessage: true,
      warningMessage: "Infelizmente estamos com problemas em nosso serviço. Por favor, tente novamente mais tarde.",
    });

    return null;
  }

  render() {
    return (
      <section className="forgot-password">

        <div className="forgot-password__container">

          <Link
            className="forgot-password__main-link"
            to={routes.MAIN_PATH}
            >

              <header className="forgot-password__header">

                <h1 className="forgot-password__header__text">
                  FYD
                  {' '}
                  <span className="forgot-password__header__text--red">CLUB</span>
                </h1>

              </header>

          </Link>

          <div className="forgot-password__title">

            <p className="forgot-password__title__text">
              Preencha o email de sua conta para que possamos lhe enviar o
              link de recuperação de senha:
            </p>

          </div>

          <div className="forgot-password__warning-wrapper">

            <WarningMessage
              message={this.state.warningMessage}
              onClose={() => {this.setState({showWarningMessage: false})}}
              visible={this.state.showWarningMessage}
              background={this.state.emailSent ? '#e1fbd8' : null}
              color={this.state.emailSent ? '#379000' : null}
            />

          </div>

          <div className="forgot-password__input-wrapper">

            <input
              className="forgot-password__input"
              name="email"
              type="email"
              placeholder="E-mail"
              onChange={(event) => this.handleInputChange(event)}
              value={this.state.email}
              onKeyDown={(event) => this.handleKeyDown(event)}
              disabled={this.state.emailSent || this.state.authenticating}
            />

          </div>

          <div className="forgot-password__request-wrapper">

            {!this.state.emailSent &&
              <button
                className="forgot-password__request-button"
                disabled={!this.inputsAreValid() || this.state.authenticating}
                onClick={() => this.requestEmail()}
              >

                <i className="fas fa-envelope"></i>
                {this.state.authenticating ? ' Enviando...' : ' Enviar email'}

              </button>
            }

          </div>

          <footer className="forgot-password__footer">

            <p className="forgot-password__footer__text">

              <i className="far fa-copyright"></i>
              {' '}{(new Date()).getFullYear()} | FYD CENTRO DE TREINAMENTO

             </p>

          </footer>

        </div>

      </section>
    );
  }
}

export default ForgotPassword;
