import React from 'react';
import { PoseGroup } from 'react-pose';
import {FadeContainer} from '../utils/pose_containers';
import PreLoader from '../utils/preloader';
import * as routes from '../constants';
import {DEFAULT_UNKNOWN_ERROR_MESSAGE,
        SUPPORT_STATUS_OPEN_ID,
        SUPPORT_STATUS_CLOSED_ID,
        SUPPORT_STATUS_AWAITING_RESPONSE_ID,
        SUPPORT_MAIN_SECTIONS} from '../constants';
import DefaultMenuButton from '../components/default_menu_button';
import DefaultMenuLayout from '../components/default_menu_layout';
import ConfirmationWindow from '../components/confirmation_window';
import OverlayWindow from '../components/overlay_window';
import {getModels,
        postModel,
        patchModel,
        getAsLocalDateString,
        getAsLocalDatetime,
        getLocalTimeIsoString,
        checkElementInContainer,
        getAbbreviatedName,
        setUrlParameters} from '../utils/functions';
import DefaultInput from '../utils/default_input';
import './customer_support.scss';

import chatArrowLeft from '../images/chat_arrow_left.svg';
import chatArrowRight from '../images/chat_arrow_right.svg';


class CustomerSupport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      support_tickets: [],
      onSendMessageOrCloseTicket: false,
      onCloseTicket: false,
      selectedTicket: null,
      messageToSend: '',
      confirmInProgress: false,
      confirmFailed: false,
      confirmFailDescription: "",
      criticalErrorMessage: null,
      screenWidth: window.innerWidth,
    };

    this.unreadMessageMap = new Map();
    this.mayCheckForUnreadMessages = false;
    this.messageContainerElement = null;
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  async getSupportTickets() {
    const parameters = {};

    switch (this.props.mainSection) {
      case SUPPORT_MAIN_SECTIONS[0]:
        parameters.food_prescription_only = true;
        break;

      case SUPPORT_MAIN_SECTIONS[1]:
        parameters.personal_training_only = true;
        break;

      case SUPPORT_MAIN_SECTIONS[2]:
        parameters.general_topics_only = true;
        break;

      default:
        break;
    }

    return await getModels(setUrlParameters(routes.SUPPORT_TICKETS_GET_API, parameters));
  }

  getTicketWeight(entry, ignoreUnreadMessages=false) {
    let weight;

    if (!ignoreUnreadMessages && entry.messages.some((message) => message.staff !== null && message.customer_visualized_at === null)) {
      weight = 4;
    }
    else {
      switch (entry.status_id) {
        case SUPPORT_STATUS_OPEN_ID:
          weight = 2;
          break;
        case SUPPORT_STATUS_CLOSED_ID:
          weight = 1;
          break;
        case SUPPORT_STATUS_AWAITING_RESPONSE_ID:
          weight = 3;
          break;
        default:
          weight = 10;
          break;
      }
    }

    return weight;
  }

  sortTicketMessageCallback(a, b) {
    return a.sent_at.localeCompare(b.sent_at);
  }

  sortTicketCallback(a, b) {
    const aWeight = this.getTicketWeight(a);
    const bWeight = this.getTicketWeight(b);

    if (aWeight === bWeight) {
      const aStatusWeight = this.getTicketWeight(a, true);
      const bStatusWeight = this.getTicketWeight(b, true);

      if (aStatusWeight === bStatusWeight) {
        let aLastMessageAt = a.updated_at;
        let bLastMessageAt = b.updated_at;

        a.messages.forEach((message) => {
          if (aLastMessageAt < message.sent_at) {
            aLastMessageAt = message.sent_at;
          }
        });
        b.messages.forEach((message) => {
          if (bLastMessageAt < message.sent_at) {
            bLastMessageAt = message.sent_at;
          }
        });

        return bLastMessageAt.localeCompare(aLastMessageAt);
      }

      return bStatusWeight - aStatusWeight;
    }

    return bWeight - aWeight;
  }

  async reloadTicketList() {
    this.setState({
      loading: true
    });

    const update = {loading: false};

    let support_tickets = this.getSupportTickets();

    support_tickets = await support_tickets;

    if(support_tickets) {
      update.support_tickets = support_tickets;
      update.support_tickets.sort((a, b) => this.sortTicketCallback(a, b));
      update.support_tickets.forEach((ticket) => ticket.messages.sort((a, b) => this.sortTicketMessageCallback(a, b)));

      let queryParameters = (new URLSearchParams(this.props.location.search));

      let support_ticket_id = queryParameters.get('support_ticket_id') || null;

      if (support_ticket_id) {
        support_ticket_id = parseInt(support_ticket_id);
        const selectedTicket = support_tickets.find((ticket) => ticket.id === support_ticket_id) || null;

        if (selectedTicket !== null) {
          this.onSelectTicket(selectedTicket);
        }
      }
    }

    this.setState(update);
  }

  async componentDidMount() {
    const update = {};

    try {
      await this.reloadTicketList();
    }
    catch(errors) {
      update.criticalErrorMessage = DEFAULT_UNKNOWN_ERROR_MESSAGE;
      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            case 209:
              update.criticalErrorMessage = 'Sessão do usuário expirada.';

              break;
            default:
          }
        }
      }
    }

    this.setState(update);

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedTicket !== this.state.selectedTicket) {
      if (this.state.selectedTicket !== null && this.state.selectedTicket.messages.some((message) => message.staff !== null && message.customer_visualized_at === null)) {
        this.mayCheckForUnreadMessages = true;
        requestAnimationFrame(this.checkForUnreadMessages.bind(this));
      }
      else {
        this.mayCheckForUnreadMessages = false;
      }
    }
  }

  componentWillUnmount() {
    this.mayCheckForUnreadMessages = false;
    window.removeEventListener("resize", this.resizeListener);
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  getConfirmationWindowTitle() {
    if(this.state.confirmFailed) {
      if(this.state.onSendMessageOrCloseTicket) {
        return 'Falha ao enviar mensagem';
      }
      if(this.state.onCloseTicket) {
        return 'Falha ao finalizar atendimento';
      }
    }
    else if(this.state.confirmInProgress) {
      if(this.state.onSendMessageOrCloseTicket) {
        return 'Enviando mensagem';
      }
      if(this.state.onCloseTicket) {
        return 'Finalizando atendimento';
      }
    }
    else {
      if(this.state.onSendMessageOrCloseTicket) {
        return 'Enviar mensagem';
      }
      if(this.state.onCloseTicket) {
        return 'Finalizar atendimento';
      }
    }

    return 'Não implementado';
  }

  getConfirmationWindowDescription() {
    if(this.state.confirmFailed) {
      return this.state.confirmFailDescription;
    }
    else if(this.state.onSendMessageOrCloseTicket) {
      return 'Aguarde enquanto enviamos sua mensagem. Obrigado!';
    }
    else if(this.state.onCloseTicket) {
      if (this.state.confirmInProgress) {
        return `Aguarde enquanto finalizamos o atendimento. Obrigado!`;
      }

      return `Ao finalizar o atendimento, considera-se que a dúvida levantada já foi sanada ou deixou de ser relevante e não necessita mais de resposta.`;
    }

    return 'Não implementado';
  }

  getConfirmationWindowConfirmButtonText() {
    if(this.state.onSendMessageOrCloseTicket) {
      return 'Enviar';
    }
    if(this.state.onCloseTicket) {
      return 'Finalizar';
    }

    return 'Não implementado';
  }

  confirmationWindowIsVisible() {
    return this.state.onSendMessageOrCloseTicket || this.state.onCloseTicket;
  }

  resetConfirmationWindow() {
    this.setState({
      onSendMessageOrCloseTicket: false,
      onCloseTicket: false,
      confirmFailed: false,
      confirmInProgress: false,
    });
  }

  async proceedConfirmationWindow() {
    this.setState({
      confirmInProgress: true
    });

    if(this.state.onCloseTicket) {
      try{
        const response = await postModel(routes.CLOSE_SUPPORT_TICKET_POST_API.replace('{support_ticket_id}', this.state.selectedTicket.id), {}, true);

        if(response) {
          const updatedTicket = {...this.state.selectedTicket, ...response.support_ticket};
          updatedTicket.messages.sort((a, b) => this.sortTicketMessageCallback(a, b))

          const support_tickets = this.state.support_tickets.filter((ticket) => ticket.id !== this.state.selectedTicket.id);
          support_tickets.push(updatedTicket);
          support_tickets.sort((a, b) => this.sortTicketCallback(a, b));

          this.setState({
            support_tickets,
            selectedTicket: null,
            messageToSend: '',
            onCloseTicket: false,
            confirmFailed: false,
            confirmInProgress: false,
          });
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE + '.';

        if(errors instanceof Array) {
          for(let error of errors) {
            switch (error.code) {
              // case 104:
              //   for(let parameter of error.parameters) {
              //     switch (parameter.name) {
              //       case 'contracts':
              //         errorDescription = 'Serviço vinculado à um contrato de aluno. Estes contratos devem ser excluídos antes de excluir este serviço.';
              //
              //         break;
              //       default:
              //     }
              //   }
              //
              //   break;
              case 209:
                errorDescription = 'Sessão do usuário expirada.';

                break;
              default:
            }
          }
        }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }
  }

  onSelectTicket(ticket) {
    this.setState({
      selectedTicket: ticket,
      messageToSend: '',
    });
  }

  getEntryActions(entry) {
    return (
      <DefaultMenuButton
        className="customer-support__ticket-list__entry__action"
        onClick={() => this.onSelectTicket(entry)}
        text="Visualizar"
        color="purple"
      />
    );
  }

  getSupportTicketListItems() {
    if(this.state.support_tickets.length > 0) {
      return this.state.support_tickets.map((entry) => {
        let ticketStatus;
        let ticketStatusStyle;

        switch (entry.status_id) {
          case SUPPORT_STATUS_OPEN_ID:
            ticketStatus = 'Aberto';
            ticketStatusStyle = '--green';
            break;
          case SUPPORT_STATUS_CLOSED_ID:
            ticketStatus = 'Finalizado';
            ticketStatusStyle = '';
            break;
          case SUPPORT_STATUS_AWAITING_RESPONSE_ID:
            ticketStatus = 'Aguardando sua resposta';
            ticketStatusStyle = '--red';
            break;
          default:
            ticketStatus = 'INDEFINIDO';
            ticketStatusStyle = '';
            break;
        }

        let lastMessageAt = entry.updated_at;
        let messagesUnreadCount = 0;

        entry.messages.forEach((message) => {
          if (lastMessageAt < message.sent_at) {
            lastMessageAt = message.sent_at;
          }

          if (message.staff !== null && message.customer_visualized_at === null) {
            messagesUnreadCount += 1;
          }
        });

        return (
          <li
            className="customer-support__ticket-list__entry"
            key={`customer_support:ticket:${entry.id}`}
          >

            <aside className={`customer-support__ticket-list__entry__status${ticketStatusStyle}`}>

              <p className="customer-support__ticket-list__entry__status__text">{ticketStatus}</p>

              {messagesUnreadCount > 0 &&
                (<p className="customer-support__ticket-list__entry__status__note-text">({messagesUnreadCount} novas mensagens)</p>)
              }

            </aside>

            <article className="customer-support__ticket-list__entry__content-wrapper">

              <header className="customer-support__ticket-list__entry__header">

                <p className="customer-support__ticket-list__entry__header__date">

                  <span className="customer-support__ticket-list__entry__header__date__label">Última entrada em </span>

                  {getAsLocalDateString(lastMessageAt)}

                </p>

                <h3 className="customer-support__ticket-list__entry__header__title">

                  <span className="customer-support__ticket-list__entry__header__title__label">Assunto:</span>

                  <span className="customer-support__ticket-list__entry__header__title__text">{entry.subject}</span>

                </h3>

              </header>

              <p className="customer-support__ticket-list__entry__description">

                <span className="customer-support__ticket-list__entry__description__label">Descrição:</span>

                <span className="customer-support__ticket-list__entry__description__text">{entry.description}</span>

              </p>

              <div className="customer-support__ticket-list__entry__action-container">

                {this.getEntryActions(entry)}

              </div>

            </article>

          </li>
        );
      });
    }

    return (<p className="customer-support__ticket-list__no-entry-message">Nenhum registro encontrado</p>);
  }

  getContentOptions() {
    if(this.state.criticalErrorMessage !== null) {
      return (
        <div className="customer-support__warning-message">

          <i className="fas fa-exclamation customer-support__warning-message__icon"></i>
          <p className="customer-support__warning-message__text">{this.state.criticalErrorMessage}</p>

        </div>
      );
    }
    else {
      let sectionText = '';
      let registerTicketLink = null;

      switch (this.props.mainSection) {
      case SUPPORT_MAIN_SECTIONS[0]:
        sectionText = ' (Nutrição)';
        registerTicketLink = routes.NUTRITIONAL_SUPPORT_REGISTER_PAGE;
        break;

      case SUPPORT_MAIN_SECTIONS[1]:
        sectionText = ' (Personal)';
        registerTicketLink = routes.PERSONAL_TRAINING_SUPPORT_REGISTER_PATH;
        break;

      case SUPPORT_MAIN_SECTIONS[2]:
        sectionText = ' (Geral)';
        break;

      default:
        break;
    }

      return (
        <div className="customer-support__wrapper">

          {registerTicketLink !== null &&
            <div className="customer-support__action-container">

              <DefaultMenuButton
                className="customer-support__action"
                linkTo={registerTicketLink}
                text={
                  <React.Fragment>

                    <i className="fa-solid fa-headset customer-support__action__icon"></i> Requisitar suporte

                  </React.Fragment>
                }
                color="green"
              />

            </div>
          }

          <section className="customer-support__ticket-list">

            <header className="customer-support__ticket-list__header">

              <h3 className="customer-support__ticket-list__header__title">Suporte / Comunicação{sectionText}</h3>

            </header>

            <ul className="customer-support__ticket-list__list">

              {this.getSupportTicketListItems()}

            </ul>

          </section>

        </div>
      );
    }
  }

  async sendMessageOrCloseTicket() {
    if(this.state.messageToSend.length > 0) {
      this.setState({
        onSendMessageOrCloseTicket: true,
        confirmInProgress: true
      });

      const data = {message: this.state.messageToSend};

      try{
        const response = await postModel(routes.SUPPORT_MESSAGE_POST_API.replace('{support_ticket_id}', this.state.selectedTicket.id), data, true);

        if(response) {
          const updatedTicket = {...this.state.selectedTicket, ...response.support_ticket};
          updatedTicket.messages.sort((a, b) => this.sortTicketMessageCallback(a, b))

          const support_tickets = this.state.support_tickets.filter((ticket) => ticket.id !== this.state.selectedTicket.id);
          support_tickets.push(updatedTicket);
          support_tickets.sort((a, b) => this.sortTicketCallback(a, b));

          this.setState({
            support_tickets,
            selectedTicket: updatedTicket,
            messageToSend: '',
            onSendMessageOrCloseTicket: false,
            confirmFailed: false,
            confirmInProgress: false,
          });
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE + '.';

        if(errors instanceof Array) {
          for(let error of errors) {
            switch (error.code) {
              // case 104:
              //   for(let parameter of error.parameters) {
              //     switch (parameter.name) {
              //       case 'contracts':
              //         errorDescription = 'Serviço vinculado à um contrato de aluno. Estes contratos devem ser excluídos antes de excluir este serviço.';
              //
              //         break;
              //       default:
              //     }
              //   }
              //
              //   break;
              case 209:
                errorDescription = 'Sessão do usuário expirada.';

                break;
              default:
            }
          }
        }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }
    else {
      this.setState({
        onCloseTicket: true
      });
    }

    return;
  }

  async markMessageAsRead(message_id, ticket) {
    try{
      const response = await patchModel(routes.SUPPORT_MESSAGE_READ_PATCH_API.replace('{support_message_id}', message_id), {}, true);

      if (response) {
        for (const message of ticket.messages) {
          if (message.id === message_id) {
            message.customer_visualized_at = response.customer_visualized_at;
            break;
          }
        }
      }
    }
    catch(errors) {
      let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE + '.';

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            // case 104:
            //   for(let parameter of error.parameters) {
            //     switch (parameter.name) {
            //       case 'contracts':
            //         errorDescription = 'Serviço vinculado à um contrato de aluno. Estes contratos devem ser excluídos antes de excluir este serviço.';
            //
            //         break;
            //       default:
            //     }
            //   }
            //
            //   break;
            case 209:
              errorDescription = 'Sessão do usuário expirada.';

              break;
            default:
          }
        }
      }

      this.setState({
        confirmFailDescription: errorDescription,
        confirmFailed: true,
        confirmInProgress: false
      });

      return;
    }
  }

  checkForUnreadMessages() {
    if (!this.mayCheckForUnreadMessages) {
      return;
    }

    if (this.messageContainerElement !== null) {
      for (const [key, value] of this.unreadMessageMap) {
        if (checkElementInContainer(value, 1, this.messageContainerElement)) {
          this.markMessageAsRead(key, this.state.selectedTicket);
          this.unreadMessageMap.delete(key);
        }
      }
    }

    requestAnimationFrame(this.checkForUnreadMessages.bind(this));
  }

  getOverlayActionContent() {
    if (this.state.selectedTicket === null) {
      return null;
    }

    let buttonText = null;
    let buttonColor = 'green';
    let buttonStyle = '';

    if (this.state.messageToSend.length > 0) {
      if (this.state.onSendMessageOrCloseTicket) {
        buttonText = 'Enviando';
      }
      else {
        buttonText = 'Enviar';
        buttonStyle = '--send';
      }
    }
    /* else if (this.state.selectedTicket.status_id !== SUPPORT_STATUS_CLOSED_ID) {
      if (this.state.onSendMessageOrCloseTicket) {
        buttonText = 'Finalizando';
      }
      else {
        buttonText = 'Finalizar atendimento';
      }

      buttonColor = 'blue';
    } */

    return (
      <div className="customer-support__send-message">

        <DefaultInput
          className="customer-support__send-message__input"
          name="messageToSend"
          type="textarea"
          placeholder="Digite aqui sua mensagem"
          isHorizontal={true}
          rows="4"
          handleInputChange={(event) => this.handleInputChange(event)}
          value={this.state.messageToSend || ''}
          disabled={this.state.onSendMessageOrCloseTicket}
        />

        <div className="customer-support__send-message__controls">

          {buttonText !== null &&
            <DefaultMenuButton
              className={`customer-support__send-message__button${buttonStyle}`}
              onClick={() => this.sendMessageOrCloseTicket()}
              text={buttonText}
              color={buttonColor}
              disabled={this.state.onSendMessageOrCloseTicket}
            />
          }

        </div>

      </div>
    );
  }

  getOverlayTitle() {
    if (this.state.selectedTicket === null) {
      return null;
    }

    return (
      <React.Fragment>

        <span className="customer-support__overlay__header__title--label">Assunto:</span>
        {this.state.selectedTicket.subject}

      </React.Fragment>
    );
  }

  getOverlayContent() {
    if (this.state.selectedTicket === null) {
      return null;
    }

    const messages = this.state.selectedTicket.messages.map((message) => {
      const sentAt = getAsLocalDatetime(message.sent_at, false);
      const sentByStaff = message.staff !== null;
      const sentText = `Enviado em ${sentAt.toLocaleDateString()} ${getLocalTimeIsoString(sentAt, false)}`;
      let sentElement;

      if (sentByStaff) {
        sentElement = (
          <React.Fragment>
            {getAbbreviatedName(message.staff.name)}<br/>{sentText}
          </React.Fragment>
        );
      }
      else {
        sentElement = sentText;
      }

      return (
        <div
          key={`support_message:${message.id}`}
          className={`customer-support__message-wrapper${sentByStaff ? '--left' : '--right'}`}
        >

          <div className="customer-support__message">

            <div className="customer-support__message__arrow">
              <img
                className="customer-support__message__arrow__image"
                src={sentByStaff ? chatArrowLeft : chatArrowRight}
                alt=''
              />
            </div>

            <div
              className="customer-support__message__balloon"
              ref={(element) => {
                if (sentByStaff && message.customer_visualized_at === null) {
                  if (element === null) {
                    this.unreadMessageMap.delete(message.id);
                  }
                  else {
                    this.unreadMessageMap.set(message.id, element);
                  }
                }
              }}
            >

              <p className="customer-support__message__sent-text">{sentElement}</p>

              <p className="customer-support__message__text">{message.message}</p>

            </div>

          </div>

        </div>
      );
    });

    return (
      <div className="customer-support__messages-container">

        {messages}

      </div>
    );
  }

  render() {
    if(this.state.loading) {
      return (
        <PoseGroup>
          <FadeContainer key="preloader">
            <PreLoader />
          </FadeContainer>
        </PoseGroup>
      );
    }

    return (
      <DefaultMenuLayout
        showBackButton={true}
        history={this.props.history}
        onLogout={() => this.props.onLogout()}
        username={this.props.username}
      >

        <div className="customer-support">

          {this.getContentOptions()}

        </div>

        <OverlayWindow
          className="customer-support__overlay"
          visible={this.state.selectedTicket !== null}
          actions={(
            <div className="customer-support__overlay__action-container">

              {this.getOverlayActionContent()}

            </div>
          )}
        >

          <header className="customer-support__overlay__header">

            <h3 className="customer-support__overlay__header__title">
              {this.getOverlayTitle()}
            </h3>

            {!this.state.onSendMessageOrCloseTicket &&
              <DefaultMenuButton
                className="customer-support__overlay__header__close-button"
                onClick={() => {
                  const support_tickets = [...this.state.support_tickets];
                  support_tickets.sort((a, b) => this.sortTicketCallback(a, b));

                  this.setState({
                    support_tickets,
                    selectedTicket: null
                  });
                }}
                text="X"
                color="red"
              />
            }

          </header>

          <div
            className="customer-support__overlay__content-container"
            ref={(element) => {
              if (element === null) {
                  this.messageContainerElement = null;
                }
                else {
                  this.messageContainerElement = element;

                  this.messageContainerElement.scrollTo(0, this.messageContainerElement.scrollHeight);
                }
            }}
          >

            {this.getOverlayContent()}

          </div>

        </OverlayWindow>

        <ConfirmationWindow
          title={this.getConfirmationWindowTitle()}
          description={this.getConfirmationWindowDescription()}
          confirmText={this.getConfirmationWindowConfirmButtonText()}
          cancelText={this.state.confirmFailed ? 'Ok' : 'Cancelar'}
          visible={this.confirmationWindowIsVisible()}
          onCancel={() => this.resetConfirmationWindow()}
          onConfirm={() => this.proceedConfirmationWindow()}
          loading={this.state.confirmInProgress}
          useErrorIcon={this.state.confirmFailed}
          hideConfirmButton={this.state.confirmFailed}
        />

      </DefaultMenuLayout>
    );
  }
}

export default CustomerSupport;
