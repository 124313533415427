import React from 'react';
import {VerticalAccordionContainer} from '../utils/pose_containers';
import './accordion_section.scss';


class AccordionSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: this.props.startAsVisible || false,
    };
  }

  isVisible() {
    if (Object.hasOwn(this.props, 'visible')) {
      return this.props.visible;
    }

    return this.state.visible;
  }

  getStatusElement() {
    if (this.props.statusElement) {
      return this.props.statusElement;
    }
    if (this.props.disabled) {
      return (<i className="fa-solid fa-circle accordion-section__header__visible-icon"></i>);
    }
    if (this.isVisible()) {
      return (<i className="fas fa-chevron-down accordion-section__header__visible-icon"></i>);
    }

    return (<i className="fas fa-chevron-up accordion-section__header__visible-icon"></i>);
  }

  onToggleVisibility() {
    const visible = !this.isVisible();

    if (this.props.onToggleVisibility) {
      this.props.onToggleVisibility(visible);
    }

    this.setState({visible});
  }

  render() {
    return (
      <section
        className={`accordion-section${this.props.className ? ' ' + this.props.className : ''}`}
      >

        <header
          className={`accordion-section__header${this.props.disabled ? '--disabled' : ''}${this.isVisible() ? '--visible' : ''}`}
          onClick={() => this.onToggleVisibility()}
        >

          <h3 className="accordion-section__header__text">
            {this.props.title}
          </h3>

          {this.getStatusElement()}

        </header>

        <VerticalAccordionContainer
          className="vertical-accordion-container accordion-section__content"
          pose={this.isVisible() ? 'verticalOpen' : 'verticalClosed'}>

          <div className="vertical-accordion-container accordion-section__content-wrapper">

            {this.props.children}

          </div>

        </VerticalAccordionContainer>

      </section>
    );
  }
}

export default AccordionSection;
