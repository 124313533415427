import React from 'react';
import DefaultMenuButton from '../default_menu_button';
import ConfirmationWindow from '../confirmation_window';
import startCount from '../../sounds/start_count_down.wav';
import startSound from '../../sounds/start_sound.wav';
import minuteTurn from '../../sounds/minute_turn_soft.mp3';
import endSound from '../../sounds/end_time.wav';
import logo from '../../images/logo_fyd_club.png';
import OverlayWindow from '../overlay_window';
import {SERVICE_GYM} from '../../constants';
import DefaultInput from '../../utils/default_input';
import {VerticalAccordionContainer} from '../../utils/pose_containers';
import * as routes from '../../constants';
import {DEFAULT_UNKNOWN_ERROR_MESSAGE} from '../../constants';
import {postModel, getAsLocalDate, parseTextForIcons} from '../../utils/functions';
import ZoneGauge from '../zone_gauge';
import ScalePicker from '../scale_picker';
import './class_board.scss';


// CONSTANTS
const TRAINING_EXECUTION_METHOD_FIXED_REPS = 'Repetições fechadas';
const TRAINING_EXECUTION_METHOD_PIRAMIDAL = 'Piramidal';
const TRAINING_EXECUTION_METHOD_QRP = 'QRP';

const CLOCK_METHOD_STOPWATCH = 'Cronômetro';
const CLOCK_METHOD_TIMER = 'Contagem regressiva';
const CLOCK_METHOD_TABATA = 'Tabata';
const CLOCK_METHOD_SERIES = 'Séries';
const CLOCK_METHOD_CUSTOM = 'Personalizado';

const QRP_CYCLES_VISIBLE_NUMBER = 3;

const CLOCK_STATE_PAUSED = 'PAUSED';
const CLOCK_STATE_PLAYING = 'PLAYING';
const CLOCK_STATE_STOPPED = 'STOPPED';
const CLOCK_STATE_FINISHED = 'FINISHED';

const CLOCK_PHASE_NORMAL = 'NORMAL';
const CLOCK_PHASE_PREPARING = 'PREPARING';
const CLOCK_PHASE_RESTING = 'RESTING';
const CLOCK_PHASE_POST_TIME = 'POST_TIME';

const FINAL_SECOND_COUNT = 5;
const TABATA_FINAL_SECOND_COUNT = 1;
const PERSONALIZED_FINAL_SECOND_COUNT = 1;

const DEFAULT_PHYSICAL_RECUPERATION_STATUS = 10;


// AUXILIAR FUNCTIONS
function getFormatedTime(minutes) {
  const secondsRemaining = Math.floor((minutes*60) % 60);

  return `${Math.floor(minutes)}'` + (secondsRemaining > 0 ? ` ${secondsRemaining}''` : '');
}


class ClassBoard extends React.Component {
  constructor(props) {
    super(props);

    let overviewSelectedGroup = null;

    if(this.props.selectedTraining.group_associations.length > 0) {
      overviewSelectedGroup = this.props.selectedTraining.group_associations[0];
    }

    let currentTrainingReferenceData = null;
    let trainingReferenceData = null;

    if(this.props.student) {
      currentTrainingReferenceData = {};
      trainingReferenceData = {};

      for(let groupAssociation of this.props.selectedTraining.group_associations) {
        trainingReferenceData[groupAssociation.id] = {
          exercise_data: {},
          exercise_association_data: {}
        };
        currentTrainingReferenceData[groupAssociation.id] = {
          exercise_data: {},
          exercise_association_data: {}
        };

        for(let exerciseAssociation of groupAssociation.exercise_associations) {
          // if(exerciseAssociation.exercise.target_service === SERVICE_GYM) {
          //   trainingReferenceData[groupAssociation.id].exercise_association_data[exerciseAssociation.id] = {...this.props.student.training_reference_data[groupAssociation.id].exercise_association_data[exerciseAssociation.id]};
          //   currentTrainingReferenceData[groupAssociation.id].exercise_association_data[exerciseAssociation.id] = {...this.props.student.training_reference_data[groupAssociation.id].exercise_association_data[exerciseAssociation.id]};
          // }
          // else {
          //   trainingReferenceData[groupAssociation.id].exercise_data[exerciseAssociation.exercise_id] = {...this.props.student.training_reference_data[groupAssociation.id].exercise_data[exerciseAssociation.exercise_id]};
          //   currentTrainingReferenceData[groupAssociation.id].exercise_data[exerciseAssociation.exercise_id] = {...this.props.student.training_reference_data[groupAssociation.id].exercise_data[exerciseAssociation.exercise_id]};
          // }
          trainingReferenceData[groupAssociation.id].exercise_association_data[exerciseAssociation.id] = {...this.props.student.training_reference_data[groupAssociation.id].exercise_association_data[exerciseAssociation.id]};
          trainingReferenceData[groupAssociation.id].exercise_data[exerciseAssociation.exercise_id] = {...this.props.student.training_reference_data[groupAssociation.id].exercise_data[exerciseAssociation.exercise_id]};
          currentTrainingReferenceData[groupAssociation.id].exercise_association_data[exerciseAssociation.id] = {...this.props.student.training_reference_data[groupAssociation.id].exercise_association_data[exerciseAssociation.id]};
          currentTrainingReferenceData[groupAssociation.id].exercise_data[exerciseAssociation.exercise_id] = {...this.props.student.training_reference_data[groupAssociation.id].exercise_data[exerciseAssociation.exercise_id]};
        }
      }
    }

    let repetitionIndex = 1;
    let enableGymMode = false;

    if(this.props.personalActivePeriodData) {
      const cycleCount = this.props.personalActivePeriodData.repetition_count;
      const periodReference = this.props.personalActivePeriodData.period_reference || 7;

      const initialDate = getAsLocalDate(this.props.personalActivePeriodData.initial_date);
      const today = getAsLocalDate((new Date()).toISOString().slice(0, 10));

      const timeDiff = Math.abs(today.getTime() - initialDate.getTime());
      let daysCount = Math.ceil(timeDiff / (1000 * 3600 * 24));

      const currentCycle = initialDate <= today ? Math.floor(daysCount / periodReference) + 1 : 1;

      repetitionIndex = Math.min(cycleCount, currentCycle)

      enableGymMode = this.props.personalActivePeriodData.target_service === SERVICE_GYM;
    }

    this.state = {
      onFinishClass: false,
      onBackClicked: false,
      onUpdateStudentReference: null,
      confirmInProgress: false,
      confirmFailed: false,
      confirmFailDescription: "",
      trainingGroupSelected: null,
      overviewSelectedGroup,
      repetitionIndex: repetitionIndex,
      clockTimeElaped: 0,
      clockLastSoundPlayedAt: 0,
      clockTimeReference: null,
      clockRound: 1,
      clockState: CLOCK_STATE_STOPPED,
      clockPhase: CLOCK_PHASE_PREPARING,
      clockExpanded: false,
      currentClockScale: 1,
      videoContainerSize: null,
      exercisevideoContainerSize: null,
      selectedExercise: null,
      studentListVisible: false,
      studentPrSectionVisible: false,
      studentRmSectionVisible: enableGymMode,
      currentTrainingReferenceData,
      trainingReferenceData,
      initialOverlayConfirmed: false,
      studentPRS: null,
      enableGymMode,
      screenWidth: window.innerWidth,
    };

    this.mainRef = React.createRef();

    this.clockElement = null;
    this.clockTimerElement = null;
    this.clockLapElement = null;

    this.willRescaleClock = false;

    this.clockRef = (element) => {
      if (element == null) {
        this.clockElement = null;
        this.clockTimerElement = null;
        this.clockLapElement = null;
      }
      else {
        this.clockElement = element;

        const clockLapSelection = element.getElementsByClassName('class-board__clock__lap');
        if (clockLapSelection.length > 0) {
          this.clockLapElement = clockLapSelection[0];
        }
        else {
          this.clockLapElement = null;
        }

        this.clockTimerElement = element.getElementsByClassName('clock-timer')[0];
      }
    };

    this.trainingGroupTitleElement = null;
    this.trainingGroupTitleRef = (element) => {
      if (element == null) {
        this.trainingGroupTitleElement = null;
      }
      else {
        this.trainingGroupTitleElement = element;
      }
    };

    this.startCountAudio = new Audio(startCount);
    this.startAudio = new Audio(startSound);
    this.minuteTurnAudio = new Audio(minuteTurn);
    this.endAudio = new Audio(endSound);

    this.descriptionVideoContainer = null;
    this.exerciseVideoContainer = null;

    this.videoContainerRef = (element) => {
      this.descriptionVideoContainer = element;

      if(element) {
        this.setState({videoContainerSize: [element.clientWidth, element.clientHeight]});
      }
    };

    this.exerciseOverlayContainerRef = (element) => {
      this.exerciseVideoContainer = element;

      if(element) {
        this.setState({exercisevideoContainerSize: [element.clientWidth, element.clientHeight]});
      }
    };
  }

  updateSize() {
    const update = {screenWidth: window.innerWidth};

    if(this.descriptionVideoContainer !== null) {
      update.videoContainerSize = [this.descriptionVideoContainer.clientWidth, this.descriptionVideoContainer.clientHeight];
    }

    if(this.exerciseVideoContainer !== null) {
      update.exercisevideoContainerSize = [this.exerciseVideoContainer.clientWidth, this.exerciseVideoContainer.clientHeight];
    }

    this.setClockToRescale();

    this.setState(update);
  }

  isFullscreen(fromDocument=false) {
    if(fromDocument) {
      return document.fullscreenElement != null;
    }

    return this.state.clockExpanded;
  }

  onChangeFullscreen() {
    if(document.fullscreenElement === null) {
      this.setState({clockExpanded: false});
    }
  }

  async componentDidMount() {
    this.resizeListener = () => this.updateSize();
    this.changeFullscreenListener = () => this.onChangeFullscreen();

    window.addEventListener("resize", this.resizeListener);
    window.addEventListener("fullscreenchange", this.changeFullscreenListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
    window.removeEventListener("fullscreenchange", this.changeFullscreenListener);
  }

  getHeaderTitle() {
    if(this.state.trainingGroupSelected !== null) {
      return parseTextForIcons(this.state.trainingGroupSelected.name, 'header:group:name', 'class-board__parsed-text');
    }

    return 'Descrição';
  }

  getConfirmationWindowTitle() {
    if(this.state.confirmFailed) {
      if(this.state.onBackClicked) {
        return 'Falha ao sair do treino';
      }
      else if(this.state.onUpdateStudentReference !== null) {
        return 'Falha ao salvar dados de referência';
      }
      else if(this.state.onFinishClass) {
        return 'Falha ao finalizar treino';
      }
    }
    else if(this.state.confirmInProgress) {
      if(this.state.onBackClicked) {
        return 'Saindo do treino';
      }
      else if(this.state.onUpdateStudentReference !== null) {
        return 'Salvando dados';
      }
      else if(this.state.onFinishClass) {
        return 'Finalizando treino';
      }
    }
    else {
      if(this.state.onBackClicked) {
        return 'Sair do treino';
      }
      else if(this.state.onUpdateStudentReference !== null) {
        return 'Salvar dados de referência';
      }
      else if(this.state.onFinishClass) {
        return 'Finalizar treino';
      }
    }

    return 'Não implementado';
  }

  getConfirmationWindowDescription() {
    if(this.state.confirmFailed) {
      return this.state.confirmFailDescription;
    }
    else if(this.state.onBackClicked) {
      return 'Deseja realmente sair do treino?';
    }
    else if(this.state.onUpdateStudentReference !== null) {
      return `Deseja realmente atualizar os dados de referência para o exercício "${this.state.onUpdateStudentReference.exerciseName}"?`;
    }
    else if(this.state.onFinishClass) {
      return 'Deseja realmente finalizar o treino? Não deixe de preencher os dados de seu desempenho na tela seguinte.';
    }

    return 'Não implementado';
  }

  getConfirmationWindowConfirmButtonText() {
    if(this.state.onBackClicked) {
      return 'Sair do treino';
    }
    else if(this.state.onUpdateStudentReference !== null) {
      return `Salvar dados`;
    }
    else if(this.state.onFinishClass) {
      return `Finalizar treino`;
    }

    return 'Não implementado';
  }

  confirmationWindowIsVisible() {
    return this.state.onBackClicked === true ||
           this.state.onUpdateStudentReference !== null ||
           this.state.onFinishClass === true;
  }

  resetConfirmationWindow() {
    this.setState({
      onBackClicked: false,
      onUpdateStudentReference: null,
      onFinishClass: false,
      confirmFailed: false,
      confirmInProgress: false,
    });
  }

  async proceedConfirmationWindow() {
    if(this.state.onBackClicked) {
      this.props.onBackClicked();
    }
    else if(this.state.onFinishClass) {
      this.props.onFinishClicked(this.state.currentTrainingReferenceData, (parameters) => this.setState(parameters));
    }
    else if(this.state.onUpdateStudentReference !== null) {
      this.setState({
        confirmInProgress: true
      });

      let dataReference;

      if(this.state.onUpdateStudentReference.exercise_association_id) {
        dataReference = this.state.currentTrainingReferenceData[this.state.onUpdateStudentReference.groupId].exercise_association_data[this.state.onUpdateStudentReference.exercise_association_id];
      }
      else {
        dataReference = this.state.currentTrainingReferenceData[this.state.onUpdateStudentReference.groupId].exercise_data[this.state.onUpdateStudentReference.exerciseId];
      }

      const selectedReferenceEntry = {
        value: parseFloat(dataReference.value),
        weight: parseFloat(dataReference.weight),
        repetition: parseInt(dataReference.repetition),
        note: dataReference.note,
        difficulty: parseInt(dataReference.difficulty),
      };

      const referenceEntry = {
        exercise_id: this.state.onUpdateStudentReference.exerciseId,
        ...selectedReferenceEntry
      };

      if(this.state.onUpdateStudentReference.exercise_association_id) {
        referenceEntry.exercise_association_id = this.state.onUpdateStudentReference.exercise_association_id;
      }

      const data = {reference_data: [referenceEntry]};

      try{
        if(await postModel(routes.EXERCISE_REFERENCES_POST_API, data)) {
          const referenceCopy = {...this.state.trainingReferenceData};

          if(this.state.onUpdateStudentReference.exercise_association_id) {
            referenceCopy[this.state.onUpdateStudentReference.groupId].exercise_association_data[this.state.onUpdateStudentReference.exercise_association_id] = {...dataReference};
          }
          else {
            referenceCopy[this.state.onUpdateStudentReference.groupId].exercise_data[this.state.onUpdateStudentReference.exerciseId] = {...dataReference};
          }

          dataReference.updated_at = (new Date()).toISOString().slice(0, 10);

          this.setState({
            onUpdateStudentReference: null,
            confirmInProgress: false,
            trainingReferenceData: referenceCopy
          });
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        if(errors instanceof Array) {
          for(let error of errors) {
            switch (error.code) {
              // case 104:
              //   for(let parameter of error.parameters) {
              //     switch (parameter.name) {
              //       case 'contracts':
              //         errorDescription = 'Serviço vinculado à um contrato de aluno. Estes contratos devem ser excluídos antes de excluir este serviço.';
              //
              //         break;
              //       default:
              //     }
              //   }
              //
              //   break;
              case 209:
                errorDescription = 'Sessão do usuário expirada.';

                break;
              default:
            }
          }
        }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }

    return;
  }

  handleReferenceChange(event) {
    const target = event.target;
    let value = target.value;
    const name = target.name;

    const currentTrainingReferenceData = {};

    for (let [key, value] of Object.entries(this.state.currentTrainingReferenceData)) {
      currentTrainingReferenceData[key] = {};

      currentTrainingReferenceData[key].exercise_data = {...value.exercise_data};
      currentTrainingReferenceData[key].exercise_association_data = {...value.exercise_association_data};
    }

    // if(name.startsWith('exercise_reference:')) {
    //   const selection = name.split(':');
    //   const associationId = parseInt(selection[1]);
    //
    //   const association = this.state.trainingGroupSelected.exercise_associations.find((association) => association.id === associationId);
    //   const exercise_id = association.exercise_id;
    //
    //   if(!value) {
    //     value = null;
    //   }
    //   else {
    //     const prPercentage = this.getPrPercentage(DEFAULT_PHYSICAL_RECUPERATION_STATUS, association.difficult_value[0]);
    //
    //     value = 100 * parseFloat(value) / prPercentage;
    //   }
    //
    //   currentTrainingReferenceData[this.state.trainingGroupSelected.id][exercise_id] = {...currentTrainingReferenceData[exercise_id]};
    //
    //   currentTrainingReferenceData[this.state.trainingGroupSelected.id][exercise_id].value = value;
    // }
    if(name.startsWith('exercise_reference_input:')) {
      const selection = name.split(':');
      const associationId = parseInt(selection[1]);
      const referenceType = selection[2];
      const parameterName = selection[3];

      let referenceId;

      if(referenceType === 'exercise_data') {
        const association = this.state.trainingGroupSelected.exercise_associations.find((association) => association.id === associationId);

        referenceId = association.exercise_id;
      }
      else {
        referenceId = associationId;
      }

      currentTrainingReferenceData[this.state.trainingGroupSelected.id][referenceType][referenceId] = {...currentTrainingReferenceData[this.state.trainingGroupSelected.id][referenceType][referenceId]};

      currentTrainingReferenceData[this.state.trainingGroupSelected.id][referenceType][referenceId][parameterName] = parameterName !== 'difficulty' ? value || null : value;

      if(currentTrainingReferenceData[this.state.trainingGroupSelected.id][referenceType][referenceId].weight && currentTrainingReferenceData[this.state.trainingGroupSelected.id][referenceType][referenceId].repetition) {
        currentTrainingReferenceData[this.state.trainingGroupSelected.id][referenceType][referenceId].value = Math.max((currentTrainingReferenceData[this.state.trainingGroupSelected.id][referenceType][referenceId].weight * 100) / (102.78 - (2.78 * currentTrainingReferenceData[this.state.trainingGroupSelected.id][referenceType][referenceId].repetition)), 0);
      }
    }

    this.setState({
      currentTrainingReferenceData,
    });
  }

  getPrPercentage(physicalRecuperationStatus, currentPrPercentage) {
    return Math.round(currentPrPercentage - (10*(10 - physicalRecuperationStatus)/3));
  }

  onSelectExerciseLink(exercise) {
    this.setState({selectedExercise: exercise});
  }

  parseVideoUrl(url) {
    const info = {mayEmbed: false};

    let suffix = 'youtube.com/watch?v=';
    let suffixPosition = url.indexOf(suffix);

    if(suffixPosition >= 0) {
      let videoId = url.slice(suffixPosition + suffix.length);
      const endPosition = videoId.indexOf('&');

      if(endPosition >= 0) {
        videoId = videoId.slice(0, endPosition);
      }

      info.mayEmbed = true;
      info.embedUrl = `https://www.youtube.com/embed/${videoId}`;
    }
    else {
      suffix = 'youtu.be/';
      suffixPosition = url.indexOf(suffix);

      if(suffixPosition >= 0) {
        info.mayEmbed = true;
        info.embedUrl = `https://www.youtube.com/embed/${url.slice(suffixPosition + suffix.length)}`;
      }
    }

    return info;
  }

  getTrainingExercises(group, repetitionIndex) {
    if(group.exercise_associations.length <= 0) {
      return (
        <p className="class-board__training-exercise__not-configured">TREINO NÃO CONFIGURADO</p>
      );
    }

    let intensities = [];
    const difficulties = [];
    const intermediateDifficulties = [];
    const advancedDifficulties = [];
    const exerciseNames = [];
    const easierOptions = [];
    const references = [];

    let showDificulties = false;
    let showIntermediateDificulties = false;
    let showAdvancedDificulties = false;
    let showEasierOptions = false;
    let showReferences = false;
    let showCycleNumber = true;

    let hasAdditionalHeader = false;

    let cycleNumberText = `${group.cycle_number}x`;

    if(group.execution_method === TRAINING_EXECUTION_METHOD_PIRAMIDAL) {
      showCycleNumber = false;
      hasAdditionalHeader = true;

      for(let i = 0; i < group.cycle_number; ++i) {
        intensities.push([(
          <p
            className="class-board__training-exercise__header"
            key={`training_exercise:intensity_header:cycle:${i}`}
          >

            {i + 1}

          </p>
        )]);
      }
    }
    else if(group.execution_method === TRAINING_EXECUTION_METHOD_QRP) {
      cycleNumberText = (
        <React.Fragment>

          <span className="class-board__training-exercise__reps-text-span">
            QRP
          </span>

          {group.clock_time_limit ?
            <span className="class-board__training-exercise__reps-text-span">
              {getFormatedTime(group.clock_time_limit)}
            </span>:
            null
          }

        </React.Fragment>
      );
      hasAdditionalHeader = true;

      for(let i = 0; i < QRP_CYCLES_VISIBLE_NUMBER + 1; ++i) {
        intensities.push([(
          <p
            className="class-board__training-exercise__header"
            key={`training_exercise:intensity_header:cycle:${i}`}
          >

            {i === QRP_CYCLES_VISIBLE_NUMBER ? 'N' : (i + 1)}

          </p>
        )]);
      }
    }

    for(let exercise of group.exercise_associations) {
      if (typeof exercise.difficult_value === 'undefined' || typeof exercise.difficult_value[repetitionIndex-1] === 'undefined') {
        return (
          <p className="class-board__training-exercise__not-configured">TREINO NÃO CONFIGURADO CORRETAMENTE</p>
        );
      }

      if(!showDificulties && exercise.difficult_value[repetitionIndex-1]) {
        showDificulties = true;
      }

      if(!showIntermediateDificulties && exercise.difficult_intermediate_value[repetitionIndex-1]) {
        showIntermediateDificulties = true;
      }

      if(!showAdvancedDificulties && exercise.difficult_advanced_value[repetitionIndex-1]) {
        showAdvancedDificulties = true;
      }

      if(!showEasierOptions && exercise.easier_option && exercise.easier_option.length > 0) {
        showEasierOptions = true;
      }

      if(!this.isFullscreen() && !showReferences && exercise.exercise.reference_url && exercise.exercise.reference_url.length > 0) {
        showReferences = true;
      }

      if(group.execution_method === TRAINING_EXECUTION_METHOD_FIXED_REPS) {
        let intensityStyle = {};
        let intensityText;

        if (typeof exercise.intensity_value[repetitionIndex-1] === 'undefined') {
          return (
            <p className="class-board__training-exercise__not-configured">TREINO NÃO CONFIGURADO CORRETAMENTE</p>
          );
        }

        if(exercise.intensity_value[repetitionIndex-1].trim().length > 0) {
          intensityText = exercise.intensity_value[repetitionIndex-1];
          intensityStyle = {};
        }
        else {
          intensityStyle.color = 'transparent';
          intensityText = '-';
        }

        intensities.push(
          <p
            className="class-board__training-exercise__intensity-text"
            key={`training_exercise:exercise:${exercise.id}:intensity`}
            style={intensityStyle}
          >

            {intensityText}

          </p>
        );
      }
      else if(group.execution_method === TRAINING_EXECUTION_METHOD_PIRAMIDAL) {
        for(let i = 0; i < group.cycle_number; ++i) {
          let intensityStyle = {};
          let intensityText;

          if (typeof exercise.intensity_value[repetitionIndex-1] === 'undefined' || typeof exercise.intensity_value[repetitionIndex-1][i] === 'undefined') {
            return (
              <p className="class-board__training-exercise__not-configured">TREINO NÃO CONFIGURADO CORRETAMENTE</p>
            );
          }

          if(exercise.intensity_value[repetitionIndex-1][i].trim().length > 0) {
            intensityText = exercise.intensity_value[repetitionIndex-1][i];
          }
          else {
            intensityStyle.color = 'transparent';
            intensityText = '-';
          }

          intensities[i].push(
            <p
              className="class-board__training-exercise__intensity-cycle-text"
              key={`training_exercise:exercise:${exercise.id}:intensity:cycle:${i}`}
              style={intensityStyle}
            >

              {intensityText}

            </p>
          );
        }
      }
      else if(group.execution_method === TRAINING_EXECUTION_METHOD_QRP) {
        let showElipsis = true;

        if (typeof exercise.intensity_value[repetitionIndex-1] === 'undefined' || typeof exercise.intensity_value_step[repetitionIndex-1] === 'undefined') {
          return (
            <p className="class-board__training-exercise__not-configured">TREINO NÃO CONFIGURADO CORRETAMENTE</p>
          );
        }

        for(let i = 0; i < QRP_CYCLES_VISIBLE_NUMBER; ++i) {
          let intensity_value = exercise.intensity_value[repetitionIndex-1] + (exercise.intensity_value_step[repetitionIndex-1]*i);

          if(intensity_value <= 0) {
            intensity_value = '-';
            showElipsis = false;
          }
          else {
            intensity_value = `${intensity_value}${exercise.intensity_unit ? ' ' + exercise.intensity_unit : ''}`;
          }

          intensities[i].push(
            <p
              className="class-board__training-exercise__intensity-cycle-text"
              key={`training_exercise:exercise:${exercise.id}:intensity:cycle:${i}`}
            >

              {intensity_value}

            </p>
          );
        }

        intensities[QRP_CYCLES_VISIBLE_NUMBER].push(
          <p
            className="class-board__training-exercise__intensity-cycle-text"
            key={`training_exercise:exercise:${exercise.id}:intensity:cycle:${QRP_CYCLES_VISIBLE_NUMBER}`}
          >

            {showElipsis ?
              <i className="fas fa-ellipsis-h"></i>:
              '-'
            }

          </p>
        );
      }

      let difficultyStyle = {};
      let difficultyText;

      if(exercise.difficult_value[repetitionIndex-1]) {
        difficultyText = `${exercise.difficult_value[repetitionIndex-1]}${exercise.difficult_unit || ''} ${exercise.difficult_name ? '(' + exercise.difficult_name + ')' : ''}`;
      }
      else {
        difficultyStyle.color = 'transparent';
        difficultyText = '-';
      }

      difficulties.push(
        <p
          className="class-board__training-exercise__difficult-text"
          key={`training_exercise:exercise:${exercise.id}:difficulty`}
          style={difficultyStyle}
        >

          {parseTextForIcons(difficultyText, `training_exercise:exercise:${exercise.id}:dificult_text`, 'class-board__parsed-text')}

        </p>
      );

      let intermediateDifficultyStyle = {};
      let intermediateDifficultyText;

      if(exercise.difficult_intermediate_value[repetitionIndex-1]) {
        intermediateDifficultyText = `${exercise.difficult_intermediate_value[repetitionIndex-1]}${exercise.difficult_unit || ''} ${exercise.difficult_name ? '(' + exercise.difficult_name + ')' : ''}`;
      }
      else {
        intermediateDifficultyStyle.color = 'transparent';
        intermediateDifficultyText = '-';
      }

      intermediateDifficulties.push(
        <p
          className="class-board__training-exercise__difficult-text"
          key={`training_exercise:exercise:${exercise.id}:intermediate_difficulty`}
          style={intermediateDifficultyStyle}
        >

          {parseTextForIcons(intermediateDifficultyText, `training_exercise:exercise:${exercise.id}:dificult_text`, 'class-board__parsed-text')}

        </p>
      );

      let advancedDifficultyStyle = {};
      let advancedDifficultyText;

      if(exercise.difficult_advanced_value[repetitionIndex-1]) {
        advancedDifficultyText = `${exercise.difficult_advanced_value[repetitionIndex-1]}${exercise.difficult_unit || ''} ${exercise.difficult_name ? '(' + exercise.difficult_name + ')' : ''}`;
      }
      else {
        advancedDifficultyStyle.color = 'transparent';
        advancedDifficultyText = '-';
      }

      advancedDifficulties.push(
        <p
          className="class-board__training-exercise__difficult-text"
          key={`training_exercise:exercise:${exercise.id}:advanced_difficulty`}
          style={advancedDifficultyStyle}
        >

          {parseTextForIcons(advancedDifficultyText, `training_exercise:exercise:${exercise.id}:dificult_text`, 'class-board__parsed-text')}

        </p>
      );

      exerciseNames.push(
        <p
          className="class-board__training-exercise__name-text"
          key={`training_exercise:exercise:${exercise.id}:name`}
        >

          {exercise.exercise_name}

        </p>
      );

      let easierOptionStyle = {};
      let easierOptionText;

      if(exercise.easier_option) {
        easierOptionText = exercise.easier_option;
      }
      else {
        easierOptionStyle.color = 'transparent';
        easierOptionText = '-';
      }

      easierOptions.push(
        <p
          className="class-board__training-exercise__easier-option-text"
          key={`training_exercise:exercise:${exercise.id}:easier_option`}
          style={easierOptionStyle}
        >

          {easierOptionText}

        </p>
      );

      if(exercise.exercise.reference_url && exercise.exercise.reference_url.length > 0) {
        const urlInfo = this.parseVideoUrl(exercise.exercise.reference_url);

        if(urlInfo.mayEmbed) {
          references.push(
            <button
              className="class-board__training-exercise__reference-link"
              onClick={() => this.onSelectExerciseLink(exercise.exercise)}
              key={`training_exercise:exercise:${exercise.id}:reference`}
            >

              <i className="fas fa-link"></i>

            </button>
          );
        }
        else {
          references.push(
            <a
              className="class-board__training-exercise__reference-link"
              href={exercise.exercise.reference_url}
              target="_blank"
              rel="noopener noreferrer"
              key={`training_exercise:exercise:${exercise.id}:reference`}
              >

                <i className="fas fa-link"></i>

              </a>
            );
        }
      }
      else {
        references.push(
          <p
            className="class-board__training-exercise__reference-link"
            key={`training_exercise:exercise:${exercise.id}:reference`}
            style={{color: 'transparent'}}
          >
            -
          </p>
        );
      }
    }

    if(group.execution_method !== TRAINING_EXECUTION_METHOD_FIXED_REPS) {
      intensities = intensities.map((entry, index) => (
        <div
          className="class-board__training-exercise__intensities"
          key={`training_exercise:intensity_cycle_column:${index}`}
        >

          {entry}

        </div>
      ));
    }

    return (
      <React.Fragment>

        <div
          className={`class-board__training-exercise__first-column${group.execution_method === TRAINING_EXECUTION_METHOD_PIRAMIDAL ? '--expanded' : ''}`}
        >

          <p
            className="class-board__training-exercise__header"
          >

          </p>

          {hasAdditionalHeader &&
            <p
              className="class-board__training-exercise__header"
            >

              {group.execution_method === TRAINING_EXECUTION_METHOD_PIRAMIDAL ? 'Série' : ''}

            </p>
          }

        </div>

        {showCycleNumber &&
          <div className="class-board__training-exercise__reps">

            <p
              className="class-board__training-exercise__header"
            >

            </p>

            {hasAdditionalHeader &&
              <p
                className="class-board__training-exercise__header"
              >

                Série

              </p>
            }

            <p className="class-board__training-exercise__reps-text">

              {cycleNumberText}

            </p>

          </div>
        }

        <div className={`class-board__training-exercise__intensities${hasAdditionalHeader ? '--collapsed' : ''}`}>

          <p
            className={`class-board__training-exercise__header${hasAdditionalHeader ? '--centered' : ''}`}
          >

            Reps

          </p>

          <div className={`class-board__training-exercise__intensities-wrapper${group.execution_method === TRAINING_EXECUTION_METHOD_FIXED_REPS ? '--vertical' : '--horizontal'}`}>

            {intensities}

          </div>

        </div>

        <div className="class-board__training-exercise__names">

          {hasAdditionalHeader &&
            <p
              className="class-board__training-exercise__header"
            >

            </p>
          }

          <p
            className="class-board__training-exercise__header"
          >

            Exercícios

          </p>

          {exerciseNames}

        </div>

        {showDificulties &&
          <div className="class-board__training-exercise__diffculties">

            {hasAdditionalHeader &&
              <p
                className="class-board__training-exercise__header"
              >

              </p>
            }

            <p
              className="class-board__training-exercise__header"
            >

              Dificuldade

            </p>

            {difficulties}

          </div>
        }

        {showIntermediateDificulties &&
          <div className="class-board__training-exercise__diffculties">

            {hasAdditionalHeader &&
              <p
                className="class-board__training-exercise__header"
              >

              </p>
            }

            <p
              className="class-board__training-exercise__header"
            >

              *

            </p>

            {intermediateDifficulties}

          </div>
        }

        {showAdvancedDificulties &&
          <div className="class-board__training-exercise__diffculties">

            {hasAdditionalHeader &&
              <p
                className="class-board__training-exercise__header"
              >

              </p>
            }

            <p
              className="class-board__training-exercise__header"
            >

              AV

            </p>

            {advancedDifficulties}

          </div>
        }

        {showEasierOptions &&
          <div className="class-board__training-exercise__easier-options">

            {hasAdditionalHeader &&
              <p
                className="class-board__training-exercise__header"
              >

              </p>
            }

            <p
              className="class-board__training-exercise__header"
            >

              Variações

            </p>

            {easierOptions}

          </div>
        }

        {showReferences &&
          <div className="class-board__training-exercise__references">

            {hasAdditionalHeader &&
              <p
                className="class-board__training-exercise__header"
              >

              </p>
            }

            <p
              className="class-board__training-exercise__header"
            >

              Links

            </p>

            {references}

          </div>
        }

      </React.Fragment>
    );
  }

  getTrainingGroup(group, repetitionIndex) {
    if(group === null) {
      return (<p className="class-board__training-group-not-configured">TREINO NÃO CONFIGURADO CORRETAMENTE</p>);
    }

    return (
      <div
        className={`class-board__training-group${this.isFullscreen(true) ? '--fullscreen' : ''}`}
      >

        <h4
          ref={this.trainingGroupTitleRef}
          className="class-board__training-group__title"
          key={`training_group:${group.name}`}
        >

          {parseTextForIcons(group.name, `training_group_name:${group.name}:name`, 'class-board__parsed-text')}

        </h4>

        <div className="class-board__training-group__exercises-container">

          {this.getTrainingExercises(group, repetitionIndex)}

        </div>

        {group.note ?
          <div className="class-board__training-group__note-container">

            <p className="class-board__training-group__note-label">
              {this.state.screenWidth > 850 ? 'OBS:' : 'OBS'}
            </p>

            <p
              className="class-board__training-group__note-text"
            >
              {group.note}
            </p>

          </div>:
          null
        }

      </div>
    );
  }

  setTrainingGroup(trainingGroup) {
    this.setState({
      trainingGroupSelected: trainingGroup,
      clockTimeElaped: 0,
      clockRound: 1,
      clockState: CLOCK_STATE_STOPPED,
      clockPhase: CLOCK_PHASE_PREPARING,
      clockLastSoundPlayedAt: 0,
      currentClockScale: 1,
    });
  }

  getMainNavigation() {
    if(this.state.training === null || !this.props.selectedTraining.group_associations.length) {
      return null;
    }

    const previousGroupIndex = this.state.trainingGroupSelected !== null ? this.state.trainingGroupSelected.order - 2 : -1;
    const nextGroupIndex = this.state.trainingGroupSelected !== null ? this.state.trainingGroupSelected.order : 0;

    let nextTrainingGroup = null;
    let previousTrainingGroup = null;

    if(previousGroupIndex >= 0) {
      previousTrainingGroup = this.props.selectedTraining.group_associations[previousGroupIndex];
    }

    if(nextGroupIndex < this.props.selectedTraining.group_associations.length) {
      nextTrainingGroup = this.props.selectedTraining.group_associations[nextGroupIndex];
    }

    if(nextTrainingGroup) {
      return (
        <nav className="class-board__main__nav-container">

          {this.state.trainingGroupSelected !== null &&
            <DefaultMenuButton
              className="class-board__main__nav-button"
              onClick={() => this.setTrainingGroup(previousTrainingGroup)}
              text={(
                <React.Fragment>

                  <i className="fas fa-chevron-left"></i>

                </React.Fragment>
              )}
              disabled={this.state.clockState === CLOCK_STATE_PLAYING}
            />
          }

          <DefaultMenuButton
            className="class-board__main__nav-button"
            onClick={() => this.setTrainingGroup(nextTrainingGroup)}
            text={(
              <React.Fragment>

                {(this.state.trainingGroupSelected === null || this.state.screenWidth > 420) &&
                  <span className="class-board__main__nav-button__text">
                    {this.state.trainingGroupSelected === null ? 'Iniciar' : parseTextForIcons(nextTrainingGroup.name, 'navigation:group:name', 'class-board__parsed-text')}
                  </span>
                }
                <i className="fas fa-chevron-right class-board__main__nav-button__icon"></i>

              </React.Fragment>
            )}
            color="blue"
            disabled={this.state.clockState === CLOCK_STATE_PLAYING}
          />

        </nav>
      );
    }

    return (
      <nav className="class-board__main__nav-container">

        {this.state.trainingGroupSelected !== null &&
          <DefaultMenuButton
            className="class-board__main__nav-button"
            onClick={() => this.setTrainingGroup(previousTrainingGroup)}
            text={(
              <React.Fragment>

                <i className="fas fa-chevron-left"></i>

              </React.Fragment>
            )}
            disabled={this.state.clockState === CLOCK_STATE_PLAYING}
          />
        }

        {!this.props.disableFinishButton &&
          <DefaultMenuButton
            className="class-board__main__nav-button"
            onClick={() => this.setState({
              onFinishClass: true,
              clockState: CLOCK_STATE_PAUSED
            })}
            text="Finalizar aula"
            color="green"
            // disabled={this.state.clockState === CLOCK_STATE_PLAYING}
          />
        }

      </nav>
    );
  }

  setClockToRescale() {
    if(this.willRescaleClock === false) {
      this.willRescaleClock = true;

      setTimeout(() => {
        if (this.clockElement !== null && this.state.trainingGroupSelected.has_clock && this.trainingGroupTitleElement !== null) {
          let clockScale = 1;

          if (this.isFullscreen() || this.state.clockState === CLOCK_STATE_PLAYING) {
            const clockRect = this.clockElement.getBoundingClientRect();
            const fontSize = parseFloat(getComputedStyle(this.clockTimerElement).fontSize);

            let targetMaxWidth;
            let currentWidth;

            const trainingGroupTitleRect = this.trainingGroupTitleElement.getBoundingClientRect();
            clockScale = this.state.currentClockScale * ((trainingGroupTitleRect.bottom - trainingGroupTitleRect.height * 0.15) - clockRect.top) / clockRect.height;

            const marginOffset = 0.5 * clockScale * fontSize;

            if (this.isFullscreen()) {
              if (this.clockLapElement !== null) {
                const clockLapRect = this.clockLapElement.getBoundingClientRect();
                currentWidth = clockRect.width - (clockLapRect.left - clockRect.left);
              }
              else {
                const clockTimerRect = this.clockTimerElement.getBoundingClientRect();
                currentWidth = clockRect.width - (clockTimerRect.left - clockRect.left);
              }

              targetMaxWidth = this.state.screenWidth - marginOffset;
            }
            else {
              currentWidth = clockRect.width;
              targetMaxWidth = this.state.screenWidth - (2 * (this.state.screenWidth - clockRect.right)) - marginOffset;
            }

            clockScale = Math.min(clockScale, this.state.currentClockScale * targetMaxWidth / currentWidth);
            clockScale = Math.max(clockScale, 1);
          }

          this.setState({
            currentClockScale: clockScale
          });

          this.willRescaleClock = false;
        }
      }, 500);
    }
  }

  playClock() {
    let clockState = CLOCK_STATE_PLAYING;

    if(this.state.trainingGroupSelected.clock_method === CLOCK_METHOD_STOPWATCH || this.state.trainingGroupSelected.clock_method === CLOCK_METHOD_TIMER) {
      if(this.state.clockTimeElaped > (this.state.trainingGroupSelected.clock_time_limit*60)) {
        clockState = CLOCK_STATE_FINISHED;
      }
    }
    else if(this.state.trainingGroupSelected.clock_method === CLOCK_METHOD_SERIES || this.state.trainingGroupSelected.clock_method === CLOCK_METHOD_TABATA) {
      if(this.state.clockRound >= this.state.trainingGroupSelected.clock_round_count && this.state.clockTimeElaped > this.state.trainingGroupSelected.clock_round_period) {
        clockState = CLOCK_STATE_FINISHED;
      }
    }
    else if(this.state.trainingGroupSelected.clock_method === CLOCK_METHOD_CUSTOM) {
      const clock_custom_array = this.state.trainingGroupSelected.clock_custom_array;
      const roundIndex = Math.min(this.state.clockRound-1, clock_custom_array.length);

      if(this.state.clockRound >= this.state.trainingGroupSelected.clock_round_count && this.state.clockTimeElaped > clock_custom_array[roundIndex].period) {
        clockState = CLOCK_STATE_FINISHED;
      }
    }

    this.setClockToRescale();

    this.setState({
      clockTimeReference: Date.now() - (this.state.clockTimeElaped*1000),
      clockState: clockState
    });

    requestAnimationFrame(this.updateClock.bind(this));
  }

  pauseClock() {
    this.setClockToRescale();

    this.setState({
      clockState: CLOCK_STATE_PAUSED
    });
  }

  resetClock() {
    this.setClockToRescale();

    this.setState({
      clockState: CLOCK_STATE_STOPPED,
      clockPhase: CLOCK_PHASE_PREPARING,
      clockTimeElaped: 0,
      clockLastSoundPlayedAt: 0,
      clockRound: 1,
    });
  }

  updateClock() {
    let updateClock = this.state.clockState === CLOCK_STATE_PLAYING;

    if(this.state.trainingGroupSelected.clock_method === CLOCK_METHOD_STOPWATCH) {
      if(this.state.clockState === CLOCK_STATE_FINISHED) {
        updateClock = true;
      }
    }

    if(updateClock) {
      const update = {};

      let elapsedTime = ((Date.now() - this.state.clockTimeReference)/1000);

      if(this.state.clockPhase === CLOCK_PHASE_PREPARING) {
        if(elapsedTime > this.state.trainingGroupSelected.clock_preparation_period) {
          update.clockPhase = CLOCK_PHASE_NORMAL;
          elapsedTime = 0;
          update.clockTimeReference = Date.now();
          this.startAudio.play();
          update.clockLastSoundPlayedAt = 0;
        }
        else if(this.state.trainingGroupSelected.clock_preparation_period - elapsedTime < 3) {
          if(elapsedTime - this.state.clockLastSoundPlayedAt > 1) {
            update.clockLastSoundPlayedAt = Math.floor(elapsedTime);
            this.startCountAudio.pause();
            this.startCountAudio.currentTime = 0
            this.startCountAudio.play();
          }
        }
        // else if(elapsedTime - this.state.clockLastSoundPlayedAt > 1) {
        //   update.clockLastSoundPlayedAt = Math.floor(elapsedTime);
        //   this.startCountAudio.pause();
        //   this.startCountAudio.currentTime = 0
        //   this.startCountAudio.play();
        // }
      }
      else if(this.state.clockPhase === CLOCK_PHASE_NORMAL) {
        if(this.state.trainingGroupSelected.clock_method === CLOCK_METHOD_SERIES) {
          if(elapsedTime > this.state.trainingGroupSelected.clock_round_period) {
            if(this.state.clockRound >= this.state.trainingGroupSelected.clock_round_count) {
              update.clockState = CLOCK_STATE_FINISHED;
              update.clockPhase = CLOCK_PHASE_POST_TIME;
              this.endAudio.pause();
              this.endAudio.currentTime = 0
              this.endAudio.play();
            }
            else {
              if(this.state.trainingGroupSelected.pause_on_round_end) {
                update.clockState = CLOCK_STATE_PAUSED;
              }
              update.clockRound = this.state.clockRound + 1;
              elapsedTime = 0;
              update.clockTimeReference = Date.now();
              update.clockLastSoundPlayedAt = 0;
            }
          }
          else if(this.state.trainingGroupSelected.clock_round_period - elapsedTime < FINAL_SECOND_COUNT) {
            if(elapsedTime - this.state.clockLastSoundPlayedAt > 1) {
              update.clockLastSoundPlayedAt = Math.floor(elapsedTime);
              this.minuteTurnAudio.pause();
              this.minuteTurnAudio.currentTime = 0
              this.minuteTurnAudio.play();
            }
          }
        }
        else if(this.state.trainingGroupSelected.clock_method === CLOCK_METHOD_TABATA) {
          if(elapsedTime > this.state.trainingGroupSelected.clock_round_period) {
            if(this.state.clockRound >= this.state.trainingGroupSelected.clock_round_count) {
              update.clockState = CLOCK_STATE_FINISHED;
              update.clockPhase = CLOCK_PHASE_POST_TIME;
              this.endAudio.pause();
              this.endAudio.currentTime = 0
              this.endAudio.play();
            }
            else {
              update.clockPhase = CLOCK_PHASE_RESTING;
              elapsedTime = 0;
              update.clockTimeReference = Date.now();
              update.clockLastSoundPlayedAt = 0;
            }
          }
          else if(this.state.trainingGroupSelected.clock_round_period - elapsedTime < TABATA_FINAL_SECOND_COUNT) {
            if(elapsedTime - this.state.clockLastSoundPlayedAt > 1) {
              update.clockLastSoundPlayedAt = Math.floor(elapsedTime);
              this.minuteTurnAudio.pause();
              this.minuteTurnAudio.currentTime = 0
              this.minuteTurnAudio.play();
            }
          }
        }
        else if(this.state.trainingGroupSelected.clock_method === CLOCK_METHOD_CUSTOM) {
          const clock_custom_array = this.state.trainingGroupSelected.clock_custom_array;
          const roundIndex = Math.min(this.state.clockRound-1, clock_custom_array.length);
          const roundData = clock_custom_array[roundIndex];

          if(elapsedTime > roundData.period) {
            if(this.state.clockRound >= this.state.trainingGroupSelected.clock_round_count) {
              update.clockState = CLOCK_STATE_FINISHED;
              update.clockPhase = CLOCK_PHASE_POST_TIME;
              this.endAudio.pause();
              this.endAudio.currentTime = 0
              this.endAudio.play();
            }
            else {
              if(roundData.pause_on_end) {
                update.clockState = CLOCK_STATE_PAUSED;
              }
              update.clockRound = this.state.clockRound + 1;
              elapsedTime = 0;
              update.clockTimeReference = Date.now();
              update.clockLastSoundPlayedAt = 0;
            }
          }
          else if(roundData.period - elapsedTime < PERSONALIZED_FINAL_SECOND_COUNT) {
            if(elapsedTime - this.state.clockLastSoundPlayedAt > 1) {
              update.clockLastSoundPlayedAt = Math.floor(elapsedTime);
              this.minuteTurnAudio.pause();
              this.minuteTurnAudio.currentTime = 0
              this.minuteTurnAudio.play();
            }
          }
        }
        else {
          if(elapsedTime > (this.state.trainingGroupSelected.clock_time_limit*60)) {
            update.clockState = CLOCK_STATE_FINISHED;
            update.clockPhase = CLOCK_PHASE_POST_TIME;
            this.endAudio.pause();
            this.endAudio.currentTime = 0
            this.endAudio.play();
          }
          else if((this.state.trainingGroupSelected.clock_time_limit*60) - elapsedTime < FINAL_SECOND_COUNT) {
            if(elapsedTime - this.state.clockLastSoundPlayedAt > 1) {
              update.clockLastSoundPlayedAt = Math.floor(elapsedTime);
              this.minuteTurnAudio.pause();
              this.minuteTurnAudio.currentTime = 0
              this.minuteTurnAudio.play();
            }
          }
          else if(elapsedTime - this.state.clockLastSoundPlayedAt > 60) {
            update.clockLastSoundPlayedAt = Math.floor(elapsedTime);
            this.minuteTurnAudio.pause();
            this.minuteTurnAudio.currentTime = 0
            this.minuteTurnAudio.play();
          }
        }
      }
      else if(this.state.clockPhase === CLOCK_PHASE_RESTING) {
        if(this.state.trainingGroupSelected.clock_method === CLOCK_METHOD_TABATA) {
          if(elapsedTime > this.state.trainingGroupSelected.clock_rest_period) {
            update.clockPhase = CLOCK_PHASE_NORMAL;
            update.clockRound = this.state.clockRound + 1;
            elapsedTime = 0;
            update.clockTimeReference = Date.now();
            this.startAudio.play();
            update.clockLastSoundPlayedAt = 0;
          }
          else if(this.state.trainingGroupSelected.clock_rest_period - elapsedTime < 3) {
            if(elapsedTime - this.state.clockLastSoundPlayedAt > 1) {
              update.clockLastSoundPlayedAt = Math.floor(elapsedTime);
              this.startCountAudio.pause();
              this.startCountAudio.currentTime = 0
              this.startCountAudio.play();
            }
          }
        }
      }

      update.clockTimeElaped = elapsedTime;

      this.setState(update);

      requestAnimationFrame(this.updateClock.bind(this));
    }
  }

  getClockTime() {
    if(this.state.clockPhase === CLOCK_PHASE_PREPARING && this.state.trainingGroupSelected.clock_preparation_period) {
      const secondsLeft = Math.ceil(this.state.trainingGroupSelected.clock_preparation_period - this.state.clockTimeElaped);
      return Math.max(secondsLeft, 0);
    }
    else if(this.state.clockPhase === CLOCK_PHASE_RESTING) {
      const secondsLeft = Math.ceil(this.state.trainingGroupSelected.clock_rest_period - this.state.clockTimeElaped);
      return Math.max(secondsLeft, 0);
    }

    if(this.state.trainingGroupSelected.clock_method === CLOCK_METHOD_TIMER) {
      const timeLeft = this.state.trainingGroupSelected.clock_time_limit*60 - this.state.clockTimeElaped;

      let minutesLeft = Math.floor(timeLeft/60);
      let secondsLeft = Math.ceil(timeLeft % 60);

      if(secondsLeft > 59) {
        secondsLeft = 0;
        minutesLeft += 1;
      }

      secondsLeft = Math.max(secondsLeft, 0);
      minutesLeft = Math.max(minutesLeft, 0);

      return `${minutesLeft.toLocaleString('en-IN', {minimumIntegerDigits: 2})}:${secondsLeft.toLocaleString('en-IN', {minimumIntegerDigits: 2})}`;
    }

    const minutesLeft = Math.floor(this.state.clockTimeElaped/60);
    const secondsLeft = Math.floor(this.state.clockTimeElaped % 60);

    return `${minutesLeft.toLocaleString('en-IN', {minimumIntegerDigits: 2})}:${secondsLeft.toLocaleString('en-IN', {minimumIntegerDigits: 2})}`;
  }

  getClockCountLabel() {
    if(this.state.trainingGroupSelected.clock_method === CLOCK_METHOD_CUSTOM) {
      const clock_custom_array = this.state.trainingGroupSelected.clock_custom_array;
      const roundIndex = Math.min(this.state.clockRound-1, clock_custom_array.length);
      const roundData = clock_custom_array[roundIndex];

      if(roundData.count_label && roundData.count_label !== null) {
        return parseTextForIcons(roundData.count_label, 'classboard:clock-label', 'class-board__clock__lap__parsed-text');
      }
    }

    return this.state.clockRound;
  }

  getClockStyle() {
    if(this.state.clockState === CLOCK_STATE_PLAYING) {
      if(this.state.trainingGroupSelected.clock_method === CLOCK_METHOD_SERIES) {
        if(this.state.clockPhase === CLOCK_PHASE_PREPARING || (this.state.trainingGroupSelected.clock_round_period - this.state.clockTimeElaped < FINAL_SECOND_COUNT)) {
          return {};
        }
        else if(this.state.trainingGroupSelected.active_color) {
          return {color: this.state.trainingGroupSelected.active_color};
        }
      }
      else if(this.state.trainingGroupSelected.clock_method === CLOCK_METHOD_CUSTOM) {
        const clock_custom_array = this.state.trainingGroupSelected.clock_custom_array;
        const roundIndex = Math.min(this.state.clockRound-1, clock_custom_array.length);
        const roundData = clock_custom_array[roundIndex];

        if(this.state.clockPhase === CLOCK_PHASE_PREPARING || (roundData.period - this.state.clockTimeElaped < FINAL_SECOND_COUNT)) {
          return {};
        }
        else {
          return {color: roundData.color};
        }
      }
    }

    return {};
  }

  getClockSpecifier() {
    if(this.state.clockState === CLOCK_STATE_PLAYING) {
      if(this.state.trainingGroupSelected.clock_method === CLOCK_METHOD_SERIES) {
        if(this.state.clockPhase === CLOCK_PHASE_PREPARING || (this.state.trainingGroupSelected.clock_round_period - this.state.clockTimeElaped < FINAL_SECOND_COUNT)) {
          return '--yellow';
        }

        return '--green';
      }
      else if(this.state.trainingGroupSelected.clock_method === CLOCK_METHOD_CUSTOM) {
        const clock_custom_array = this.state.trainingGroupSelected.clock_custom_array;
        const roundIndex = Math.min(this.state.clockRound-1, clock_custom_array.length);
        const roundData = clock_custom_array[roundIndex];

        if(this.state.clockPhase === CLOCK_PHASE_PREPARING || (roundData.period - this.state.clockTimeElaped < FINAL_SECOND_COUNT)) {
          return '--yellow';
        }

        return '--green';
      }
      else if(this.state.trainingGroupSelected.clock_method === CLOCK_METHOD_TABATA) {
        if(this.state.clockPhase === CLOCK_PHASE_RESTING) {
          if((this.state.trainingGroupSelected.clock_rest_period - this.state.clockTimeElaped < FINAL_SECOND_COUNT)) {
            return '--yellow';
          }

          return '--blue';
        }
        else if(this.state.clockPhase === CLOCK_PHASE_PREPARING || (this.state.trainingGroupSelected.clock_round_period - this.state.clockTimeElaped < FINAL_SECOND_COUNT)) {
          return '--yellow';
        }

        return '--green';
      }
      else {
        if(this.state.clockPhase === CLOCK_PHASE_PREPARING || ((this.state.trainingGroupSelected.clock_time_limit*60) - this.state.clockTimeElaped < FINAL_SECOND_COUNT)) {
          return '--yellow';
        }

        return '--green';
      }
    }

    return '';
  }

  toggleFullscreen() {
    if(!this.state.clockExpanded) {
      this.mainRef.current.requestFullscreen();
    }
    else if(document.fullscreenElement) {
      document.exitFullscreen();
    }

    this.setClockToRescale();

    this.setState({clockExpanded: !this.state.clockExpanded});
  }

  getOverviewNavButtons() {
    if(this.props.selectedTraining === null) {
      return null;
    }

    return this.props.selectedTraining.group_associations.map((group) => (
      <DefaultMenuButton
        key={`overview:training_groups:${group.id}`}
        className="class-board__overview-navigation__nav-button"
        onClick={() => this.setState({overviewSelectedGroup: group})}
        text={parseTextForIcons(group.name, `overview:training_groups:${group.id}:name`, 'class-board__parsed-text')}
        disabled={this.state.overviewSelectedGroup === group}
      />
    ));
  }

  getMainContent() {
    if(this.state.trainingGroupSelected !== null) {
      const mainClockStyle = {};
      mainClockStyle.transform = `scale(${this.state.currentClockScale}, ${this.state.currentClockScale})`;

      return (
        <React.Fragment>

          <div className={`class-board__clock-container${!this.state.trainingGroupSelected.has_clock ? '--hidden' : ''}`}>

            <div
              className={`class-board__clock${this.isFullscreen() ? '--super-expanded' : this.state.clockState === CLOCK_STATE_PLAYING ? '--expanded' : ''}`}
              ref={this.clockRef}
              style={mainClockStyle}
            >

              {!this.isFullscreen() &&
                <div className="class-board__clock__controls">

                  {(this.state.clockState !== CLOCK_STATE_STOPPED && this.state.clockState !== CLOCK_STATE_PLAYING) &&
                    <DefaultMenuButton
                      className="class-board__clock__control-button"
                      onClick={() => this.resetClock()}
                      text={(<i className="fas fa-undo-alt"></i>)}
                    />
                  }

                  {(this.state.clockState === CLOCK_STATE_PLAYING || (this.state.trainingGroupSelected.clock_method === CLOCK_METHOD_STOPWATCH && this.state.clockState === CLOCK_STATE_FINISHED)) &&
                    <DefaultMenuButton
                      className="class-board__clock__control-button"
                      onClick={() => this.pauseClock()}
                      text={(<i className="fas fa-pause"></i>)}
                    />
                  }

                  {(this.state.clockState !== CLOCK_STATE_PLAYING && this.state.clockState !== CLOCK_STATE_FINISHED) &&
                    <DefaultMenuButton
                      className="class-board__clock__control-button"
                      onClick={() => this.playClock()}
                      text={(<i className="fas fa-play"></i>)}
                    />
                  }

                </div>
              }

              {(this.state.trainingGroupSelected.clock_method === CLOCK_METHOD_TABATA || this.state.trainingGroupSelected.clock_method === CLOCK_METHOD_SERIES || this.state.trainingGroupSelected.clock_method === CLOCK_METHOD_CUSTOM) &&
                <div className={`class-board__clock__lap-wrapper${this.getClockSpecifier()}`}>

                  <p
                    className="class-board__clock__lap"
                    style={this.getClockStyle()}
                  >

                    {this.getClockCountLabel()}

                  </p>

                </div>
              }

              <div
                className={`class-board__clock__time-wrapper${this.getClockSpecifier()}`}
                onClick={() => this.isFullscreen(true) ? null : this.toggleFullscreen()}
              >

                <p
                  className={`class-board__clock__time${(this.state.clockPhase === CLOCK_PHASE_PREPARING || this.state.clockPhase === CLOCK_PHASE_RESTING) ? '--centered' : ''} clock-timer`}
                  style={this.getClockStyle()}
                >

                  {this.getClockTime()}

                </p>

                <button
                  className="class-board__clock__compress-button"
                  onClick={() => this.toggleFullscreen()}
                >
                  <i className="fas fa-compress"></i>
                </button>

              </div>

            </div>

          </div>

          {this.getTrainingGroup(this.state.trainingGroupSelected, (this.state.trainingGroupSelected && this.state.trainingGroupSelected.group_is_phase_constant) ? 1 : this.state.repetitionIndex)}

        </React.Fragment>
      );
    }

    if(this.props.selectedTraining.description_url !== null) {
      const urlInfo = this.parseVideoUrl(this.props.selectedTraining.description_url);

      return (
        <div
          ref={this.videoContainerRef}
          className="class-board__video-container"
        >

          {this.state.videoContainerSize !== null &&
            <iframe
              // key={`description_video:size:${this.state.videoContainerSize[0]}x${this.state.videoContainerSize[1]}`}
              className="class-board__description-video"
              title="Descrição do treino"
              width={this.state.videoContainerSize[0]}
              height={this.state.videoContainerSize[1]}
              src={urlInfo.embedUrl}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
              allowFullScreen={true}
            ></iframe>
          }

        </div>
      );
    }
    else {
      return (
        <React.Fragment>

          <nav className="class-board__overview-navigation">

            <div className="class-board__overview-navigation__buttons-wrapper">

              {this.getOverviewNavButtons()}

            </div>


          </nav>

          {this.getTrainingGroup(this.state.overviewSelectedGroup, (this.state.overviewSelectedGroup && this.state.overviewSelectedGroup.group_is_phase_constant) ? 1 : this.state.repetitionIndex)}

        </React.Fragment>
      );
    }
    // else {
    //   return (
    //     <div className="home-training__no-description-video-warning-wrapper">
    //
    //       <p className="home-training__no-description-video-warning">Vídeo não configurado</p>
    //
    //     </div>
    //   );
    // }
  }

  getExerciseOverlayTitle() {
    if(this.state.selectedExercise !== null) {
      return this.state.selectedExercise.name;
    }

    return 'Não selecionado';
  }

  getExerciseOverlayVideo() {
    if(this.state.selectedExercise !== null && this.state.exercisevideoContainerSize !== null) {
      const urlInfo = this.parseVideoUrl(this.state.selectedExercise.reference_url);

      return (
        <iframe
          key={`exercise_video:${this.state.selectedExercise.id}`} //:size:${this.state.exercisevideoContainerSize[0]}x${this.state.exercisevideoContainerSize[1]}
          className="class-board__description-video"
          title={`Descrição do exercício ${this.state.selectedExercise.name}`}
          width={this.state.exercisevideoContainerSize[0]}
          height={this.state.exercisevideoContainerSize[1]}
          src={urlInfo.embedUrl}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
          allowFullScreen={true}
        ></iframe>
      );
    }

    return null;
  }

  getStudentOverlayData(selectPrEntries=true) {
    if(!this.props.student || this.state.trainingGroupSelected === null) {
      return null;
    }

    const referenceType = selectPrEntries ? 'exercise_data' : 'exercise_association_data';

    let filteredAssociations;

    if(selectPrEntries) {
      filteredAssociations = this.state.trainingGroupSelected.exercise_associations.filter((association) => association.difficult_name === 'PR');
    }
    else {
      // filteredAssociations = this.state.trainingGroupSelected.exercise_associations.filter((association) => association.exercise.target_service === SERVICE_GYM);
      filteredAssociations = this.state.trainingGroupSelected.exercise_associations;
    }

    return filteredAssociations.map((association) => {
      let referenceId;

      if(referenceType === 'exercise_data') {
        referenceId = association.exercise_id;
      }
      else {
        referenceId = association.id;
      }

      const prValue = this.state.currentTrainingReferenceData[this.state.trainingGroupSelected.id][referenceType][referenceId].value;

      const normalMaxPercentage = association.difficult_value[this.state.repetitionIndex - 1];
      let maxPercentage = normalMaxPercentage;
      let minPercentage = normalMaxPercentage - 30;

      const prPercentage = this.getPrPercentage(this.state.studentPRS || DEFAULT_PHYSICAL_RECUPERATION_STATUS, normalMaxPercentage);

      if(association.difficult_intermediate_value) {
        const intermediateMaxPercentage = association.difficult_intermediate_value[this.state.repetitionIndex - 1];

        if(intermediateMaxPercentage > 0) {
          maxPercentage = Math.max(intermediateMaxPercentage, maxPercentage);
          minPercentage = Math.min(intermediateMaxPercentage - 30, minPercentage);
        }
      }

      if(association.difficult_advanced_value) {
        const advancedMaxPercentage = association.difficult_advanced_value[this.state.repetitionIndex - 1];

        if(advancedMaxPercentage > 0) {
          maxPercentage = Math.max(advancedMaxPercentage, maxPercentage);
          minPercentage = Math.min(advancedMaxPercentage - 30, minPercentage);
        }
      }

      let prText = '';

      // if(prValue) {
      //   prText = prValue*prPercentage*0.01;
      //
      //   prText *= 1000;
      //   prText = Math.round(prText);
      //   prText = prText / 1000;
      // }

      const percentageGraduation = [];

      minPercentage = Math.max(minPercentage, 0);

      const step = Math.ceil((maxPercentage - minPercentage) / 6);
      minPercentage = maxPercentage - (6*step);

      const percentageReference = (Math.floor(((prPercentage - minPercentage) / step))*step) + minPercentage;

      if((!this.state.prReferenceShowInputs && selectPrEntries) || (this.state.enableGymMode && association.difficult_name === 'PR')) {
        const resistanceEntries = [];
        const hypertrophyEntries = [];
        const strengthEntries = [];

        for(let percentage=maxPercentage; percentage >= minPercentage; percentage -= step) {
          if(percentage < 0) {
            break;
          }

          if(prValue) {
            prText = prValue*percentage*0.01;

            prText *= 10;
            prText = Math.round(prText);
            prText = prText / 10;
          }

          const entry = (
            <div
              className={`class-board__student-panel__pr-section__reference__input-container${percentage === percentageReference ? '--selected' : ''}`}
              key={`class_board:exercise_reference:${association.id}:pr_output:${percentage}`}
            >

              <p className="class-board__student-panel__pr-section__reference__pr-input-header">
                <span className="class-board__student-panel__pr-section__reference__input-prefix--highlighted">{percentage.toFixed(0)}%</span>
              </p>

              <DefaultInput
                className="class-board__student-panel__pr-section__reference__input"
                name={`exercise_reference:${association.id}`}
                type="number"
                placeholder="-"
                min="0"
                step="0.1"
                handleInputChange={(event) => this.handleReferenceChange(event)}
                value={prText}
                autoComplete="off"
                suffix="kg"
                // prefix={(
                //   <p className="class-board__student-panel__pr-section__reference__input-prefix">
                //
                //     <span className="class-board__student-panel__pr-section__reference__input-prefix--highlighted">{prPercentage.toFixed(0)}%</span>
                //     {this.state.screenWidth > 510 ? ' do PR' : ''}
                //
                //   </p>
                // )}
                onFocus={(event) => event.target.select()}
                disabled={true}
              />

              {/* {prValue ?
                <p className="class-board__student-panel__pr-section__reference__generic-text">
                  (<b>100%</b> do PR: <b>{prValue.toFixed(0)}</b> kg)
                </p>:
                null
              } */}

            </div>
          );

          if (percentage < 60) {
            resistanceEntries.push(entry);
          }
          else if (percentage < 85) {
            hypertrophyEntries.push(entry);
          }
          else {
            strengthEntries.push(entry);
          }
        }

        if (strengthEntries.length > 0) {
          percentageGraduation.push(
            <div
              className="class-board__student-panel__pr-section__reference__input-group-container"
              key={`class_board:exercise_reference:${association.id}:pr_output_group:strength`}
            >

              <div className="class-board__student-panel__pr-section__reference__input-group-container__title-wrapper--strength">

                <p className="class-board__student-panel__pr-section__reference__input-group-container__title">Força</p>

              </div>

              <div className="class-board__student-panel__pr-section__reference__percentages-wrapper">

                {strengthEntries}

              </div>

            </div>
          );
        }
        if (hypertrophyEntries.length > 0) {
          percentageGraduation.push(
            <div
              className="class-board__student-panel__pr-section__reference__input-group-container"
              key={`class_board:exercise_reference:${association.id}:pr_output_group:hypertrophy`}
            >

              <div className="class-board__student-panel__pr-section__reference__input-group-container__title-wrapper--hypertrophy">

                <p className="class-board__student-panel__pr-section__reference__input-group-container__title">Hipertrofia</p>

              </div>

              <div className="class-board__student-panel__pr-section__reference__percentages-wrapper">

                {hypertrophyEntries}

              </div>

            </div>
          );
        }
        if (resistanceEntries.length > 0) {
          percentageGraduation.push(
            <div
              className="class-board__student-panel__pr-section__reference__input-group-container"
              key={`class_board:exercise_reference:${association.id}:pr_output_group:resistance`}
            >

              <div className="class-board__student-panel__pr-section__reference__input-group-container__title-wrapper--resistance">

                <p className="class-board__student-panel__pr-section__reference__input-group-container__title">Resistência</p>

              </div>

              <div className="class-board__student-panel__pr-section__reference__percentages-wrapper">

                {resistanceEntries}

              </div>

            </div>
          );
        }
      }

      let addSaveAction = false;
      let updateObject = null;

      if(prValue !== this.state.trainingReferenceData[this.state.trainingGroupSelected.id][referenceType][referenceId].value ||
            this.state.currentTrainingReferenceData[this.state.trainingGroupSelected.id][referenceType][referenceId].weight !== this.state.trainingReferenceData[this.state.trainingGroupSelected.id][referenceType][referenceId].weight ||
            this.state.currentTrainingReferenceData[this.state.trainingGroupSelected.id][referenceType][referenceId].repetition !== this.state.trainingReferenceData[this.state.trainingGroupSelected.id][referenceType][referenceId].repetition ||
            this.state.currentTrainingReferenceData[this.state.trainingGroupSelected.id][referenceType][referenceId].note !== this.state.trainingReferenceData[this.state.trainingGroupSelected.id][referenceType][referenceId].note ||
            this.state.currentTrainingReferenceData[this.state.trainingGroupSelected.id][referenceType][referenceId].difficulty !== this.state.trainingReferenceData[this.state.trainingGroupSelected.id][referenceType][referenceId].difficulty) {
        addSaveAction = true;
        updateObject = {
          groupId: this.state.trainingGroupSelected.id,
          exerciseId: association.exercise_id,
          exerciseName: association.exercise_name
        };

        if(!selectPrEntries) {
          updateObject.exercise_association_id = association.id;
        }
      }

      let updatedAtText = null;

      if(this.state.currentTrainingReferenceData[this.state.trainingGroupSelected.id][referenceType][referenceId].updated_at) {
        const today = getAsLocalDate((new Date()).toISOString().slice(0, 10));
        const updatedAt = getAsLocalDate(this.state.currentTrainingReferenceData[this.state.trainingGroupSelected.id][referenceType][referenceId].updated_at.slice(0, 10));

        const timeDiff = Math.abs(today.getTime() - updatedAt.getTime());
        let daysCount = Math.ceil(timeDiff / (1000 * 3600 * 24));

        updatedAtText = `Salvo em ${updatedAt.toLocaleDateString()} (${daysCount} dia(s))`;
      }

      return (
        <div
          key={`student:${this.props.student.id}:exercise_reference:${association.id}`}
          className="class-board__student-panel__pr-section__reference-wrapper"
        >

          <div className="class-board__student-panel__pr-section__reference__header">

            <h4 className="class-board__student-panel__pr-section__reference__label">{association.exercise_name}</h4>

            {updatedAtText !== null &&
              <p className="class-board__student-panel__pr-section__reference__date-text">

                {updatedAtText}

              </p>
            }

          </div>

          <div className="class-board__student-panel__pr-section__reference__content-wrapper">

            {(!selectPrEntries && this.state.enableGymMode) &&
              <div className="class-board__student-panel__pr-section__reference">

                <div className="class-board__student-panel__pr-section__reference-content">

                  <div className="class-board__student-panel__pr-section__reference__input-groups-wrapper">

                    {percentageGraduation}

                  </div>

                </div>

              </div>
            }

            <div
              className="class-board__student-panel__pr-section__reference"
            >

              {addSaveAction &&
                <DefaultMenuButton
                  className="class-board__student-panel__action-button"
                  onClick={() => this.setState({onUpdateStudentReference: updateObject})}
                  text="Salvar"
                  color="green"
                  disabled={this.state.onUpdateStudentReference !== null}
                />
              }

              <div className="class-board__student-panel__pr-section__reference-content">

                {(!this.state.prReferenceShowInputs && selectPrEntries) ?
                  <div className="class-board__student-panel__pr-section__reference__percentages-wrapper">

                    {percentageGraduation}

                  </div>:
                  <div className="class-board__student-panel__pr-section__reference__input-container--horizontal">

                    <div className="class-board__student-panel__pr-section__reference__pr-input-wrapper">

                      <DefaultInput
                        key={`class_board:exercise_reference:${association.id}:weight`}
                        className="class-board__student-panel__pr-section__reference__input--extended"
                        name={`exercise_reference_input:${association.id}:${selectPrEntries ? 'exercise_data' : 'exercise_association_data'}:weight`}
                        type="number"
                        placeholder="-"
                        min="0"
                        step="0.1"
                        handleInputChange={(event) => this.handleReferenceChange(event)}
                        value={this.state.currentTrainingReferenceData[this.state.trainingGroupSelected.id][referenceType][referenceId].weight || ''}
                        autoComplete="off"
                        suffix="kg"
                        prefix="Peso"
                        onFocus={(event) => event.target.select()}
                      />

                      <DefaultInput
                        key={`class_board:exercise_reference:${association.id}:repetition`}
                        className="class-board__student-panel__pr-section__reference__input--extended"
                        name={`exercise_reference_input:${association.id}:${selectPrEntries ? 'exercise_data' : 'exercise_association_data'}:repetition`}
                        type="number"
                        placeholder="-"
                        min="0"
                        step="1"
                        handleInputChange={(event) => this.handleReferenceChange(event)}
                        value={this.state.currentTrainingReferenceData[this.state.trainingGroupSelected.id][referenceType][referenceId].repetition || ''}
                        autoComplete="off"
                        prefix="Repetições"
                        onFocus={(event) => event.target.select()}
                      />

                    </div>

                    {(this.state.currentTrainingReferenceData[this.state.trainingGroupSelected.id][referenceType][referenceId].repetition &&
                      this.state.currentTrainingReferenceData[this.state.trainingGroupSelected.id][referenceType][referenceId].weight &&
                      prValue > 0) &&
                      <React.Fragment>

                        <i className="fas fa-long-arrow-alt-right class-board__student-panel__pr-section__reference__arrow-icon"></i>

                        <p className="class-board__student-panel__pr-section__reference__pr-text">

                          (<b>100%</b> do PR: <b>{prValue.toFixed(0)}</b> kg)

                        </p>

                      </React.Fragment>
                    }

                  </div>
                }

              </div>

            </div>

            {!selectPrEntries &&
              <React.Fragment>

                <div className="class-board__student-panel__pr-section__reference__difficulty-input">

                  <button
                    className="class-board__student-panel__pr-section__reference__difficulty-input__option--yellow"
                    onClick={() => this.handleReferenceChange({
                      target: {
                        name: `exercise_reference_input:${association.id}:${selectPrEntries ? 'exercise_data' : 'exercise_association_data'}:difficulty`,
                        value: 1
                      }
                    })}
                    disabled={this.state.currentTrainingReferenceData[this.state.trainingGroupSelected.id][referenceType][referenceId].difficulty === 1}
                  >

                    <i className="fas fa-angle-double-up class-board__student-panel__pr-section__reference__difficulty-input__option__icon"></i>

                    {this.state.screenWidth > 375 ? this.state.screenWidth > 545 ? 'Facil/Aumentar' : 'Facil' : null}

                  </button>

                  <button
                    className="class-board__student-panel__pr-section__reference__difficulty-input__option--green"
                    onClick={() => this.handleReferenceChange({
                      target: {
                        name: `exercise_reference_input:${association.id}:${selectPrEntries ? 'exercise_data' : 'exercise_association_data'}:difficulty`,
                        value: 0
                      }
                    })}
                    disabled={this.state.currentTrainingReferenceData[this.state.trainingGroupSelected.id][referenceType][referenceId].difficulty === 0}
                  >

                    <i className="fas fa-check class-board__student-panel__pr-section__reference__difficulty-input__option__icon"></i>

                    {this.state.screenWidth > 375 ? this.state.screenWidth > 545 ? 'Moderado/Manter' : 'Moderado' : null}

                  </button>

                  <button
                    className="class-board__student-panel__pr-section__reference__difficulty-input__option--red"
                    onClick={() => this.handleReferenceChange({
                      target: {
                        name: `exercise_reference_input:${association.id}:${selectPrEntries ? 'exercise_data' : 'exercise_association_data'}:difficulty`,
                        value: -1
                      }
                    })}
                    disabled={this.state.currentTrainingReferenceData[this.state.trainingGroupSelected.id][referenceType][referenceId].difficulty === -1}
                  >

                    <i className="fas fa-angle-double-down class-board__student-panel__pr-section__reference__difficulty-input__option__icon"></i>

                    {this.state.screenWidth > 375 ? this.state.screenWidth > 545 ? 'Pesado/Diminuir' : 'Pesado' : null}

                  </button>

                </div>

                <DefaultInput
                  className="class-board__student-panel__pr-section__reference__note-input"
                  key={`class_board:exercise_reference:${association.id}:note`}
                  name={`exercise_reference_input:${association.id}:${selectPrEntries ? 'exercise_data' : 'exercise_association_data'}:note`}
                  label="Anotação:"
                  type="textarea"
                  placeholder="Anotação do exercício"
                  isHorizontal={true}
                  rows="3"
                  handleInputChange={(event) => this.handleReferenceChange(event)}
                  value={this.state.currentTrainingReferenceData[this.state.trainingGroupSelected.id][referenceType][referenceId].note || ''}
                />

              </React.Fragment>

            }

          </div>

        </div>
      );
    });
  }

  getZoneIndex() {
    if(this.props.student) {
      return this.props.student.zone_index;
    }
    else if(this.props.zone_index) {
      return this.props.zone_index;
    }

    return 0;
  }

  getStudentMetrics() {
    let zone_index = this.getZoneIndex() * 100;
    zone_index = Math.round(zone_index) / 100;

    let zoneClass = '--alert';

    if(zone_index >= 0.8 && zone_index <= 1.3) {
      zoneClass = '--normal';
    }
    else if(zone_index > 1.3 && zone_index < 1.5) {
      zoneClass = '--danger';
    }
    else if(zone_index >= 1.5) {
      zoneClass = '--extreme-danger';
    }

    return (
      <React.Fragment>

        <article className="class-board__student-panel__metric">

          <h3 className="class-board__student-panel__metric__title">RAC</h3>

          <p className={`class-board__student-panel__metric__value rac${zoneClass}`}>{zone_index.toFixed(2)}</p>

        </article>

      </React.Fragment>
    );
  }

  getPrSection() {
    if(!this.state.enableGymMode && this.state.trainingGroupSelected !== null && this.state.trainingGroupSelected.exercise_associations.some((association) => association.difficult_name === 'PR')) {
      return (
        <React.Fragment>

          <hr className="class-board__horizontal-rule" />

          <section className="class-board__student-panel__list-section">

            <header
              className="class-board__student-panel__list-section__header"
              onClick={() => this.setState({studentPrSectionVisible: !this.state.studentPrSectionVisible})}
            >

              <h3 className="class-board__student-panel__list-section__header__text">
                <i className="fas fa-info class-board__student-panel__list-section__header__text-icon"></i>
                Personal Record (PR)
              </h3>

              {this.state.studentPrSectionVisible ?
                <i className="fas fa-chevron-down class-board__student-panel__list-section__header__visible-icon"></i>:
                <i className="fas fa-chevron-up class-board__student-panel__list-section__header__visible-icon"></i>
              }

            </header>

            <VerticalAccordionContainer
              className="vertical-accordion-container class-board__student-panel__list-section__content"
              pose={this.state.studentPrSectionVisible ? 'verticalOpen' : 'verticalClosed'}>

              <div className="vertical-accordion-container class-board__student-panel__list-section__content-wrapper">

                <p className="class-board__student-panel__list-section__generic-note">

                  <strong className="class-board__student-panel__list-section__generic-note__label">OBS:</strong> Lembre-se de incluir o peso da barra.

                </p>

                <div className="class-board__student-panel__pr-section__controls-container">

                  <DefaultMenuButton
                    className="class-board__student-panel__pr-section__toggle-button"
                    onClick={() => this.setState({
                      prReferenceShowInputs: !this.state.prReferenceShowInputs
                    })}
                    text={(<i className="fas fa-exchange-alt"></i>)}
                  />

                </div>

                {this.getStudentOverlayData(true)}

              </div>

            </VerticalAccordionContainer>

          </section>

        </React.Fragment>
      );
    }

    return null;
  }

  getRmSection() {
    // .some((association) => association.exercise.target_service === SERVICE_GYM)
    if(this.state.trainingGroupSelected !== null && this.state.trainingGroupSelected.exercise_associations.length > 0) {
      return (
        <React.Fragment>

          <hr className="class-board__horizontal-rule" />

          <section className="class-board__student-panel__list-section">

            <header
              className="class-board__student-panel__list-section__header"
              onClick={() => this.setState({studentRmSectionVisible: !this.state.studentRmSectionVisible})}
            >

              <h3 className="class-board__student-panel__list-section__header__text">
                <i className="fas fa-info class-board__student-panel__list-section__header__text-icon"></i>
                Repetição Máxima (RM)
              </h3>

              {this.state.studentRmSectionVisible ?
                <i className="fas fa-chevron-down class-board__student-panel__list-section__header__visible-icon"></i>:
                <i className="fas fa-chevron-up class-board__student-panel__list-section__header__visible-icon"></i>
              }

            </header>

            <VerticalAccordionContainer
              className="vertical-accordion-container class-board__student-panel__list-section__content"
              pose={this.state.studentRmSectionVisible ? 'verticalOpen' : 'verticalClosed'}>

              <div className="vertical-accordion-container class-board__student-panel__list-section__content-wrapper">

                <p className="class-board__student-panel__list-section__generic-note">

                  <strong className="class-board__student-panel__list-section__generic-note__label">OBS:</strong>
                  Preencher dados da menor ou última série e lembre-se de incluir o peso da barra quando possível. Utilize o valor 1 para pesos corporais.

                </p>

                {this.getStudentOverlayData(false)}

              </div>

            </VerticalAccordionContainer>

          </section>

        </React.Fragment>
      );
    }

    return null;
  }

  hasPrAssociated() {
    if(this.props.hidePRSInput) {
      return false;
    }

    return this.props.selectedTraining !== null && this.props.selectedTraining.group_associations.some((group) => group.exercise_associations.some((association) => association.difficult_name === 'PR'));
  }

  getZoneData() {
    const zone_index = this.getZoneIndex();

    if(zone_index < 0.8) {
      return {
        specifier: 'alert',
        result: 'Destreino',
        description: 'Cuidado com a retomada no treinamento. Não exagere na intensidade.'
      }
    }
    else if(zone_index <= 1.3) {
      return {
        specifier: 'normal',
        result: 'Ótimo',
        description: 'Excelente ritmo de treinamento! Lembre-se: aumentos na intensidade do treino devem ser feitas de forma gradual.'
      }
    }
    else if(zone_index < 1.5) {
      return {
        specifier: 'danger',
        result: 'Cuidado', //Risco de lesão
        description: 'Cuidado para não exigir além da capacidade de seu corpo. Se necessário reduza a intensidade de seus treinos.'
      }
    }
    else {
      return {
        specifier: 'extreme-danger',
        result: 'Alerta', //Risco extremo de lesão
        description: 'Aumentos repentinos no ritmo ou intensidades nos treinos podem ocasionar lesões anormais. Procure maneirar nos próximos treinos ou descanse.'
      }
    }
  }

  onSetPse(value) {
    this.setState({
      studentPRS: value
    });
  }

  render() {
    const hasPrAssociates = this.hasPrAssociated();
    const zoneData = this.getZoneData();

    return (
      <React.Fragment>

        <div className="class-board">

          <header className="class-board__header">

            <div className="class-board__header__logo-wrapper">

              <img className="class-board__header__logo" src={logo} alt="Logo da FYD" />

            </div>

            <div className="class-board__header__buttons-container">

              <DefaultMenuButton
                className="class-board__header__control-button"
                onClick={() => this.setState({onBackClicked: true})}
                text={(
                  <React.Fragment>

                    <i className="fas fa-angle-left default-header__back-button__icon"></i>
                    {this.state.screenWidth > 420 &&
                      <span>Sair</span>
                    }

                  </React.Fragment>
                )}
              />

            </div>

            <div className="class-board__header__title-wrapper">

              <h1 className="class-board__header__title">

                {this.getHeaderTitle()}

              </h1>

            </div>

          </header>

          <main
            ref={this.mainRef}
            className="class-board__main"
          >

            {this.getMainContent()}

          </main>

          {this.getMainNavigation()}

          {this.props.student &&
            <aside className={`class-board__student-panel${this.state.studentListVisible ? '--visible' : ''}`}>

              <div className="class-board__student-panel__controls">

                <DefaultMenuButton
                  className="class-board__student-panel__toggle-button"
                  onClick={() => this.setState({studentListVisible: !this.state.studentListVisible})}
                  text={(<i className="fas fa-chevron-down"></i>)}
                  color="red"
                />

              </div>

              <header className="class-board__student-panel__header">

                <div className="class-board__student-panel__header__title-wrapper">

                  <h2 className="class-board__student-panel__header__title">

                    {this.props.student.name.split(" ")[0]}

                  </h2>

                </div>

              </header>

              <div className="class-board__student-panel__data-container">

                {(this.props.student.note !== null && this.props.student.note.length > 0) &&
                  <React.Fragment>

                    <section className="class-board__student-panel__note">

                      <h3 className="class-board__student-panel__note__title">OBS:</h3>

                      <p className="class-board__student-panel__note__text">{this.props.student.note}</p>

                    </section>

                    <hr className="class-board__horizontal-rule" />
                  </React.Fragment>
                }

                <section className="class-board__student-panel__metrics-container">

                  {this.getStudentMetrics()}

                </section>

                {this.getPrSection()}

                {this.getRmSection()}

              </div>

            </aside>
          }

        </div>

        <OverlayWindow
          className="class-board__overlay"
          visible={this.state.selectedExercise !== null}
          actions={(
            <div className="class-board__overlay__action-container">

              <DefaultMenuButton
                className="class-board__overlay__action-button"
                onClick={() => this.setState({selectedExercise: null})}
                text="Fechar"
                color="black"
              />

            </div>
          )}
        >

          <header className="class-board__overlay__header">

            <h3 className="class-board__overlay__header__title">
              {this.getExerciseOverlayTitle()}
            </h3>

          </header>

          <hr className="class-board__horizontal-rule" />

          <div
            ref={this.exerciseOverlayContainerRef}
            className="class-board__overlay__reference-container"
          >

            {this.getExerciseOverlayVideo()}

          </div>

        </OverlayWindow>

        {!this.state.initialOverlayConfirmed &&
          <OverlayWindow
            className="class-board__default-overlay"
            visible={true}
            actions={(
              <div className="class-board__default-overlay__action-container">

                <DefaultMenuButton
                  className="class-board__default-overlay__action-button"
                  onClick={() => {
                    this.setState({initialOverlayConfirmed: true});
                  }}
                  text={hasPrAssociates ? 'Confirmar' : 'Ok'}
                  disabled={hasPrAssociates && this.state.studentPRS === null}
                  color="green"
                />

              </div>
            )}
          >

            <header className="class-board__default-overlay__header">

              <h3 className="class-board__default-overlay__header__title">
                {hasPrAssociates ? 'Selecione sua PSR' : 'Observações'}
              </h3>

            </header>

            <hr className="class-board__horizontal-rule" />

            <div className="class-board__default-overlay__reference-container">

              <section className="class-board__rac-section">

                <header className="class-board__rac-section__header">

                  <h3 className="class-board__rac-section__header__title">RAC</h3>

                </header>

                <div className="class-board__rac-section-wrapper">

                  <div className="class-board__rac-section__graph">

                    <ZoneGauge
                      zone_index={this.getZoneIndex()}
                    />

                  </div>

                  <div className="class-board__rac-section__result">

                    <h4 className="class-board__rac-section__result__label">Resultado</h4>

                    <div className="class-board__rac-section__result__value-wrapper">

                      <p className={`class-board__rac-section__result__value--${zoneData.specifier}`}>{zoneData.result}</p>

                      <p className="class-board__rac-section__result__description">{zoneData.description}</p>

                    </div>

                  </div>

                </div>

              </section>

              <div className="class-board__default-overlay-note">

                <p className="class-board__default-overlay-note__text">
                  A <strong>análise de carga interna</strong>, ou <strong>RAC</strong> (Relação de carga Aguda/Crônica), é um indicador gerado a
                  partir dos dados de seus treinos recentes e é utilizada para avaliar o risco de lesão durante a
                  prática de atividade física.
                </p>

              </div>

              {hasPrAssociates &&
                <div className="class-board__default-overlay-content-wrapper">

                  <h3 className="class-board__default-overlay-content-sub-title">

                    PSR - Qual o seu nível de

                    <span className="class-board__default-overlay-content-sub-title--highlighted">
                      cansaço FÍSICO?
                    </span>

                  </h3>

                  <ScalePicker
                    value={this.state.studentPRS}
                    onSetValue={(value) => this.onSetPse(value)}
                  />

                  <div className="class-board__overlay-note">

                    <p className="class-board__overlay-note__text">
                      A escala de <strong>percepção subjetiva de recuperação</strong>, ou <strong>PSR</strong>, deve ser preenchido somente com base na sua
                      fadiga ou disposição <strong>física</strong>, e não com base no seu ânimo ou cansaço psicológico.
                    </p>

                  </div>

                </div>
              }

            </div>

          </OverlayWindow>
        }

        <ConfirmationWindow
          title={this.getConfirmationWindowTitle()}
          description={this.getConfirmationWindowDescription()}
          confirmText={this.getConfirmationWindowConfirmButtonText()}
          cancelText={this.state.confirmFailed ? 'Ok' : 'Cancelar'}
          visible={this.confirmationWindowIsVisible()}
          onCancel={() => this.resetConfirmationWindow()}
          onConfirm={() => this.proceedConfirmationWindow()}
          loading={this.state.confirmInProgress}
          useErrorIcon={this.state.confirmFailed}
          hideConfirmButton={this.state.confirmFailed}
        />

      </React.Fragment>
    );
  }
}

export default ClassBoard;
