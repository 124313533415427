import React from 'react';
import { PoseGroup } from 'react-pose';
import './training_list.scss';
import {FadeContainer} from '../../utils/pose_containers';
import PreLoader from '../../utils/preloader';
import * as routes from '../../constants';
import {DEFAULT_UNKNOWN_ERROR_MESSAGE} from '../../constants';
import DefaultMenuButton from '../../components/default_menu_button';
import DefaultMenuLayout from '../../components/default_menu_layout';
import ConfirmationWindow from '../../components/confirmation_window';
import {getModels, deleteModel, getAsLocalDate} from '../../utils/functions';


const ANIMATION_PERIOD = 2000; //ms

class TrainingList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      recent_trainings: [],
      zone_index: 0,
      removeCustomTraining: null,
      removePersonalTraining: null,
      confirmInProgress: false,
      confirmFailed: false,
      confirmFailDescription: "",
      screenWidth: window.innerWidth,
    };

    this.current_index = 0;
    this.timeReference = 0;
    this.indexReference = 0;
    this.graphIsSet = false;
    this.animating = false;
    this.textOpacity = 1;
    this.stopDrawing = false;

    this.canvasSizeSet = false;

    this.canvasRef = React.createRef();
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  async getTrainings() {
    return await getModels(routes.RECENT_TRAINING_GET_API);
  }

  async reloadList() {
    this.setState({
      loading: true
    });

    this.graphIsSet = false;
    this.canvasSizeSet = false;

    const update = {loading: false};

    let data = this.getTrainings();

    data = await data;

    if(data) {
      update.recent_trainings = data.training_list;
      update.zone_index = data.zone_index;
    }

    this.setState(update);
  }

  async componentDidMount() {
    this.reloadList();

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  async componentDidUpdate(prevProps, prevState) {
    if(!this.state.loading && !this.graphIsSet) {
      this.setGraphAnimation();
    }
    else if(!this.animating && prevState.screenWidth !== this.state.screenWidth) {
      const canvas = this.canvasRef.current;
      canvas.width = 0;
      canvas.height = 0;

      this.canvasSizeSet = false;

      requestAnimationFrame(this.drawZoneGraph.bind(this));
    }
  }

  componentWillUnmount() {
    this.animating = false;
    this.stopDrawing = true;

    window.removeEventListener("resize", this.resizeListener);
  }

  setGraphAnimation() {
    this.graphIsSet = true;
    this.timeReference = Date.now();
    this.indexReference = this.current_index;
    this.animating = true;

    const canvas = this.canvasRef.current;
    canvas.width = 0;
    canvas.height = 0;

    requestAnimationFrame(this.drawZoneGraph.bind(this));
  }

  getCurrentZoneIndex() {
    const increment = this.state.zone_index - this.indexReference;
    const timeRatio = (Date.now() - this.timeReference) / ANIMATION_PERIOD;

    // let result = this.indexReference + (Date.now() - this.timeReference) * increment / ANIMATION_PERIOD;
    // let result = this.indexReference + increment*Math.sin(timeRatio * 0.5*Math.PI );
    let result = this.indexReference + increment*((timeRatio - 1)*(timeRatio - 1)*(timeRatio - 1) + 1);

    if(timeRatio > 0.25) {
      const opacityRatio = (timeRatio - 0.25)/0.75;
      this.textOpacity = (opacityRatio - 1)*(opacityRatio - 1)*(opacityRatio - 1) + 1;
    }
    else {
      this.textOpacity = 0;
    }

    if(timeRatio >= 1) {
      result = this.state.zone_index;
      this.animating = false;
      this.current_index = this.state.zone_index;
    }

    return result;
  }

  drawZoneGraph() {
    if(this.stopDrawing) {
      return;
    }

    const canvas = this.canvasRef.current;

    if(!canvas) {
      return;
    }

    const margin = (this.state.screenWidth > 950 && window.innerHeight > 850) ? 15 : 5;

    const maxZoneValue = 2.4;
    let zoneIndex = this.getCurrentZoneIndex();
    const zoneIndexText = zoneIndex.toFixed(2);
    zoneIndex = Math.min(zoneIndex, maxZoneValue);

    if(!this.canvasSizeSet) {
      canvas.width = canvas.parentNode.offsetWidth;
      canvas.height = canvas.parentNode.offsetHeight;

      this.canvasSizeSet = true;
    }

    const context = canvas.getContext('2d');

    context.clearRect(0, 0, canvas.width, canvas.height);

    const textMargin = (this.state.screenWidth > 950 && window.innerHeight > 850) ? 12 : 8;
    const textSize = Math.min(Math.max(this.state.screenWidth*0.023, 14), 30);
    const textSidePadding = (this.state.screenWidth > 950 && window.innerHeight > 850) ? 10 : 5;

    context.globalAlpha = 1;

    context.font = `${textSize}px Orbitron, sans-serif`;
    context.textBaseline  = 'bottom';
    context.textAlign   = 'left';
    const zoneTextMetric = context.measureText(zoneIndexText);
    const maxTextWidth = textSize*3;

    const radius = Math.min((canvas.width - 2*margin - 2*textMargin - 2*maxTextWidth - 2*textSidePadding)*0.5, canvas.height - 2*margin - textMargin - textSize);
    const segmentThickness = radius*0.25;

    const centerX = canvas.width*0.5;
    const centerY = canvas.height-margin;

    const angleGap = 0.025*Math.PI;

    let initialAngle = Math.PI;
    let finalAngle = initialAngle + (0.8 / maxZoneValue)*Math.PI;
    context.lineJoin = "round";
    context.lineWidth = radius*0.03;

    context.strokeStyle = '#fdcd01';
    context.fillStyle = '#fdcd01';
    context.beginPath();
    context.arc(centerX, centerY, radius - segmentThickness, initialAngle, finalAngle, false);
    context.arc(centerX, centerY, radius, finalAngle, initialAngle, true);
    context.closePath()
    context.fill();
    context.stroke();

    initialAngle = finalAngle;
    finalAngle = initialAngle + ((1.3 - 0.8) / maxZoneValue)*Math.PI;
    initialAngle += angleGap;

    context.strokeStyle = '#029642';
    context.fillStyle = '#029642';
    context.beginPath();
    context.arc(centerX, centerY, radius - segmentThickness, initialAngle, finalAngle, false);
    context.arc(centerX, centerY, radius, finalAngle, initialAngle, true);
    context.closePath()
    context.fill();
    context.stroke();

    initialAngle = finalAngle;
    finalAngle = initialAngle + ((1.5 - 1.3) / maxZoneValue)*Math.PI;
    initialAngle += angleGap;

    context.strokeStyle = '#e82310';
    context.fillStyle = '#e82310';
    context.beginPath();
    context.arc(centerX, centerY, radius - segmentThickness, initialAngle, finalAngle, false);
    context.arc(centerX, centerY, radius, finalAngle, initialAngle, true);
    context.closePath()
    context.fill();
    context.stroke();

    initialAngle = finalAngle;
    finalAngle = 2*Math.PI;
    initialAngle += angleGap;

    context.strokeStyle = '#bc0100';
    context.fillStyle = '#bc0100';
    context.beginPath();
    context.arc(centerX, centerY, radius - segmentThickness, initialAngle, finalAngle, false);
    context.arc(centerX, centerY, radius, finalAngle, initialAngle, true);
    context.closePath()
    context.fill();
    context.stroke();

    const baseWidth = radius*0.08;
    const tipWidth = radius*0.02;
    const indicatorLength = radius;

    let zoneAngle = (zoneIndex/maxZoneValue) * Math.PI;

    context.strokeStyle = '#3a3839';
    context.fillStyle = '#3a3839';
    context.lineWidth = 2;

    context.beginPath();
    context.moveTo(centerX - Math.sin(zoneAngle)*baseWidth*0.5, centerY + Math.cos(zoneAngle)*baseWidth*0.5);
    context.lineTo(centerX - Math.cos(zoneAngle)*indicatorLength - Math.sin(zoneAngle)*tipWidth*0.5, centerY - Math.sin(zoneAngle)*indicatorLength + Math.cos(zoneAngle)*tipWidth*0.5);
    context.arc(centerX - Math.cos(zoneAngle)*indicatorLength, centerY - Math.sin(zoneAngle)*indicatorLength, tipWidth*0.5, -1.5*Math.PI+zoneAngle, -2.5*Math.PI + zoneAngle);
    context.lineTo(centerX + Math.sin(zoneAngle)*baseWidth*0.5, centerY - Math.cos(zoneAngle)*baseWidth*0.5);
    context.arc(centerX, centerY, baseWidth*0.5, -0.5*Math.PI+zoneAngle, -1.5*Math.PI + zoneAngle);
    context.fill();
    context.stroke();

    const initialTextPoistion = [centerX - Math.cos(zoneAngle)*(indicatorLength + textMargin), centerY - Math.sin(zoneAngle)*(indicatorLength + textMargin)];
    let textOffset = textSidePadding*0.5;

    if(zoneIndex < maxZoneValue*0.5) {
      initialTextPoistion[0] -= (zoneTextMetric.width + textSidePadding);
    }

    context.globalAlpha = this.textOpacity;

    context.lineJoin = "round";
    context.lineWidth = (this.state.screenWidth > 950 && window.innerHeight > 850) ? 7 : 4;
    context.strokeStyle = '#3a3839';
    context.fillStyle = '#3a3839';

    context.fillRect(initialTextPoistion[0], initialTextPoistion[1] - (textSize + context.lineWidth*0.5), zoneTextMetric.width + textSidePadding, textSize);
    context.strokeRect(initialTextPoistion[0], initialTextPoistion[1] - (textSize + context.lineWidth*0.5), zoneTextMetric.width + textSidePadding, textSize);

    context.fillStyle = 'white';
    context.fillText(zoneIndexText, initialTextPoistion[0] + textOffset, initialTextPoistion[1]);

    if(this.animating) {
      requestAnimationFrame(this.drawZoneGraph.bind(this));
    }
  }

  getDatetimeText(isoDatetime) {
    const date = getAsLocalDate(isoDatetime);

    let dateText;

    if(this.state.screenWidth > 360) {
      dateText = date.toLocaleDateString();
    }
    else {
      const dateFormat = {
        day: '2-digit',
        month: '2-digit'
      };

      dateText = new Intl.DateTimeFormat('pt-BR', dateFormat).format(date);
    }

    return dateText;
  }

  getConfirmationWindowTitle() {
    if(this.state.confirmFailed) {
      if(this.state.removeCustomTraining !== null) {
        return 'Falha ao remover treino complementar';
      }
      else if(this.state.removePersonalTraining !== null) {
        return 'Falha ao remover treino de personal';
      }
    }
    else if(this.state.confirmInProgress) {
      if(this.state.removeCustomTraining !== null) {
        return 'Removendo treino complementar';
      }
      else if(this.state.removePersonalTraining !== null) {
        return 'Removendo treino de personal';
      }
    }
    else {
      if(this.state.removeCustomTraining !== null) {
        return 'Remover treino complementar';
      }
      else if(this.state.removePersonalTraining !== null) {
        return 'Remover treino de personal';
      }
    }

    return 'Não implementado';
  }

  getConfirmationWindowDescription() {
    if(this.state.confirmFailed) {
      return this.state.confirmFailDescription;
    }
    else if(this.state.removeCustomTraining !== null) {
      return `Deseja realmente remover este treino complementar?`;
    }
    else if(this.state.removePersonalTraining !== null) {
      return `Deseja realmente remover este treino de personal?`;
    }

    return 'Não implementado';
  }

  getConfirmationWindowConfirmButtonText() {
    if(this.state.removeCustomTraining !== null) {
      return 'Remover';
    }
    else if(this.state.removePersonalTraining !== null) {
      return 'Remover';
    }

    return 'Não implementado';
  }

  confirmationWindowIsVisible() {
    return this.state.removeCustomTraining !== null || this.state.removePersonalTraining !== null;
  }

  resetConfirmationWindow() {
    this.setState({
      removeCustomTraining: null,
      removePersonalTraining: null,
      confirmFailed: false,
      confirmInProgress: false,
    });
  }

  async proceedConfirmationWindow() {
    if(this.state.removeCustomTraining !== null) {
      this.setState({
        confirmInProgress: true
      });

      try{
        if(await deleteModel(`${routes.CUSTOM_TRAINING_DELETE_API}${this.state.removeCustomTraining}`)) {
          this.setState({
            removeCustomTraining: null,
            confirmFailed: false,
            confirmInProgress: false,
          });

          this.reloadList();
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE + '.';

        if(errors instanceof Array) {
          for(let error of errors) {
            switch (error.code) {
              // case 104:
              //   for(let parameter of error.parameters) {
              //     switch (parameter.name) {
              //       case 'contracts':
              //         errorDescription = 'Serviço vinculado à um contrato de aluno. Estes contratos devem ser excluídos antes de excluir este serviço.';
              //
              //         break;
              //       default:
              //     }
              //   }
              //
              //   break;
              case 209:
                errorDescription = 'Sessão do usuário expirada.';

                break;
              default:
            }
          }
        }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }
    else if(this.state.removePersonalTraining !== null) {
      this.setState({
        confirmInProgress: true
      });

      try{
        if(await deleteModel(`${routes.PERSONAL_TRAINING_DATA_DELETE_API}${this.state.removePersonalTraining}`)) {
          this.setState({
            removePersonalTraining: null,
            confirmFailed: false,
            confirmInProgress: false,
          });

          this.reloadList();
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE + '.';

        if(errors instanceof Array) {
          for(let error of errors) {
            switch (error.code) {
              // case 104:
              //   for(let parameter of error.parameters) {
              //     switch (parameter.name) {
              //       case 'contracts':
              //         errorDescription = 'Serviço vinculado à um contrato de aluno. Estes contratos devem ser excluídos antes de excluir este serviço.';
              //
              //         break;
              //       default:
              //     }
              //   }
              //
              //   break;
              case 209:
                errorDescription = 'Sessão do usuário expirada.';

                break;
              default:
            }
          }
        }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }

    return;
  }

  getZoneData() {
    if(this.state.zone_index < 0.8) {
      return {
        specifier: 'alert',
        result: 'Destreino',
        description: 'Cuidado com a retomada no treinamento. Não exagere na intensidade.'
      }
    }
    else if(this.state.zone_index <= 1.3) {
      return {
        specifier: 'normal',
        result: 'Ótimo',
        description: 'Excelente ritmo de treinamento! Lembre-se: aumentos na intensidade do treino devem ser feitas de forma gradual.'
      }
    }
    else if(this.state.zone_index < 1.5) {
      return {
        specifier: 'danger',
        result: 'Cuidado', //Risco de lesão
        description: 'Cuidado para não exigir além da capacidade de seu corpo. Se necessário reduza a intensidade de seus treinos.'
      }
    }
    else {
      return {
        specifier: 'extreme-danger',
        result: 'Alerta', //Risco extremo de lesão
        description: 'Aumentos repentinos no ritmo ou intensidades nos treinos podem ocasionar lesões anormais. Procure maneirar nos próximos treinos ou descanse.'
      }
    }
  }

  getEntryActions(entry) {
    if(entry.is_custom) {
      return (
        <React.Fragment>

          <DefaultMenuButton
            className="training-list__recent-trainings__entry__action"
            linkTo={`${routes.EDIT_CUSTOM_TRAINING_PATH}${entry.id}`}
            text={<i className="fas fa-edit"></i>}
            color="blue"
          />

          <DefaultMenuButton
            className="training-list__recent-trainings__entry__action"
            onClick={() => this.setState({removeCustomTraining: entry.id})}
            text={<i className="fas fa-trash-alt"></i>}
            color="red"
          />

        </React.Fragment>
      );
    }
    else if(entry.is_personal) {
      return (
        <React.Fragment>

          <DefaultMenuButton
            className="training-list__recent-trainings__entry__action"
            linkTo={`${routes.PERSONAL_TRAINING_REPORT_PATH}${entry.id}`}
            text={<i className="fas fa-chart-bar"></i>}
          />

          <DefaultMenuButton
            className="training-list__recent-trainings__entry__action"
            linkTo={`${routes.PERSONAL_TRAINING_EDIT_PATH}${entry.id}`}
            text={<i className="fas fa-edit"></i>}
            color="blue"
          />

          <DefaultMenuButton
            className="training-list__recent-trainings__entry__action"
            onClick={() => this.setState({removePersonalTraining: entry.id})}
            text={<i className="fas fa-trash-alt"></i>}
            color="red"
          />

        </React.Fragment>
      );
    }
    else {
      return (
        <React.Fragment>

          <DefaultMenuButton
            className="training-list__recent-trainings__entry__action"
            linkTo={`${routes.TRAINING_DAY_REPORT_PATH}${entry.training_day_id}`}
            text={<i className="fas fa-chart-bar"></i>}
          />

          <DefaultMenuButton
            className="training-list__recent-trainings__entry__action"
            linkTo={`${routes.EDIT_DEFAULT_TRAINING_PATH}${entry.id}`}
            text={<i className="fas fa-edit"></i>}
            color="blue"
          />

        </React.Fragment>
      );
    }
  }

  getTrainingListItems() {
    if(this.state.recent_trainings.length > 0) {
      return this.state.recent_trainings.map((entry) => {
        let showNotification = false;

        if(entry.pse === null || entry.pse === 0) {
          showNotification = true;
        }

        return (
          <div
            className={`training-list__recent-trainings__entry${entry.is_personal ? '--personal' : ''}`}
            key={`${entry.is_custom ? 'custom_training' : 'training'}:${entry.id}`}
          >

            <p className="training-list__recent-trainings__entry__date">{this.getDatetimeText(entry.date)}</p>

            <div className="training-list__recent-trainings__entry__title-wrapper">

              <p className="training-list__recent-trainings__entry__title"> {entry.name}</p>

              {showNotification &&
                <p className="training-list__recent-trainings__entry__notification">PSE não preenchida</p>
              }

            </div>

            <div className="training-list__recent-trainings__entry__action-container">

              {this.getEntryActions(entry)}

            </div>

          </div>
        );
      });
    }

    return (<p className="training-list__recent-trainings__no-entry-message">Nenhum treino cadastrado</p>);
  }

  render() {
    if(this.state.loading) {
      return (
        <PoseGroup>
          <FadeContainer key="preloader">
            <PreLoader />
          </FadeContainer>
        </PoseGroup>
      );
    }

    const zoneData = this.getZoneData();

    return (
      <DefaultMenuLayout
        showBackButton={true}
        history={this.props.history}
        onLogout={() => this.props.onLogout()}
        username={this.props.username}
      >

        <div className="training-list">

          <div className="training-list__wrapper">

            <section className="training-list__indicators">

              <header className="training-list__indicators__header">

                <h3 className="training-list__indicators__header__title">Análise de carga interna</h3>

              </header>

              <div className="training-list__indicators-wrapper">

                <div className="training-list__indicators__graph">

                  <canvas ref={this.canvasRef} />

                </div>

                <div className="training-list__indicators__result">

                  <h4 className="training-list__indicators__result__label">Resultado</h4>

                  <div className="training-list__indicators__result__value-wrapper">

                    <p className={`training-list__indicators__result__value--${zoneData.specifier}`}>{zoneData.result}</p>

                    <p className="training-list__indicators__result__description">{zoneData.description}</p>

                  </div>

                </div>

              </div>

            </section>

            <div className="training-list__note">

              <p className="training-list__note__text">
                A <strong>análise de carga interna</strong>, ou <strong>RAC</strong> (Relação de carga Aguda/Crônica), é um indicador gerado a
                partir dos dados de seus treinos recentes e é utilizada para avaliar o risco de lesão durante a
                prática de atividade física.
              </p>

            </div>

            <div className="training-list__action-container">

              <DefaultMenuButton
                className="training-list__action"
                linkTo={routes.ADD_CUSTOM_TRAINING_PATH}
                text={
                  <React.Fragment>

                    <i className="fas fa-plus training-list__action__icon"></i> Cadastrar treino complementar

                  </React.Fragment>
                }
                color="green"
              />

            </div>

            <section className="training-list__recent-trainings">

              <header className="training-list__recent-trainings__header">

                <h3 className="training-list__recent-trainings__header__title">Treinos recentes</h3>

              </header>

              <div className="training-list__recent-trainings__list">

                {this.getTrainingListItems()}

              </div>

            </section>

          </div>

        </div>

        <ConfirmationWindow
          title={this.getConfirmationWindowTitle()}
          description={this.getConfirmationWindowDescription()}
          confirmText={this.getConfirmationWindowConfirmButtonText()}
          cancelText={this.state.confirmFailed ? 'Ok' : 'Cancelar'}
          visible={this.confirmationWindowIsVisible()}
          onCancel={() => this.resetConfirmationWindow()}
          onConfirm={() => this.proceedConfirmationWindow()}
          loading={this.state.confirmInProgress}
          useErrorIcon={this.state.confirmFailed}
          hideConfirmButton={this.state.confirmFailed}
        />

      </DefaultMenuLayout>
    );
  }
}

export default TrainingList;
