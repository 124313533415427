import React from 'react';
import './custom_training_data.scss';
import DefaultInput, {SelectOption} from '../../utils/default_input';
import {HorizontalRule} from '../../utils/default_section';
import {getAsLocalDateString} from '../../utils/functions';
import WarningMessage from '../../components/warning_message';
import DefaultMenuButton from '../../components/default_menu_button';
import {CUSTOM_TRAINING_MIN_DATE_OFFSET} from '../../constants';

class CustomTrainingData extends React.Component {
  handleKeyDown(event) {
    if(event.keyCode === 13 && this.props.enableSave) {
      this.props.onSave();
    }
  }

  isHighlighted(propertyName) {
    return this.props.highlights.includes(propertyName);
  }

  isEditMode() {
    return this.props.training.id && this.props.training.id > 0;
  }

  getMinDate() {
    const date = new Date();

    date.setDate(date.getDate() - CUSTOM_TRAINING_MIN_DATE_OFFSET);

    return date.toISOString().slice(0, 10);
  }

  getMaxDate() {
    const date = new Date();

    return date.toISOString().slice(0, 10);
  }

  isHomeTraining() {
    return this.props.training.training_day_id !== null;
  }

  getActivityOptions() {
    if (this.props.training.activity_reference_id === null) {
      return [
        SelectOption('', 'Selecione um exercício'),
        ...this.props.exercise_activities.map((activity) => SelectOption(activity.id, activity.name))
      ];
    }

    return this.props.exercise_activities.map((activity) => SelectOption(activity.id, activity.name));
  }

  render() {
    const isHomeTraining = this.isHomeTraining();

    let minutes = '';
    let seconds = '';

    if(this.props.training.total_seconds) {
      minutes = Math.floor(this.props.training.total_seconds / 60);
      seconds = this.props.training.total_seconds % 60;
    }

    return (
      <React.Fragment>

        <div className="custom-training-data__warning-container">

          <WarningMessage
            message={this.props.warningMessage}
            onClose={this.props.onCloseWarning}
            visible={this.props.showWarningMessage}
          />

        </div>

        <div className="custom-training-data__input-container">

          {isHomeTraining ? (
            <React.Fragment>

              <div className="custom-training-data__input-wrapper">

                <h4 className="custom-training-data__input-tag-label">Serviço:</h4>

                <p className="custom-training-data__input-tag">Home training</p>

              </div>

              <div className="custom-training-data__input-wrapper">

                <h4 className="custom-training-data__input-tag-label">Exercício:</h4>

                <p className="custom-training-data__input-tag">{this.props.training.exercise}</p>

              </div>

              <div className="custom-training-data__input-wrapper">

                <h4 className="custom-training-data__input-tag-label">Data:</h4>

                <p className="custom-training-data__input-tag">{getAsLocalDateString(this.props.training.date)}</p>

              </div>

            </React.Fragment>
          ) : (
            <React.Fragment>

              <div className="custom-training-data__input-wrapper">

                <h4 className="custom-training-data__input-label">Exercício:</h4>

                <DefaultInput
                  className="custom-training-data__input--extended"
                  name="activity_reference_id"
                  isHighlighted={this.isHighlighted("activity_reference_id")}
                  type="select"
                  handleInputChange={this.props.handleInputChange}
                  value={this.props.training.activity_reference_id || ''}
                  options={this.getActivityOptions()}
                  isHorizontal={this.props.screenWidth > 580}
                />

              </div>

              <div className="custom-training-data__input-wrapper">

                <h4 className="custom-training-data__input-label">Data:</h4>

                <DefaultInput
                  className="custom-training-data__input--normal"
                  name="date"
                  isHighlighted={this.isHighlighted("date")}
                  min={this.getMinDate()}
                  max={this.getMaxDate()}
                  type="date"
                  placeholder="Data de execução"
                  handleInputChange={this.props.handleInputChange}
                  value={this.props.training.date}
                  autoComplete="off"
                />

              </div>

            </React.Fragment>
          )}

          <div className="custom-training-data__input-wrapper">

            {isHomeTraining ?
              <React.Fragment>

                <h4 className="custom-training-data__input-label--force-width">Tempo do workout:</h4>

                <DefaultInput
                  className="custom-training-data__input"
                  name="minutes"
                  type="number"
                  placeholder="-"
                  min="0"
                  step="1"
                  handleInputChange={this.props.handleInputChange}
                  value={minutes}
                  autoComplete="off"
                  suffix={this.props.screenWidth > 680 ? 'minutos' : 'min'}
                  onFocus={(event) => event.target.select()}
                />

                <DefaultInput
                  className="custom-training-data__input"
                  name="seconds"
                  type="number"
                  placeholder="-"
                  min="0"
                  step="1"
                  handleInputChange={this.props.handleInputChange}
                  value={seconds}
                  autoComplete="off"
                  suffix={this.props.screenWidth > 680 ? 'segundos' : 'seg'}
                  onFocus={(event) => event.target.select()}
                />

              </React.Fragment>:
              <React.Fragment>

                <h4 className="custom-training-data__input-label--force-width">Tempo total:</h4>

                <DefaultInput
                  className="custom-training-data__input"
                  name="total_time"
                  isHighlighted={this.isHighlighted("total_time")}
                  type="number"
                  placeholder="-"
                  min="0"
                  step="1"
                  handleInputChange={this.props.handleInputChange}
                  value={this.props.training.total_time}
                  autoComplete="off"
                  suffix="minutos"
                  onFocus={(event) => event.target.select()}
                />

              </React.Fragment>
            }

          </div>

          {isHomeTraining &&
            <div className="custom-training-data__input-wrapper">

              <h4 className="custom-training-data__input-label">Repetições:</h4>

              <DefaultInput
                className="custom-training-data__input"
                name="total_repetitions"
                type="number"
                placeholder="-"
                min="0"
                step="1"
                handleInputChange={this.props.handleInputChange}
                value={this.props.training.total_repetitions || ''}
                autoComplete="off"
                suffix="reps"
                onFocus={(event) => event.target.select()}
              />
            </div>
          }

          <div className="custom-training-data__input-wrapper--vertical">

            <h4 className="custom-training-data__input-label">PSE - O quão pesado foi seu treino?</h4>

            <div className="custom-training-data__notification-container">

              <i className="fas fa-exclamation custom-training-data__notification-container__icon"></i>

              <p className="custom-training-data__notification-container__text">

                O preenchimento da PSE deve ser feito cerca de 20-30 minutos após o fim da atividade física para
                utilizar nossa metodologia de análise de carga de forma precisa.

              </p>

            </div>

            <ul className={`custom-training-data__scale${this.isHighlighted('pse') ? '--highlight' : ''}`}>

              <li className="custom-training-data__scale__group">

                <div className="custom-training-data__scale__group__image--2">

                  <i className="fas fa-kiss-beam custom-training-data__scale__group__image-icon"></i>

                </div>

                <div className="custom-training-data__scale__group__options">

                  <button
                    className="custom-training-data__scale__group__option--1"
                    onClick={() => this.props.onSetPse(1)}
                    disabled={this.props.training.pse === 1}
                  >

                    <p className="custom-training-data__scale__group__option-number">
                      1
                    </p>

                    {/* <p className="custom-training-data__scale__group__option-text--flex">
                      Muito leve
                    </p> */}

                  </button>

                  <button
                    className="custom-training-data__scale__group__option--2"
                    onClick={() => this.props.onSetPse(2)}
                    disabled={this.props.training.pse === 2}
                  >

                    <p className="custom-training-data__scale__group__option-number">
                      2
                    </p>

                    {/* <p className="custom-training-data__scale__group__option-text--flex">
                      Leve
                    </p> */}

                  </button>

                </div>

                <div className="custom-training-data__scale__group__description--2">

                  <p className="custom-training-data__scale__group__description__text">
                    Muito leve
                  </p>

                </div>

              </li>

              <li className="custom-training-data__scale__group">

                <div className="custom-training-data__scale__group__image--4">

                  <i className="fas fa-grin custom-training-data__scale__group__image-icon"></i>

                </div>

                <div className="custom-training-data__scale__group__options">

                  <button
                    className="custom-training-data__scale__group__option--3"
                    onClick={() => this.props.onSetPse(3)}
                    disabled={this.props.training.pse === 3}
                  >

                    <p className="custom-training-data__scale__group__option-number">
                      3
                    </p>

                    {/* <p className="custom-training-data__scale__group__option-text--flex">
                      Tranquilo
                    </p> */}

                  </button>

                  <button
                    className="custom-training-data__scale__group__option--4"
                    onClick={() => this.props.onSetPse(4)}
                    disabled={this.props.training.pse === 4}
                  >

                    <p className="custom-training-data__scale__group__option-number">
                      4
                    </p>

                    {/* <p className="custom-training-data__scale__group__option-text--flex">
                      Moderado
                    </p> */}

                  </button>

                </div>

                <div className="custom-training-data__scale__group__description--4">

                  <p className="custom-training-data__scale__group__description__text">
                    Leve
                  </p>

                </div>

              </li>

              <li className="custom-training-data__scale__group">

                <div className="custom-training-data__scale__group__image--6">

                  <i className="fas fa-meh custom-training-data__scale__group__image-icon"></i>

                </div>

                <div className="custom-training-data__scale__group__options">

                  <button
                    className="custom-training-data__scale__group__option--5"
                    onClick={() => this.props.onSetPse(5)}
                    disabled={this.props.training.pse === 5}
                  >

                    <p className="custom-training-data__scale__group__option-number">
                      5
                    </p>

                    {/* <p className="custom-training-data__scale__group__option-text--flex">
                      Requer esforço
                    </p> */}

                  </button>

                  <button
                    className="custom-training-data__scale__group__option--6"
                    onClick={() => this.props.onSetPse(6)}
                    disabled={this.props.training.pse === 6}
                  >

                    <p className="custom-training-data__scale__group__option-number">
                      6
                    </p>

                    {/* <p className="custom-training-data__scale__group__option-text--flex">
                      Pouco pesado
                    </p> */}

                  </button>

                </div>

                <div className="custom-training-data__scale__group__description--6">

                  <p className="custom-training-data__scale__group__description__text">
                    Moderado
                  </p>

                </div>

              </li>

              <li className="custom-training-data__scale__group">

                <div className="custom-training-data__scale__group__image--8">

                  <i className="fas fa-frown-open custom-training-data__scale__group__image-icon"></i>

                </div>

                <div className="custom-training-data__scale__group__options">

                  <button
                    className="custom-training-data__scale__group__option--7"
                    onClick={() => this.props.onSetPse(7)}
                    disabled={this.props.training.pse === 7}
                  >

                    <p className="custom-training-data__scale__group__option-number">
                      7
                    </p>

                    {/* <p className="custom-training-data__scale__group__option-text--flex">
                      Pesado
                    </p> */}

                  </button>

                  <button
                    className="custom-training-data__scale__group__option--8"
                    onClick={() => this.props.onSetPse(8)}
                    disabled={this.props.training.pse === 8}
                  >

                    <p className="custom-training-data__scale__group__option-number">
                      8
                    </p>

                    {/* <p className="custom-training-data__scale__group__option-text--flex">
                      Muito pesado
                    </p> */}

                  </button>

                </div>

                <div className="custom-training-data__scale__group__description--8">

                  <p className="custom-training-data__scale__group__description__text">
                    Pesado
                  </p>

                </div>

              </li>

              <li className="custom-training-data__scale__group">

                <div className="custom-training-data__scale__group__image--10">

                  <i className="fas fa-tired custom-training-data__scale__group__image-icon"></i>

                </div>

                <div className="custom-training-data__scale__group__options">

                  <button
                    className="custom-training-data__scale__group__option--9"
                    onClick={() => this.props.onSetPse(9)}
                    disabled={this.props.training.pse === 9}
                  >

                    <p className="custom-training-data__scale__group__option-number">
                      9
                    </p>

                    {/* <p className="custom-training-data__scale__group__option-text--flex">
                      Extremamente pesado
                    </p> */}

                  </button>

                  <button
                    className="custom-training-data__scale__group__option--10"
                    onClick={() => this.props.onSetPse(10)}
                    disabled={this.props.training.pse === 10}
                  >

                    <p className="custom-training-data__scale__group__option-number">
                      10
                    </p>

                    {/* <p className="custom-training-data__scale__group__option-text--flex">
                      Quase impossível
                    </p> */}

                  </button>

                </div>

                <div className="custom-training-data__scale__group__description--10">

                  <p className="custom-training-data__scale__group__description__text">
                    Muito pesado
                  </p>

                </div>

              </li>

            </ul>

            <p className="custom-training-data__small-note">

              *A PSE é uma estimativa para quantificar o esforço físico necessário para realizar a atividade
              proposta e está diretamente relacionada com o cansaço físico persistente depois de sua execução.

            </p>

          </div>

        </div>

        <HorizontalRule />

        <div className="custom-training-data__buttons-container">

          <DefaultMenuButton
            className="custom-training-data__save-button"
            onClick={this.props.onSave}
            text="Salvar"
            color="blue"
          />

          <DefaultMenuButton
            className="custom-training-data__cancel-button"
            onClick={this.props.onCancelPath}
            text="Cancelar"
          />

        </div>

      </React.Fragment>
    );
  }
}

export default CustomTrainingData;
