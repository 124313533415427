import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { PoseGroup } from 'react-pose';
import './libs/fontawesome-6.5.1-web/scss/fontawesome.scss';
import './libs/fontawesome-6.5.1-web/scss/solid.scss';
import './libs/fontawesome-6.5.1-web/scss/brands.scss';
import './init.scss';
import './general.scss';
import * as serviceWorker from './serviceWorker';
import Login from './views/login';
import {FadeContainer} from './utils/pose_containers';
import PreLoader from './utils/preloader';
import {signOut, getAbbreviatedName} from './utils/functions';
import * as routes from './constants';
import {DEFAULT_ERROR_MESSAGE, SUPPORT_MAIN_SECTIONS} from './constants';
import MainMenu from './views/main_menu';
import Checkin from './views/checkin';
import ForgotPassword from './views/forgot_password';
import PasswordRecovery from './views/password_recovery';
import ValidateAccount from './views/validate_account';
import Prompt from './components/prompt';
import logo from './images/logo_fyd_club.png';
import TrainingList from './views/trainings/training_list';
import AddTrainingData from './views/trainings/add_custom_training';
import EditTrainingData from './views/trainings/edit_custom_training';
import EditDefaultTrainingData from './views/trainings/edit_default_training';
import TrainingDayReport from './views/trainings/training_day_report';
import EmailUpdate from './views/email_update';
import HomeTraining from './views/home_training';
import PersonalTraining from './views/personal_training';
import PersonalTrainingEdit from './views/personal_training_edit';
import PersonalTrainingReport from './views/personal_training_report';
import FoodPrescription from './views/food_prescription';
import PersonalRecord from './views/personal_record';
import CustomerSupport from './views/customer_support';
import RegisterSupportTicket from './views/register_support_ticket';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageLoaded: false,
      authenticated: false,
      deferredPrompt: null,
      username: "",
      permission_ids: [],
      screenWidth: window.innerWidth,
    };

    sessionStorage.clear();
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  async isAuthenticated() {
    try {
      let response = await fetch(routes.IS_AUTHENTICATED_API, {cache: 'no-store', credentials: 'same-origin'});

      if(!response.ok) {
        throw Error(DEFAULT_ERROR_MESSAGE);
      }

      let data = await response.json();

      return data;
    }
    catch(error) {
      console.log(error);
      return null;
    }
  }

  async componentDidMount() {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      this.setState({deferredPrompt: e});
    });

    const updates = {};

    let data = this.isAuthenticated();

    data = await data;

    updates.pageLoaded = true;
    updates.authenticated = false;

    if(data) {
        updates.username = data.username ? getAbbreviatedName(data.username) : '';
        updates.authenticated = data.is_authenticated || false;
    }

    this.setState(updates);

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  // componentDidUpdate(prevProps, prevState) {
  //   if(!prevState.authenticated && this.state.authenticated) {
  //
  //     window.addEventListener('beforeinstallprompt', (e) => {
  //       e.preventDefault();
  //       this.setState({deferredPrompt: e});
  //     });
  //   }
  // }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  async onAuthenticate(data) {
    this.setState({
      username: data.username ? getAbbreviatedName(data.username) : '',
      authenticated: true,
    });
  }

  async onLogout() {
    this.setState({
      pageLoaded: false,
    });

    if(await signOut()) {
      this.setState({
        authenticated: false,
        username: "",
        pageLoaded: true,
      });

      return;
    }

    window.alert('Infelizmente estamos com problemas em nosso serviço. Por favor, tente novamente mais tarde.');

    this.setState({
      pageLoaded: true,
    });
  }

  render() {
    return !this.state.pageLoaded ? (
      <PoseGroup>
        <FadeContainer key="preloader">
          <PreLoader />
        </FadeContainer>
      </PoseGroup>
    ):
    (
      <BrowserRouter>

        <React.Fragment>

          {this.state.authenticated ?
            (
              <React.Fragment>

                <Switch>

                  <Route
                    path={routes.MAIN_PATH}
                    exact
                    render={(props) => (
                      <MainMenu
                        {...props}
                        onLogout={() => this.onLogout()}
                        username={this.state.username}
                      />
                    )}
                  />

                  <Route
                    path={routes.CHECKIN_PATH}
                    render={(props) => (
                      <Checkin
                        key="route:student_checkin"
                        {...props}
                        onLogout={() => this.onLogout()}
                        username={this.state.username}
                      />
                    )}
                  />

                  <Route
                    path={routes.TRAININGS_PATH}
                    render={(props) => (
                      <TrainingList
                        key="route:student_training_list"
                        {...props}
                        onLogout={() => this.onLogout()}
                        username={this.state.username}
                      />
                    )}
                  />

                  <Route
                    path={routes.ADD_CUSTOM_TRAINING_PATH}
                    render={(props) => (
                      <AddTrainingData
                        key="route:add_custom_training"
                        {...props}
                        onLogout={() => this.onLogout()}
                        username={this.state.username}
                      />
                    )}
                  />

                  <Route
                    path={`${routes.EDIT_CUSTOM_TRAINING_PATH}:customTrainingId`}
                    render={(props) => (
                      <EditTrainingData
                        key="route:edit_custom_training"
                        {...props}
                        onLogout={() => this.onLogout()}
                        username={this.state.username}
                      />
                    )}
                  />

                  <Route
                    path={`${routes.EDIT_DEFAULT_TRAINING_PATH}:defaultTrainingId`}
                    render={(props) => (
                      <EditDefaultTrainingData
                        key="route:edit_default_training"
                        {...props}
                        onLogout={() => this.onLogout()}
                        username={this.state.username}
                      />
                    )}
                  />

                  <Route
                    path={`${routes.TRAINING_DAY_REPORT_PATH}:trainingDayId`}
                    render={(props) => (
                      <TrainingDayReport
                        key="route:training_day_report"
                        {...props}
                        onLogout={() => this.onLogout()}
                        username={this.state.username}
                      />
                    )}
                  />

                  <Route
                    path={`${routes.EMAIL_UPDATE_PATH}:authHash`}
                    render={(props) => (
                      <EmailUpdate
                        key="route:email_update"
                        {...props}
                      />
                    )}
                  />

                  <Route
                    path={routes.HOME_TRAINING_PATH}
                    render={(props) => (
                      <HomeTraining
                        key="route:home_training"
                        {...props}
                        onLogout={() => this.onLogout()}
                        username={this.state.username}
                      />
                    )}
                  />

                  <Route
                    path={`${routes.PERSONAL_TRAINING_REPORT_PATH}:personalTrainingId`}
                    render={(props) => (
                      <PersonalTrainingReport
                        key="route:personal_training_report"
                        {...props}
                        onLogout={() => this.onLogout()}
                        username={this.state.username}
                      />
                    )}
                  />

                  <Route
                    path={routes.PERSONAL_TRAINING_SUPPORT_PATH}
                    render={(props) => (
                      <CustomerSupport
                        key="route:customer_support:personal_training"
                        {...props}
                        onLogout={() => this.onLogout()}
                        username={this.state.username}
                        mainSection={SUPPORT_MAIN_SECTIONS[1]}
                      />
                    )}
                  />

                  <Route
                    path={routes.PERSONAL_TRAINING_SUPPORT_REGISTER_PATH}
                    render={(props) => (
                      <RegisterSupportTicket
                        key="route:register_support_ticket:personal_training"
                        {...props}
                        onLogout={() => this.onLogout()}
                        username={this.state.username}
                        mainSection={SUPPORT_MAIN_SECTIONS[1]}
                      />
                    )}
                  />

                  <Route
                    path={`${routes.PERSONAL_TRAINING_EDIT_PATH}:personalTrainingId`}
                    render={(props) => (
                      <PersonalTrainingEdit
                        key="route:personal_training_edit"
                        {...props}
                        onLogout={() => this.onLogout()}
                        username={this.state.username}
                      />
                    )}
                  />

                  <Route
                    path={routes.PERSONAL_TRAINING_PATH}
                    render={(props) => (
                      <PersonalTraining
                        key="route:personal_training"
                        {...props}
                        onLogout={() => this.onLogout()}
                        username={this.state.username}
                      />
                    )}
                  />

                  <Route
                    path={routes.NUTRITIONAL_SUPPORT_PATH}
                    render={(props) => (
                      <CustomerSupport
                        key="route:customer_support:food_prescription"
                        {...props}
                        onLogout={() => this.onLogout()}
                        username={this.state.username}
                        mainSection={SUPPORT_MAIN_SECTIONS[0]}
                      />
                    )}
                  />

                  <Route
                    path={routes.NUTRITIONAL_SUPPORT_REGISTER_PAGE}
                    render={(props) => (
                      <RegisterSupportTicket
                        key="route:register_support_ticket:food_prescription"
                        {...props}
                        onLogout={() => this.onLogout()}
                        username={this.state.username}
                        mainSection={SUPPORT_MAIN_SECTIONS[0]}
                      />
                    )}
                  />

                  <Route
                    path={routes.FOOD_PRESCRIPTION_PATH}
                    render={(props) => (
                      <FoodPrescription
                        key="route:food_prescription"
                        {...props}
                        onLogout={() => this.onLogout()}
                        username={this.state.username}
                      />
                    )}
                  />

                  <Route
                    path={routes.PERSONAL_RECORD_PATH}
                    render={(props) => (
                      <PersonalRecord
                        key="route:personal_record"
                        {...props}
                        onLogout={() => this.onLogout()}
                        username={this.state.username}
                      />
                    )}
                  />

                  <Redirect from="/" to={routes.MAIN_PATH} />

                </Switch>

                {this.state.deferredPrompt != null &&
                  <Prompt
                    title="Adicione nosso aplicativo Web em sua área de trabalho. Não ocupa espaço em seu aparelho!"
                    logo={<img className="prompt__logo" src={logo} alt="Logo da FYD" />}
                    actionText={this.state.screenWidth > 360 ? 'Adicionar' : 'Add'}
                    cancelText={<i className="fas fa-times"></i>}
                    onConfirm={() => {
                      this.state.deferredPrompt.prompt();

                      this.state.deferredPrompt.userChoice.then((choiceResult) => {
                        this.setState({deferredPrompt: null});
                      });
                    }}
                    onCancel={() => this.setState({deferredPrompt: null})}
                  />
                }

              </React.Fragment>
            ):
            (
              <Switch>

                <Route
                  path={routes.FORGOT_PASSWORD_PATH}
                  render={(props) => (
                    <ForgotPassword
                      key="route:forgot_password"
                      {...props}
                    />
                  )}
                />

                <Route
                  path={`${routes.PASSWORD_RECOVERY_PATH}:authHash`}
                  render={(props) => (
                    <PasswordRecovery
                      key="route:password_recovery"
                      {...props}
                    />
                  )}
                />

                <Route
                  path={`${routes.VALIDATE_ACCOUNT_PATH}:authHash`}
                  render={(props) => (
                    <ValidateAccount
                      key="route:validate_account"
                      {...props}
                    />
                  )}
                />

                <Route
                  path={`${routes.EMAIL_UPDATE_PATH}:authHash`}
                  render={(props) => (
                    <EmailUpdate
                      key="route:email_update"
                      {...props}
                    />
                  )}
                />

                <Route
                  path={routes.MAIN_PATH}
                  render={(props) => (
                    <Login
                      {...props}
                      onAuthenticate={(data) => this.onAuthenticate(data)}
                    />
                  )}
                />

                {/* <Redirect from="/" to={routes.MAIN_PATH} /> */}

              </Switch>
            )
          }

        </React.Fragment>

      </BrowserRouter>
    );
  }
}

ReactDOM.render((
  <App />
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
