import React from 'react';
import { PoseGroup } from 'react-pose';
import './personal_record.scss';
import {FadeContainer} from '../utils/pose_containers';
import PreLoader from '../utils/preloader';
import * as routes from '../constants';
import {DEFAULT_UNKNOWN_ERROR_MESSAGE} from '../constants';
import DefaultMenuButton from '../components/default_menu_button';
import DefaultMenuLayout from '../components/default_menu_layout';
import ConfirmationWindow from '../components/confirmation_window';
import OverlayWindow from '../components/overlay_window';
import DefaultInput from '../utils/default_input';
import DefaultFilterInput from '../components/default_filter_input';
import {getModel, getModels, postModel, getAsLocalDate} from '../utils/functions';


class PersonalRecord extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      active_units: [],
      selectedUnit: null,
      exercises: [],
      exerciseNameFilter: "",
      exerciseReferenceMap: new Map(),
      selectedExercise: null,
      originalReferenceData: null,
      onUpdateExerciseReference: false,
      confirmInProgress: false,
      confirmFailed: false,
      confirmFailDescription: "",
      criticalErrorMessage: null,
      screenWidth: window.innerWidth,
    };
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  async getExercises() {
    if (this.state.selectedUnit === null) {
      return [];
    }

    return await getModels(routes.PERSONAL_RECORD_EXERCISES_GET_API.replace('{unit_id}', this.state.selectedUnit.id));
  }

  async getActiveUnits() {
    return await getModel(routes.ACTIVE_UNITS_GET_API);
  }

  async reloadExerciseList() {
    this.setState({
      loading: true
    });

    const update = {loading: false};

    let exercises = this.getExercises();

    exercises = await exercises;

    if(exercises) {
      update.exercises = exercises;
      update.exercises.sort((a, b) => a.name.localeCompare(b.name));
    }

    this.setState(update);
  }

  async componentDidMount() {
    const update = {loading: false};

    try {
      let active_units = await this.getActiveUnits();

      if(active_units) {
        update.active_units = active_units;

        if(update.active_units.length === 1) {
          if(update.active_units[0].registration_incomplete) {
            update.criticalErrorMessage = (
              <span>

                Por favor, finalize o procedimento de cadastro de aluno para liberar esta funcionalidade clicando
                <a
                  className="personal-record__external-text-link"
                  href={update.active_units[0].registration_link}
                  target="_blank"
                  rel="noopener noreferrer"
                >

                    aqui

                </a>

              </span>
            );
          }
          else {
            update.selectedUnit = update.active_units[0];
          }
        }
      }
    }
    catch(errors) {
      update.criticalErrorMessage = DEFAULT_UNKNOWN_ERROR_MESSAGE;
      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            case 208:
              if(error.message.startsWith('User registration is incomplete.')) {
                update.criticalErrorMessage = 'Por favor, finalize o procedimento de cadastro de aluno para liberar esta funcionalidade.';
              }

              break;
            case 209:
              update.criticalErrorMessage = 'Sessão do usuário expirada.';

              break;
            default:
          }
        }
      }
    }

    this.setState(update);

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedUnit !== this.state.selectedUnit) {
      this.reloadExerciseList();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  onSelectUnit(unit) {
    if(unit.registration_incomplete) {
      this.setState({
        criticalErrorMessage: (
          <span>

            Por favor, finalize o procedimento de cadastro de aluno para liberar esta funcionalidade clicando
            <a
              className="personal-record__external-text-link"
              href={unit.registration_link}
              target="_blank"
              rel="noopener noreferrer"
            >

                aqui

            </a>

          </span>
        )
      });
    }
    else {
      this.setState({
        selectedUnit: unit
      });
    }
  }

  getUnitOptions() {
    return this.state.active_units.map((unit, index) => (
      <DefaultMenuButton
        key={`personal_record:unit_selector:${unit.id}`}
        className="personal-record__unit-button"
        onClick={() => this.onSelectUnit(unit)}
        text={unit.name}
        color="blue"
      />
    ));
  }

  async onSelectExercisePR(exercise) {
    const update = {};

    if (!this.state.exerciseReferenceMap.has(exercise.id)) {
      this.setState({
        loading: true
      });

      update.loading = false;

      let exerciseReference = await getModel(`${routes.EXERCISE_REFERENCE_GET_API}${exercise.id}`);

      exerciseReference = await exerciseReference;

      if(exerciseReference) {
        this.state.exerciseReferenceMap.set(exercise.id, exerciseReference);
        update.exerciseReferenceMap = new Map(this.state.exerciseReferenceMap);
      }
    }

    update.selectedExercise = exercise;
    update.originalReferenceData = {...this.state.exerciseReferenceMap.get(exercise.id)};

    this.setState(update);
  }

  handleReferenceChange(event) {
    if (this.state.selectedExercise === null) {
      return;
    }

    const target = event.target;
    let value = target.value;
    const name = target.name;

    const exerciseReference = {...this.state.exerciseReferenceMap.get(this.state.selectedExercise.id)};

    exerciseReference[name] = parseFloat(value);

    if(exerciseReference.weight && exerciseReference.repetition) {
      exerciseReference.value = (exerciseReference.weight * 100) / (102.78 - (2.78 * exerciseReference.repetition));
    }

    this.state.exerciseReferenceMap.set(this.state.selectedExercise.id, exerciseReference);

    this.setState({
      exerciseReferenceMap: new Map(this.state.exerciseReferenceMap)
    });
  }

  getConfirmationWindowTitle() {
    if(this.state.confirmFailed) {
      if(this.state.onUpdateExerciseReference) {
        return 'Falha ao atualizar PR';
      }
    }
    else if(this.state.confirmInProgress) {
      if(this.state.onUpdateExerciseReference) {
        return 'Atualizando PR';
      }
    }
    else {
      if(this.state.onUpdateExerciseReference) {
        return 'Atualizar PR';
      }
    }

    return 'Não implementado';
  }

  getConfirmationWindowDescription() {
    if(this.state.confirmFailed) {
      return this.state.confirmFailDescription;
    }
    else if(this.state.onUpdateExerciseReference) {
      return `Deseja realmente atualizar seu PR para o exercício ${this.state.selectedExercise.name}?`;
    }

    return 'Não implementado';
  }

  getConfirmationWindowConfirmButtonText() {
    if(this.state.onUpdateExerciseReference) {
      return 'Atualizar';
    }

    return 'Não implementado';
  }

  confirmationWindowIsVisible() {
    return this.state.onUpdateExerciseReference;
  }

  resetConfirmationWindow() {
    this.setState({
      onUpdateExerciseReference: false,
      confirmFailed: false,
      confirmInProgress: false,
    });
  }

  async proceedConfirmationWindow() {
    if(this.state.onUpdateExerciseReference) {
      this.setState({
        confirmInProgress: true
      });

      const exerciseReference = this.state.exerciseReferenceMap.get(this.state.selectedExercise.id);

      const data = {
        reference_data: [
          {
            exercise_id: this.state.selectedExercise.id,
            value: parseFloat(exerciseReference.value),
            weight: parseFloat(exerciseReference.weight),
            repetition: parseInt(exerciseReference.repetition),
          }
        ]
      };

      try{
        if(await postModel(routes.EXERCISE_REFERENCES_POST_API, data)) {
          this.setState({
            onUpdateExerciseReference: false,
            confirmFailed: false,
            confirmInProgress: false,
            originalReferenceData: {...exerciseReference}
          });
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE + '.';

        if(errors instanceof Array) {
          for(let error of errors) {
            switch (error.code) {
              // case 104:
              //   for(let parameter of error.parameters) {
              //     switch (parameter.name) {
              //       case 'contracts':
              //         errorDescription = 'Serviço vinculado à um contrato de aluno. Estes contratos devem ser excluídos antes de excluir este serviço.';
              //
              //         break;
              //       default:
              //     }
              //   }
              //
              //   break;
              case 209:
                errorDescription = 'Sessão do usuário expirada.';

                break;
              default:
            }
          }
        }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }

    return;
  }

  getEntryActions(entry) {
    return (
      <DefaultMenuButton
        className="personal-record__exercise-list__entry__action"
        onClick={() => this.onSelectExercisePR(entry)}
        text="PR"
        color="black"
      />
    );
  }

  getExerciseListItems() {
    let filteredExercises = this.state.exercises;

    if (this.state.exerciseNameFilter.length > 0) {
      filteredExercises = filteredExercises.filter((entry) => {
        const filterText = this.state.exerciseNameFilter.normalize("NFD").replace(this.accentRegex, "").toLowerCase();

        return entry.name.normalize("NFD").replace(this.accentRegex, "").toLowerCase().includes(filterText);
      });
    }

    if(filteredExercises.length > 0) {
      return filteredExercises.map((entry) => {
        return (
          <div
            className="personal-record__exercise-list__entry"
            key={`personal_record:exercise:${entry.id}`}
          >
            <p className="personal-record__exercise-list__entry__title">{entry.name}</p>

            <div className="personal-record__exercise-list__entry__action-container">

              {this.getEntryActions(entry)}

            </div>

          </div>
        );
      });
    }

    return (<p className="personal-record__exercise-list__no-entry-message">Nenhum exercício encontrato</p>);
  }

  getContentOptions() {
    if(this.state.criticalErrorMessage !== null) {
      return (
        <div className="personal-record__warning-message">

          <i className="fas fa-exclamation personal-record__warning-message__icon"></i>
          <p className="personal-record__warning-message__text">{this.state.criticalErrorMessage}</p>

        </div>
      );
    }
    else if(this.state.selectedUnit === null) {
      if(this.state.active_units.length <= 0) {
        return (
          <div className="personal-record__warning-message">

            <i className="fas fa-exclamation personal-record__warning-message__icon"></i>
            <p className="personal-record__warning-message__text">Nenhum serviço contratado.</p>

          </div>
        );
      }
      else {
        return (
          <React.Fragment>

            <h1 className="personal-record__instructions">

              Selecione a unidade desejada:

            </h1>

            <div className="personal-record__unit-selector">

              {this.getUnitOptions()}

            </div>

          </React.Fragment>
        );
      }
    }
    else {
      return (
        <div className="personal-record__wrapper">

          <DefaultFilterInput
            name="exercise_filter"
            label="Filtro:"
            placeholder={this.state.screenWidth > 420 ? 'Digite o nome do exercício' : 'Nome do exercício'}
            autoComplete="off"
            value={this.state.exerciseNameFilter}
            handleInputChange={(event) => this.setState({exerciseNameFilter: event.target.value})}
          />

          <section className="personal-record__exercise-list">

            <header className="personal-record__exercise-list__header">

              <h3 className="personal-record__exercise-list__header__title">Exercícios de LPO</h3>

            </header>

            <div className="personal-record__exercise-list__list">

              {this.getExerciseListItems()}

            </div>

          </section>

        </div>
      );
    }
  }

  getOverlayActions() {
    if (this.state.selectedExercise === null) {
      return null;
    }

    let addSaveAction = false;

    const exerciseReference = this.state.exerciseReferenceMap.get(this.state.selectedExercise.id);

    if(exerciseReference.value !== this.state.originalReferenceData.value) {
      addSaveAction = true;
    }

    return (
      <React.Fragment>

        <DefaultMenuButton
          className="personal-record__overlay__action-button"
          onClick={() => {
            this.state.exerciseReferenceMap.set(this.state.selectedExercise.id, this.state.originalReferenceData);

            this.setState({
              exerciseReferenceMap: new Map(this.state.exerciseReferenceMap),
              selectedExercise: null,
              originalReferenceData: null,
            });
          }}
          text={addSaveAction ? 'Cancelar' : 'Fechar'}
          disabled={this.state.onUpdateExerciseReference}
        />

        {addSaveAction &&
          <DefaultMenuButton
            className="personal-record__overlay__action-button"
            onClick={() => this.setState({onUpdateExerciseReference: true})}
            text="Salvar"
            color="green"
            disabled={this.state.onUpdateExerciseReference}
          />
        }

      </React.Fragment>
    );
  }

  getExerciseReferenceData() {
    if (this.state.selectedExercise === null) {
      return null;
    }

    const maxPercentage = 100;
    const minPercentage = 50;
    const step = 5;

    const exerciseReference = this.state.exerciseReferenceMap.get(this.state.selectedExercise.id);

    const percentageGraduation = [];

    const resistanceEntries = [];
    const hypertrophyEntries = [];
    const strengthEntries = [];

    for(let percentage=maxPercentage; percentage >= minPercentage; percentage -= step) {
      if(percentage < 0) {
        break;
      }

      let prText = '';

      if(exerciseReference.value) {
        prText = exerciseReference.value * percentage * 0.01;

        prText *= 10;
        prText = Math.round(prText);
        prText = prText / 10;
      }

      const entry = (
        <div
          className="personal-record__overlay__reference__input-container"
          key={`personal_record:exercise_reference:${this.state.selectedExercise.id}:pr_output:${percentage}`}
        >

          <p className="personal-record__overlay__reference__pr-input-header">
            <span className="personal-record__overlay__reference__input-prefix--highlighted">{percentage.toFixed(0)}%</span>
          </p>

          <DefaultInput
            className="personal-record__overlay__reference__input"
            name={`exercise_reference:${this.state.selectedExercise.id}`}
            type="number"
            placeholder="-"
            min="0"
            step="0.1"
            value={prText}
            autoComplete="off"
            suffix="kg"
            onFocus={(event) => event.target.select()}
            disabled={true}
          />

        </div>
      );

      if (percentage < 60) {
        resistanceEntries.push(entry);
      }
      else if (percentage < 85) {
        hypertrophyEntries.push(entry);
      }
      else {
        strengthEntries.push(entry);
      }
    }

    if (strengthEntries.length > 0) {
      percentageGraduation.push(
        <div
          className="personal-record__overlay__reference__input-group-container"
          key={`class_board:exercise_reference:${this.state.selectedExercise.id}:pr_output_group:strength`}
        >

          <div className="personal-record__overlay__reference__input-group-container__title-wrapper--strength">

            <p className="personal-record__overlay__reference__input-group-container__title">Força</p>

          </div>

          <div className="personal-record__overlay__reference__percentages-wrapper">

            {strengthEntries}

          </div>

        </div>
      );
    }
    if (hypertrophyEntries.length > 0) {
      percentageGraduation.push(
        <div
          className="personal-record__overlay__reference__input-group-container"
          key={`class_board:exercise_reference:${this.state.selectedExercise.id}:pr_output_group:hypertrophy`}
        >

          <div className="personal-record__overlay__reference__input-group-container__title-wrapper--hypertrophy">

            <p className="personal-record__overlay__reference__input-group-container__title">Hipertrofia</p>

          </div>

          <div className="personal-record__overlay__reference__percentages-wrapper">

            {hypertrophyEntries}

          </div>

        </div>
      );
    }
    if (resistanceEntries.length > 0) {
      percentageGraduation.push(
        <div
          className="personal-record__overlay__reference__input-group-container"
          key={`class_board:exercise_reference:${this.state.selectedExercise.id}:pr_output_group:resistance`}
        >

          <div className="personal-record__overlay__reference__input-group-container__title-wrapper--resistance">

            <p className="personal-record__overlay__reference__input-group-container__title">Resistência</p>

          </div>

          <div className="personal-record__overlay__reference__percentages-wrapper">

            {resistanceEntries}

          </div>

        </div>
      );
    }

    let updatedAtText = null;

    if(exerciseReference.updated_at) {
      const today = getAsLocalDate((new Date()).toISOString().slice(0, 10));
      const updatedAt = getAsLocalDate(exerciseReference.updated_at.slice(0, 10));

      const timeDiff = Math.abs(today.getTime() - updatedAt.getTime());
      let daysCount = Math.ceil(timeDiff / (1000 * 3600 * 24));

      updatedAtText = `Salvo em ${updatedAt.toLocaleDateString()} (${daysCount} dia(s))`;
    }

    return (
      <React.Fragment>

        <div className="personal-record__overlay__reference__header">

          {updatedAtText !== null &&
            <p className="personal-record__overlay__reference__date-text">

              {updatedAtText}

            </p>
          }

        </div>

        <div className="personal-record__overlay__reference__content-wrapper">

          <div className="personal-record__overlay__reference">

            <div className="personal-record__overlay__reference-content">

              <div className="personal-record__overlay__reference__input-groups-wrapper">

                {percentageGraduation}

              </div>

            </div>

          </div>

          <div
            className="personal-record__overlay__reference"
          >

            <div className="personal-record__overlay__reference-content">

              <div className="personal-record__overlay__reference__input-container--horizontal">

                <div className="personal-record__overlay__reference__pr-input-wrapper">

                  <DefaultInput
                    className="personal-record__overlay__reference__input--extended"
                    name="weight"
                    type="number"
                    placeholder="-"
                    min="0"
                    step="0.1"
                    handleInputChange={(event) => this.handleReferenceChange(event)}
                    value={exerciseReference.weight || ''}
                    autoComplete="off"
                    suffix="kg"
                    prefix="Peso"
                    onFocus={(event) => event.target.select()}
                  />

                  <DefaultInput
                    className="personal-record__overlay__reference__input--extended"
                    name="repetition"
                    type="number"
                    placeholder="-"
                    min="0"
                    step="1"
                    handleInputChange={(event) => this.handleReferenceChange(event)}
                    value={exerciseReference.repetition || ''}
                    autoComplete="off"
                    prefix="Repetições"
                    onFocus={(event) => event.target.select()}
                  />

                </div>

                {(exerciseReference.repetition && exerciseReference.weight) &&
                  <React.Fragment>

                    <i className="fas fa-long-arrow-alt-right personal-record__overlay__reference__arrow-icon"></i>

                    <p className="personal-record__overlay__reference__pr-text">

                      (<b>100%</b> do PR: <b>{exerciseReference.value.toFixed(0)}</b> kg)

                    </p>

                  </React.Fragment>
                }

              </div>

            </div>

          </div>

        </div>

      </React.Fragment>
    );
  }

  render() {
    if(this.state.loading) {
      return (
        <PoseGroup>
          <FadeContainer key="preloader">
            <PreLoader />
          </FadeContainer>
        </PoseGroup>
      );
    }

    return (
      <DefaultMenuLayout
        showBackButton={true}
        history={this.props.history}
        onLogout={() => this.props.onLogout()}
        username={this.props.username}
      >

        <div className="personal-record">

          {this.getContentOptions()}

        </div>

        <OverlayWindow
          className="personal-record__overlay"
          visible={this.state.selectedExercise !== null}
          loading={false}
          actions={(
            <div className="personal-record__overlay__action-container">

              {this.getOverlayActions()}

            </div>
          )}
        >

          <header className="personal-record__overlay__header">

            <h3 className="personal-record__overlay__header__title">
              PR: {this.state.selectedExercise !== null ? this.state.selectedExercise.name : ''}
            </h3>

          </header>

          <hr className="personal-record__horizontal-rule" />

          <div className="personal-record__overlay__reference-container">

            {this.getExerciseReferenceData()}

          </div>

        </OverlayWindow>

        <ConfirmationWindow
          title={this.getConfirmationWindowTitle()}
          description={this.getConfirmationWindowDescription()}
          confirmText={this.getConfirmationWindowConfirmButtonText()}
          cancelText={this.state.confirmFailed ? 'Ok' : 'Cancelar'}
          visible={this.confirmationWindowIsVisible()}
          onCancel={() => this.resetConfirmationWindow()}
          onConfirm={() => this.proceedConfirmationWindow()}
          loading={this.state.confirmInProgress}
          useErrorIcon={this.state.confirmFailed}
          hideConfirmButton={this.state.confirmFailed}
        />

      </DefaultMenuLayout>
    );
  }
}

export default PersonalRecord;
