import React from 'react';
import './warning_message.scss';
import posed from 'react-pose';

const WarningContainer = posed.div({
  hidden: { height: 0 },
  visible: { height: 'auto' }
});

class WarningMessage extends React.Component {

  render() {
    const containerStyle = {};
    const textStyle = {};

    if(this.props.background) {
      containerStyle.background = this.props.background;
    }

    if(this.props.color) {
      textStyle.color = this.props.color;
    }

    return (
      <WarningContainer
        className="warning-message"
        style={containerStyle}
        pose={this.props.visible ? 'visible' : 'hidden'}
      >

        <p
          className="warning-message__message"
          style={textStyle}
        >

          {this.props.message}

        </p>

        <div className="warning-message__control-container">

          <button
            className="warning-message__close-button"
            style={textStyle}
            onClick={() => this.props.onClose()}
          >

            <i className="fas fa-times"></i>

          </button>

        </div>

      </WarningContainer>
    );
  }
}

export default WarningMessage;
