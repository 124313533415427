import React from 'react';
import { PoseGroup } from 'react-pose';
import './edit_custom_training.scss';
import {FadeContainer} from '../../utils/pose_containers';
import PreLoader from '../../utils/preloader';
import * as routes from '../../constants';
import {DEFAULT_UNKNOWN_ERROR_MESSAGE} from '../../constants';
import DefaultMenuLayout from '../../components/default_menu_layout';
import {getModel, getModels, patchModel} from '../../utils/functions';
import CustomTrainingData from './custom_training_data';
import DefaultMenuButton from '../../components/default_menu_button';

class EditTrainingData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      training: null,
      exercise_activities: [],
      highlights: [],
      warningMessage: "",
      showWarningMessage: false,
      supportTableVisible: false,
      screenWidth: window.innerWidth,
    };
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  async getCustomTraining() {
    return await getModel(`${routes.CUSTOM_TRAINING_GET_API}${this.props.match.params.customTrainingId}`);
  }

  async componentDidMount() {
    const update = {loading: false};

    let data = this.getCustomTraining();

    data = await data;

    if(data) {
      update.training = data;
    }

    if (update.training.training_day_id === null) {
      let exercise_activities = getModels(routes.EXERCISE_ACTIVITIES_GET_API);

      exercise_activities = await exercise_activities;

      if(exercise_activities) {
        update.exercise_activities = exercise_activities;
        update.exercise_activities.sort((a, b) => a.name.localeCompare(b.name))
      }
    }

    this.setState(update);

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  isHomeTraining() {
    if(!this.state.training) {
      return false;
    }

    return this.state.training.training_day_id !== null;
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;

    if(name === 'minutes') {
      const totalSeconds = this.state.training.total_seconds || 0;

      if(value !== null) {
        value = (parseInt(value)*60) + (totalSeconds % 60);
      }

      name = 'total_seconds';
    }
    else if(name === 'seconds') {
      const totalSeconds = this.state.training.total_seconds || 0;

      if(value !== null) {
        value = parseInt(value) + (Math.floor(totalSeconds / 60)*60);
      }

      name = 'total_seconds';
    }

    const newData = {...this.state.training, [name]: value};

    this.setState({
      training: newData
    });
  }

  onSetPse(value) {
    const newData = {...this.state.training, pse: value};

    this.setState({
      training: newData
    });
  }

  inputsAreValid() {
    return true;
  }

  async saveData() {
    let warningMessages = [];
    let highlights = [];

    if (this.isHomeTraining()) {
      if(this.state.training.exercise.length <= 0) {
        highlights.push('exercise');
        warningMessages.push('Nome do exercício obrigatório');
      }
    }
    else if(this.state.training.activity_reference_id === null || this.state.training.activity_reference_id.length <= 0) {
      highlights.push('activity_reference_id');
      warningMessages.push('Exercício obrigatório');
    }

    if(this.state.training.date.length <= 0) {
      highlights.push('date');
      warningMessages.push('Data obrigatório');
    }
    if(this.state.training.pse === null) {
      highlights.push('pse');
      warningMessages.push('Selecione o PSE');
    }
    if(!this.isHomeTraining() && this.state.training.total_time <= 0) {
      highlights.push('total_time');
      warningMessages.push('Preencha o tempo do total do exercício');
    }

    if(highlights.length > 0) {
      this.setState({
        highlights: highlights,
        showWarningMessage: true,
        warningMessage: `${warningMessages.join('; ')}.`,
      });

      return;
    }

    this.setState({
      highlights: [],
      showWarningMessage: false,
      loading: true
    });

    const data = {...this.state.training}
    data.total_time = parseFloat(data.total_time);
    data.difficulty = data.difficulty || null;
    data.pse = parseInt(data.pse);
    data.total_repetitions = parseInt(data.total_repetitions);
    data.total_seconds = parseFloat(data.total_seconds);

    if (!this.isHomeTraining()) {
      data.activity_reference_id = parseInt(data.activity_reference_id);
      data.exercise = this.state.exercise_activities.find((entry) => entry.id === data.activity_reference_id).name;
    }

    try {
      await patchModel(`${routes.CUSTOM_TRAINING_PATCH_API}${this.props.match.params.customTrainingId}`, data);
    }
    catch(errors) {
      warningMessages = [];
      highlights = [];

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            case 102:
              for(let parameter of error.parameters) {
                switch (parameter.name) {
                  case 'date':
                    warningMessages.push('Data inválida. Data cadastrada não pode ser nem no futuro e nem mais do que 28 dias atrás.');
                    highlights.push('date');

                    break;
                  default:
                }
              }

              break;
            case 209:
              warningMessages.push('Sessão do usuário expirada');

              break;
            default:
          }
        }
      }

      if(warningMessages.length <= 0) {
        warningMessages.push(DEFAULT_UNKNOWN_ERROR_MESSAGE);
      }

      this.setState({
        highlights: highlights,
        showWarningMessage: true,
        warningMessage: `${warningMessages.join('; ')}.`,
        loading: false
      });

      return;
    }

    this.props.history.goBack();
    // this.props.history.replace(routes.TRAININGS_PATH);
  }

  hasSupportTable() {
    if(!this.state.training || !this.state.training.training_day) {
      return false;
    }

    return this.state.training.training_day.group_associations.some((association) => association.has_support_table);
  }

  getSupportTables() {
    const filteredAssociations = this.state.training.training_day.group_associations.filter((association) => association.has_support_table);

    return filteredAssociations.map((association) => {
      const rows = association.support_table.map((row, index) => (
        <div
          className="edit-custom-training__support-table__table__row-wrapper"
          key={`training_day_group_association:${association.id}:support_table:row:${index}`}
        >

          <p className="edit-custom-training__support-table__table__cell">
            {row[0]}
          </p>

          <p className="edit-custom-training__support-table__table__cell">
            {row[1]}
          </p>

        </div>
      ));

      return (
        <div
          className="edit-custom-training__support-table__table"
          key={`training_day_group_association:${association.id}:support_table`}
        >

          <div className="edit-custom-training__support-table__table__header">

            <h3 className="edit-custom-training__support-table__table__title">
              {association.name}
            </h3>

          </div>

          <div className="edit-custom-training__support-table__table__row-wrapper">

            <p className="edit-custom-training__support-table__table__column-label">
              Nº Série
            </p>

            <p className="edit-custom-training__support-table__table__column-label">
              Repetições
            </p>

          </div>

          {rows}

        </div>
      );
    });
  }

  render() {
    if(this.state.loading) {
      return (
        <PoseGroup>
          <FadeContainer key="preloader">
            <PreLoader />
          </FadeContainer>
        </PoseGroup>
      );
    }

    return (
      <React.Fragment>

        <DefaultMenuLayout
          showBackButton={true}
          history={this.props.history}
          onLogout={() => this.props.onLogout()}
          username={this.props.username}
        >

          <div className="edit-custom-training">

            <section className="edit-custom-training__data">

              <header className="edit-custom-training__data__header">

              <h3 className="edit-custom-training__data__header__title">
                <i className="fas fa-edit"></i>
                {' '}Editar treino complementar
              </h3>

              </header>

              <div className="edit-custom-training__data__content">

                <CustomTrainingData
                  warningMessage={this.state.warningMessage}
                  showWarningMessage={this.state.showWarningMessage}
                  training={this.state.training}
                  screenWidth={this.state.screenWidth}
                  onSave={() => this.saveData()}
                  onCloseWarning={() => {this.setState({highlights: [], showWarningMessage: false})}}
                  enableSave={this.inputsAreValid()}
                  handleInputChange={(event) => this.handleInputChange(event)}
                  highlights={this.state.highlights}
                  onCancelPath={() => this.props.history.goBack()}
                  onSetPse={(value) => this.onSetPse(value)}
                  exercise_activities={this.state.exercise_activities}
                />

              </div>

            </section>

          </div>

        </DefaultMenuLayout>

        {this.hasSupportTable() &&
          <aside className={`edit-custom-training__support-table${this.state.supportTableVisible ? '--visible' : ''}`}>

            <div className="edit-custom-training__support-table__controls">

              <DefaultMenuButton
                className="edit-custom-training__support-table__toggle-button"
                onClick={() => this.setState({supportTableVisible: !this.state.supportTableVisible})}
                text={(<i className="fas fa-chevron-down"></i>)}
                color="red"
              />

            </div>

            <header className="edit-custom-training__support-table__header">

              <div className="edit-custom-training__support-table__header__title-wrapper">

                <h2 className="edit-custom-training__support-table__header__title">

                  Tabela auxiliar

                </h2>

              </div>

            </header>

            <div className="edit-custom-training__support-table__content">

              {this.getSupportTables()}

            </div>

          </aside>
        }

      </React.Fragment>
    );
  }
}

export default EditTrainingData;
