import React from 'react';
import { PoseGroup } from 'react-pose';
import {FadeContainer} from '../utils/pose_containers';
import PreLoader from '../utils/preloader';
import * as routes from '../constants';
import {DEFAULT_ERROR_MESSAGE,
        FORM_ANSWER_TYPE_LONG_TEXT_ID,
        FORM_ANSWER_TYPE_HORIZONTAL_SELECT_ID} from '../constants';
import {getModel, postModel, setUrlParameters} from '../utils/functions';
import DefaultMenuButton from '../components/default_menu_button';
import DefaultMenuLayout from '../components/default_menu_layout';
import ConfirmationWindow from '../components/confirmation_window';
import OverlayWindow from '../components/overlay_window';
import DefaultInput from '../utils/default_input';
import './main_menu.scss';


class MainMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      has_personal_training: false,
      has_food_prescription: false,
      pending_nutri_followup_form: false,
      is_missing_pse_on_trainings: false,
      has_access_to_functional_training: false,
      has_pending_nutritional_support_tickets: false,
      has_pending_personal_support_tickets: false,
      unread_nutritional_support_ticket_id: null,
      unread_personal_support_ticket_id: null,
      fyd_challenge_url: null,
      showMonthlySurveyForm: false,
      highlightRequiredFYDFormQuestions: false,
      FYDForm: null,
      FYDFormAnswerMap: new Map(),
      submittingFYDForm: false,
      FYDFormSubmissionFailed: false,
      FYDFormSubmitted: false,
      maySelectFeedbackUrl: false,
      submissionFailDescription: "",
      feedback_options: [],
      showLastTrainingPath: false,
      showNutriFollowupFormPath: false,
      showUnreadNutritionalSupportTicketPath: false,
      showUnreadPersonalSupportTicketPath: false,
      screenWidth: window.innerWidth,
    };

    this.monthDateFormat = new Intl.DateTimeFormat('pt-BR', {
      month: 'long',
    });

    this.FYDFormInputRefMap = new Map();
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  async getMenuStatus() {
    return await getModel(routes.MENU_STATUS_GET_API);
  }

  async componentDidMount() {
    const update = {loading: false};

    let menuStatus = await this.getMenuStatus();

    if(menuStatus) {
      update.has_personal_training = menuStatus.personal_training_is_active;
      update.has_food_prescription = menuStatus.food_prescription_is_active;
      update.is_missing_pse_on_trainings = menuStatus.missing_pse;
      update.pending_nutri_followup_form = menuStatus.pending_nutri_followup_form;
      update.has_access_to_functional_training = menuStatus.has_access_to_functional_training;
      update.has_pending_nutritional_support_tickets = menuStatus.has_pending_nutritional_support_tickets;
      update.has_pending_personal_support_tickets = menuStatus.has_pending_personal_support_tickets;
      update.unread_nutritional_support_ticket_id = menuStatus.unread_nutritional_support_ticket_id;
      update.unread_personal_support_ticket_id = menuStatus.unread_personal_support_ticket_id;
      update.fyd_challenge_url = menuStatus.fyd_challenge_url;
      update.FYDForm = menuStatus.monthly_satisfaction_survey_form;

      if (update.pending_nutri_followup_form) {
        update.showNutriFollowupFormPath = true;
      }
      else if (update.unread_nutritional_support_ticket_id !== null) {
        update.showUnreadNutritionalSupportTicketPath = true;
      }
      else if (update.unread_personal_support_ticket_id !== null) {
        update.showUnreadPersonalSupportTicketPath = true;
      }
      else if (update.FYDForm !== null) {
        update.showMonthlySurveyForm = true;

        update.FYDForm.questions.sort((a, b) => a.order - b.order);

        update.FYDFormAnswerMap = new Map();

        for (const question of update.FYDForm.questions) {
          update.FYDFormAnswerMap.set(question.id, null);
          this.FYDFormInputRefMap.set(question.id, null);
        }
      }
    }

    this.setState(update);

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;

    if(name.startsWith('fyd_form:question:')) {
      const selection = name.split(':');
      const questionId = parseInt(selection[2]);

      const FYDFormAnswerMap = new Map(this.state.FYDFormAnswerMap);

      FYDFormAnswerMap.set(questionId, value);

      this.setState({
        FYDFormAnswerMap
      });
    }
    else {
      this.setState({
        [name]: value
      });
    }
  }

  onSetFYDFormAnswer(questionId, answer) {
    const FYDFormAnswerMap = new Map(this.state.FYDFormAnswerMap);

    FYDFormAnswerMap.set(questionId, answer);

    this.setState({
      FYDFormAnswerMap
    });
  }

  getWarningPopupTitle() {
    if (this.state.showLastTrainingPath) {
      return "Treinos recentes sem Percepção Subjetiva de Esforço (PSE)";
    }
    else if (this.state.showNutriFollowupFormPath) {
      return "Formulário de acompanhamento NUTRICIONAL não preenchido";
    }
    else if (this.state.showUnreadNutritionalSupportTicketPath || this.state.showUnreadPersonalSupportTicketPath) {
      return "Você recebeu uma nova mensagem";
    }
    else if (this.state.FYDFormSubmissionFailed) {
      return "Falha ao enviar dados";
    }
  }

  getWarningPopupDescription() {
    if (this.state.showLastTrainingPath) {
      return "Para liberar o checkin, preencha a PSE de seus treinos recentes clicando no botão abaixo. A PSE faz parte de nossa metodologia e é fundamental para que possamos lhe oferecer um serviço personalizado e de maior qualidade.";
    }
    else if (this.state.showNutriFollowupFormPath) {
      return "Para liberar o uso do aplicativo, realize o preenchimento do formulário de acompanhamento nutricional clicando no botão abaixo. (Não leva nem 1 minuto!)";
    }
    else if (this.state.showUnreadNutritionalSupportTicketPath || this.state.showUnreadPersonalSupportTicketPath) {
      return 'Você possui mensagens não lidas de nossa equipe. Clique no botão "Ver mensagem" para visualizá-las.';
    }
    else if (this.state.FYDFormSubmissionFailed) {
      return this.state.submissionFailDescription;
    }
  }

  getWarningPopupConfirmText() {
    if (this.state.showLastTrainingPath) {
      return "Meus treinos";
    }
    else if (this.state.showNutriFollowupFormPath) {
      return "Preencher formulário";
    }
    else if (this.state.showUnreadNutritionalSupportTicketPath || this.state.showUnreadPersonalSupportTicketPath) {
      return "Ver mensagem";
    }
  }

  onConfirmWarningPopup() {
    if (this.state.showLastTrainingPath) {
      this.props.history.push(routes.TRAININGS_PATH);
    }
    else if (this.state.showNutriFollowupFormPath) {
      this.props.history.push(routes.FOOD_PRESCRIPTION_PATH);
    }
    else if (this.state.showUnreadNutritionalSupportTicketPath) {
      const parameters = {support_ticket_id: this.state.unread_nutritional_support_ticket_id};

      this.props.history.push(setUrlParameters(routes.NUTRITIONAL_SUPPORT_PATH, parameters));
    }
    else if (this.state.showUnreadPersonalSupportTicketPath) {
      const parameters = {support_ticket_id: this.state.unread_personal_support_ticket_id};

      this.props.history.push(setUrlParameters(routes.PERSONAL_TRAINING_SUPPORT_PATH, parameters));
    }
  }

  onCancelWarningPopup() {
    this.setState({
      showUnreadNutritionalSupportTicketPath: false,
      showUnreadPersonalSupportTicketPath: false,
      FYDFormSubmissionFailed: false
    });
  }

  async onSubmitFYDForm() {
    if (this.state.FYDForm.questions.some((question) => {
      const requerimentNotFilled = question.is_required && this.state.FYDFormAnswerMap.get(question.id) === null;

      if (requerimentNotFilled) {
        this.FYDFormInputRefMap.get(question.id).scrollIntoView();
      }

      return requerimentNotFilled;
    })) {
      this.setState({highlightRequiredFYDFormQuestions: true});
      return;
    }

    const data = {
      answers: [],
    };

    for (const [key, value] of this.state.FYDFormAnswerMap) {
      data.answers.push({
        answer: value,
        fyd_form_question_id: key
      });
    }

    this.setState({
      submittingFYDForm: true,
    });

    try{
      const response = await postModel(routes.MONTHLY_SATISFACTION_SURVEY_POST_API, data, true);

      if(response) {
        this.setState({
          feedback_options: response.feedback_options,
          submittingFYDForm: false,
          FYDFormSubmissionFailed: false,
          FYDFormSubmitted: true
        });
      }
    }
    catch(errors) {
      let errorDescription = DEFAULT_ERROR_MESSAGE + '.';

      if(errors instanceof Array) {
        for(let error of errors) {
          switch (error.code) {
            // case 104:
            //   for(let parameter of error.parameters) {
            //     switch (parameter.name) {
            //       case 'contracts':
            //         errorDescription = 'Serviço vinculado à um contrato de aluno. Estes contratos devem ser excluídos antes de excluir este serviço.';
            //
            //         break;
            //       default:
            //     }
            //   }
            //
            //   break;
            case 201:
              errorDescription = 'Limite de registros de resposta alcançado. Por favor, tente novamente dentro de alguns minutos.';

              break;
            case 209:
              errorDescription = 'Sessão do usuário expirada.';

              break;
            default:
          }
        }
      }

      this.setState({
        submissionFailDescription: errorDescription,
        FYDFormSubmissionFailed: true,
        submittingFYDForm: false
      });

      return;
    }
  }

  getOverlayTitle() {
    if (this.state.showMonthlySurveyForm) {
      if (this.state.submittingFYDForm) {
        return (<React.Fragment>
          <span className="main-menu__overlay__header__title--red">Enviando</span> dados...
        </React.Fragment>);
      }
      if (this.state.FYDFormSubmitted) {
        return (<React.Fragment>
          Dados enviados. <span className="main-menu__overlay__header__title--red">Obrigado!</span>
        </React.Fragment>);
      }

      const currentDate = new Date();
      currentDate.setDate(1);
      currentDate.setMonth(currentDate.getMonth() - 1);

      return (<React.Fragment>
        Pesquisa de satisfação sobre o mês de <span className="main-menu__overlay__header__title--red">{this.monthDateFormat.format(currentDate)}</span>
      </React.Fragment>);
    }

    return "";
  }

  getFormQuestion(question) {
    const highligthRequirement = this.state.highlightRequiredFYDFormQuestions && question.is_required && this.state.FYDFormAnswerMap.get(question.id) === null;

    switch (question.fyd_form_answer_type_id) {
      case FORM_ANSWER_TYPE_HORIZONTAL_SELECT_ID:
        if (question.answer_options === null) {
          return (
            <p
              key={`fyd_form:question:${question.id}`}
              className="main-menu__alert-text"
            >

              <i className="fas fa-exclamation main-menu__alert-text__icon"></i>
              OPÇÕES DE PERGUNTA NÃO CONFIGURADAS CORRETAMENTE

            </p>
          );
        }

        const defaultCharacterWidth = 1.1;

        let answerOptions = JSON.parse(question.answer_options);

        const minOptionWidth = defaultCharacterWidth * Math.max(...answerOptions.map((option) => option.value.length));

        answerOptions = answerOptions.map((option, index) => {
          const selected = this.state.FYDFormAnswerMap.get(question.id) === option.value;

          let itemStyle = {
            borderColor: option.color,
          };

          if (selected) {
            itemStyle.background = option.color;
          }
          else {
            itemStyle.color = option.color;
          }

          return (
            <li
              key={`fyd_form:question:${question.id}:answer_option:${index}`}
              className="main-menu__fyd-form__question__answer-options__item"
              style={{minWidth: `${minOptionWidth}em`}}
            >

              <button
                className="main-menu__fyd-form__question__answer-options__item__button"
                onClick={() => this.onSetFYDFormAnswer(question.id, option.value)}
                disabled={selected}
                style={itemStyle}
              >

                <p className="main-menu__fyd-form__question__answer-options__item__value">
                  {option.value}
                </p>

              </button>

            </li>
          );
        });

        return (
          <div
            key={`fyd_form:question:${question.id}`}
            className={`main-menu__fyd-form__question${highligthRequirement ? '--required' : ''}`}
            ref={(element) => {
              if (element == null) {
                this.FYDFormInputRefMap.set(question.id, null);
              }
              else {
                this.FYDFormInputRefMap.set(question.id, element);
              }
            }}
          >
            <div className="main-menu__fyd-form__question__content-wrapper">

              <h4 className="main-menu__fyd-form__question__label">{question.question_title}</h4>

              <ul
                className="main-menu__fyd-form__question__answer-options"
              >

                {answerOptions}

              </ul>

            </div>

            {highligthRequirement && (
              <div className="main-menu__fyd-form__question__required-warning">

                <i className="fa-solid fa-circle-exclamation main-menu__fyd-form__question__required-warning__icon"></i>
                <p className="main-menu__fyd-form__question__required-warning__text">
                  Preenchimento obrigatório
                </p>

              </div>
            )}

          </div>
        );
      case FORM_ANSWER_TYPE_LONG_TEXT_ID:
        return (
          <div
            key={`fyd_form:question:${question.id}`}
            className={`main-menu__fyd-form__question${highligthRequirement ? '--required' : ''}`}
            ref={(element) => {
              if (element == null) {
                this.FYDFormInputRefMap.set(question.id, null);
              }
              else {
                this.FYDFormInputRefMap.set(question.id, element);
              }
            }}
          >

            <div className="main-menu__fyd-form__question__content-wrapper">

              <h4 className="main-menu__fyd-form__question__label">{question.question_title}</h4>

              <DefaultInput
                className="main-menu__fyd-form__question__long-text-input"
                name={`fyd_form:question:${question.id}`}
                type="textarea"
                placeholder={question.answer_tip}
                rows="4"
                handleInputChange={(event) => this.handleInputChange(event)}
                value={this.state.FYDFormAnswerMap.get(question.id) || ''}
                autoComplete="off"
                onFocus={(event) => event.target.select()}
              />

            </div>

          </div>
        );
      default:
        return (
          <p
            key={`fyd_form:question:${question.id}`}
            className="main-menu__alert-text"
          >

            <i className="fas fa-exclamation main-menu__alert-text__icon"></i>
            PERGUNTA NÃO CONFIGURADA CORRETAMENTE

          </p>
        );
    }
  }

  onSelectFeebackUrl(url=null) {
    if (url !== null) {
      window.open(url, '_blank');

      this.setState({showMonthlySurveyForm: false});
      return;
    }

    if (this.state.feedback_options.length <= 0) {
      return;
    }

    if (this.state.feedback_options.length > 1) {
      this.setState({maySelectFeedbackUrl: true});
    }

    window.open(this.state.feedback_options[0].url, '_blank');
    this.setState({showMonthlySurveyForm: true});
  }

  getOverlayContent() {
    if (!this.state.showMonthlySurveyForm) {
      return null;
    }

    if (this.state.showMonthlySurveyForm) {
      if (this.state.submittingFYDForm) {
        return (
          <div className="main-menu__overlay__loading-icon">

          </div>
        );
      }
      if (this.state.FYDFormSubmitted) {
        if (this.state.maySelectFeedbackUrl) {
          return (
            <React.Fragment>

              <p className="main-menu__overlay__message__text">

                Por favor, selecione a unidade que deseja enviar sua crítica ou elogio:

              </p>

              {this.state.feedback_options.map(entry => (
                <DefaultMenuButton
                  key={`feedback_url:${entry.name}`}
                  className="main-menu__overlay__message__button"
                  onClick={() => this.onSelectFeebackUrl(entry.url)}
                  text={entry.name}
                  color="blue"
                />
              ))}

            </React.Fragment>
          );
        }

        return (
          <React.Fragment>

            <p className="main-menu__overlay__message__text">

              Caso queira acrescentar alguma crítica ou elogio, sinta-se à vontade de fazê-la pelo botão abaixo:

            </p>

            <DefaultMenuButton
              className="main-menu__overlay__message__button"
              onClick={() => this.onSelectFeebackUrl()}
              text="Crítica ou elogio"
              color="purple"
            />

          </React.Fragment>
        );
      }

      return (
        <React.Fragment>

          {this.state.FYDForm.user_note !== null &&
            <p className="main-menu__fyd-form__user-note">{this.state.FYDForm.user_note}</p>
          }

          {this.state.FYDForm.questions.map((question) => this.getFormQuestion(question))}

        </React.Fragment>
      );
    }
  }

  getOverlayActionButtons() {
    if (!this.state.showMonthlySurveyForm) {
      return null;
    }

    if (this.state.showMonthlySurveyForm && !this.state.FYDFormSubmitted) {
          return (
            <DefaultMenuButton
              className="main-menu__overlay__action-button"
              onClick={() => this.onSubmitFYDForm()}
              text={this.state.submittingFYDForm ? 'Enviando' : 'Enviar'}
              color="green"
              disabled={this.state.submittingFYDForm}
            />
          );
    }

    return (
      <DefaultMenuButton
        className="main-menu__overlay__action-button"
        onClick={() => this.setState({showMonthlySurveyForm: false})}
        text="Fechar"
        color="black"
      />
    );
  }

  render() {
    if(this.state.loading) {
      return (
        <PoseGroup>
          <FadeContainer key="preloader">
            <PreLoader />
          </FadeContainer>
        </PoseGroup>
      );
    }

    return (
      <DefaultMenuLayout
        onLogout={() => this.props.onLogout()}
        username={this.props.username}
      >

        <div className="main-menu">

          <div className="main-menu__link-wrapper trainings-menu-option">

            <DefaultMenuButton
              className="main-menu__button"
              linkTo={routes.TRAININGS_PATH}
              text="Meus treinos"
              color="purple"
            >
              {this.state.is_missing_pse_on_trainings &&
                <i className="fas fa-exclamation-circle main-menu__button__icon"></i>
              }
            </DefaultMenuButton>

          </div>

          <div className="main-menu__link-wrapper checkin-menu-option">

            {this.state.is_missing_pse_on_trainings ?
              <DefaultMenuButton
                className="main-menu__button"
                onClick={() => this.setState({showLastTrainingPath: true})}
                text="Check-in"
                color="blue"
              />:
              <DefaultMenuButton
                className="main-menu__button"
                linkTo={routes.CHECKIN_PATH}
                text="Check-in"
                color="blue"
              />
            }

          </div>

          <div className="main-menu__link-wrapper home-training-menu-option">

            <DefaultMenuButton
              className="main-menu__button"
              linkTo={routes.HOME_TRAINING_PATH}
              text="Home training"
              color="green"
            />

          </div>

          {this.state.has_personal_training &&
            <div className="main-menu__link-wrapper personal-training-menu-option">

              <DefaultMenuButton
                className="main-menu__button"
                linkTo={routes.PERSONAL_TRAINING_PATH}
                text="Personal training"
                color="red"
              >
                {(this.state.has_pending_personal_support_tickets || this.state.unread_personal_support_ticket_id !== null) &&
                  <i className="fas fa-exclamation-circle main-menu__button__icon"></i>
                }
              </DefaultMenuButton>

            </div>
          }

          {this.state.has_food_prescription &&
            <div className="main-menu__link-wrapper food-prescription-menu-option">

              <DefaultMenuButton
                className="main-menu__button"
                linkTo={routes.FOOD_PRESCRIPTION_PATH}
                text="Nutrição"
                color="yellow"
              >
                {(this.state.pending_nutri_followup_form || this.state.has_pending_nutritional_support_tickets || this.state.unread_nutritional_support_ticket_id !== null) &&
                  <i className="fas fa-exclamation-circle main-menu__button__icon"></i>
                }
              </DefaultMenuButton>

            </div>
          }

          {this.state.has_access_to_functional_training &&
            <div className="main-menu__link-wrapper">

              <DefaultMenuButton
                className="main-menu__button"
                linkTo={routes.PERSONAL_RECORD_PATH}
                text="Personal record"
                color="black"
              />

            </div>
          }

          {this.state.fyd_challenge_url !== null &&
            <div className="main-menu__link-wrapper">

              <DefaultMenuButton
                className="main-menu__button"
                href={`https://portfolio.ninelab.com.br/mtr/desafio/?hash=${this.state.fyd_challenge_url}`}
                rel="noreferrer noopener"
                target="_blank"
                text="Desafio"
                color="dark"
              />

            </div>
          }

        </div>

        <OverlayWindow
          className="main-menu__overlay"
          visible={this.state.showMonthlySurveyForm}
          actions={(this.state.FYDForm !== null) ? (
            <div className="main-menu__overlay__action-container">

              {this.getOverlayActionButtons()}

            </div>
          ) : null}
        >

          <header className="main-menu__overlay__header">

            <h3 className="main-menu__overlay__header__title">
              {this.getOverlayTitle()}
            </h3>

          </header>

          <hr className="main-menu__horizontal-rule" />

          <div
            className="main-menu__overlay__content-container"
          >

            {this.getOverlayContent()}

          </div>

        </OverlayWindow>

        <ConfirmationWindow
          className="main-menu__warning-pop-up"
          title={this.getWarningPopupTitle()}
          description={this.getWarningPopupDescription()}
          confirmText={this.getWarningPopupConfirmText()}
          cancelText={this.state.FYDFormSubmissionFailed ? 'Ok' : 'Cancelar'}
          hideCancelButton={!this.state.showUnreadNutritionalSupportTicketPath &&
                            !this.state.showUnreadPersonalSupportTicketPath &&
                            !this.state.FYDFormSubmissionFailed}
          hideConfirmButton={this.state.FYDFormSubmissionFailed}
          onConfirm={() => this.onConfirmWarningPopup()}
          onCancel={() => this.onCancelWarningPopup()}
          visible={this.state.showLastTrainingPath ||
                   this.state.showNutriFollowupFormPath ||
                   this.state.showUnreadNutritionalSupportTicketPath ||
                   this.state.showUnreadPersonalSupportTicketPath ||
                   this.state.FYDFormSubmissionFailed}
        />

      </DefaultMenuLayout>
    );
  }
}

export default MainMenu;
