import React from 'react';
import { Link } from 'react-router-dom';
import './default_menu_button.scss';

function DefaultMenuButton(props) {
  if (props.linkTo) {
    return (
      <Link
        className={`default-menu-button--${props.color || 'black'} ${props.className}`}
        to={props.linkTo}
        target={props.target}
        rel={props.rel}
        replace={props.replace}
      >

        <p className="default-menu-button__outset-text">
          {props.text}
        </p>

        {props.children}

      </Link>
    );
  }
  else if (props.href) {
    return (
      <a
        className={`default-menu-button--${props.color || 'black'} ${props.className}`}
        href={props.href}
        target={props.target}
        rel={props.rel}
      >

        <p className="default-menu-button__outset-text">
          {props.text}
        </p>

        {props.children}

      </a>
    );
  }

  return (
    <button
      className={`default-menu-button--${props.color || 'black'} ${props.className}`}
      onClick={() => props.onClick()}
      disabled={props.disabled}
    >

      <p className="default-menu-button__outset-text">
        {props.text}
      </p>

      {props.children}

    </button>
  );
}

export default DefaultMenuButton;
